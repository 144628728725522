import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaTrash, FaTrashRestore, FaShoppingCart } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const ManageOnlineShops = () => {
    const { user } = useAuth();
    const [shops, setShops] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);

    useEffect(() => {
        const fetchShops = async () => {
            setIsLoading(true);
            try {
                const endpoint = showDeleted
                    ? `${process.env.REACT_APP_BACKEND_URL}/api/online-shops/deleted`
                    : `${process.env.REACT_APP_BACKEND_URL}/api/online-shops`;

                const response = await fetch(endpoint, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setShops(data || []);
                } else {
                    throw new Error('Error al carregar les botigues');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchShops();
    }, [showDeleted]);

    const handleDelete = async (shopId) => {
        if (window.confirm('Estàs segur que vols eliminar aquesta botiga?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${shopId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.ok) {
                    setShops(shops.filter(shop => shop.id !== shopId));
                    alert('Botiga eliminada correctament');
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error al eliminar la botiga');
                }
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Administrar Botigues Online</h1>
                    <div className="flex gap-4">
                        <button
                            onClick={() => setShowDeleted(!showDeleted)}
                            className={`${
                                showDeleted 
                                    ? 'bg-red-500 hover:bg-red-600' 
                                    : 'bg-gray-500 hover:bg-gray-600'
                            } text-white px-4 py-2 rounded transition duration-200 flex items-center`}
                        >
                            <FaTrashRestore className="mr-2" />
                            {showDeleted ? 'Ocultar eliminades' : 'Mostrar eliminades'}
                        </button>
                        <Link
                            to="/cobraments"
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                        >
                            <FaArrowLeft className="mr-2" /> Tornar
                        </Link>
                    </div>
                </div>

                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="text-red-500 text-center py-4">{error}</div>
                ) : (
                    <div className="bg-white rounded-lg shadow overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nom
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Passarel·la
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Email de contacte
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Estat
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Data creació
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Accions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {shops.map((shop) => (
                                    <tr key={shop.id} className={shop.deleted_at ? 'bg-gray-50' : ''}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {shop.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {shop.payment_gateway?.name || 'No especificada'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {shop.configuration?.contact_email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                shop.status === 'active' ? 'bg-green-100 text-green-800' : 
                                                'bg-red-100 text-red-800'
                                            }`}>
                                                {shop.status === 'active' ? 'Activa' : 'Inactiva'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {new Date(shop.created_at).toLocaleDateString('ca-ES')}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <div className="flex justify-end space-x-2">
                                                <button 
                                                    onClick={() => window.location.href = `/online-shops/${shop.id}`}
                                                    className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                    title="Veure detalls"
                                                >
                                                    <FaEye />
                                                </button>
                                                
                                                <button 
                                                    onClick={() => window.location.href = `/online-shops/${shop.id}/orders`}
                                                    className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors duration-200"
                                                    title="Gestionar comandes"
                                                >
                                                    <FaShoppingCart />
                                                </button>
                                                
                                                {!showDeleted && (
                                                    <button 
                                                        onClick={() => handleDelete(shop.id)}
                                                        className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                        title="Eliminar"
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {shops.length === 0 && (
                                    <tr>
                                        <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                            {showDeleted 
                                                ? 'No s\'han trobat botigues eliminades'
                                                : 'No s\'han trobat botigues'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default ManageOnlineShops; 