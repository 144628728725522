import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaTrash, FaFilePdf, FaTrashRestore } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const FilterSection = ({ searchTerm, setSearchTerm, statusFilter, setStatusFilter, dateFilter, setDateFilter, totalResults }) => {
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Cerca per nom, client, import..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
          />
        </div>

        <div>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
          >
            <option value="all">Tots els estats</option>
            <option value="active">Actiu</option>
            <option value="paused">Pausat</option>
            <option value="inactive">Inactiu</option>
          </select>
        </div>

        <div>
          <select
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
          >
            <option value="all">Totes les dates</option>
            <option value="30days">Últims 30 dies</option>
            <option value="90days">Últims 90 dies</option>
          </select>
        </div>

        <div className="flex items-center justify-end text-gray-600">
          {totalResults} resultats trobats
        </div>
      </div>
    </div>
  );
};

const ManageRecurringPayments = () => {
  const { user, loading: authLoading } = useAuth();
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleted, setShowDeleted] = useState(false);
  
  // Estados para los filtros
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');

  useEffect(() => {
    if (!authLoading) {
      fetchPayments();
    }
  }, [showDeleted, authLoading]);

  useEffect(() => {
    filterPayments();
  }, [payments, searchTerm, statusFilter, dateFilter]);

  const filterPayments = () => {
    let filtered = [...payments];

    if (searchTerm) {
      filtered = filtered.filter(payment => 
        payment.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.customer?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.customer?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.amount.toString().includes(searchTerm)
      );
    }

    if (statusFilter !== 'all') {
      filtered = filtered.filter(payment => payment.status === statusFilter);
    }

    if (dateFilter !== 'all') {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
      const ninetyDaysAgo = new Date(now.setDate(now.getDate() - 90));

      filtered = filtered.filter(payment => {
        const paymentDate = new Date(payment.created_at);
        switch (dateFilter) {
          case '30days':
            return paymentDate >= thirtyDaysAgo;
          case '90days':
            return paymentDate >= ninetyDaysAgo;
          default:
            return true;
        }
      });
    }

    setFilteredPayments(filtered);
  };

  const fetchPayments = async () => {
    try {
      const endpoint = showDeleted 
        ? `${process.env.REACT_APP_BACKEND_URL}/api/payments/deleted?payment_type=cobrament-recurrent`
        : `${process.env.REACT_APP_BACKEND_URL}/api/payments?payment_type=cobrament-recurrent`;
      
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setPayments(data || []);
      } else {
        throw new Error('Error al carregar els cobraments');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error fetching payments:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (paymentId) => {
    if (window.confirm('Estàs segur que vols eliminar aquest cobrament recurrent?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${paymentId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          setPayments(payments.filter(payment => payment.id !== paymentId));
          alert('Cobrament recurrent eliminat correctament');
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al eliminar el cobrament');
        }
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    }
  };

  const handleRestore = async (paymentId) => {
    if (window.confirm('Estàs segur que vols restaurar aquest cobrament recurrent?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${paymentId}/restore`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          setPayments(payments.filter(payment => payment.id !== paymentId));
          alert('Cobrament recurrent restaurat correctament');
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al restaurar el cobrament');
        }
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    }
  };

  const viewDocument = (documentPath) => {
    const filename = documentPath.split('/').pop();
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/documents/${filename}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(response => {
        if (!response.ok) throw new Error('Error al obtener el documento');
        return response.blob();
    })
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    })
    .catch(error => {
        console.error('Error:', error);
        alert('Error al obtener el documento');
    });
  };

  const getFrequencyText = (frequency) => {
    const frequencies = {
      'daily': 'Diari',
      'weekly': 'Setmanal',
      'bi-weekly': 'Quinzenal',
      'monthly': 'Mensual',
      'bi-monthly': 'Bimensual',
      'quarterly': 'Trimestral',
      'semi-annually': 'Semestral',
      'annually': 'Anual'
    };
    return frequencies[frequency] || frequency;
  };

  if (isLoading || authLoading) {
    return <LoadingSpinner message="Carregant pagaments recurrents..." />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-8 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Administrar Cobraments Recurrents</h1>
          <div className="flex gap-4">
            <button
              onClick={() => setShowDeleted(!showDeleted)}
              className={`${
                showDeleted 
                  ? 'bg-red-500 hover:bg-red-600' 
                  : 'bg-gray-500 hover:bg-gray-600'
              } text-white px-4 py-2 rounded transition duration-200 flex items-center`}
            >
              <FaTrashRestore className="mr-2" />
              {showDeleted ? 'Ocultar eliminats' : 'Mostrar eliminats'}
            </button>
            <Link
              to="/cobraments"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
            >
              <FaArrowLeft className="mr-2" /> Tornar
            </Link>
          </div>
        </div>

        {error ? (
          <div className="bg-white rounded-lg shadow p-8">
            <div className="text-center">
              <div className="text-red-500 text-xl font-semibold mb-2">
                Error al carregar els pagaments
              </div>
              <p className="text-gray-600">{error}</p>
            </div>
          </div>
        ) : (
          <>
            <FilterSection 
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              totalResults={filteredPayments.length}
            />
            <div className="bg-white rounded-lg shadow overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nom
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Destinatari
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Import
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Freqüència
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Finalització
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estat
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Accions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredPayments.map((payment) => (
                    <tr key={payment.id} className={payment.deleted_at ? 'bg-gray-50' : ''}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {payment.name}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-col">
                          {payment.customer && (
                            <>
                              {payment.customer.name && (
                                <span className="font-medium">{payment.customer.name}</span>
                              )}
                              <span className="text-gray-600">{payment.customer.email}</span>
                              {payment.customer.phone && (
                                <span className="text-gray-500 text-sm">{payment.customer.phone}</span>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {payment.amount}€
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {getFrequencyText(payment.configuration?.frequency)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {payment.configuration?.end_date ? (
                          `Data: ${new Date(payment.configuration.end_date).toLocaleDateString('ca-ES')}`
                        ) : payment.configuration?.until_amount_completed ? (
                          `Import: ${payment.configuration.until_amount_completed}€`
                        ) : (
                          'No especificat'
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          payment.status === 'active' ? 'bg-green-100 text-green-800' : 
                          payment.status === 'paused' ? 'bg-yellow-100 text-yellow-800' : 
                          'bg-red-100 text-red-800'
                        }`}>
                          {payment.status === 'active' ? 'Actiu' :
                           payment.status === 'paused' ? 'Pausat' :
                           'Inactiu'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex justify-end space-x-2">
                          <button 
                            onClick={() => window.location.href = `/payments/${payment.id}`}
                            className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                            title="Veure detalls"
                          >
                            <FaEye />
                          </button>
                          
                          {showDeleted ? (
                            <button 
                              onClick={() => handleRestore(payment.id)}
                              className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors duration-200"
                              title="Restaurar"
                            >
                              <FaTrashRestore />
                            </button>
                          ) : (
                            <>
                              {payment.configuration?.document_path && (
                                <button
                                  onClick={() => viewDocument(payment.configuration.document_path)}
                                  className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                  title="Veure document"
                                >
                                  <FaFilePdf />
                                </button>
                              )}
                              
                              {!payment.deleted_at && (
                                <button 
                                  onClick={() => handleDelete(payment.id)}
                                  className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                  title="Eliminar"
                                >
                                  <FaTrash />
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {filteredPayments.length === 0 && (
                    <tr>
                      <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                        {showDeleted 
                          ? 'No s\'han trobat cobraments recurrents eliminats'
                          : 'No s\'han trobat cobraments recurrents'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ManageRecurringPayments; 