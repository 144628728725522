import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const CreateCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCategories, setIsLoadingCategories] = useState(true);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        parent_id: '',
        order: 0,
        online_shop_id: id
    });

    useEffect(() => {
        if (!id) {
            navigate('/online-shops');
            return;
        }
        fetchCategories();
    }, [id]);

    const fetchCategories = async () => {
        try {
            setIsLoadingCategories(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al carregar les categories');
            
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoadingCategories(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            if (!id) {
                throw new Error('ID de botiga no vàlid');
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    online_shop_id: id
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al crear la categoria');
            }

            alert('Categoria creada correctament');
            navigate(`/online-shops/${id}`);

        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (!id) {
        return <div>Error: No s'ha trobat la botiga</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8">
                {isLoadingCategories ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar les categories
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : (
                    <>
                        {/* Capçalera */}
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">Crear nova categoria</h1>
                            <Link
                                to={`/online-shops/${id}`}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                            >
                                <FaArrowLeft className="mr-2" /> Tornar
                            </Link>
                        </div>

                        {/* Formulari */}
                        <div className="bg-white rounded-lg shadow p-6">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                {error && (
                                    <div className="bg-red-50 text-red-500 p-4 rounded-lg mb-6">
                                        {error}
                                    </div>
                                )}

                                {/* Nom de la categoria */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Nom de la categoria *
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        value={formData.name}
                                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        placeholder="Introdueix el nom de la categoria"
                                    />
                                </div>

                                {/* Descripció */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Descripció
                                    </label>
                                    <textarea
                                        value={formData.description}
                                        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        rows="3"
                                        placeholder="Descripció opcional de la categoria"
                                    />
                                </div>

                                {/* Categoria pare */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Categoria pare
                                    </label>
                                    <select
                                        value={formData.parent_id}
                                        onChange={(e) => setFormData(prev => ({ ...prev, parent_id: e.target.value }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    >
                                        <option value="">Sense categoria pare</option>
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Opcional. Selecciona si aquesta categoria és una subcategoria.
                                    </p>
                                </div>

                                {/* Ordre */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Ordre
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        value={formData.order}
                                        onChange={(e) => setFormData(prev => ({ ...prev, order: parseInt(e.target.value) }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                    <p className="mt-1 text-sm text-gray-500">
                                        Determina l'ordre d'aparició de la categoria. Les categories s'ordenaran de menor a major.
                                    </p>
                                </div>

                                {/* Botons */}
                                <div className="flex justify-end space-x-3">
                                    <Link
                                        to={`/online-shops/${id}`}
                                        className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                                    >
                                        Cancel·lar
                                    </Link>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64A41] disabled:opacity-50"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Creant...' : 'Crear categoria'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default CreateCategory; 