import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from './common/LoadingSpinner';

const Payments = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner message="Carregant cobraments..." />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Cobraments</h1>
          <Link
            to="/dashboard"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Tornar
          </Link>
        </div>

        {/* Tipus de Cobraments */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <PaymentCard 
            title="Cobraments Únics"
            description="Enviament d'un cobrament puntual a una o varies persones"
            createLink="/payments/create/unic"
            manageLink="/payments/manage/unic"
          />

          <PaymentCard 
            title="Cobraments Recurrents"
            description="Cobraments periòdics automatitzats"
            createLink="/payments/create/recurrent"
            manageLink="/payments/manage/recurrent"
          />

          <PaymentCard 
            title="Cobraments Oberts"
            description="Permet als clients decidir l'import"
            createLink="/payments/create/obert"
            manageLink="/payments/manage/obert"
          />

          <PaymentCard 
            title="Enllaç/QR/Codi barres"
            description="Genera enllaços i codis per compartir"
            createLink="/payments/create/link"
            singleButton
          />

          <PaymentCard 
            title="Formulari"
            description="Crea formularis personalitzats de pagament"
            createLink="/payments/create/formulari"
            manageLink="/payments/forms"
          />

          <PaymentCard 
            title="Botiga Online"
            description="Configura la teva botiga virtual"
            createLink="/online-shops/create"
            manageLink="/online-shops"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

// Componente para las tarjetas de pagos
const PaymentCard = ({ title, description, createLink, manageLink, singleButton }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
    <div className="flex-grow">
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
    </div>
    <div className="mt-auto">
      <hr className="my-4 border-gray-200" />
      <div className="flex space-x-4">
        {singleButton ? (
          <Link 
            to={createLink}
            className="flex-1 text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
          >
            Crear i Administrar
          </Link>
        ) : (
          <>
            <Link 
              to={createLink}
              className="flex-1 text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
            >
              Crear
            </Link>
            <Link 
              to={manageLink}
              className="flex-1 text-center border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-green-500 hover:text-white transition duration-200"
            >
              Administrar
            </Link>
          </>
        )}
      </div>
    </div>
  </div>
);

export default Payments;
