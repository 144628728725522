import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaArrowLeft, FaSave } from 'react-icons/fa';
import Header from '../../Header';
import Footer from '../../Footer';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useAuth } from '../../../contexts/AuthContext';

const EditPaymentForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [form, setForm] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);

    useEffect(() => {
        Promise.all([
            fetchFormDetails(),
            fetchPaymentData()
        ]).finally(() => {
            setIsLoading(false);
        });
    }, [id]);

    const fetchPaymentData = async () => {
        try {
            const [gatewaysResponse, typesResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }),
                fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-types`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
            ]);

            if (gatewaysResponse.ok && typesResponse.ok) {
                const gateways = await gatewaysResponse.json();
                const types = await typesResponse.json();
                setPaymentGateways(gateways);
                setPaymentTypes(types);
            }
        } catch (error) {
            setError('Error al carregar les opcions de pagament');
        }
    };

    const fetchFormDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els detalls del formulari');

            const data = await response.json();
            setForm(data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (form.payment_type_id === '2' && 
            !form.configuration?.end_date && 
            !form.configuration?.until_amount_completed) {
            setError('Debes especificar una fecha de finalización o un importe máximo para pagos recurrentes');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(form)
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Error al actualizar el formulario');
            }

            navigate(`/payments/forms/${id}`);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar el formulari
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : !form ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-gray-500 text-xl font-semibold">
                                No s'ha trobat el formulari
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">Editar Formulari de Pagament</h1>
                            <div className="flex items-center">
                                <Link
                                    to={`/payments/forms/${id}`}
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                >
                                    <FaArrowLeft className="mr-2" /> Tornar
                                </Link>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                {/* Informació bàsica */}
                                <div>
                                    <h2 className="text-lg font-medium text-gray-900 mb-4">Informació bàsica</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Nom del formulari *
                                            </label>
                                            <input
                                                type="text"
                                                value={form.name}
                                                onChange={(e) => setForm({...form, name: e.target.value})}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Nom del servei *
                                            </label>
                                            <input
                                                type="text"
                                                value={form.service_name}
                                                onChange={(e) => setForm({...form, service_name: e.target.value})}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                            />
                                        </div>

                                        <div className="md:col-span-2">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Descripció del formulari
                                            </label>
                                            <textarea
                                                value={form.description || ''}
                                                onChange={(e) => setForm({...form, description: e.target.value})}
                                                rows="3"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                placeholder="Descripció general del formulari"
                                            />
                                        </div>

                                        <div className="md:col-span-2">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Descripció del servei
                                            </label>
                                            <textarea
                                                value={form.service_description || ''}
                                                onChange={(e) => setForm({...form, service_description: e.target.value})}
                                                rows="3"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                placeholder="Descripció detallada del servei"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Preu base
                                            </label>
                                            <input
                                                type="number"
                                                value={form.base_price}
                                                onChange={(e) => setForm({...form, base_price: e.target.value})}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                                step="0.01"
                                            />
                                        </div>

                                        <div className="space-y-4">
                                            <div className="flex items-center">
                                                <label className="flex items-center space-x-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={form.is_public}
                                                        onChange={(e) => setForm({...form, is_public: e.target.checked})}
                                                        className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                                    />
                                                    <span className="text-sm text-gray-700">
                                                        Formulari públic
                                                    </span>
                                                </label>
                                            </div>

                                            {!form.is_public && (
                                                <div className="mt-3">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Codi d'accés *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={form.access_code || ''}
                                                        onChange={(e) => setForm({...form, access_code: e.target.value})}
                                                        required
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                        placeholder="Introdueix un codi d'accés"
                                                    />
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        Aquest codi serà necessari per accedir al formulari
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Missatges de resposta */}
                                <div>
                                    <h2 className="text-lg font-medium text-gray-900 mb-4">Missatges de resposta</h2>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Missatge d'èxit
                                            </label>
                                            <textarea
                                                value={form.success_message || ''}
                                                onChange={(e) => setForm({...form, success_message: e.target.value})}
                                                rows="2"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                placeholder="Missatge que es mostrarà quan el pagament s'hagi realitzat correctament"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Missatge d'error
                                            </label>
                                            <textarea
                                                value={form.error_message || ''}
                                                onChange={(e) => setForm({...form, error_message: e.target.value})}
                                                rows="2"
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                placeholder="Missatge que es mostrarà si hi ha algun problema amb el pagament"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Configuración de pago */}
                                <div>
                                    <h2 className="text-lg font-medium text-gray-900 mb-4">Configuració de pagament</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Pasarela de Pago *
                                            </label>
                                            <select
                                                value={form.payment_gateway_id || ''}
                                                onChange={(e) => setForm({...form, payment_gateway_id: e.target.value})}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                            >
                                                <option value="">Selecciona una pasarela</option>
                                                {paymentGateways.map(gateway => (
                                                    <option key={gateway.id} value={gateway.id}>
                                                        {gateway.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Tipus de Pagament *
                                            </label>
                                            <select
                                                value={form.payment_type_id || ''}
                                                onChange={(e) => {
                                                    const newTypeId = e.target.value;
                                                    setForm(prev => ({
                                                        ...prev,
                                                        payment_type_id: newTypeId,
                                                        configuration: {
                                                            ...prev.configuration,
                                                            frequency: newTypeId === '2' ? 'monthly' : null // 2 = recurring
                                                        }
                                                    }));
                                                }}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                            >
                                                <option value="">Selecciona un tipus</option>
                                                {paymentTypes.map(type => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        {form.payment_type_id === '2' && (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Freqüència *
                                                </label>
                                                <select
                                                    value={form.configuration?.frequency || ''}
                                                    onChange={(e) => setForm(prev => ({
                                                        ...prev,
                                                        configuration: {
                                                            ...prev.configuration,
                                                            frequency: e.target.value
                                                        }
                                                    }))}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                    required
                                                >
                                                    <option value="">Selecciona una freqüència</option>
                                                    <option value="daily">Diari</option>
                                                    <option value="weekly">Setmanal</option>
                                                    <option value="bi-weekly">Quinzenal</option>
                                                    <option value="monthly">Mensual</option>
                                                    <option value="bi-monthly">Bimestral</option>
                                                    <option value="quarterly">Trimestral</option>
                                                    <option value="semi-annually">Semestral</option>
                                                    <option value="annually">Anual</option>
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Configuración temporal */}
                                <div>
                                    <h2 className="text-lg font-medium text-gray-900 mb-4">Configuració temporal</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Data d'inici *
                                            </label>
                                            <input
                                                type="date"
                                                value={form.configuration?.start_date || ''}
                                                onChange={(e) => setForm(prev => ({
                                                    ...prev,
                                                    configuration: {...prev.configuration, start_date: e.target.value}
                                                }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                            />
                                        </div>

                                        {form.payment_type_id === '2' && (
                                            <>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Import màxim a cobrar
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={form.configuration?.until_amount_completed || ''}
                                                        onChange={(e) => setForm(prev => ({
                                                            ...prev,
                                                            configuration: {
                                                                ...prev.configuration,
                                                                until_amount_completed: e.target.value ? parseFloat(e.target.value) : null,
                                                                end_date: e.target.value ? null : prev.configuration?.end_date
                                                            }
                                                        }))}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                        step="0.01"
                                                        min="0"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Data de finalització
                                            </label>
                                            <input
                                                type="date"
                                                value={form.configuration?.end_date || ''}
                                                onChange={(e) => setForm(prev => ({
                                                    ...prev,
                                                    configuration: {
                                                        ...prev.configuration,
                                                        end_date: e.target.value || null,
                                                        until_amount_completed: e.target.value ? null : prev.configuration?.until_amount_completed
                                                    }
                                                }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                            />
                                            <p className="mt-1 text-sm text-gray-500">
                                                {form.payment_type_id === '2' ? 'Opcional si s\'especifica import màxim' : ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Botó de guardar */}
                                <div className="flex justify-end pt-6">
                                    <button
                                        type="submit"
                                        className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaSave className="mr-2" /> Guardar canvis
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default EditPaymentForm; 