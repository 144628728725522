import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import logo from '../resources/iconsmall.png';
import { getErrorMessage } from '../utils/errorMessages';

function Alert({ errors, message, type }) {
  const alertStyles = {
    error: 'bg-red-50 border-red-500 text-red-700',
    success: 'bg-green-50 border-green-500 text-green-700',
    warning: 'bg-yellow-50 border-yellow-500 text-yellow-700'
  };

  return (
    <div className={`border-l-4 p-3 mb-4 ${alertStyles[type]} text-sm`} role="alert">
      <h3 className="font-semibold text-base mb-1">
        {type === 'error' ? 'Hi ha hagut alguns errors:' : type === 'success' ? 'Èxit!' : 'Avís!'}
      </h3>
      {Array.isArray(errors) ? (
        <ul className="list-disc list-inside">
          {errors.map((error, index) => (
            <li key={index} className="mb-1">{error}</li>
          ))}
        </ul>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
}

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert(null);

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    if (!token || !email) {
      setAlert({ message: getErrorMessage('invalid_reset_link'), type: 'error' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setAlert({ message: getErrorMessage('password_reset_success'), type: 'success' });
        setTimeout(() => navigate('/login'), 3000);
      } else {
        if (data.errors) {
          const errorMessages = Object.entries(data.errors).map(([field, errors]) => {
            return errors.map(error => getErrorMessage(error)).join(', ');
          });
          setAlert({ errors: errorMessages, type: 'error' });
        } else {
          setAlert({ message: getErrorMessage(data.message) || getErrorMessage('unknown_error'), type: 'error' });
        }
      }
    } catch (error) {
      setAlert({ message: getErrorMessage('network_error'), type: 'error' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="w-full max-w-2xl p-6 bg-white shadow-lg rounded-lg">
        <div className="text-center mb-6">
          <Link to="/">
            <img src={logo} alt="Logotipo" className="w-20 mx-auto mb-4 cursor-pointer" />
          </Link>
          <h2 className="text-2xl font-bold">Restablir Contrasenya</h2>
          <p className="text-sm text-gray-500">Introdueix la teva nova contrasenya</p>
        </div>
        {alert && (
          <Alert errors={alert.errors} message={alert.message} type={alert.type} />
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Nova Contrasenya</label>
            <input
              type="password"
              className="w-full mt-1 p-2 border rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Confirmar Nova Contrasenya</label>
            <input
              type="password"
              className="w-full mt-1 p-2 border rounded"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#ED5B52] text-white p-3 rounded hover:bg-[#D64A41] transition duration-200"
          >
            Restablir Contrasenya
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to="/login" className="text-[#ED5B52] hover:underline">Tornar a l'inici de sessió</Link>
        </div>
      </div>
      <footer className="mt-8 text-center text-gray-500">
        <p>&copy; {new Date().getFullYear()} cobraments.com . Tots els drets reservats.</p>
        <p>
          <Link to="/politica-privacitat" className="text-[#ED5B52] hover:underline">Política de privacitat</Link> |&nbsp; 
          <Link to="/termes-servei" className="text-[#ED5B52] hover:underline">Termes del servei</Link>
        </p>
      </footer>
    </div>
  );
}

export default ResetPassword;
