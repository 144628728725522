export const errorMessages = {
  // Errores de validación de Laravel
  'validation.unique': 'Aquest valor ja està registrat',
  'validation.confirmed': 'La confirmació no coincideix',
  'validation.required': 'Aquest camp és obligatori',
  'validation.email': 'Cal introduir una adreça de correu electrònic vàlida',
  'validation.min': 'Aquest camp ha de tenir almenys :min caràcters',
  'validation.max': 'Aquest camp no pot tenir més de :max caràcters',
  'validation.string': 'Aquest camp ha de ser una cadena de text',
  'validation.numeric': 'Aquest camp ha de ser un número',
  'validation.date': 'Aquest camp ha de ser una data vàlida',
  'validation.accepted': 'Cal acceptar aquest camp',

  // Errores específicos de autenticación
  'auth.failed': 'Aquestes credencials no coincideixen amb els nostres registres',
  'auth.throttle': 'Massa intents d\'inici de sessió. Si us plau, torna-ho a provar en :seconds segons',

  // Errores de Stripe
  'stripe_error.card_declined': 'La targeta ha estat rebutjada',
  'stripe_error.expired_card': 'La targeta ha caducat',
  'stripe_error.incorrect_cvc': 'El codi CVC és incorrecte',
  'stripe_error.incorrect_number': 'El número de targeta és incorrecte',
  'stripe_error.invalid_number': 'El número de targeta no és un número de targeta vàlid',
  'stripe_error.invalid_expiry_month': 'El mes de caducitat de la targeta no és vàlid',
  'stripe_error.invalid_expiry_year': 'L\'any de caducitat de la targeta no és vàlid',
  'stripe_error.invalid_cvc': 'El codi de seguretat de la targeta no és vàlid',
  'stripe_error.missing': 'No hi ha cap targeta en un client que està sent carregat',
  'stripe_error.processing_error': 'S\'ha produït un error en processar la targeta',
  'stripe_error.rate_limit': 'S\'ha produït un error a causa de massa sol·licituds a l\'API de Stripe',

  // Errores generales
  'server_error': 'S\'ha produït un error en el servidor',
  'network_error': 'Error de connexió. Si us plau, comprova la teva connexió a internet',
  'unknown_error': 'S\'ha produït un error desconegut',

  // Nous missatges
  'invalid_reset_link': 'Enllaç de restabliment de contrasenya invàlid.',
  'password_reset_success': 'La contrasenya s\'ha restablert correctament. Seràs redirigit a la pàgina d\'inici de sessió.',
};

export const getErrorMessage = (errorKey, params = {}) => {
  let message = errorMessages[errorKey] || errorKey;
  Object.entries(params).forEach(([key, value]) => {
    message = message.replace(`:${key}`, value);
  });
  return message;
};

