import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaTrash, FaTrashRestore, FaSearch } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const ManageCustomers = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [phoneFilter, setPhoneFilter] = useState('');
    const [taxNumberFilter, setTaxNumberFilter] = useState('');

    useEffect(() => {
        fetchCustomers();
    }, [showDeleted]);

    const fetchCustomers = async () => {
        try {
            const endpoint = showDeleted
                ? `${process.env.REACT_APP_BACKEND_URL}/api/customers/deleted`
                : `${process.env.REACT_APP_BACKEND_URL}/api/customers`;

            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els clients');

            const data = await response.json();
            setCustomers(data || []);
        } catch (error) {
            setError(error.message);
            setCustomers([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (customerId) => {
        if (window.confirm('Estàs segur que vols eliminar aquest client?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customers/${customerId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.ok) {
                    setCustomers(customers.filter(customer => customer.id !== customerId));
                    alert('Client eliminat correctament');
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error al eliminar el client');
                }
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };

    const handleRestore = async (customerId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customers/${customerId}/restore`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                setCustomers(customers.filter(customer => customer.id !== customerId));
                alert('Client restaurat correctament');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al restaurar el client');
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const filteredCustomers = customers.filter(customer => {
        const searchFields = [
            customer.name,
            customer.email,
            customer.phone,
            customer.tax_number
        ].join(' ').toLowerCase();

        const matchesSearch = searchFields.includes(searchTerm.toLowerCase());
        const matchesEmail = !emailFilter || customer.email?.toLowerCase().includes(emailFilter.toLowerCase());
        const matchesPhone = !phoneFilter || customer.phone?.includes(phoneFilter);
        const matchesTaxNumber = !taxNumberFilter || customer.tax_number?.includes(taxNumberFilter);

        return matchesSearch && matchesEmail && matchesPhone && matchesTaxNumber;
    });

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="flex-grow container mx-auto px-4 py-8">
                    <LoadingSpinner />
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">
                    Administrar Clients
                    </h1>
                    <div className="flex gap-4">
                        <button
                            onClick={() => setShowDeleted(!showDeleted)}
                            className={`${
                                showDeleted 
                                    ? 'bg-red-500 hover:bg-red-600' 
                                    : 'bg-gray-500 hover:bg-gray-600'
                            } text-white px-4 py-2 rounded transition duration-200 flex items-center`}
                        >
                            <FaTrashRestore className="mr-2" />
                            {showDeleted ? 'Ocultar eliminats' : 'Mostrar eliminats'}
                        </button>
                        <Link
                            to="/clients"
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                        >
                            <FaArrowLeft className="mr-2" /> Tornar
                        </Link>
                    </div>
                </div>

                {error ? (
                    <div className="text-red-500 text-center py-4">{error}</div>
                ) : (
                    <>
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Cerca
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Cerca per nom..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="w-full p-2 border rounded-md pl-10"
                                        />
                                        <FaSearch className="absolute left-3 top-3 text-gray-400" />
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Filtra per email"
                                        value={emailFilter}
                                        onChange={(e) => setEmailFilter(e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Telèfon
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Filtra per telèfon"
                                        value={phoneFilter}
                                        onChange={(e) => setPhoneFilter(e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        NIF/CIF
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Filtra per NIF/CIF"
                                        value={taxNumberFilter}
                                        onChange={(e) => setTaxNumberFilter(e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Nom
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Email
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Telèfon
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            NIF/CIF
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Adreces
                                        </th>
                                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Accions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredCustomers.length > 0 ? (
                                        filteredCustomers.map((customer) => (
                                            <tr key={customer.id} className={customer.deleted_at ? 'bg-gray-50' : ''}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {customer.name}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {customer.email}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {customer.phone || '-'}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {customer.tax_number || '-'}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {customer.addresses?.length || 0}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <div className="flex justify-end space-x-2">
                                                        {showDeleted ? (
                                                            <button 
                                                                onClick={() => handleRestore(customer.id)}
                                                                className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors duration-200"
                                                                title="Restaurar"
                                                            >
                                                                <FaTrashRestore />
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <button 
                                                                    onClick={() => navigate(`/clients/${customer.id}`)}
                                                                    className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                    title="Veure detalls"
                                                                >
                                                                    <FaEye />
                                                                </button>
                                                                <button 
                                                                    onClick={() => handleDelete(customer.id)}
                                                                    className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                    title="Eliminar"
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                                {showDeleted 
                                                    ? 'No s\'han trobat clients eliminats'
                                                    : 'No s\'han trobat clients'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default ManageCustomers; 