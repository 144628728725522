import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaTrash, FaUserPlus, FaTimes, FaLink, FaExternalLinkAlt, FaLock } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const AddParticipantModal = ({ onSubmit, onClose, newParticipant, setNewParticipant }) => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">Afegir Participant</h3>
                <button 
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700"
                >
                    <FaTimes />
                </button>
            </div>
            <form onSubmit={onSubmit}>
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Nom *</label>
                        <input
                            type="text"
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                            value={newParticipant.name}
                            onChange={(e) => setNewParticipant({...newParticipant, name: e.target.value})}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Email *</label>
                        <input
                            type="email"
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                            value={newParticipant.email}
                            onChange={(e) => setNewParticipant({...newParticipant, email: e.target.value})}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Telèfon</label>
                        <input
                            type="tel"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                            value={newParticipant.phone}
                            onChange={(e) => setNewParticipant({...newParticipant, phone: e.target.value})}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Notes</label>
                        <textarea
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                            value={newParticipant.notes}
                            onChange={(e) => setNewParticipant({...newParticipant, notes: e.target.value})}
                        />
                    </div>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                    >
                        Cancel·lar
                    </button>
                    <button
                        type="submit"
                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41]"
                    >
                        Afegir
                    </button>
                </div>
            </form>
        </div>
    </div>
);

const CampaignDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, loading: authLoading } = useAuth();
    const [campaign, setCampaign] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newParticipant, setNewParticipant] = useState({
        name: '',
        email: '',
        phone: '',
        notes: ''
    });
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        type: 'event',
        start_date: '',
        end_date: '',
        location: '',
        max_participants: '',
        status: 'draft'
    });

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().slice(0, 16); // Format: "YYYY-MM-DDThh:mm"
    };

    useEffect(() => {
        if (!authLoading) {
            fetchCampaignData();
        }
    }, [id, authLoading]);

    const fetchCampaignData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Error al carregar les dades de la campanya');
            
            const data = await response.json();
            setCampaign(data);
            setParticipants(data.participants || []);
            setFormData({
                name: data.name,
                description: data.description || '',
                type: data.type,
                start_date: formatDateForInput(data.start_date),
                end_date: formatDateForInput(data.end_date),
                location: data.location || '',
                max_participants: data.max_participants || '',
                status: data.status
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveParticipant = async (participantId) => {
        if (!window.confirm('Estàs segur que vols eliminar aquest participant?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/${id}/participants/${participantId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al eliminar el participant');

            setParticipants(participants.filter(p => p.id !== participantId));
        } catch (error) {
            setError(error.message);
        }
    };

    const handleAddParticipant = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/${id}/participants`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newParticipant)
            });

            if (!response.ok) throw new Error('Error al afegir el participant');

            const data = await response.json();
            
            const newParticipantWithPivot = {
                ...data,
                pivot: {
                    registration_date: data.pivot?.registration_date || new Date().toISOString(),
                    ...data.pivot
                }
            };

            setParticipants([...participants, newParticipantWithPivot]);
            setShowAddModal(false);
            setNewParticipant({ name: '', email: '', phone: '', notes: '' });
        } catch (error) {
            setError(error.message);
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) throw new Error('Error al actualitzar la campanya');

            alert('Campanya actualitzada correctament');
            // Recarreguem la pàgina per mostrar les dades actualitzades
            window.location.reload();
        } catch (error) {
            setError(error.message);
        }
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'number' ? (value ? parseInt(value) : '') : value
        }));
    };

    const copyRegistrationLink = () => {
        if (!campaign.public_hash) {
            alert('No hi ha enllaç disponible per copiar');
            return;
        }
        const campaignUrl = `${window.location.origin}/c/${campaign.public_hash}`;
        navigator.clipboard.writeText(campaignUrl);
        alert('Enllaç copiat al portapapers');
    };

    if (authLoading || isLoading) {
        return <LoadingSpinner message="Carregant detalls de la campanya..." />;
    }

    if (!campaign) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="container mx-auto px-4 py-8 flex-grow">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                        No s'ha trobat la campanya
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">{campaign?.name}</h1>
                    <div className="flex gap-4">
                        {!isEditing && (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                            >
                                <FaEdit className="mr-2" /> Editar
                            </button>
                        )}
                        <button
                            onClick={() => navigate('/clients/campaigns/manage')}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                        >
                            <FaArrowLeft className="mr-2" /> Tornar
                        </button>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow p-6 mb-8">
                    {isEditing ? (
                        <form onSubmit={handleEdit} className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Nom *
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        required
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Tipus
                                    </label>
                                    <select
                                        name="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    >
                                        <option value="event">Esdeveniment</option>
                                        <option value="promotion">Promoció</option>
                                        <option value="workshop">Taller</option>
                                        <option value="webinar">Webinar</option>
                                        <option value="other">Altre</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Data d'inici *
                                    </label>
                                    <input
                                        type="datetime-local"
                                        name="start_date"
                                        required
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Data de fi *
                                    </label>
                                    <input
                                        type="datetime-local"
                                        name="end_date"
                                        required
                                        value={formData.end_date}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Ubicació
                                    </label>
                                    <input
                                        type="text"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Màxim participants
                                    </label>
                                    <input
                                        type="number"
                                        name="max_participants"
                                        value={formData.max_participants}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Descripció
                                </label>
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    rows="4"
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    type="button"
                                    onClick={() => setIsEditing(false)}
                                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                                >
                                    Cancel·lar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41]"
                                >
                                    Guardar Canvis
                                </button>
                            </div>
                        </form>
                    ) : (
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <h3 className="text-lg font-medium text-gray-700 mb-2">Detalls de la Campanya</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <span className="text-gray-600">Tipus:</span>
                                            <span className="ml-2 font-medium">{campaign.type}</span>
                                        </div>
                                        <div>
                                            <span className="text-gray-600">Estat:</span>
                                            <span className={`ml-2 px-2 py-1 text-xs font-semibold rounded-full ${
                                                campaign.status === 'active' ? 'bg-green-100 text-green-800' :
                                                campaign.status === 'draft' ? 'bg-gray-100 text-gray-800' :
                                                'bg-red-100 text-red-800'
                                            }`}>
                                                {campaign.status}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="text-gray-600">Data d'inici:</span>
                                            <span className="ml-2 font-medium">
                                                {new Date(campaign.start_date).toLocaleString()}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="text-gray-600">Data de fi:</span>
                                            <span className="ml-2 font-medium">
                                                {new Date(campaign.end_date).toLocaleString()}
                                            </span>
                                        </div>
                                        {campaign.location && (
                                            <div>
                                                <span className="text-gray-600">Ubicació:</span>
                                                <span className="ml-2 font-medium">{campaign.location}</span>
                                            </div>
                                        )}
                                        {campaign.max_participants && (
                                            <div>
                                                <span className="text-gray-600">Màxim participants:</span>
                                                <span className="ml-2 font-medium">{campaign.max_participants}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <h3 className="text-lg font-medium text-gray-700 mb-2">Descripció</h3>
                                    <p className="text-gray-600">{campaign.description || 'Sense descripció'}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="bg-white rounded-lg shadow p-6 mb-8">
                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                        <FaLink className="mr-2 text-gray-500" />
                        Enllaç d'inscripció
                    </h2>
                    <div className="space-y-4">
                        {campaign.public_hash ? (
                            <>
                                <div className="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg">
                                    <input
                                        type="text"
                                        value={`${window.location.origin}/c/${campaign.public_hash}`}
                                        className="flex-1 bg-transparent border-none focus:ring-0"
                                        readOnly
                                    />
                                    <button
                                        onClick={copyRegistrationLink}
                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                        title="Copiar enllaç d'inscripció"
                                    >
                                        <FaLink className="mr-2" /> Copiar
                                    </button>
                                    <a
                                        href={`/c/${campaign.public_hash}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                        title="Veure pàgina d'inscripció"
                                    >
                                        <FaExternalLinkAlt className="mr-2" /> Veure
                                    </a>
                                </div>
                                {campaign.requires_access_code === true && (
                                    <div className="text-sm text-gray-600 flex items-center">
                                        <FaLock className="mr-2" />
                                        Aquesta campanya requereix codi d'accés
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="text-sm text-gray-600">
                                No s'ha pogut generar l'enllaç d'inscripció
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold text-gray-800">Participants</h2>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => setShowAddModal(true)}
                                className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                            >
                                <FaUserPlus className="mr-2" /> Afegir Participant
                            </button>
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nom
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Data Registre
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Accions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {participants.map((participant) => (
                                    <tr key={participant.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">{participant.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{participant.email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {new Date(participant.pivot.registration_date).toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right">
                                            <button
                                                onClick={() => handleRemoveParticipant(participant.id)}
                                                className="text-red-600 hover:text-red-900"
                                                title="Eliminar participant"
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {participants.length === 0 && (
                                    <tr>
                                        <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                            No hi ha participants registrats
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
            <Footer />
            {showAddModal && (
                <AddParticipantModal 
                    onSubmit={handleAddParticipant}
                    onClose={() => setShowAddModal(false)}
                    newParticipant={newParticipant}
                    setNewParticipant={setNewParticipant}
                />
            )}
        </div>
    );
};

export default CampaignDetails; 