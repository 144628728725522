import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { FaUser, FaEnvelope, FaPhone, FaFileAlt } from 'react-icons/fa';

const PublicCampaign = () => {
    const { hash } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(null);
    const [requiresAccessCode, setRequiresAccessCode] = useState(false);
    const [isAccessCodeValid, setIsAccessCodeValid] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [accessCodeError, setAccessCodeError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        notes: ''
    });

    useEffect(() => {
        fetchCampaignData();
    }, [hash]);

    const fetchCampaignData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/public/${hash}`);
            if (!response.ok) throw new Error('No s\'ha trobat la campanya');
            
            const data = await response.json();
            setCampaign(data);
            setRequiresAccessCode(!!data.access_code);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAccessCodeSubmit = async (e) => {
        e.preventDefault();
        setAccessCodeError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/public/${hash}/validate-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ access_code: accessCode })
            });

            if (!response.ok) {
                throw new Error('Codi d\'accés incorrecte');
            }

            setIsAccessCodeValid(true);
        } catch (error) {
            setAccessCodeError(error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/campaigns/public/${hash}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error en el registre');
            }

            setRegistrationStatus('success');
            setFormData({ name: '', email: '', phone: '', notes: '' });
        } catch (error) {
            setError(error.message);
            setRegistrationStatus('error');
        } finally {
            setIsProcessing(false);
        }
    };

    const renderAccessCodeForm = () => {
        return (
            <div className="max-w-md mx-auto mt-8">
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold mb-6">Accés Protegit</h2>
                    <p className="text-gray-600 mb-6">
                        Aquesta campanya requereix un codi d'accés. Si us plau, introdueix-lo per continuar.
                    </p>
                    <form onSubmit={handleAccessCodeSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Codi d'accés
                            </label>
                            <input
                                type="text"
                                value={accessCode}
                                onChange={(e) => setAccessCode(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                placeholder="Introdueix el codi d'accés"
                                maxLength="6"
                                required
                            />
                            {accessCodeError && (
                                <p className="mt-2 text-sm text-red-600">
                                    {accessCodeError}
                                </p>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#ED5B52] text-white px-4 py-2 rounded-md hover:bg-[#D64A41] transition-colors"
                        >
                            Validar
                        </button>
                    </form>
                </div>
            </div>
        );
    };

    const renderRegistrationStatus = () => {
        if (!registrationStatus) return null;

        return (
            <div className={`mb-6 p-4 rounded-lg ${
                registrationStatus === 'success' 
                    ? 'bg-green-100 text-green-700' 
                    : 'bg-red-100 text-red-700'
            }`}>
                {registrationStatus === 'success' 
                    ? 'T\'has registrat correctament a la campanya' 
                    : 'Hi ha hagut un error en el registre'}
            </div>
        );
    };

    const layoutProps = {
        shop: campaign?.shop || {
            name: "Campanya",
            description: "Registre a la campanya"
        },
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    if (isLoading) {
        return <PublicLayout {...layoutProps}><div>Carregant...</div></PublicLayout>;
    }

    if (error) {
        return <PublicLayout {...layoutProps}><div>Error: {error}</div></PublicLayout>;
    }

    if (requiresAccessCode && !isAccessCodeValid) {
        return <PublicLayout {...layoutProps}>{renderAccessCodeForm()}</PublicLayout>;
    }

    if (!campaign) {
        return <PublicLayout {...layoutProps}><div>No s'ha trobat la campanya</div></PublicLayout>;
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">
                {renderRegistrationStatus()}
                <section className="mb-12">
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="p-8">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4">
                                        {campaign.name}
                                    </h2>
                                    <p className="text-gray-600 mb-6">
                                        {campaign.description}
                                    </p>
                                    
                                    <div className="mb-6">
                                        <h3 className="font-semibold mb-2">Data</h3>
                                        <p className="text-gray-600">
                                            {new Date(campaign.start_date).toLocaleDateString()} - {new Date(campaign.end_date).toLocaleDateString()}
                                        </p>
                                    </div>

                                    {campaign.location && (
                                        <div className="mb-6">
                                            <h3 className="font-semibold mb-2">Ubicació</h3>
                                            <p className="text-gray-600">{campaign.location}</p>
                                        </div>
                                    )}

                                    {campaign.max_participants && (
                                        <div className="mb-6">
                                            <h3 className="font-semibold mb-2">Places disponibles</h3>
                                            <p className="text-gray-600">
                                                {campaign.max_participants - (campaign.participants_count || 0)} de {campaign.max_participants}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <form onSubmit={handleSubmit} className="space-y-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Nom complet *
                                            </label>
                                            <div className="relative">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <FaUser className="text-gray-400" />
                                                </div>
                                                <input
                                                    type="text"
                                                    required
                                                    value={formData.name}
                                                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                                                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="El teu nom"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Email *
                                            </label>
                                            <div className="relative">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <FaEnvelope className="text-gray-400" />
                                                </div>
                                                <input
                                                    type="email"
                                                    required
                                                    value={formData.email}
                                                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                                                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="El teu email"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Telèfon
                                            </label>
                                            <div className="relative">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <FaPhone className="text-gray-400" />
                                                </div>
                                                <input
                                                    type="tel"
                                                    value={formData.phone}
                                                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="El teu telèfon"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Notes
                                            </label>
                                            <div className="relative">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <FaFileAlt className="text-gray-400" />
                                                </div>
                                                <textarea
                                                    value={formData.notes}
                                                    onChange={(e) => setFormData({...formData, notes: e.target.value})}
                                                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="Notes addicionals"
                                                    rows="4"
                                                />
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={isProcessing}
                                            className={`w-full border-2 px-6 py-3 rounded-lg text-center font-semibold transition-colors
                                                ${isProcessing
                                                    ? 'border-[#ED5B52] bg-[#ED5B52] text-white cursor-wait opacity-75'
                                                    : 'border-[#ED5B52] bg-[#ED5B52] text-white hover:bg-[#D64A41]'
                                                }`}
                                        >
                                            {isProcessing ? 'Processant...' : 'Registrar-me'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </PublicLayout>
    );
};

export default PublicCampaign; 