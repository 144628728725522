import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import PaymentTypeImage from './PaymentTypeImage';
import logo from './../../resources/iconsmall.png';

const PublicPayment = () => {
    const { hash } = useParams();
    const [payment, setPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [requiresAccessCode, setRequiresAccessCode] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [isAccessCodeValid, setIsAccessCodeValid] = useState(false);
    const [accessCodeError, setAccessCodeError] = useState('');

    // Funció per renderitzar el formulari d'accés
    const renderAccessCodeForm = () => {
        return (
            <div className="max-w-md mx-auto mt-8">
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold mb-6">Accés Protegit</h2>
                    <p className="text-gray-600 mb-6">
                        Aquest pagament requereix un codi d'accés. Si us plau, introdueix-lo per continuar.
                    </p>
                    <form onSubmit={handleAccessCodeSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Codi d'accés
                            </label>
                            <input
                                type="text"
                                value={accessCode}
                                onChange={(e) => setAccessCode(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                placeholder="Introdueix el codi d'accés"
                                maxLength="6"
                                required
                            />
                            {accessCodeError && (
                                <p className="mt-2 text-sm text-red-600">
                                    {accessCodeError}
                                </p>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-[#ED5B52] text-white px-4 py-2 rounded-md hover:bg-[#D64A41] transition-colors"
                        >
                            Validar
                        </button>
                    </form>
                </div>
            </div>
        );
    };

    const handleAccessCodeSubmit = async (e) => {
        e.preventDefault();
        setAccessCodeError('');

        try {
            
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-payments/${hash}/verify-access-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ access_code: accessCode })
            });

            const data = await response.json();

            if (response.ok) {
                setPayment(data.payment);
                setIsAccessCodeValid(true);
                setRequiresAccessCode(false);
            } else {
                setAccessCodeError(data.error || 'Codi d\'accés incorrecte');
            }
        } catch (error) {
            console.error('Error:', error);
            setAccessCodeError('Error en validar el codi d\'accés');
        }
    };

    // Definim fetchPayment abans d'utilitzar-la
    const fetchPayment = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-payments/${hash}`);
            if (!response.ok) {
                throw new Error('No s\'ha pogut carregar el pagament');
            }
            const data = await response.json();

            // Comprovació explícita de l'estat de validació
            if (data.requires_access_code && !data.is_validated) {
                setRequiresAccessCode(true);
                setIsAccessCodeValid(false);
                return;
            }

            // Si està validat o no requereix codi, actualitzem el payment
            setPayment(data.payment);
            setRequiresAccessCode(false);
            setIsAccessCodeValid(true);

            if (data.payment?.status === 'completed') {
                setPaymentStatus('success');
            } else if (data.payment?.status === 'failed') {
                setPaymentStatus('failed');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Obtenim l'estat del pagament dels paràmetres de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        if (status) {
            setPaymentStatus(status);
        }

        fetchPayment();
    }, [hash]);

    // Afegim un interval per actualitzar l'estat
    useEffect(() => {
        /*const interval = setInterval(() => {
            if (!isLoading && !isProcessing) {
                fetchPayment();
            }
        }, 5000); // Actualitzar cada 5 segons

        return () => clearInterval(interval);*/
    }, [isLoading, isProcessing, hash]);

    const renderAttachments = () => {
        if (!payment.configuration?.document_path) return null;

        return (
            <div className="mb-6">
                <h3 className="font-semibold mb-2">Documents adjunts</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                    <a 
                        href={`${process.env.REACT_APP_BACKEND_URL}/storage/${payment.configuration.document_path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-[#ED5B52] hover:text-[#D64A41] transition-colors"
                    >
                        <svg 
                            className="w-5 h-5 mr-2" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" 
                            />
                        </svg>
                        Veure document
                    </a>
                </div>
            </div>
        );
    };

    const getFrequencyText = (frequency) => {
        const frequencies = {
            'daily': 'Diari',
            'weekly': 'Setmanal',
            'bi-weekly': 'Quinzenal',
            'monthly': 'Mensual',
            'bi-monthly': 'Bimestral',
            'quarterly': 'Trimestral',
            'semi-annually': 'Semestral',
            'annually': 'Anual'
        };
        return frequencies[frequency] || frequency;
    };

    const renderRecurringInfo = () => {
        if (!payment?.payment_type?.slug) return null;
        if (payment.payment_type.slug !== 'cobrament-recurrent') return null;

        // Calculem el nombre de pagaments i l'import total
        const calculatePaymentDetails = () => {
            const startDate = new Date(payment.configuration.start_date);
            const amount = parseFloat(payment.amount);
            
            // Cas 1: Tenim data de finalització
            if (payment.configuration.end_date) {
                const endDate = new Date(payment.configuration.end_date);
                const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                              (endDate.getMonth() - startDate.getMonth());
                
                let numberOfPayments;
                switch(payment.configuration.frequency) {
                    case 'daily': 
                        numberOfPayments = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); 
                        break;
                    case 'weekly': 
                        numberOfPayments = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 7)); 
                        break;
                    case 'bi-weekly': 
                        numberOfPayments = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 14)); 
                        break;
                    case 'monthly': 
                        numberOfPayments = months + 1; 
                        break;
                    case 'bi-monthly': 
                        numberOfPayments = Math.ceil((months + 1) / 2); 
                        break;
                    case 'quarterly': 
                        numberOfPayments = Math.ceil((months + 1) / 3); 
                        break;
                    case 'semi-annually': 
                        numberOfPayments = Math.ceil((months + 1) / 6); 
                        break;
                    case 'annually': 
                        numberOfPayments = Math.ceil((months + 1) / 12); 
                        break;
                    default: 
                        numberOfPayments = 0;
                }
                
                return {
                    numberOfPayments,
                    totalAmount: (amount * numberOfPayments).toFixed(2),
                    type: 'date'
                };
            }
            // Cas 2: Tenim import total objectiu
            else if (payment.configuration.until_amount_completed) {
                const totalAmount = parseFloat(payment.configuration.until_amount_completed);
                return {
                    numberOfPayments: Math.ceil(totalAmount / amount),
                    totalAmount: totalAmount.toFixed(2),
                    type: 'amount'
                };
            }
            
            return null;
        };

        const paymentDetails = calculatePaymentDetails();

        return (
            <div className="mb-6">
                <h3 className="font-semibold mb-2">Detalls del pagament recurrent</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                    <div className="space-y-2">
                        {/* Freqüència i import per pagament (sempre es mostren) */}
                        <div className="flex items-center text-gray-600">
                            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <span>Freqüència: {getFrequencyText(payment.configuration?.frequency)}</span>
                        </div>

                        <div className="flex items-center text-gray-600">
                            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>Import per pagament: {payment.amount}€</span>
                        </div>

                        {/* Informació calculada */}
                        {paymentDetails && (
                            <>
                                <div className="flex items-center text-gray-600">
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    <span>Import total: {paymentDetails.totalAmount}€</span>
                                </div>
                                <div className="flex items-center text-gray-600">
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                    </svg>
                                    <span>Nombre total de pagaments: {paymentDetails.numberOfPayments}</span>
                                </div>
                            </>
                        )}

                        {/* Data d'inici (sempre es mostra) */}
                        <div className="flex items-center text-gray-600">
                            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <span>Data d'inici: {new Date(payment.configuration.start_date).toLocaleDateString('ca-ES')}</span>
                        </div>

                        {/* Data de finalització (només si existeix) */}
                        {payment.configuration.end_date && (
                            <div className="flex items-center text-gray-600">
                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span>Data de finalització: {new Date(payment.configuration.end_date).toLocaleDateString('ca-ES')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const layoutProps = {
        shop: {
            id: payment?.shop_id,
            name: payment?.shop_name || "La meva botiga",
            description: payment?.shop_description || "Benvingut a la nostra botiga online",
            address: payment?.shop_address,
            email: payment?.shop_email,
            phone: payment?.shop_phone
        },
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    const processPayment = async () => {
        setIsProcessing(true);
        try {
            // Si és un pagament recurrent
            if (payment.payment_type.slug === 'cobrament-recurrent') {
                if (payment.merchant_identifier) {
                    // Confirmació abans de fer el cobrament
                    if (!window.confirm('Es realitzarà un cobrament amb la targeta registrada. Vols continuar?')) {
                        setIsProcessing(false);
                        return;
                    }

                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${payment.id}/charge-now`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const data = await response.json();
                    
                    if (!response.ok) {
                        throw new Error(data.error || 'Error processant el pagament');
                    }

                    if (data.success) {
                        setPaymentStatus('success');
                        fetchPayment();
                    } else {
                        setPaymentStatus('failed');
                        setError(data.error || 'Error processant el pagament');
                    }
                } else {
                    // Primer pagament - Registre de targeta
                    if (!window.confirm('Aquest és un pagament recurrent. Es registrarà la targeta per a futurs cobraments. Vols continuar?')) {
                        setIsProcessing(false);
                        return;
                    }

                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${hash}/charge-now`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const data = await response.json();
                    
                    if (!response.ok) {
                        throw new Error(data.message || 'Error processant el pagament');
                    }

                    // Redirigir a Redsys
                    if (data.success && data.data) {
                        // Crear formulari dinàmic
                        const form = document.createElement('form');
                        form.method = data.data.method || 'POST';
                        form.action = data.data.url;

                        // Afegir tots els camps necessaris
                        Object.entries(data.data.fields || {}).forEach(([key, value]) => {
                            const input = document.createElement('input');
                            input.type = 'hidden';
                            input.name = key;
                            input.value = value;
                            form.appendChild(input);
                        });

                        // Afegir el formulari al DOM i enviar-lo
                        document.body.appendChild(form);
                        form.submit();
                        return;
                    }
                }
            } else {
                // Pagament únic normal
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/${hash}/charge-now`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
        
                const data = await response.json();
                
                if (data.success && data.data) {
                    // Crear formulario dinámico para Redsys
                    const form = document.createElement('form');
                    form.method = data.data.method;
                    form.action = data.data.url;
        
                    // Añadir campos necesarios
                    Object.entries(data.data.fields).forEach(([key, value]) => {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    });
        
                    document.body.appendChild(form);
                    form.submit();
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    // Renderitzem un missatge segons l'estat del pagament
    const renderPaymentStatus = () => {
        if (!paymentStatus) return null;

        return (
            <div className={`mb-6 p-4 rounded-lg ${
                paymentStatus === 'success' 
                    ? 'bg-green-100 text-green-700' 
                    : 'bg-red-100 text-red-700'
            }`}>
                {paymentStatus === 'success' 
                    ? 'El pagament s\'ha realitzat correctament' 
                    : 'El pagament ha estat cancel·lat'}
            </div>
        );
    };

    // Funció per determinar si el pagament està completat
    const isPaymentCompleted = () => {
        return payment?.status === 'completed';
    };

    // Funció per obtenir el text del botó segons l'estat
    const getButtonText = () => {
        if (isProcessing) return 'Processant...';
        if (isPaymentCompleted()) return 'Pagament Completat';
        
        if (payment?.payment_type?.slug === 'cobrament-recurrent' && payment?.merchant_identifier) {
            return 'Realitzar Cobrament';
        }
        
        return 'Realitzar Pagament';
    };

    if (isLoading) {
        return <PublicLayout {...layoutProps}><div>Carregant...</div></PublicLayout>;
    }

    if (error) {
        return <PublicLayout {...layoutProps}><div>Error: {error}</div></PublicLayout>;
    }

    if (requiresAccessCode && !isAccessCodeValid) {
        return <PublicLayout {...layoutProps}>{renderAccessCodeForm()}</PublicLayout>;
    }

    if (!payment) {
        return <PublicLayout><div>No s'ha trobat el pagament</div></PublicLayout>;
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">
                {renderPaymentStatus()}
                <section className="mb-12">
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                        <div className="p-8">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4">
                                        {payment.name || 'Pagament'}
                                    </h2>
                                    <p className="text-gray-600 mb-6">
                                        {payment.description || ''}
                                    </p>
                                    <div className="mb-6">
                                        <h3 className="font-semibold mb-2">Tipus de Pagament</h3>
                                        <p className="text-gray-600">{payment.payment_type.name}</p>
                                    </div>
                                    
                                    {/* Afegim la informació de recurrència */}
                                    {renderRecurringInfo()}
                                    
                                    <div className="mb-6">
                                        <h3 className="font-semibold mb-2">Import</h3>
                                        {payment.configuration?.allow_custom_amount ? (
                                            <p className="text-gray-600">Import personalitzable</p>
                                        ) : (
                                            <p className="text-2xl font-bold text-[#ED5B52]">{payment.amount}€</p>
                                        )}
                                    </div>
                                    
                                    {/* Afegim la secció de documents adjunts */}
                                    {renderAttachments()}
                                    
                                    {/* Si hi ha més d'un document */}
                                    {payment.configuration?.additional_documents && (
                                        <div className="mb-6">
                                            <h3 className="font-semibold mb-2">Documents addicionals</h3>
                                            <div className="space-y-2">
                                                {payment.configuration.additional_documents.map((doc, index) => (
                                                    <div 
                                                        key={index}
                                                        className="bg-gray-50 rounded-lg p-4"
                                                    >
                                                        <a 
                                                            href={`${process.env.REACT_APP_BACKEND_URL}/storage/${doc.path}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="flex items-center text-[#ED5B52] hover:text-[#D64A41] transition-colors"
                                                        >
                                                            <svg 
                                                                className="w-5 h-5 mr-2" 
                                                                fill="none" 
                                                                stroke="currentColor" 
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path 
                                                                    strokeLinecap="round" 
                                                                    strokeLinejoin="round" 
                                                                    strokeWidth={2} 
                                                                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" 
                                                                />
                                                            </svg>
                                                            {doc.name || `Document ${index + 1}`}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Imatge i Botó de Pagament */}
                                <div className="flex flex-col items-center">
                                    <div className="w-full max-w-md mb-6">
                                        <PaymentTypeImage 
                                            paymentType={payment.payment_type.slug}
                                            amount={payment.amount}
                                            allowCustomAmount={payment.configuration?.allow_custom_amount}
                                        />
                                    </div>
                                    <button 
                                        onClick={processPayment}
                                        disabled={isProcessing || isPaymentCompleted()}
                                        className={`w-full max-w-md border-2 px-6 py-3 rounded-lg text-center font-semibold transition-colors
                                            ${isPaymentCompleted()
                                                ? 'border-green-500 bg-green-500 text-white cursor-not-allowed opacity-75'
                                                : isProcessing
                                                    ? 'border-[#ED5B52] bg-[#ED5B52] text-white cursor-wait'
                                                    : 'border-[#ED5B52] bg-[#ED5B52] text-white hover:bg-[#D64A41]'
                                            }`}
                                    >
                                        {getButtonText()}
                                    </button>
                                    {isPaymentCompleted() && (
                                        <p className="mt-2 text-sm text-green-600">
                                            Aquest pagament ja ha estat processat correctament
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </PublicLayout>
    );

};

export default PublicPayment;