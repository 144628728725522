import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../resources/iconsmall.png'; // Asegúrate de que el logo esté disponible en esta ruta
import { getErrorMessage } from '../utils/errorMessages';
import { useAuth } from '../contexts/AuthContext';

function Alert({ errors, message, type }) {
  const alertStyles = {
    error: 'bg-red-50 border-red-500 text-red-700',
    success: 'bg-green-50 border-green-500 text-green-700',
    warning: 'bg-yellow-50 border-yellow-500 text-yellow-700'
  };

  return (
    <div className={`border-l-4 p-3 mb-4 ${alertStyles[type]} text-sm`} role="alert">
      <h3 className="font-semibold text-base mb-1">
        {type === 'error' ? 'Hi ha hagut alguns errors:' : type === 'success' ? 'Èxit!' : 'Avís!'}
      </h3>
      {Array.isArray(errors) ? (
        <ul className="list-disc list-inside">
          {errors.map((error, index) => (
            <li key={index} className="mb-1">{error}</li>
          ))}
        </ul>
      ) : (
        <p>{message || errors}</p>
      )}
    </div>
  );
}

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setAlert(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error en iniciar sessió');
      }

      if (!data.access_token || !data.user) {
        throw new Error('Resposta del servidor incompleta');
      }

      // Primer fem login
      await login(data.access_token, data.user);

      setAlert({ 
        message: data.message || 'Inici de sessió correcte. Redirigint...', 
        type: 'success' 
      });

      // Forcem la navegació després d'un petit delay
      setTimeout(() => {
        navigate('/dashboard', { replace: true });
      }, 100);

    } catch (error) {
      console.error('Error during login:', error);
      setAlert({
        message: error.message || 'Error en iniciar sessió',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="w-full max-w-2xl p-6 bg-white shadow-lg rounded-lg">
        <div className="text-center mb-6">
          <Link to="/">
            <img src={logo} alt="Logotipo" className="w-20 mx-auto mb-4 cursor-pointer" />
          </Link>
          <h2 className="text-2xl font-bold">Iniciar Sesión</h2>
        </div>
        {alert && <div className="mb-4"><Alert errors={alert.errors} message={alert.message} type={alert.type} /></div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              className="w-full mt-1 p-2 border rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Contraseña</label>
            <input
              type="password"
              className="w-full mt-1 p-2 border rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#ED5B52] text-white p-3 rounded hover:bg-[#D64A41] transition duration-200"
          >
            Iniciar Sesión
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to="/recordar-contrasenya" className="text-[#ED5B52] hover:underline">Has oblidat la contrasenya?</Link>
        </div>
      </div>
      <footer className="mt-8 text-center text-gray-500">
        <p>&copy; {new Date().getFullYear()} cobraments.com . Todos los derechos reservados.</p>
        <p>
          <Link to="/politica-privacidad" className="text-[#ED5B52] hover:underline">Política de privacidad</Link> |&nbsp;
          <Link to="/terminos-servicio" className="text-[#ED5B52] hover:underline">Términos del servicio</Link>
        </p>
      </footer>
    </div>
  );
}

export default Login;
