import React from 'react';
import { FaRegCalendarAlt, FaRegCreditCard, FaRegMoneyBillAlt } from 'react-icons/fa';

export const PaymentTypeImage = ({ paymentType, amount, allowCustomAmount }) => {
    // Funció per obtenir la configuració segons el tipus
    const getTypeConfig = (type) => {
        const configs = {
            'cobrament-recurrent': {
                icon: <FaRegCalendarAlt className="w-10 h-10" />,
                label: 'Pagament Recurrent',
                bgColor: 'from-blue-50 to-blue-100',
                iconColor: 'text-blue-500'
            },
            'cobrament-unic': {
                icon: <FaRegCreditCard className="w-10 h-10" />,
                label: 'Pagament Únic',
                bgColor: 'from-green-50 to-green-100',
                iconColor: 'text-green-500'
            },
            'cobrament-obert': {
                icon: <FaRegMoneyBillAlt className="w-10 h-10" />,
                label: 'Pagament Obert',
                bgColor: 'from-purple-50 to-purple-100',
                iconColor: 'text-purple-500'
            },
            default: {
                icon: <FaRegCreditCard className="w-10 h-10" />,
                label: 'Pagament',
                bgColor: 'from-gray-50 to-gray-100',
                iconColor: 'text-gray-500'
            }
        };
        return configs[type] || configs.default;
    };

    const config = getTypeConfig(paymentType);

    return (
        <div className={`w-full h-full rounded-lg bg-gradient-to-br ${config.bgColor} flex flex-col items-center justify-center p-4`}>
            <div className={`mb-3 ${config.iconColor}`}>
                {config.icon}
            </div>
            <h5 className="text-sm font-medium text-gray-700 mb-2">
                {config.label}
            </h5>
            <p className="text-sm text-gray-600">
                {allowCustomAmount ? (
                    "Import personalitzable"
                ) : (
                    amount ? `${amount}€` : "Sense import"
                )}
            </p>
        </div>
    );
};

export default PaymentTypeImage; 