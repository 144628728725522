import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const LoadingSpinner = ({ message = "Carregant...", submessage = "Si us plau, espera uns moments" }) => (
  <div className="min-h-screen bg-gray-100 flex flex-col">
    <main className="container mx-auto px-4 py-12 flex-grow">
      <div className="bg-white rounded-lg shadow">
        <div className="flex flex-col items-center justify-center min-h-[400px]">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#ED5B52] mb-4"></div>
          <p className="text-xl text-gray-600 font-semibold">{message}</p>
          <p className="text-sm text-gray-500 mt-2">{submessage}</p>
        </div>
      </div>
    </main>
  </div>
);

export default LoadingSpinner; 