import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSave, FaGavel } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const LegalSettings = () => {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        terms_conditions: '',
        privacy_policy: '',
        cookies_policy: '',
        terms_version: '1.0',
        privacy_version: '1.0',
        cookies_version: '1.0'
    });

    useEffect(() => {
        fetchLegalTexts();
    }, []);

    const fetchLegalTexts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/legal-texts`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al carregar els textos legals');
            }

            const data = await response.json();
            setFormData(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditorChange = (content, editor, fieldName) => {
        setFormData(prev => ({
            ...prev,
            [fieldName]: content
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/legal-texts`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Error al guardar els textos legals');
            }

            const data = await response.json();
            setSuccessMessage(data.message);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsSaving(false);
        }
    };

    // Configuració de l'editor Quill
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link'],
            ['clean'],
            [{ 'color': [] }, { 'background': [] }],
            ['blockquote', 'code-block'],
        ]
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'align',
        'link',
        'color', 'background',
        'blockquote', 'code-block',
    ];

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-12 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Textos Legals</h1>
                    <Link
                        to="/configuracio"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                        {successMessage}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
                    {/* Condicions */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaGavel className="mr-2" /> Condicions d'ús
                        </h2>
                        <div className="flex items-center mb-2">
                            <input
                                type="text"
                                name="terms_version"
                                value={formData.terms_version}
                                onChange={handleChange}
                                className="w-24 p-2 border rounded mr-2 focus:ring-green-500 focus:border-green-500"
                                placeholder="Versió"
                            />
                            <span className="text-sm text-gray-500">Versió</span>
                        </div>
                        <div className="border rounded">
                            <ReactQuill
                                theme="snow"
                                value={formData.terms_conditions}
                                onChange={(content) => handleEditorChange(content, null, 'terms_conditions')}
                                modules={modules}
                                formats={formats}
                                className="h-96 mb-12"
                            />
                        </div>
                    </div>

                    {/* Privacitat */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaGavel className="mr-2" /> Política de Privacitat
                        </h2>
                        <div className="flex items-center mb-2">
                            <input
                                type="text"
                                name="privacy_version"
                                value={formData.privacy_version}
                                onChange={handleChange}
                                className="w-24 p-2 border rounded mr-2 focus:ring-green-500 focus:border-green-500"
                                placeholder="Versió"
                            />
                            <span className="text-sm text-gray-500">Versió</span>
                        </div>
                        <div className="border rounded">
                            <ReactQuill
                                theme="snow"
                                value={formData.privacy_policy}
                                onChange={(content) => handleEditorChange(content, null, 'privacy_policy')}
                                modules={modules}
                                formats={formats}
                                className="h-96 mb-12"
                            />
                        </div>
                    </div>

                    {/* Cookies */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaGavel className="mr-2" /> Política de Cookies
                        </h2>
                        <div className="flex items-center mb-2">
                            <input
                                type="text"
                                name="cookies_version"
                                value={formData.cookies_version}
                                onChange={handleChange}
                                className="w-24 p-2 border rounded mr-2 focus:ring-green-500 focus:border-green-500"
                                placeholder="Versió"
                            />
                            <span className="text-sm text-gray-500">Versió</span>
                        </div>
                        <div className="border rounded">
                            <ReactQuill
                                theme="snow"
                                value={formData.cookies_policy}
                                onChange={(content) => handleEditorChange(content, null, 'cookies_policy')}
                                modules={modules}
                                formats={formats}
                                className="h-96 mb-12"
                            />
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={isSaving}
                            className={`bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center ${
                                isSaving ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            <FaSave className="mr-2" />
                            {isSaving ? 'Guardant...' : 'Guardar Canvis'}
                        </button>
                    </div>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default LegalSettings; 