import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { FaShoppingBag } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext';
import CheckoutForm from './CheckoutForm';
import CartSidebar from '../cart/CartSidebar';

// Component per mostrar imatge per defecte
const DefaultImage = ({ type = 'product', seed }) => {
    // Configuracions de colors (fons i icona)
    const colorConfigs = [
        { bg: 'from-blue-50 to-blue-100', icon: 'text-blue-500' },
        { bg: 'from-green-50 to-green-100', icon: 'text-green-500' },
        { bg: 'from-purple-50 to-purple-100', icon: 'text-purple-500' },
        { bg: 'from-pink-50 to-pink-100', icon: 'text-pink-500' },
        { bg: 'from-yellow-50 to-yellow-100', icon: 'text-yellow-500' },
        { bg: 'from-indigo-50 to-indigo-100', icon: 'text-indigo-500' }
    ];

    // Seleccionar configuració basada en el seed
    const colorIndex = seed ? Math.abs(seed.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % colorConfigs.length : 0;
    const { bg, icon } = colorConfigs[colorIndex];

    return (
        <div className={`w-full h-full rounded-lg bg-gradient-to-br ${bg} flex items-center justify-center`}>
            <FaShoppingBag className={`w-12 h-12 ${icon}`} />
        </div>
    );
};

const PublicOnlineShop = () => {
    const { id } = useParams();
    const { cart, total } = useCart();
    const [shop, setShop] = useState(null);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCheckout, setShowCheckout] = useState(false);

    useEffect(() => {
        const fetchShopData = async () => {
            try {
                const [shopResponse, categoriesResponse, featuredResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${id}`),
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${id}/categories`),
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${id}/featured-products`)
                ]);

                if (!shopResponse.ok || !categoriesResponse.ok || !featuredResponse.ok) {
                    throw new Error('Error carregant la botiga');
                }

                const [shopData, categoriesData, featuredData] = await Promise.all([
                    shopResponse.json(),
                    categoriesResponse.json(),
                    featuredResponse.json()
                ]);

                setShop(shopData);
                setCategories(categoriesData);
                setFeaturedProducts(featuredData);
                
                // Carreguem els productes inicials
                const productsResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${id}/products`);
                if (!productsResponse.ok) throw new Error('Error carregant els productes');
                const productsData = await productsResponse.json();
                setProducts(productsData);

            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchShopData();
    }, [id]);

    const handleCategorySelect = async (categoryId) => {
        setSelectedCategory(categoryId);
        try {
            const response = categoryId
                ? await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${id}/products?category=${categoryId}`)
                : await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${id}/products`);
            if (!response.ok) throw new Error('Error carregant els productes');
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        }
    };

    const renderCategories = () => {
        return (
            <div className="bg-white rounded-lg shadow p-4">
                <h2 className="text-lg font-semibold mb-4">Categories</h2>
                
                {/* Botón para mostrar todos los productos */}
                <button
                    onClick={() => handleCategorySelect(null)}
                    className={`w-full text-left px-3 py-2 rounded-md mb-2 transition-colors ${
                        !selectedCategory 
                            ? 'bg-[#ED5B52] text-white' 
                            : 'hover:bg-gray-100'
                    }`}
                >
                    Tots els productes
                </button>

                {/* Lista de categorías */}
                <div className="space-y-1">
                    {categories.map(category => (
                        <button
                            key={category.id}
                            onClick={() => handleCategorySelect(category.id)}
                            className={`w-full text-left px-3 py-2 rounded-md transition-colors ${
                                selectedCategory === category.id 
                                    ? 'bg-[#ED5B52] text-white' 
                                    : 'hover:bg-gray-100'
                            }`}
                        >
                            {category.name}
                            {category.products_count > 0 && (
                                <span className="ml-2 text-sm">
                                    ({category.products_count})
                                </span>
                            )}
                        </button>
                    ))}
                </div>
            </div>
        );
    };

    const renderFeaturedProducts = () => (
        <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-6">Productes Destacats</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {featuredProducts.map(product => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
        </div>
    );

    const renderProducts = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {products.map(product => (
                <ProductCard key={product.id} product={product} />
            ))}
        </div>
    );

    const handleCheckoutSuccess = (paymentHash) => {
        // Redirigir a la página de pago
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/payment/${paymentHash}`;
    };

    if (isLoading) {
        return (
            <PublicLayout>
                <div className="flex items-center justify-center min-h-screen">
                    <div className="text-center">Carregant...</div>
                </div>
            </PublicLayout>
        );
    }

    if (error) {
        return (
            <PublicLayout>
                <div className="flex items-center justify-center min-h-screen">
                    <div className="text-red-500">{error}</div>
                </div>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout 
            shop={shop}
            error={error}
            hasShops={true}
            hasPayments={true}
            hasForms={true}
            shopInfo={{
                address: shop?.configuration?.address,
                email: shop?.configuration?.contact_email,
                phone: shop?.configuration?.phone,
                description: shop?.description,
                social: {
                    facebook: shop?.configuration?.social?.facebook,
                    instagram: shop?.configuration?.social?.instagram,
                    twitter: shop?.configuration?.social?.twitter
                }
            }}
        >
            {shop && <CartSidebar shop={shop} />}
            <main className="max-w-7xl mx-auto px-4 py-8">
                {showCheckout ? (
                    <div className="max-w-2xl mx-auto">
                        <h2 className="text-2xl font-bold mb-6">Finalizar compra</h2>
                        <CheckoutForm 
                            shop={shop} 
                            onSuccess={handleCheckoutSuccess}
                        />
                    </div>
                ) : (
                    <>
                        {/* Nova capçalera */}
                        <section className="relative bg-gray-200 text-gray-800 py-20 rounded-lg overflow-hidden mb-12">
                            <div className="relative z-10 max-w-3xl mx-auto text-center px-4">
                                <h1 className="text-4xl font-bold mb-6">{shop?.name}</h1>
                                <p className="text-xl mb-8">{shop?.description}</p>
                            </div>
                            <div className="absolute inset-0 bg-gray-300 opacity-50"></div>
                        </section>

                        {/* Contingut principal */}
                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                            {/* Sidebar amb categories */}
                            <aside className="lg:col-span-1">
                                {renderCategories()}
                            </aside>

                            {/* Productes */}
                            <div className="lg:col-span-3">
                                {!selectedCategory && renderFeaturedProducts()}
                                {renderProducts()}
                            </div>
                        </div>
                    </>
                )}
            </main>
        </PublicLayout>
    );
};

// Component de targeta de producte actualitzat
const ProductCard = ({ product }) => {
    const { addToCart } = useCart();
    const [quantity, setQuantity] = useState(1);
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [currentPrice, setCurrentPrice] = useState(product.pivot.price);

    // Agrupar atributos por nombre
    const groupedAttributes = useMemo(() => {
        if (!product.attributes) return {};
        return product.attributes.reduce((acc, attr) => {
            if (!acc[attr.name]) {
                acc[attr.name] = [];
            }
            acc[attr.name].push(attr);
            return acc;
        }, {});
    }, [product.attributes]);

    // Calcular precio final basado en los atributos seleccionados
    const calculatePrice = useCallback((basePrice, attributes) => {
        let finalPrice = parseFloat(basePrice);
        Object.values(attributes).forEach(attr => {
            if (attr.priceModifier) {
                if (attr.priceModifierType === 'fixed') {
                    finalPrice += parseFloat(attr.priceModifier);
                } else if (attr.priceModifierType === 'percentage') {
                    finalPrice += (finalPrice * parseFloat(attr.priceModifier)) / 100;
                }
            }
        });
        return finalPrice.toFixed(2);
    }, []);

    // Manejar cambios en los atributos
    const handleAttributeChange = (name, value) => {
        const newAttributes = {
            ...selectedAttributes,
            [name]: JSON.parse(value)
        };
        setSelectedAttributes(newAttributes);
        setCurrentPrice(calculatePrice(product.pivot.price, newAttributes));
    };

    // Verificar si todos los atributos requeridos están seleccionados
    const areAllAttributesSelected = Object.keys(groupedAttributes).length === Object.keys(selectedAttributes).length;

    return (
        <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
            <div className="relative pt-[100%]">
                <div className="absolute inset-0">
                    {product.images && product.images.length > 0 ? (
                        <img
                            src={product.images[0]}
                            alt={product.name}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                                const parent = e.target.parentElement;
                                parent.innerHTML = '';
                                parent.appendChild(
                                    (() => {
                                        const div = document.createElement('div');
                                        div.className = 'w-full h-full';
                                        div.innerHTML = DefaultImage({ type: 'product', seed: product.name }).props.children;
                                        return div;
                                    })()
                                );
                            }}
                        />
                    ) : (
                        <div className="w-full h-full">
                            <DefaultImage type="product" seed={product.name} />
                        </div>
                    )}
                </div>
            </div>
            <div className="p-4 flex flex-col flex-grow">
                <h3 className="font-semibold mb-2 line-clamp-1">{product.name}</h3>
                <p className="text-gray-600 text-sm mb-4 line-clamp-2">{product.description}</p>
                
                {/* Selectores de atributos */}
                {Object.entries(groupedAttributes).length > 0 && (
                    <div className="space-y-3 mb-4">
                        {Object.entries(groupedAttributes).map(([name, values]) => (
                            <div key={name}>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {name}
                                </label>
                                <select
                                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    onChange={(e) => handleAttributeChange(name, e.target.value)}
                                    value={selectedAttributes[name] ? JSON.stringify(selectedAttributes[name]) : ''}
                                >
                                    <option value="">Selecciona {name.toLowerCase()}</option>
                                    {values.map((attr, index) => (
                                        <option key={index} value={JSON.stringify(attr)}>
                                            {attr.value}
                                            {attr.priceModifier > 0 && 
                                                ` (+${attr.priceModifier}${attr.priceModifierType === 'percentage' ? '%' : '€'})`
                                            }
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ))}
                    </div>
                )}
                
                <div className="mt-auto">
                    <div className="text-lg font-bold mb-3">{currentPrice}€</div>
                    <div className="grid grid-cols-3 gap-2">
                        <input
                            type="number"
                            min="1"
                            value={quantity}
                            onChange={(e) => setQuantity(parseInt(e.target.value))}
                            className="col-span-1 p-1.5 border rounded text-center"
                        />
                        <button 
                            onClick={() => addToCart({
                                ...product,
                                selectedAttributes,
                                finalPrice: currentPrice
                            }, quantity)}
                            className="col-span-2 border-2 border-[#ED5B52] bg-white text-[#ED5B52] px-4 py-1.5 rounded hover:bg-[#ED5B52] hover:text-white transition-colors text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={!areAllAttributesSelected && Object.keys(groupedAttributes).length > 0}
                        >
                            {!areAllAttributesSelected && Object.keys(groupedAttributes).length > 0 
                                ? 'Selecciona opcions' 
                                : 'Comprar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicOnlineShop; 