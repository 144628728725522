import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../resources/iconsmall.png';

function FeatureCard({ title, description }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}

function Home() {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <img src={logo} alt="Logotip" className="w-12" />
          <nav>
            <Link to="/login" className="text-[#ED5B52] hover:text-[#D64A41] mr-4">Iniciar sessió</Link>
            <Link to="/register" className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200">
              Registrar-se
            </Link>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <section className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Benvingut a cobraments.com</h1>
          <p className="text-xl text-gray-600 mb-8">La teva plataforma integral de gestió de cobraments per a passarel·les de pagament <b>REDSYS</b> i <b>CECA</b>.</p>
          <Link to="/register" className="bg-green-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-green-700 transition duration-200">
            Comença ara
          </Link>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-center">Els nostres serveis</h2>
          <p className="text-xl text-gray-600 mb-8 text-center">Tens una passarel·la <b>REDSYS</b> o <b>CECA</b> i només la fas servir per a la teva botiga en línia? Amb Cobraments.com, podràs acceptar tot tipus de pagaments, oferint als teus clients més opcions per pagar els teus productes i serveis.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard 
              title="Passarel·la oberta" 
              description="Cobra qualsevol concepte i quantitat. Opció de bloqueig amb codi d'accés."
            />
            <FeatureCard 
              title="Passarel·la amb formulari" 
              description="Ideal per a inscripcions, matrícules i serveis amb preus variables."
            />
            <FeatureCard 
              title="Botiga en línia" 
              description="Carretó de compra bàsic amb gestió de descomptes."
            />
            <FeatureCard 
              title="Integració API" 
              description="Connecta la teva aplicació externa per a cobraments sense problemes."
            />
            <FeatureCard 
              title="Sol·licituds de cobrament únics o recurrents" 
              description="Envia peticions de pagament individuals o en massa."
            />
            <FeatureCard 
              title="Cobraments mitjançant enllaç, QR i codi de barres" 
              description="Si no disposes de TPV físic, podràs utilitzar aquests mètodes per a rebre pagaments."
            />
          </div>
        </section>

        <section className="text-center mb-16">
          <h2 className="text-3xl font-semibold mb-4">Connecta múltiples passarel·les de pagament</h2>
          <p className="text-xl text-gray-600 mb-8">Integrem les passarel·les de pagament més populars com són <b>REDSYS</b> i <b>CECA</b> en una única plataforma per maximitzar-ne el rendiment. A més a més, si disposes de diverses passarel·les <b>REDSYS</b> o <b>CECA</b>, les podràs integrar a la mateixa plataforma i decidir quina utilitzar en cada cas.</p>
        </section>

        <section className="text-center mb-16">
          <h2 className="text-3xl font-semibold mb-4">Preus transparents i assequibles</h2>
          <p className="text-xl text-gray-600 mb-8">Sistema de preus flexible que s'adapta a les teves necessitats.</p>
          
          {/* Crèdit inicial */}
          <div className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto mb-12">
            <div className="pb-2 mb-2">
              <h3 className="text-2xl font-semibold mb-2">Crèdit inicial</h3>
              <p className="text-sm text-gray-600 mb-2">El crèdit inicial per utilitzar el servei és obligatori i no reembolsable<br/>Amb aquest crèdit inicial, podràs generar i enviar sol·licituds de qualsevol tipus.</p>
              <p className="text-4xl font-bold">100€</p>
            </div>
          </div>

          {/* Sistema de funcionament */}
          <div className="bg-white p-6 rounded-lg shadow-md max-w-4xl mx-auto mb-12">
            <h3 className="text-2xl font-semibold mb-4">Com funciona?</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="p-4">
                <h4 className="text-lg font-semibold mb-2">1. Crèdit Inicial</h4>
                <p className="text-gray-600">Comença a utilitzar el servei complet amb 100€ de crèdit inicial.</p>
              </div>
              <div className="p-4">
                <h4 className="text-lg font-semibold mb-2">2. Consum Flexible</h4>
                <p className="text-gray-600">Un cop consumit el crèdit inicial, podràs mantenir-ho il·limitat o bé establir un límit de consum mitjançant un crèdit mensual.</p>
              </div>
              <div className="p-4">
                <h4 className="text-lg font-semibold mb-2">3. Control Total</h4>
                <p className="text-gray-600">En tot moment podrás establir notificacions sobre límits de consum.</p>
              </div>
            </div>
          </div>

          {/* Preus per tipus de sol·licitud */}
          <div className="mb-12">
            <h3 className="text-2xl font-semibold mb-2">Preus per tipus de sol·licitud</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl mx-auto mb-4">
              {/* Grup 1: Cobraments Bàsics */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Cobraments Bàsics</h3>
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <span>Únic</span>
                    <span className="font-bold">0,65€</span>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span>Recurrent</span>
                    <span className="font-bold">0,90€</span>
                  </div>
                </div>
              </div>

              {/* Grup 2: Cobraments Interactius */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Cobraments Interactius</h3>
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <span>Carrito</span>
                    <span className="font-bold">0,85€</span>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span>Formulari</span>
                    <span className="font-bold">1,00€</span>
                  </div>
                </div>
              </div>

              {/* Grup 3: Cobraments Avançats */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Cobraments Avançats</h3>
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <span>Obert</span>
                    <span className="font-bold">0,70€</span>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span>API</span>
                    <span className="font-bold">1,50€</span>
                  </div>
                </div>
              </div>

              {/* Grup 4: Cobraments Directes */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Cobraments Directes</h3>
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <span>Enllaç</span>
                    <span className="font-bold">0,70€</span>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span>QR i codi de barres</span>
                    <span className="font-bold">0,75€</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-sm text-gray-600 mt-4 bg-gray-50 p-4 rounded-lg inline-block">
              <p className="flex items-center">
                <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                El costos especificats son per cada sol·licitud enviada o generada d'aquell tipus, independentment del seu estat de pagament final.
              </p>
            </div>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-md inline-block">
            <h3 className="text-2xl font-semibold mb-4">Avantatges del sistema</h3>
            <ul className="text-center text-gray-600 mb-6">
              <li className="mb-2">✓ Sense quota mensual fixa</li>
              <li className="mb-2">✓ Sense permanència</li>
              <li className="mb-2">✓ Control total de la despesa</li>
              <li className="mb-2">✓ Flexibilitat en el pagament</li>
              <li className="mb-2">✓ Suport tècnic inclòs</li>
              <li className="mb-2">✓ Facturació mensual</li>
              <li className="mb-2">✓ Perfil propi, subdomini i email @cobraments.com</li>
              <li>✓ Escala segons les teves necessitats</li>
            </ul>
            <hr/>
            <div className="text-sm text-gray-600 mt-6">
              <p>* Preus sense IVA.<br/>* Ni el crèdit inicial, ni el cost generat mensualment és reemborsable.<br/>* Els comptes sense noves sol·licituds durant 30 dies, es donaran de baixa automàticament.</p>
            </div>
          </div>
        </section>

        <section className="text-center">
          <h2 className="text-3xl font-semibold mb-8">Què necessito?</h2>
          <p className="text-xl text-gray-600 mb-8">
            Només necessites donar d'alta al teu banc una passarel·la de pagament <b>REDSYS</b> o <b>CECA</b> i a continuació registrar-te a cobraments.com i començar a generar i enviar sol·licituds de cobrament als teus clients.<br/>
          </p>
          <Link to="/register" className="bg-green-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-green-700 transition duration-200">
            Comença ara
          </Link>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} Cobraments.com. Tots els drets reservats. <Link to="https://venditec.com" className="hover:underline">Un producte de Venditec Technologies SL</Link></p>
          <p className="mt-2">
            <Link to="/politica-privacitat" className="hover:underline mr-4">Política de privacitat</Link>
            <Link to="/termes-servei" className="hover:underline">Termes del servei</Link>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
