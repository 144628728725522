import React from 'react';
import { Link } from 'react-router-dom';
import { 
  FaArrowLeft, FaBuilding, FaPalette, FaFileInvoiceDollar, 
  FaGavel, FaAddressCard, FaChartBar, FaShieldAlt, 
  FaClipboardList, FaTicketAlt, FaBell, FaGlobe, FaDatabase
} from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from './common/LoadingSpinner';

const SettingsBlock = ({ icon: Icon, title, description, links }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
    <div className="flex items-center mb-4">
      <Icon className="text-2xl text-green-500 mr-3" />
      <h3 className="text-xl font-semibold">{title}</h3>
    </div>
    <div className="flex-grow">
      <p className="text-gray-600 mb-4">{description}</p>
    </div>
    <div className="mt-auto">
      <hr className="my-4 border-gray-200" />
      <div className="flex space-x-4">
        {links.map((link, index) => (
          <Link 
            key={index}
            to={link.to} 
            className={`flex-1 text-center ${
              link.primary 
                ? "bg-green-500 text-white hover:bg-green-600" 
                : "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
            } px-4 py-2 rounded transition duration-200`}
          >
            {link.text}
          </Link>
        ))}
      </div>
    </div>
  </div>
);

const Settings = () => {
  const { user, loading: authLoading } = useAuth();

  if (authLoading) {
    return <LoadingSpinner message="Carregant configuració..." />;
  }

  const settingsBlocks = [
    {
      icon: FaBuilding,
      title: "Dades i Perfil",
      description: "Gestiona les dades oficials de la societat i del perfil d'empresa",
      links: [{ to: "/configuracio/perfil", text: "Gestionar", primary: true }]
    },
    {
      icon: FaPalette,
      title: "Estils",
      description: "Personalitza els colors, tipografia i logotip de la teva marca",
      links: [{ to: "/configuracio/styles", text: "Personalitzar", primary: true }]
    },
    {
      icon: FaFileInvoiceDollar,
      title: "Facturació",
      description: "Gestiona factures, mètodes de pagament i opcions de subscripció",
      links: [
        { to: "/configuracio/billing", text: "Gestionar", primary: true },
        { to: "/configuracio/billing/history", text: "Historial" }
      ]
    },
    {
      icon: FaGavel,
      title: "Texts Legals",
      description: "Configura la privacitat, condicions del servei i política de cookies",
      links: [{ to: "/configuracio/legal", text: "Configurar", primary: true }]
    },
    {
      icon: FaAddressCard,
      title: "Dades de Contacte",
      description: "Gestiona les direccions físiques i electròniques de contacte",
      links: [{ to: "/configuracio/contacte", text: "Gestionar", primary: true }]
    },
    {
      icon: FaChartBar,
      title: "Estadístiques",
      description: "Consulta les estadístiques d'ús del servei i rendiment",
      links: [{ to: "/configuracio/estadistiques", text: "Visualitzar", primary: true }]
    },
    {
      icon: FaShieldAlt,
      title: "Seguretat",
      description: "Configura opcions de seguretat, PIN i bloqueig del servei",
      links: [
        { to: "/configuracio/security", text: "Configurar", primary: true },
      ]
    },
    {
      icon: FaTicketAlt,
      title: "Incidències",
      description: "Gestiona els tiquets de suport i seguiment d'incidències",
      links: [
        { to: "/configuracio/tickets", text: "Gestionar", primary: true },
        { to: "/configuracio/tickets/new", text: "Nou Tiquet" }
      ]
    },
    {
      icon: FaBell,
      title: "Notificacions",
      description: "Configura les preferències de notificacions i alertes",
      links: [{ to: "/configuracio/notifications", text: "Configurar", primary: true }]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Configuració del Sistema</h1>
          <Link
            to="/dashboard"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Tornar
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {settingsBlocks.map((block, index) => (
            <SettingsBlock key={index} {...block} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Settings; 