import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white shadow-md mt-auto">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-center md:text-left">
            <p className="text-gray-600">
              © {currentYear} Venditec Technologies SL. Tots els drets reservats.
            </p>
          </div>
          
          <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-6">
            <Link 
              to="/privacy-policy" 
              className="text-gray-600 hover:text-[#ED5B52] transition duration-200"
            >
              Política de Privacitat
            </Link>
            <Link 
              to="/terms-of-service" 
              className="text-gray-600 hover:text-[#ED5B52] transition duration-200"
            >
              Termes i Condicions
            </Link>
            <Link 
              to="/report-issue" 
              className="text-gray-600 hover:text-[#ED5B52] transition duration-200"
            >
              Estat del Servei
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
