import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft, FaFilePdf, FaEdit, FaCheck, FaTimes, FaTrash, FaUpload, FaEye, FaLink, FaExternalLinkAlt, FaLock } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const OpenPaymentDetails = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [payment, setPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editForm, setEditForm] = useState({
        name: '',
        description: '',
        amount: '',
        concept: '',
        status: '',
        payment_type_id: '',
        configuration: {
            is_open: true,
            allow_custom_amount: false,
            allow_custom_concept: false,
            start_date: '',
            end_date: '',
            recipients: [],
            frequency: '',
            until_amount_completed: ''
        }
    });
    const [payments, setPayments] = useState([]);
    const [isLoadingPayments, setIsLoadingPayments] = useState(true);
    const [paymentsError, setPaymentsError] = useState(null);

    const frequencyTranslations = {
        'daily': 'Diària',
        'weekly': 'Setmanal',
        'bi-weekly': 'Quinzenal',
        'monthly': 'Mensual',
        'bi-monthly': 'Bimensual',
        'quarterly': 'Trimestral',
        'semi-annually': 'Semestral',
        'annually': 'Anual'
    };

    useEffect(() => {
        fetchPaymentDetails();
    }, [id]);

    useEffect(() => {
        if (payment?.id) {
            fetchAssociatedPayments();
        }
    }, [payment]);

    useEffect(() => {
        if (payment) {
            setEditForm({
                name: payment.name || '',
                description: payment.description || '',
                amount: payment.amount || '',
                concept: payment.concept || '',
                status: payment.status || '',
                payment_type_id: payment.payment_type?.id || '',
                configuration: {
                    is_open: payment.configuration?.is_open ?? true,
                    allow_custom_amount: payment.configuration?.allow_custom_amount ?? false,
                    allow_custom_concept: payment.configuration?.allow_custom_concept ?? false,
                    start_date: payment.configuration?.start_date || '',
                    end_date: payment.configuration?.end_date || '',
                    recipients: payment.configuration?.recipients || [],
                    frequency: payment.configuration?.frequency || '',
                    until_amount_completed: payment.configuration?.until_amount_completed || ''
                }
            });
        }
    }, [payment]);

    const fetchPaymentDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els detalls del cobrament');

            const data = await response.json();
            setPayment(data);
            
            // Actualitzem el formulari d'edició amb l'estructura correcta
            setEditForm({
                name: data.name || '',
                description: data.description || '',
                amount: data.amount || '',
                concept: data.concept || '',
                payment_type_id: data.payment_type?.id || '',
                configuration: {
                    is_open: data.configuration?.is_open ?? true,
                    allow_custom_amount: data.configuration?.allow_custom_amount ?? false,
                    allow_custom_concept: data.configuration?.allow_custom_concept ?? false,
                    start_date: data.configuration?.start_date || '',
                    end_date: data.configuration?.end_date || '',
                    recipients: data.configuration?.recipients || [],
                    frequency: data.configuration?.frequency || '',
                    until_amount_completed: data.configuration?.until_amount_completed || ''
                },
                documents: []
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAssociatedPayments = async () => {
        if (!payment?.id) return;

        try {
            setIsLoadingPayments(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${payment.id}/payments`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) throw new Error('Error al cargar los pagos asociados');
            
            const data = await response.json();
            console.log('Datos recibidos:', data);
            setPayments(data);
        } catch (error) {
            console.error('Error completo:', error);
            setPaymentsError(error.message);
        } finally {
            setIsLoadingPayments(false);
        }
    };

    const handleToggleStatus = async () => {
        try {
            const newStatus = payment.status === 'active' ? 'inactive' : 'active';
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus })
            });

            if (!response.ok) throw new Error('Error al canviar l\'estat');

            setPayment({ ...payment, status: newStatus });
            alert('Estat actualitzat correctament');
        } catch (error) {
            alert(error.message);
        }
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        e.target.value = '';
        
        setEditForm(prev => ({
            ...prev,
            documents: [...prev.documents, ...files]
        }));
    };

    const handleRemoveNewDocument = (indexToRemove) => {
        setEditForm(prev => ({
            ...prev,
            documents: prev.documents.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleDeleteDocument = async (documentId) => {
        if (!window.confirm('Estàs segur que vols eliminar aquest document?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${id}/documents/${documentId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al eliminar el document');

            await fetchPaymentDetails();
            
            alert('Document eliminat correctament');
        } catch (error) {
            alert(error.message);
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        try {
            const isRecurrent = payment.payment_type?.slug === 'cobrament-recurrent';
            
            // Format de data YYYY-MM-DD
            const formatDate = (date) => {
                if (!date) return null;
                const d = new Date(date);
                return d instanceof Date && !isNaN(d) 
                    ? d.toISOString().split('T')[0]
                    : null;
            };

            const paymentData = {
                name: editForm.name,
                description: editForm.description,
                amount: editForm.configuration.allow_custom_amount ? null : parseFloat(editForm.amount),
                concept: editForm.configuration.allow_custom_concept ? null : editForm.concept,
                payment_type_id: payment.payment_type?.id,
                currency: 'EUR',
                configuration: {
                    is_open: true,
                    allow_custom_amount: editForm.configuration.allow_custom_amount,
                    allow_custom_concept: editForm.configuration.allow_custom_concept,
                    start_date: formatDate(editForm.configuration.start_date),
                    recipients: []
                }
            };

            // Configuració específica segons el tipus de pagament
            if (isRecurrent) {
                paymentData.configuration = {
                    ...paymentData.configuration,
                    frequency: editForm.configuration.frequency,
                    until_amount_completed: editForm.configuration.until_amount_completed 
                        ? parseFloat(editForm.configuration.until_amount_completed)
                        : null,
                    end_date: null // Els pagaments recurrents no tenen data fi
                };
            } else {
                paymentData.configuration = {
                    ...paymentData.configuration,
                    end_date: formatDate(editForm.configuration.end_date),
                    frequency: null,
                    until_amount_completed: null
                };
            }

            // Mantenir la configuració existent
            paymentData.configuration = {
                ...payment.configuration,
                ...paymentData.configuration
            };

            console.log('Enviant dades:', paymentData);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(paymentData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error detallat:', errorData);
                throw new Error(errorData.message || 'Error al actualitzar el cobrament');
            }

            await fetchPaymentDetails();
            setIsEditing(false);
            alert('Cobrament actualitzat correctament');
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const viewDocument = (documentPath) => {
        const filename = documentPath.split('/').pop();
        
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${id}/documents/${filename}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            if (!response.ok) throw new Error('Error al obtenir el document');
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Error al obtenir el document');
        });
    };

    // Gestionar canvis en l'import personalitzat
    const handleCustomAmountChange = (isCustom) => {
        setEditForm(prev => ({
            ...prev,
            configuration: {
                ...prev.configuration,
                allow_custom_amount: isCustom
            },
            amount: isCustom ? '' : prev.amount
        }));
    };

    // Gestionar canvis en el concepte personalitzat
    const handleCustomConceptChange = (isCustom) => {
        setEditForm(prev => ({
            ...prev,
            configuration: {
                ...prev.configuration,
                allow_custom_concept: isCustom
            },
            concept: isCustom ? '' : prev.concept
        }));
    };

    // Formulari d'edició
    const renderEditForm = () => {
        if (!isEditing || !payment) return null;

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                <div className="relative top-20 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-semibold">Editar Cobrament Obert</h3>
                        <button
                            onClick={() => setIsEditing(false)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <FaTimes />
                        </button>
                    </div>
                    <form onSubmit={handleSubmitEdit}>
                        {/* Nom del cobrament */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Nom del cobrament
                            </label>
                            <input
                                type="text"
                                value={editForm.name}
                                onChange={(e) => setEditForm(prev => ({ ...prev, name: e.target.value }))}
                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                required
                            />
                        </div>

                        {/* Descripció */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Descripció
                            </label>
                            <textarea
                                value={editForm.description}
                                onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                rows="3"
                            />
                        </div>

                        {/* Tipus de cobrament */}
                        <div className="space-y-6 border-b border-gray-200 py-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Tipus de cobrament</h3>
                            
                            <div className="space-y-4">
                                {/* Mostrar el tipus de pagament (no editable) */}
                                <div className="text-gray-700">
                                    {payment.payment_type?.name || 'No especificat'}
                                </div>

                                {/* Camps específics segons el tipus */}
                                {payment.payment_type?.slug === 'cobrament-unic' ? (
                                    <div className="ml-7 mt-4 space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Data d'inici
                                            </label>
                                            <input
                                                type="date"
                                                value={editForm.configuration.start_date}
                                                onChange={(e) => setEditForm(prev => ({
                                                    ...prev,
                                                    configuration: {
                                                        ...prev.configuration,
                                                        start_date: e.target.value
                                                    }
                                                }))}
                                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Data de finalització
                                            </label>
                                            <input
                                                type="date"
                                                value={editForm.configuration.end_date}
                                                onChange={(e) => setEditForm(prev => ({
                                                    ...prev,
                                                    configuration: {
                                                        ...prev.configuration,
                                                        end_date: e.target.value
                                                    }
                                                }))}
                                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                required
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ml-7 mt-4 space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Data d'inici
                                            </label>
                                            <input
                                                type="date"
                                                value={editForm.configuration.start_date}
                                                onChange={(e) => setEditForm(prev => ({
                                                    ...prev,
                                                    configuration: {
                                                        ...prev.configuration,
                                                        start_date: e.target.value
                                                    }
                                                }))}
                                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Freqüència
                                            </label>
                                            <select
                                                value={editForm.configuration.frequency}
                                                onChange={(e) => setEditForm(prev => ({
                                                    ...prev,
                                                    configuration: {
                                                        ...prev.configuration,
                                                        frequency: e.target.value
                                                    }
                                                }))}
                                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                required
                                            >
                                                {Object.entries(frequencyTranslations).map(([value, label]) => (
                                                    <option key={value} value={value}>{label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Import total a cobrar
                                            </label>
                                            <div className="flex items-center max-w-xs">
                                                <input
                                                    type="number"
                                                    value={editForm.configuration.until_amount_completed}
                                                    onChange={(e) => setEditForm(prev => ({
                                                        ...prev,
                                                        configuration: {
                                                            ...prev.configuration,
                                                            until_amount_completed: e.target.value
                                                        }
                                                    }))}
                                                    className="w-full p-2 border rounded-l focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    step="0.01"
                                                    min="0"
                                                />
                                                <span className="px-3 py-2 bg-gray-50 border border-l-0 rounded-r">€</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Configuració del cobrament */}
                        <div className="space-y-6">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Configuració del cobrament</h3>
                            
                            {/* Import */}
                            <div className="space-y-4">
                                <div className="flex items-center space-x-3">
                                    <input
                                        type="radio"
                                        id="fixedAmount"
                                        checked={!editForm.configuration.allow_custom_amount}
                                        onChange={() => handleCustomAmountChange(false)}
                                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                    />
                                    <label htmlFor="fixedAmount" className="text-sm font-medium text-gray-700">
                                        Import fix
                                    </label>
                                </div>
                                
                                {!editForm.configuration.allow_custom_amount && (
                                    <div className="ml-7">
                                        <div className="flex items-center max-w-xs">
                                            <input
                                                type="number"
                                                value={editForm.amount}
                                                onChange={(e) => setEditForm(prev => ({ ...prev, amount: e.target.value }))}
                                                className="w-full p-2 border rounded-l focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                step="0.01"
                                                min="0"
                                                required={!editForm.configuration.allow_custom_amount}
                                            />
                                            <span className="px-3 py-2 bg-gray-50 border border-l-0 rounded-r">€</span>
                                        </div>
                                    </div>
                                )}

                                <div className="flex items-center space-x-3">
                                    <input
                                        type="radio"
                                        id="customAmount"
                                        checked={editForm.configuration.allow_custom_amount}
                                        onChange={() => handleCustomAmountChange(true)}
                                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                    />
                                    <label htmlFor="customAmount" className="text-sm font-medium text-gray-700">
                                        Permetre que el destinatari introdueixi l'import
                                    </label>
                                </div>
                            </div>

                            {/* Concepte */}
                            <div className="space-y-4">
                                <div className="flex items-center space-x-3">
                                    <input
                                        type="radio"
                                        id="fixedConcept"
                                        checked={!editForm.configuration.allow_custom_concept}
                                        onChange={() => handleCustomConceptChange(false)}
                                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                    />
                                    <label htmlFor="fixedConcept" className="text-sm font-medium text-gray-700">
                                        Concepte fix
                                    </label>
                                </div>

                                {!editForm.configuration.allow_custom_concept && (
                                    <div className="ml-7">
                                        <input
                                            type="text"
                                            value={editForm.concept}
                                            onChange={(e) => setEditForm(prev => ({ ...prev, concept: e.target.value }))}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            required={!editForm.configuration.allow_custom_concept}
                                        />
                                    </div>
                                )}

                                <div className="flex items-center space-x-3">
                                    <input
                                        type="radio"
                                        id="customConcept"
                                        checked={editForm.configuration.allow_custom_concept}
                                        onChange={() => handleCustomConceptChange(true)}
                                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                    />
                                    <label htmlFor="customConcept" className="text-sm font-medium text-gray-700">
                                        Permetre que el destinatari introdueixi el concepte
                                    </label>
                                </div>
                            </div>

                            {/* Missatge informatiu */}
                            <div className="mt-4 bg-blue-50 p-4 rounded-md">
                                <p className="text-sm text-blue-700">
                                    {editForm.configuration.allow_custom_amount && editForm.configuration.allow_custom_concept ? (
                                        "El destinatari podrà introduir tant l'import com el concepte del pagament."
                                    ) : editForm.configuration.allow_custom_amount ? (
                                        "El destinatari podrà introduir l'import del pagament, però s'utilitzarà la descripció com a concepte."
                                    ) : editForm.configuration.allow_custom_concept ? (
                                        "El destinatari podrà introduir el concepte del pagament, però s'utilitzarà l'import fix especificat."
                                    ) : (
                                        "S'utilitzaran l'import i la descripció especificats com a valors fixos."
                                    )}
                                </p>
                            </div>
                        </div>

                        {/* Botons */}
                        <div className="flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={() => setIsEditing(false)}
                                className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Cancel·lar
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64A41]"
                            >
                                Guardar canvis
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    const copyPaymentLink = () => {
        const link = `${window.location.origin}/public-open-payments/${payment.public_hash}`;
        navigator.clipboard.writeText(link)
            .then(() => alert('Enllaç copiat al portapapers'))
            .catch(() => alert('Error al copiar l\'enllaç'));
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar el cobrament obert
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : payment ? (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">{payment.name}</h1>
                            <Link
                                to="/cobraments"
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                            >
                                <FaArrowLeft className="mr-2" /> Tornar
                            </Link>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6">
                            {isEditing ? (
                                renderEditForm()
                            ) : (
                                <div className="space-y-6">
                                    <div className="flex justify-end space-x-2">
                                        <button
                                            onClick={() => setIsEditing(true)}
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
                                        >
                                            <FaEdit className="mr-2" /> Editar
                                        </button>
                                        <button
                                            onClick={handleToggleStatus}
                                            className={`${
                                                payment.status === 'active' ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                                            } text-white px-4 py-2 rounded flex items-center`}
                                        >
                                            {payment.status === 'active' ? <FaTimes className="mr-2" /> : <FaCheck className="mr-2" />}
                                            {payment.status === 'active' ? 'Desactivar' : 'Activar'}
                                        </button>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Informació General</h3>
                                            <dl className="space-y-2">
                                                <div>
                                                    <dt className="font-medium">Nom:</dt>
                                                    <dd>{payment.name}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Descripció:</dt>
                                                    <dd>{payment.description || 'No especificada'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Import:</dt>
                                                    <dd>{payment.amount ? `${payment.amount}€` : 'Import personalitzat'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Concepte:</dt>
                                                    <dd>{payment.concept || 'No especificat'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Tipus de pagament:</dt>
                                                    <dd>{payment.payment_type?.name || 'No especificat'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Estat:</dt>
                                                    <dd>
                                                        <span className={`px-2 py-1 rounded-full text-sm ${
                                                            payment.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                        }`}>
                                                            {payment.status === 'active' ? 'Actiu' : 'Inactiu'}
                                                        </span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>

                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Configuració</h3>
                                            <dl className="space-y-2">
                                                <div>
                                                    <dt className="font-medium">Data Inici:</dt>
                                                    <dd>{payment.configuration?.start_date 
                                                        ? new Date(payment.configuration.start_date).toLocaleDateString('ca-ES')
                                                        : 'No especificada'}
                                                    </dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Data Fi:</dt>
                                                    <dd>
                                                        {payment.configuration?.end_date 
                                                            ? new Date(payment.configuration.end_date).toLocaleDateString('ca-ES')
                                                            : payment.configuration?.until_amount_completed
                                                                ? `Fins arribar a ${payment.configuration.until_amount_completed}€`
                                                                : 'No especificada'}
                                                    </dd>
                                                </div>
                                                {payment.payment_type?.slug === 'cobrament-recurrent' && (
                                                    <div>
                                                        <dt className="font-medium">Freqüència:</dt>
                                                        <dd>{payment.configuration?.frequency ? frequencyTranslations[payment.configuration.frequency] : 'No especificada'}</dd>
                                                    </div>
                                                )}
                                                <div>
                                                    <dt className="font-medium">Permet import personalitzat:</dt>
                                                    <dd>{payment.configuration?.allow_custom_amount ? 'Sí' : 'No'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Permet concepte personalitzat:</dt>
                                                    <dd>{payment.configuration?.allow_custom_concept ? 'Sí' : 'No'}</dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>

                                    {payment.documents?.length > 0 && (
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Documents</h3>
                                            <ul className="space-y-2">
                                                {payment.documents.map((doc, index) => (
                                                    <li key={index} className="flex items-center space-x-2">
                                                        <button
                                                            onClick={() => viewDocument(doc.file_path)}
                                                            className="flex items-center text-blue-500 hover:text-blue-700"
                                                        >
                                                            <FaFilePdf className="mr-2" />
                                                            {doc.file_name}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="bg-white rounded-lg shadow p-6 mt-6">
                            <h2 className="text-xl font-semibold mb-4 flex items-center">
                                <FaLink className="mr-2 text-gray-500" />
                                Enllaç de pagament
                            </h2>
                            <div className="space-y-4">
                                <div className="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg">
                                    <input
                                        type="text"
                                        value={`${window.location.origin}/public-open-payments/${payment.public_hash}`}
                                        className="flex-1 bg-transparent border-none focus:ring-0"
                                        readOnly
                                    />
                                    <button
                                        onClick={copyPaymentLink}
                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                        title="Copiar enllaç de pagament"
                                    >
                                        <FaLink className="mr-2" /> Copiar
                                    </button>
                                    <a
                                        href={`/public-open-payments/${payment.public_hash}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                        title="Veure pàgina de pagament"
                                    >
                                        <FaExternalLinkAlt className="mr-2" /> Veure
                                    </a>
                                </div>
                                {Boolean(payment.requires_access_code) && (
                                    <div className="text-sm text-gray-600 flex items-center">
                                        <FaLock className="mr-2" />
                                        Aquest pagament requereix codi d'accés
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6 mt-6">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Pagaments Associats</h2>
                            
                            {isLoadingPayments ? (
                                <div className="text-center py-4">Carregant pagaments...</div>
                            ) : paymentsError ? (
                                <div className="text-red-500 text-center py-4">{paymentsError}</div>
                            ) : (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Data
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Import
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Concepte
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Estat
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Referència
                                                </th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Accions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {isLoadingPayments ? (
                                                <tr>
                                                    <td colSpan="6" className="px-6 py-4 text-center">Carregant...</td>
                                                </tr>
                                            ) : !Array.isArray(payments) ? (
                                                <tr>
                                                    <td colSpan="6" className="px-6 py-4 text-center">Error: Dades no vàlides</td>
                                                </tr>
                                            ) : payments.length === 0 ? (
                                                <tr>
                                                    <td colSpan="6" className="px-6 py-4 text-center">No hi ha pagaments associats</td>
                                                </tr>
                                            ) : (
                                                payments.map((paymentItem, index) => {
                                                    if (!paymentItem) {
                                                        console.error('Payment item is null at index:', index);
                                                        return null;
                                                    }

                                                    return (
                                                        <tr key={paymentItem.id || index}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {paymentItem.created_at ? new Date(paymentItem.created_at).toLocaleDateString('ca-ES') : '-'}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {paymentItem.amount ? `${paymentItem.amount}€` : '-'}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {paymentItem.concept || 'No especificat'}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <span className={`px-2 py-1 rounded-full text-xs ${
                                                                    paymentItem.status === 'completed' 
                                                                        ? 'bg-green-100 text-green-800'
                                                                        : paymentItem.status === 'pending'
                                                                        ? 'bg-yellow-100 text-yellow-800'
                                                                        : paymentItem.status === 'active'
                                                                        ? 'bg-blue-100 text-blue-800'
                                                                        : paymentItem.status === 'paused'
                                                                        ? 'bg-gray-100 text-gray-800'
                                                                        : 'bg-red-100 text-red-800'
                                                                }`}>
                                                                    {paymentItem.status === 'completed' ? 'Completat' : 
                                                                     paymentItem.status === 'pending' ? 'Pendent' : 
                                                                     paymentItem.status === 'active' ? 'Actiu' :
                                                                     paymentItem.status === 'paused' ? 'Pausat' :
                                                                     'Cancel·lat'}
                                                                </span>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {'No disponible'}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                {paymentItem.id && (
                                                                    <button
                                                                        onClick={() => window.location.href = `/payments/${paymentItem.id}`}
                                                                        className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                        title="Veure detalls"
                                                                    >
                                                                        <FaEye />
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-gray-500 text-xl font-semibold">
                                No s'ha trobat el cobrament obert
                            </div>
                        </div>
                    </div>
                )}
            </main>
            <Footer />
            {payment && renderEditForm()}
        </div>
    );
};

export default OpenPaymentDetails; 