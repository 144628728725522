import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaPaperPlane, FaSearch } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from './common/LoadingSpinner';

function Dashboard() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [accountStats, setAccountStats] = useState({
    totalRequests: 0,
    totalAmount: 0,
    monthlyRequests: 0,
    monthlyAmount: 0,
    availableCredit: 0,
    monthlyCost: 0
  });

  useEffect(() => {
    if (!authLoading && user) {
      setIsLoading(false);
    }
  }, [authLoading, user]);

  if (isLoading || authLoading) {
    return <LoadingSpinner message="Carregant el tauler de control..." />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">

        {/* Estadísticas */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <StatCard title="Sol·licituds" value={accountStats.monthlyRequests} />
          <StatCard title="Import total" value={`${accountStats.totalAmount}€`} />
          <StatCard title="Credit disponible" value={`${accountStats.monthlyCost}€`} />
        </div>

        {/* Accesos Directos - 6 cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <DashboardCard 
            title="Pagaments" 
            description="Controla els pagaments rebuts i pendents"
            link="/payment-transactions"
          />
          <DashboardCard 
            title="Cobraments" 
            description="Gestiona tots els tipus de cobraments"
            link="/cobraments"
          />
          <DashboardCard 
            title="Clients" 
            description="Administra els teus clients i els seus pagaments"
            link="/clients"
          />
          <DashboardCard 
            title="Passarel·les bancaries" 
            description="Gestiona les teves passarel·les de pagament"
            link="/passarelles"
          />
          <DashboardCard 
            title="Usuaris" 
            description="Gestiona els usuaris i els seus permisos"
            link="/users"
          />
          <DashboardCard 
            title="Configuració" 
            description="Configura les dades del teu compte"
            link="/configuracio"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}

const DashboardCard = ({ title, description, link }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
    <div className="flex-grow">
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
    </div>
    <div className="mt-auto">
      <hr className="my-4 border-gray-200" />
      <div className="flex space-x-4">
        <Link 
          to={link}
          className="flex-1 text-center border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-green-500 hover:text-white transition duration-200"
        >
          Accedir
        </Link>
      </div>
    </div>
  </div>
);

function StatCard({ title, value }) {
  return (
    <div className="bg-gray-50 p-4 rounded-lg">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-2xl font-bold text-[#ED5B52]">{value}</p>
    </div>
  );
}

export default Dashboard;
