import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import logo from '../resources/iconsmall.png';
import { getErrorMessage } from '../utils/errorMessages';

function Alert({ errors, message, type }) {
  const alertStyles = {
    error: 'bg-red-50 border-red-500 text-red-700',
    success: 'bg-green-50 border-green-500 text-green-700',
    warning: 'bg-yellow-50 border-yellow-500 text-yellow-700'
  };

  return (
    <div className={`border-l-4 p-3 mb-4 ${alertStyles[type]} text-sm`} role="alert">
      <h3 className="font-semibold text-base mb-1">
        {type === 'error' ? 'Hi ha hagut alguns errors:' : type === 'success' ? 'Èxit!' : 'Avís!'}
      </h3>
      {Array.isArray(errors) ? (
        <ul className="list-disc list-inside">
          {errors.map((error, index) => (
            <li key={index} className="mb-1">{error}</li>
          ))}
        </ul>
      ) : (
        <p>{message || errors}</p>
      )}
    </div>
  );
}

function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');

        if (!token) {
          setAlert({ message: 'Enllaç de verificació invàlid.', type: 'error' });
          setIsLoading(false);
          return;
        }

        const url = `${process.env.REACT_APP_BACKEND_URL}/api/email/verify?token=${encodeURIComponent(token)}`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
        });

        const data = await response.json();

        if (response.ok) {
          setAlert({ message: 'Email verificat correctament!', type: 'success' });
          setTimeout(() => navigate('/login'), 3000);
        } else {
          setAlert({ message: data.message || getErrorMessage('unknown_error'), type: 'error' });
        }
      } catch (error) {
        console.error('Error during email verification:', error);
        setAlert({ message: getErrorMessage('network_error'), type: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="w-full max-w-2xl p-6 bg-white shadow-lg rounded-lg text-center">
        <Link to="/">
          <img src={logo} alt="Logotipo" className="w-20 mx-auto mb-4 cursor-pointer" />
        </Link>
        <h2 className="text-2xl font-bold mb-4">Verificació d'email</h2>
        {isLoading ? (
          <p className="text-gray-600 mb-4">Si us plau, espera mentre verifiquem el teu email...</p>
        ) : alert ? (
          <Alert message={alert.message} type={alert.type} />
        ) : null}
        <div className="mt-6">
          <Link to="/register" className="text-[#ED5B52] hover:underline mr-4">Registrar-se</Link>
          <Link to="/login" className="text-[#ED5B52] hover:underline">Iniciar sessió</Link>
        </div>
      </div>
      <footer className="mt-8 text-center text-gray-500">
        <p>&copy; {new Date().getFullYear()} cobraments.com . Tots els drets reservats.</p>
        <p>
          <Link to="/politica-privacitat" className="text-[#ED5B52] hover:underline">Política de privacitat</Link> |&nbsp; 
          <Link to="/termes-servei" className="text-[#ED5B52] hover:underline">Termes del servei</Link>
        </p>
      </footer>
    </div>
  );
}

export default VerifyEmail;
