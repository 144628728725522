import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaSearch } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const OnlineShopOrders = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [orders, setOrders] = useState([]);
    const [shop, setShop] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    useEffect(() => {
        Promise.all([
            fetchShopDetails(),
            fetchOrders()
        ]).finally(() => {
            setIsLoading(false);
        });
    }, [id]);

    const fetchShopDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setShop(data);
            }
        } catch (error) {
            setError('Error al carregar els detalls de la botiga');
        }
    };

    const fetchOrders = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/orders`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setOrders(data);
            } else {
                throw new Error('Error al carregar les comandes');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const getStatusBadgeClass = (status) => {
        const statusClasses = {
            'pending': 'bg-yellow-100 text-yellow-800',
            'processing': 'bg-blue-100 text-blue-800',
            'completed': 'bg-green-100 text-green-800',
            'cancelled': 'bg-red-100 text-red-800'
        };
        return statusClasses[status] || 'bg-gray-100 text-gray-800';
    };

    const filteredOrders = orders.filter(order => {
        const matchesSearch = order.reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            order.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            order.customer_email.toLowerCase().includes(searchTerm.toLowerCase());
        
        const matchesDate = !dateFilter || order.created_at.includes(dateFilter);
        const matchesStatus = !statusFilter || order.status === statusFilter;

        return matchesSearch && matchesDate && matchesStatus;
    });

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="flex-grow container mx-auto px-4 py-8">
                    <LoadingSpinner />
                </main>
                <Footer />
            </div>
        );
    }

    if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
    if (!shop) return <div className="text-center py-4">No s'ha trobat la botiga</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8">
                {/* Capçalera */}
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Comandes de {shop.name}</h1>
                    <Link
                        to={`/online-shops`}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {/* Filtres */}
                <div className="bg-white rounded-lg shadow p-6 mb-6">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cerca
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Cerca per referència, client..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-full p-2 border rounded-md pl-10"
                                />
                                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Data
                            </label>
                            <input
                                type="date"
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Estat
                            </label>
                            <select
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            >
                                <option value="">Tots els estats</option>
                                <option value="pending">Pendent</option>
                                <option value="processing">En procés</option>
                                <option value="completed">Completada</option>
                                <option value="cancelled">Cancel·lada</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Taula de comandes */}
                <div className="bg-white rounded-lg shadow overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Referència
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Client
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Data
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Estat
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Accions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredOrders.length > 0 ? (
                                filteredOrders.map((order) => (
                                    <tr key={order.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {order.reference}
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm font-medium text-gray-900">
                                                {order.customer_name}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {order.customer_email}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {new Date(order.created_at).toLocaleDateString('ca-ES')}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {new Intl.NumberFormat('ca-ES', {
                                                style: 'currency',
                                                currency: shop.currency
                                            }).format(order.total)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(order.status)}`}>
                                                {order.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button
                                                onClick={() => window.location.href = `/online-shops/${shop.id}/orders/${order.id}`}
                                                className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                title="Veure detalls"
                                            >
                                                <FaEye />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                        No s'han trobat comandes
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default OnlineShopOrders; 