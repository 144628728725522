import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaTrash, FaTrashRestore, FaPlus } from 'react-icons/fa';
import Header from '../../Header';
import Footer from '../../Footer';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingSpinner from '../../common/LoadingSpinner';

const ManageGroups = () => {
    const { user, loading: authLoading } = useAuth();
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);

    useEffect(() => {
        if (!authLoading) {
            fetchGroups();
        }
    }, [showDeleted, authLoading]);

    const fetchGroups = async () => {
        try {
            const endpoint = showDeleted
                ? `${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/deleted`
                : `${process.env.REACT_APP_BACKEND_URL}/api/customer-groups`;

            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setGroups(data || []);
            } else {
                throw new Error('Error al carregar els grups');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (groupId) => {
        if (window.confirm('Estàs segur que vols eliminar aquest grup?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${groupId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.ok) {
                    setGroups(groups.filter(group => group.id !== groupId));
                    alert('Grup eliminat correctament');
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error al eliminar el grup');
                }
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };

    const handleRestore = async (groupId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${groupId}/restore`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                setGroups(groups.filter(group => group.id !== groupId));
                alert('Grup restaurat correctament');
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al restaurar el grup');
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    if (authLoading || isLoading) {
        return <LoadingSpinner message="Carregant grups..." />;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Administrar Grups</h1>
                    <div className="flex gap-4">
                        <button
                            onClick={() => setShowDeleted(!showDeleted)}
                            className={`${
                                showDeleted 
                                    ? 'bg-red-500 hover:bg-red-600' 
                                    : 'bg-gray-500 hover:bg-gray-600'
                            } text-white px-4 py-2 rounded transition duration-200 flex items-center`}
                        >
                            <FaTrashRestore className="mr-2" />
                            {showDeleted ? 'Ocultar eliminats' : 'Mostrar eliminats'}
                        </button>
                        <Link
                            to="/clients"
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                        >
                            <FaArrowLeft className="mr-2" /> Tornar
                        </Link>
                    </div>
                </div>

                {isLoading ? (
                    <div className="text-center py-4">Carregant...</div>
                ) : error ? (
                    <div className="text-red-500 text-center py-4">{error}</div>
                ) : (
                    <div className="bg-white rounded-lg shadow overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nom
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Descripció
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Estat
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Clients
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Accions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {groups.map((group) => (
                                    <tr key={group.id} className={group.deleted_at ? 'bg-gray-50' : ''}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {group.name}
                                        </td>
                                        <td className="px-6 py-4">
                                            {group.description || '-'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                group.is_active
                                                    ? 'bg-green-100 text-green-800'
                                                    : 'bg-red-100 text-red-800'
                                            }`}>
                                                {group.is_active ? 'Actiu' : 'Inactiu'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {group.customers_count || 0}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <div className="flex justify-end space-x-2">
                                                {showDeleted ? (
                                                    <button 
                                                        onClick={() => handleRestore(group.id)}
                                                        className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors duration-200"
                                                        title="Restaurar"
                                                    >
                                                        <FaTrashRestore />
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button 
                                                            onClick={() => window.location.href = `/clients/groups/${group.id}`}
                                                            className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                            title="Veure detalls"
                                                        >
                                                            <FaEye />
                                                        </button>
                                                        <button 
                                                            onClick={() => handleDelete(group.id)}
                                                            className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                            title="Eliminar"
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {groups.length === 0 && (
                                    <tr>
                                        <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                            {showDeleted 
                                                ? 'No s\'han trobat grups eliminats'
                                                : 'No s\'han trobat grups'}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default ManageGroups; 