import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaUsers, FaUserTag, FaUserCog } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from './common/LoadingSpinner';

const Users = () => {
  const { user, loading: authLoading } = useAuth();

  if (authLoading) {
    return <LoadingSpinner message="Carregant gestió d'usuaris..." />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Gestió d'Usuaris del Sistema</h1>
          <Link
            to="/dashboard"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Tornar
          </Link>
        </div>

        {/* Blocs principals */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {/* Usuaris */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
            <div className="flex items-center mb-4">
              <FaUsers className="text-2xl text-green-500 mr-3" />
              <h3 className="text-xl font-semibold">Usuaris</h3>
            </div>
            <div className="flex-grow">
              <p className="text-gray-600 mb-4">
                Gestiona els usuaris que tenen accés al sistema i les seves dades
              </p>
            </div>
            <div className="mt-auto">
              <hr className="my-4 border-gray-200" />
              <div className="flex space-x-4">
                <Link 
                  to="/users/create" 
                  className="flex-1 text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
                >
                  Crear
                </Link>
                <Link 
                  to="/users/manage" 
                  className="flex-1 text-center border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-green-500 hover:text-white transition duration-200"
                >
                  Administrar
                </Link>
              </div>
            </div>
          </div>

          {/* Rols */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
            <div className="flex items-center mb-4">
              <FaUserTag className="text-2xl text-green-500 mr-3" />
              <h3 className="text-xl font-semibold">Rols</h3>
            </div>
            <div className="flex-grow">
              <p className="text-gray-600 mb-4">
                Defineix i gestiona els diferents rols i els seus permisos dins del sistema
              </p>
            </div>
            <div className="mt-auto">
              <hr className="my-4 border-gray-200" />
              <div className="flex space-x-4">
                <Link 
                  to="/users/roles/create" 
                  className="flex-1 text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
                >
                  Crear
                </Link>
                <Link 
                  to="/users/manage-roles" 
                  className="flex-1 text-center border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-green-500 hover:text-white transition duration-200"
                >
                  Administrar
                </Link>
              </div>
            </div>
          </div>

          {/* Assignació de Rols */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
            <div className="flex items-center mb-4">
              <FaUserCog className="text-2xl text-green-500 mr-3" />
              <h3 className="text-xl font-semibold">Assignació de Rols</h3>
            </div>
            <div className="flex-grow">
              <p className="text-gray-600 mb-4">
                Assigna i gestiona els rols dels usuaris per controlar els seus permisos i accés
              </p>
            </div>
            <div className="mt-auto">
              <hr className="my-4 border-gray-200" />
              <div className="flex space-x-4">
                <Link 
                  to="/users/assign-roles" 
                  className="flex-1 text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
                >
                  Assignar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Users; 