import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaTrash, FaFileAlt, FaTrashRestore, FaPlus } from 'react-icons/fa';
import Header from '../../Header';
import Footer from '../../Footer';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useAuth } from '../../../contexts/AuthContext';

const ManagePaymentForms = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [forms, setForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    const fetchForms = async () => {
      setIsLoading(true);
      try {
        const endpoint = showDeleted
          ? `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/deleted`
          : `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms`;
          
        const response = await fetch(endpoint, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (response.ok) {
          const result = await response.json();
          setForms(result);
        } else {
          throw new Error('Error al carregar els formularis');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchForms();
  }, [showDeleted]);

  const handleDelete = async (formId) => {
    if (window.confirm('Estàs segur que vols eliminar aquest formulari?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          setForms(forms.filter(form => form.id !== formId));
          alert('Formulari eliminat correctament');
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al eliminar el formulari');
        }
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    }
  };

  const handleRestore = async (formId) => {
    if (window.confirm('Estàs segur que vols restaurar aquest formulari?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/restore`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          setForms(forms.filter(form => form.id !== formId));
          alert('Formulari restaurat correctament');
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al restaurar el formulari');
        }
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-8 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Administrar Formularis de Pagament</h1>
          <div className="flex gap-4">
            <button
              onClick={() => setShowDeleted(!showDeleted)}
              className={`${
                showDeleted 
                  ? 'bg-red-500 hover:bg-red-600' 
                  : 'bg-gray-500 hover:bg-gray-600'
              } text-white px-4 py-2 rounded transition duration-200 flex items-center`}
            >
              <FaTrashRestore className="mr-2" />
              {showDeleted ? 'Ocultar eliminats' : 'Mostrar eliminats'}
            </button>
            <Link
              to="/cobraments"
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
            >
              <FaArrowLeft className="mr-2" /> Tornar
            </Link>
          </div>
        </div>

        {isLoading ? (
            <LoadingSpinner />
        ) : error ? (
          <div className="text-red-500 text-center py-4">{error}</div>
        ) : (
          <div className="bg-white rounded-lg shadow overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nom
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Servei
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Preu Base
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Moneda
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Estat
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Públic
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Accions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {forms.map((form) => (
                  <tr key={form.id} className={form.deleted_at ? 'bg-gray-50' : ''}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {form.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {form.service_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {form.base_price}€
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {form.currency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        form.status === 'active' ? 'bg-green-100 text-green-800' : 
                        'bg-red-100 text-red-800'
                      }`}>
                        {form.status === 'active' ? 'Actiu' : 'Inactiu'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        form.is_public ? 'bg-green-100 text-green-800' : 
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {form.is_public ? 'Sí' : 'No'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        {showDeleted ? (
                          <button 
                            onClick={() => handleRestore(form.id)}
                            className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors duration-200"
                            title="Restaurar"
                          >
                            <FaTrashRestore />
                          </button>
                        ) : (
                          <>
                            <button 
                              onClick={() => navigate(`/payments/forms/${form.id}`)}
                              className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                              title="Veure detalls"
                            >
                              <FaEye />
                            </button>
                            <button 
                              onClick={() => navigate(`/payments/forms/${form.id}/responses`)}
                              className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors duration-200"
                              title="Veure respostes"
                            >
                              <FaFileAlt />
                            </button>
                            <button 
                              onClick={() => handleDelete(form.id)}
                              className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                              title="Eliminar"
                            >
                              <FaTrash />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
                {forms.length === 0 && (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                      {showDeleted 
                        ? 'No s\'han trobat formularis eliminats'
                        : 'No s\'han trobat formularis'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ManagePaymentForms; 