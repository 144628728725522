import React, { createContext, useContext, useState, useEffect } from 'react';

const StyleContext = createContext();

const defaultStyles = {
    style: {
        primary_color: '#ED5B52',
        secondary_color: '#D64A41',
        accent_color: '#ED5B52',
        text_color: '#111827',
        heading_color: '#1F2937',
        background_color: '#FFFFFF',
        secondary_background: '#F3F4F6',
        heading_font: 'Inter',
        body_font: 'Inter',
        logo_width: '150px',
        custom_css: [],
        button_styles: [],
        card_styles: [],
        logo_path: null,
        favicon_path: null
    }
};

export const StyleProvider = ({ children }) => {
    const [styles, setStyles] = useState(defaultStyles);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStyles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/styles`);
                if (response.ok) {
                    const data = await response.json();
                    setStyles(data);
                }
            } catch (error) {
                console.error('Error fetching styles:', error);
                // Si hay error, mantenemos los estilos por defecto
                setStyles(defaultStyles);
            } finally {
                setLoading(false);
            }
        };

        fetchStyles();
    }, []);

    // Proporcionamos tanto los estilos como el estado de carga
    const value = {
        ...styles,
        loading
    };

    return (
        <StyleContext.Provider value={value}>
            {children}
        </StyleContext.Provider>
    );
};

export const useStyles = () => {
    const context = useContext(StyleContext);
    if (!context) {
        throw new Error('useStyles must be used within a StyleProvider');
    }
    return context;
};

export default StyleContext; 