import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../../Header';
import Footer from '../../../Footer';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { useAuth } from '../../../../contexts/AuthContext';
import { FaArrowLeft, FaSave, FaPlus, FaTrash } from 'react-icons/fa';

const EditCondition = () => {
    const { formId, conditionId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [condition, setCondition] = useState({
        name: '',
        description: '',
        rules: {
            operator: 'AND',
            conditions: []
        },
        actions: {
            price_modifications: []
        },
        priority: 0,
        is_active: true
    });
    const [formFields, setFormFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            console.log('LoadData - formId:', formId, 'conditionId:', conditionId);
            if (!formId) return;
            
            try {
                setIsLoading(true);
                await Promise.all([
                    fetchCondition(),
                    fetchFormFields()
                ]);
            } catch (error) {
                console.error('Error loadData:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [formId, conditionId]);

    const fetchCondition = async () => {
        if (!conditionId) return;
        
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/conditions/${conditionId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (!response.ok) throw new Error('Error al carregar la condició');

            const data = await response.json();
            setCondition(data);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchFormFields = async () => {
        if (!formId) return;
        
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/all-fields`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Error al carregar els camps');
            }

            const fields = await response.json();
            setFormFields(fields);
            
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
            setFormFields([]); // Assegurem que sempre tenim un array
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = conditionId 
                ? `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/conditions/${conditionId}`
                : `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/conditions`;

            const response = await fetch(url, {
                method: conditionId ? 'PUT' : 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(condition)
            });

            if (!response.ok) throw new Error('Error al desar la condició');

            navigate(`/payments/forms/${formId}`);
        } catch (error) {
            setError(error.message);
        }
    };

    const addRule = () => {
        setCondition({
            ...condition,
            rules: {
                ...condition.rules,
                conditions: [
                    ...condition.rules.conditions,
                    {
                        field: '',
                        operator: 'equals',
                        value: ''
                    }
                ]
            }
        });
    };

    const removeRule = (index) => {
        setCondition({
            ...condition,
            rules: {
                ...condition.rules,
                conditions: condition.rules.conditions.filter((_, i) => i !== index)
            }
        });
    };

    const addPriceModification = () => {
        setCondition({
            ...condition,
            actions: {
                ...condition.actions,
                price_modifications: [
                    ...condition.actions.price_modifications,
                    {
                        type: 'add',
                        amount: 0
                    }
                ]
            }
        });
    };

    const removePriceModification = (index) => {
        setCondition({
            ...condition,
            actions: {
                ...condition.actions,
                price_modifications: condition.actions.price_modifications.filter((_, i) => i !== index)
            }
        });
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar la condició
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">
                                Editar Condició
                            </h1>
                            <div className="flex items-center">
                                <button
                                    onClick={() => navigate(`/payments/forms/${formId}`)}
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                >
                                    <FaArrowLeft className="mr-2" /> Tornar
                                </button>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                {/* Informació bàsica */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Nom
                                        </label>
                                        <input
                                            type="text"
                                            value={condition.name}
                                            onChange={(e) => setCondition({...condition, name: e.target.value})}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Prioritat
                                        </label>
                                        <input
                                            type="number"
                                            value={condition.priority}
                                            onChange={(e) => setCondition({...condition, priority: parseInt(e.target.value)})}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            min="0"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Descripció
                                    </label>
                                    <textarea
                                        value={condition.description || ''}
                                        onChange={(e) => setCondition({
                                            ...condition,
                                            description: e.target.value
                                        })}
                                        rows={3}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                    />
                                </div>

                                {/* Regles */}
                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="text-lg font-medium text-gray-900">Regles</h3>
                                        <button
                                            type="button"
                                            onClick={addRule}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                                        >
                                            <FaPlus className="inline mr-1" /> Afegir regla
                                        </button>
                                    </div>
                                    <div className="mt-1 space-y-4">
                                        {condition.rules.conditions.map((rule, index) => (
                                            <div key={index} className="flex items-center gap-4">
                                                <select
                                                    value={formFields.find(field => field.name === rule.field)?.id || ''}
                                                    onChange={(e) => {
                                                        const selectedField = formFields.find(field => field.id === parseInt(e.target.value));
                                                        const newRules = [...condition.rules.conditions];
                                                        newRules[index] = {
                                                            ...rule, 
                                                            field: selectedField ? selectedField.name : ''
                                                        };
                                                        setCondition({
                                                            ...condition,
                                                            rules: {...condition.rules, conditions: newRules}
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                >
                                                    <option value="">Selecciona un camp</option>
                                                    {formFields.map(field => (
                                                        <option 
                                                            key={field.id} 
                                                            value={field.id}
                                                        >
                                                            {field.section_name} - {field.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    value={rule.operator}
                                                    onChange={(e) => {
                                                        const newRules = [...condition.rules.conditions];
                                                        newRules[index] = {...rule, operator: e.target.value};
                                                        setCondition({
                                                            ...condition,
                                                            rules: {...condition.rules, conditions: newRules}
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                >
                                                    <option value="equals">Igual a</option>
                                                    <option value="not_equals">Diferent de</option>
                                                    <option value="greater_than">Major que</option>
                                                    <option value="less_than">Menor que</option>
                                                    <option value="contains">Conté</option>
                                                </select>
                                                <input
                                                    type="text"
                                                    value={rule.value}
                                                    onChange={(e) => {
                                                        const newRules = [...condition.rules.conditions];
                                                        newRules[index] = {...rule, value: e.target.value};
                                                        setCondition({
                                                            ...condition,
                                                            rules: {...condition.rules, conditions: newRules}
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="Valor"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => removeRule(index)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Accions - Modificacions de preu */}
                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h3 className="text-lg font-medium text-gray-900">
                                            Modificacions de preu
                                        </h3>
                                        <button
                                            type="button"
                                            onClick={addPriceModification}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                                        >
                                            <FaPlus className="inline mr-1" /> Afegir modificació
                                        </button>
                                    </div>
                                    <div className="space-y-4">
                                        {condition.actions.price_modifications.map((mod, index) => (
                                            <div key={index} className="flex items-center gap-4">
                                                <select
                                                    value={mod.type || ''}
                                                    onChange={(e) => {
                                                        const newMods = [...condition.actions.price_modifications];
                                                        newMods[index] = {
                                                            ...mod,
                                                            type: e.target.value,
                                                            value: mod.value || 0
                                                        };
                                                        setCondition({
                                                            ...condition,
                                                            actions: {
                                                                ...condition.actions,
                                                                price_modifications: newMods
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                >
                                                    <option value="">Selecciona tipus</option>
                                                    <option value="fixed">Import fix</option>
                                                    <option value="percentage">Percentatge</option>
                                                </select>
                                                <input
                                                    type="number"
                                                    value={mod.value || 0}
                                                    onChange={(e) => {
                                                        const newMods = [...condition.actions.price_modifications];
                                                        newMods[index] = {
                                                            ...mod,
                                                            value: parseFloat(e.target.value) || 0
                                                        };
                                                        setCondition({
                                                            ...condition,
                                                            actions: {
                                                                ...condition.actions,
                                                                price_modifications: newMods
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                    step="0.01"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => removePriceModification(index)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Estat */}
                                <div>
                                    <label className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            checked={condition.is_active}
                                            onChange={(e) => setCondition({...condition, is_active: e.target.checked})}
                                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                        />
                                        <span className="text-sm text-gray-700">Condició activa</span>
                                    </label>
                                </div>

                                {/* Botó de guardar */}
                                <div className="flex justify-end pt-6">
                                    <button
                                        type="submit"
                                        className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaSave className="mr-2" /> Desar canvis
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default EditCondition; 