import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaExternalLinkAlt, FaCheck, FaExclamation } from 'react-icons/fa';
import Header from '../../../Header';
import Footer from '../../../Footer';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { useAuth } from '../../../../contexts/AuthContext';

const PaymentFormResponseDetails = () => {
    const { formId, responseId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [response, setResponse] = useState(null);
    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        Promise.all([
            fetchResponseDetails(),
            fetchFormSections()
        ]).finally(() => {
            setIsLoading(false);
        });
    }, [formId, responseId]);

    const fetchResponseDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/responses/${responseId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els detalls de la resposta');

            const data = await response.json();
            setResponse(data);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchFormSections = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (!response.ok) throw new Error('Error al carregar les seccions');

            const data = await response.json();
            setSections(data);
        } catch (error) {
            console.error('Error carregant seccions:', error);
        }
    };

    const updateResponseStatus = async (newStatus) => {
        try {
            setIsUpdating(true);
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/responses/${responseId}/status`, 
                {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ status: newStatus })
                }
            );

            if (!response.ok) throw new Error('Error al actualitzar l\'estat de la resposta');

            const updatedResponse = await response.json();
            setResponse(updatedResponse);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsUpdating(false);
        }
    };

    const getSectionName = (sectionOrder) => {
        const section = sections.find(s => s.order === parseInt(sectionOrder));
        return section?.name || `Secció ${parseInt(sectionOrder) + 1}`;
    };

    const groupResponsesBySection = () => {
        const groupedResponses = {};
        
        if (response?.responses) {
            Object.entries(response.responses).forEach(([key, value]) => {
                const [sectionOrder, fieldName] = key.split('-');
                
                if (!groupedResponses[sectionOrder]) {
                    groupedResponses[sectionOrder] = {
                        name: getSectionName(sectionOrder),
                        fields: {}
                    };
                }
                
                const formattedFieldName = fieldName.replace(/_/g, ' ');
                groupedResponses[sectionOrder].fields[formattedFieldName] = value;
            });
        }
        
        return groupedResponses;
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="flex-grow">
                    <LoadingSpinner />
                </main>
                <Footer />
            </div>
        );
    }

    if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
    if (!response) return <div className="text-center py-4">No s'ha trobat la resposta</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center">
                        <button
                            onClick={() => navigate(`/payments/forms/${formId}/responses`)}
                            className="mr-4 text-gray-600 hover:text-gray-900"
                        >
                            <FaArrowLeft className="text-xl" />
                        </button>
                        <h1 className="text-3xl font-bold text-gray-800">
                            Detalls de la Resposta
                        </h1>
                    </div>
                    <div className="flex space-x-3">
                        <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                            response.status === 'validated' 
                                ? 'bg-green-100 text-green-800'
                                : response.status === 'in_correction'
                                ? 'bg-yellow-100 text-yellow-800'
                                : 'bg-gray-100 text-gray-600'
                        }`}>
                            {response.status === 'validated' ? 'Validada' 
                                : response.status === 'in_correction' ? 'En correcció'
                                : 'Pendent'}
                        </span>
                        {response.status === 'pending' && (
                            <>
                                <button
                                    onClick={() => updateResponseStatus('validated')}
                                    disabled={isUpdating}
                                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                    <FaCheck className="mr-2" />
                                    Validar
                                </button>
                                <button
                                    onClick={() => updateResponseStatus('in_correction')}
                                    disabled={isUpdating}
                                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                >
                                    <FaExclamation className="mr-2" />
                                    Marcar per corregir
                                </button>
                            </>
                        )}
                        <button
                            onClick={() => navigate(`/payments/${response.payment_id}`)}
                            className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ED5B52]"
                        >
                            <FaExternalLinkAlt className="mr-2" />
                            Veure pagament
                        </button>
                    </div>
                </div>

                <div className="bg-white shadow rounded-lg">
                    <div className="px-6 py-5 border-b border-gray-200">
                        <h3 className="text-lg font-medium text-gray-900">
                            Detall de la resposta
                        </h3>
                    </div>
                    <div className="px-6 py-5 space-y-8">
                        {Object.entries(groupResponsesBySection()).map(([sectionId, sectionData], index) => (
                            <div 
                                key={sectionId} 
                                className={`
                                    rounded-lg p-6
                                    ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                                    border border-gray-200
                                    transition duration-150 ease-in-out
                                    hover:shadow-md
                                `}
                            >
                                <div className="flex items-center mb-6">
                                    <div className="flex-shrink-0 w-8 h-8 bg-[#ED5B52] rounded-full flex items-center justify-center text-white font-semibold">
                                        {parseInt(sectionId) + 1}
                                    </div>
                                    <h4 className="ml-4 text-lg font-medium text-gray-800">
                                        {sectionData.name}
                                    </h4>
                                </div>
                                
                                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 pl-12">
                                    {Object.entries(sectionData.fields).map(([fieldName, value]) => (
                                        <div 
                                            key={fieldName} 
                                            className="flex flex-col p-2 bg-white rounded-lg border border-gray-100 hover:border-[#ED5B52] transition-colors duration-200"
                                        >
                                            <label className="text-sm font-medium text-gray-500">
                                                {fieldName}
                                            </label>
                                            <span className="text-sm text-gray-900">
                                                {value || '-'}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-6 bg-white shadow rounded-lg">
                    <div className="px-6 py-5 border-b border-gray-200">
                        <h3 className="text-lg font-medium text-gray-900">
                            Detalls del pagament
                        </h3>
                    </div>
                    <div className="px-6 py-5 grid grid-cols-1 gap-6 md:grid-cols-2">
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-500">
                                Import base
                            </label>
                            <span className="mt-1 text-sm text-gray-900">
                                {response.metadata?.base_price} €
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-500">
                                Import final
                            </label>
                            <span className="mt-1 text-sm text-gray-900">
                                {response.calculated_price} €
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-500">
                                Estat del pagament
                            </label>
                            <span className={`mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                response.payment?.status === 'completed' 
                                    ? 'bg-green-100 text-green-800' 
                                    : response.payment?.status === 'failed'
                                    ? 'bg-red-100 text-red-800'
                                    : response.payment?.status === 'cancelled'
                                    ? 'bg-gray-100 text-gray-800'
                                    : 'bg-yellow-100 text-yellow-800'
                            }`}>
                                {response.payment?.status === 'completed' ? 'Pagat' 
                                    : response.payment?.status === 'failed' ? 'Fallat'
                                    : response.payment?.status === 'cancelled' ? 'Cancel·lat'
                                    : 'Pendent'}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-500">
                                Data de creació
                            </label>
                            <span className="mt-1 text-sm text-gray-900">
                                {new Date(response.created_at).toLocaleDateString('ca-ES')}
                            </span>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default PaymentFormResponseDetails; 