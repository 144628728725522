import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSave, FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock, FaGlobe } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const ContactSettings = () => {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [existingContact, setExistingContact] = useState(null);
    const [formData, setFormData] = useState({
        addresses: [{
            street: '',
            number: '',
            floor: '',
            door: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'España'
        }],
        phones: [{
            number: ''
        }],
        emails: [{
            email: ''
        }],
        schedule: {
            monday: { open: true, hours: '09:00-14:00,16:00-19:00' },
            tuesday: { open: true, hours: '09:00-14:00,16:00-19:00' },
            wednesday: { open: true, hours: '09:00-14:00,16:00-19:00' },
            thursday: { open: true, hours: '09:00-14:00,16:00-19:00' },
            friday: { open: true, hours: '09:00-14:00,16:00-19:00' },
            saturday: { open: false, hours: '' },
            sunday: { open: false, hours: '' }
        },
        website: '',
        google_maps_url: '',
        additional_info: ''
    });

    const WEEKDAYS = {
        monday: 'Dilluns',
        tuesday: 'Dimarts',
        wednesday: 'Dimecres',
        thursday: 'Dijous',
        friday: 'Divendres',
        saturday: 'Dissabte',
        sunday: 'Diumenge'
    };

    useEffect(() => {
        fetchContactData();
    }, []);

    const fetchContactData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/contact`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) throw new Error('Error al carregar les dades de contacte');
            
            const data = await response.json();
            if (data && data.id) {
                const sanitizedData = {
                    ...data,
                    website: data.website || '',
                    google_maps_url: data.google_maps_url || '',
                    additional_info: data.additional_info || '',
                    schedule: {
                        ...formData.schedule,
                        ...data.schedule
                    }
                };
                setExistingContact(sanitizedData);
                setFormData(sanitizedData);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e, section) => {
        const { name, value } = e.target;
        
        if (section) {
            setFormData(prev => ({
                ...prev,
                [section]: [{
                    ...prev[section][0],
                    [name]: value
                }]
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleScheduleChange = (day, field, value) => {
        setFormData(prev => ({
            ...prev,
            schedule: {
                ...prev.schedule,
                [day]: {
                    ...prev.schedule[day],
                    [field]: field === 'open' ? value : value
                }
            }
        }));
    };

    const addItem = (section) => {
        setFormData(prev => ({
            ...prev,
            [section]: [...prev[section], getEmptyItem(section)]
        }));
    };

    const removeItem = (section, index) => {
        setFormData(prev => ({
            ...prev,
            [section]: prev[section].filter((_, i) => i !== index)
        }));
    };

    const getEmptyItem = (section) => {
        switch (section) {
            case 'addresses':
                return {
                    type: '',
                    street: '',
                    number: '',
                    floor: '',
                    door: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: 'España',
                    is_primary: false
                };
            case 'phones':
                return {
                    type: '',
                    number: '',
                    is_primary: false
                };
            case 'emails':
                return {
                    type: '',
                    email: '',
                    is_primary: false
                };
            default:
                return {};
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError('');
        setSuccessMessage('');

        try {
            const method = existingContact ? 'PUT' : 'POST';
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/contact`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Error al guardar les dades de contacte');
            }

            setSuccessMessage('Dades de contacte guardades correctament');
            if (!existingContact) {
                setExistingContact(data.data);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsSaving(false);
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-12 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Dades de Contacte</h1>
                    <Link
                        to="/configuracio"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                        {successMessage}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-8">
                    {/* Adreça */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaMapMarkerAlt className="mr-2" /> Adreça
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Carrer *</label>
                                <input
                                    type="text"
                                    name="street"
                                    value={formData.addresses[0].street}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Número</label>
                                <input
                                    type="text"
                                    name="number"
                                    value={formData.addresses[0].number}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Pis</label>
                                <input
                                    type="text"
                                    name="floor"
                                    value={formData.addresses[0].floor}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Porta</label>
                                <input
                                    type="text"
                                    name="door"
                                    value={formData.addresses[0].door}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Ciutat *</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.addresses[0].city}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Província *</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.addresses[0].state}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Codi Postal *</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    value={formData.addresses[0].postal_code}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">País</label>
                                <input
                                    type="text"
                                    name="country"
                                    value={formData.addresses[0].country}
                                    onChange={(e) => handleChange(e, 'addresses')}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                    {/* Telèfon */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaPhone className="mr-2" /> Telèfon
                        </h2>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Número de telèfon</label>
                            <input
                                type="tel"
                                name="number"
                                value={formData.phones[0].number}
                                onChange={(e) => handleChange(e, 'phones')}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                        </div>
                    </div>

                    {/* Correu electrònic */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaEnvelope className="mr-2" /> Correu electrònic
                        </h2>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Adreça de correu</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.emails[0].email}
                                onChange={(e) => handleChange(e, 'emails')}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            />
                        </div>
                    </div>

                    {/* Horari */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaClock className="mr-2" /> Horari
                        </h2>
                        {Object.entries(WEEKDAYS).map(([dayKey, dayName]) => {
                            const schedule = formData.schedule[dayKey] || { open: false, hours: '' };
                            return (
                                <div key={dayKey} className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={schedule.open}
                                            onChange={(e) => handleScheduleChange(dayKey, 'open', e.target.checked)}
                                            className="mr-2"
                                        />
                                        <label>{dayName}</label>
                                    </div>
                                    {schedule.open && (
                                        <input
                                            type="text"
                                            value={schedule.hours || ''}
                                            onChange={(e) => handleScheduleChange(dayKey, 'hours', e.target.value)}
                                            placeholder="09:00-14:00,16:00-19:00"
                                            className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    {/* Informació addicional */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaGlobe className="mr-2" /> Informació addicional
                        </h2>
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Lloc web</label>
                                <input
                                    type="url"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    placeholder="https://www.exemple.com"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">URL de Google Maps</label>
                                <input
                                    type="url"
                                    name="google_maps_url"
                                    value={formData.google_maps_url}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    placeholder="https://goo.gl/maps/..."
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Informació addicional</label>
                                <textarea
                                    name="additional_info"
                                    value={formData.additional_info}
                                    onChange={handleChange}
                                    rows={4}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    placeholder="Informació addicional de contacte..."
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={isSaving}
                            className={`bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center ${
                                isSaving ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            <FaSave className="mr-2" />
                            {isSaving ? 'Guardant...' : 'Guardar Canvis'}
                        </button>
                    </div>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default ContactSettings; 