import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaUpload, FaTrash } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const OpenPayment = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [endType, setEndType] = useState('date');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    amount: '',
    concept: '',
    document: null,
    payment_type_id: 'cobrament-unic',
    configuration: {
      is_open: true,
      allow_custom_amount: false,
      allow_custom_concept: false,
      start_date: '',
      end_date: '',
      frequency: 'monthly',
      until_amount_completed: null
    }
  });

  // Carregar passarel·les de pagament
  useEffect(() => {
    const fetchPaymentGateways = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setPaymentGateways(data);
        }
      } catch (error) {
        console.error('Error al carregar les passarel·les:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentGateways();
  }, []);

  // Afegir useEffect per carregar els tipus de pagament
  useEffect(() => {
    const fetchPaymentTypes = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-types`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data)) {
            setPaymentTypes(data);
          } else {
            console.error('La resposta no és un array:', data);
            setPaymentTypes([]);
          }
        }
      } catch (error) {
        console.error('Error en obtenir els tipus de pagament:', error);
        setPaymentTypes([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentTypes();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFormData(prev => ({
        ...prev,
        document: e.target.files[0]
      }));
    }
  };

  // Funció per gestionar el canvi de tipus de pagament
  const handlePaymentTypeChange = (slug) => {
    setFormData(prev => ({
      ...prev,
      payment_type_id: slug,
      configuration: {
        ...prev.configuration,
        // Eliminar els camps específics de recurrent si canviem a únic
        ...(slug !== 'cobrament-recurrent' && {
          frequency: undefined,
          until_amount_completed: undefined
        }),
        // Afegir camps específics si és recurrent
        ...(slug === 'cobrament-recurrent' && {
          frequency: 'monthly',
          until_amount_completed: null
        })
      }
    }));
  };

  const handleCustomAmountChange = (isCustom) => {
    setFormData(prev => ({
      ...prev,
      configuration: {
        ...prev.configuration,
        allow_custom_amount: isCustom
      },
      amount: isCustom ? '' : prev.amount
    }));
  };

  const handleCustomConceptChange = (isCustom) => {
    setFormData(prev => ({
      ...prev,
      configuration: {
        ...prev.configuration,
        allow_custom_concept: isCustom
      },
      concept: isCustom ? '' : prev.concept
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!selectedGateway) {
        throw new Error('Has de seleccionar una passarel·la de pagament');
      }

      // Trobar el tipus de pagament seleccionat
      const selectedPaymentType = paymentTypes.find(type => type.slug === formData.payment_type_id);
      if (!selectedPaymentType) {
        throw new Error('Tipus de pagament no vàlid');
      }

      const paymentData = {
        name: formData.name,
        description: formData.description,
        amount: formData.configuration.allow_custom_amount ? null : formData.amount,
        concept: formData.configuration.allow_custom_concept ? null : formData.concept,
        payment_gateway_id: selectedGateway,
        payment_type_id: selectedPaymentType.id,
        configuration: {
          is_open: true,
          allow_custom_amount: formData.configuration.allow_custom_amount,
          allow_custom_concept: formData.configuration.allow_custom_concept,
          start_date: formData.configuration.start_date,
          end_date: formData.configuration.end_date,
          ...(formData.payment_type_id === 'cobrament-recurrent' && {
            frequency: formData.configuration.frequency,
            ...(endType === 'amount' ? {
              until_amount_completed: parseFloat(formData.configuration.until_amount_completed)
            } : {})
          })
        }
      };

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(paymentData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error: ${response.status}`);
      }

      const result = await response.json();

      // Si hi ha document adjunt, pujar-lo
      if (formData.document) {
        const formDataFile = new FormData();
        formDataFile.append('document', formData.document);
        formDataFile.append('payment_ids', result.id);

        const uploadResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/upload-document`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formDataFile
        });

        if (!uploadResponse.ok) {
          const errorData = await uploadResponse.json();
          throw new Error(errorData.message || 'Error al pujar el document');
        }
      }

      alert('Cobrament creat correctament');
      window.location.href = '/cobraments';
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Objecte per traduir les freqüències
  const frequencyTranslations = {
    'daily': 'Diari',
    'weekly': 'Setmanal',
    'bi-weekly': 'Quinzenal',
    'monthly': 'Mensual',
    'bi-monthly': 'Bimensual',
    'quarterly': 'Trimestral',
    'semi-annually': 'Semestral',
    'annually': 'Anual'
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        {isLoading ? (
            <LoadingSpinner />
        ) : (
          <>
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold text-gray-800">Crear nou cobrament obert</h1>
              <Link
                to="/cobraments"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
              >
                <FaArrowLeft className="mr-2" /> Tornar
              </Link>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Nom del cobrament */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom del cobrament
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    required
                  />
                </div>

                {/* Descripció */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Descripció
                  </label>
                  <textarea
                    value={formData.description}
                    onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    rows="3"
                  />
                </div>

                {/* Tipus de cobrament */}
                <div className="space-y-6 border-b border-gray-200 py-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Tipus de cobrament</h3>
                  
                  <div className="space-y-4">
                    {/* Cobrament únic amb els seus camps específics */}
                    <div>
                      <div className="flex items-center space-x-3">
                        <input
                          type="radio"
                          id="singlePayment"
                          checked={formData.payment_type_id === 'cobrament-unic'}
                          onChange={() => handlePaymentTypeChange('cobrament-unic')}
                          className="text-[#ED5B52] focus:ring-[#ED5B52]"
                        />
                        <label htmlFor="singlePayment" className="text-sm font-medium text-gray-700">
                          Cobrament únic
                        </label>
                      </div>

                      {/* Camps específics per cobrament únic */}
                      {formData.payment_type_id === 'cobrament-unic' && (
                        <div className="ml-7 mt-4 space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Data d'inici
                            </label>
                            <input
                              type="date"
                              value={formData.configuration.start_date}
                              onChange={(e) => setFormData(prev => ({
                                ...prev,
                                configuration: {
                                  ...prev.configuration,
                                  start_date: e.target.value
                                }
                              }))}
                              className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                              required
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Data de finalització
                            </label>
                            <input
                              type="date"
                              value={formData.configuration.end_date}
                              onChange={(e) => setFormData(prev => ({
                                ...prev,
                                configuration: {
                                  ...prev.configuration,
                                  end_date: e.target.value
                                }
                              }))}
                              className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                              required
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Cobrament recurrent amb els seus camps específics */}
                    <div>
                      <div className="flex items-center space-x-3">
                        <input
                          type="radio"
                          id="recurringPayment"
                          checked={formData.payment_type_id === 'cobrament-recurrent'}
                          onChange={() => handlePaymentTypeChange('cobrament-recurrent')}
                          className="text-[#ED5B52] focus:ring-[#ED5B52]"
                        />
                        <label htmlFor="recurringPayment" className="text-sm font-medium text-gray-700">
                          Cobrament recurrent
                        </label>
                      </div>

                      {/* Camps específics per cobrament recurrent */}
                      {formData.payment_type_id === 'cobrament-recurrent' && (
                        <div className="ml-7 mt-4 space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Data d'inici
                            </label>
                            <input
                              type="date"
                              value={formData.configuration.start_date}
                              onChange={(e) => setFormData(prev => ({
                                ...prev,
                                configuration: {
                                  ...prev.configuration,
                                  start_date: e.target.value
                                }
                              }))}
                              className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                              required
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Freqüència
                            </label>
                            <select
                              value={formData.configuration.frequency}
                              onChange={(e) => setFormData(prev => ({
                                ...prev,
                                configuration: {
                                  ...prev.configuration,
                                  frequency: e.target.value
                                }
                              }))}
                              className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                              required
                            >
                              {Object.entries(frequencyTranslations).map(([value, label]) => (
                                <option key={value} value={value}>{label}</option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                              Finalització per
                            </label>
                            <div className="space-y-2">
                              <div className="flex items-center space-x-3">
                                <input
                                  type="radio"
                                  id="endByDate"
                                  checked={endType === 'date'}
                                  onChange={() => setEndType('date')}
                                  className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                />
                                <label htmlFor="endByDate" className="text-sm text-gray-700">
                                  Data
                                </label>
                              </div>
                              <div className="flex items-center space-x-3">
                                <input
                                  type="radio"
                                  id="endByAmount"
                                  checked={endType === 'amount'}
                                  onChange={() => setEndType('amount')}
                                  className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                />
                                <label htmlFor="endByAmount" className="text-sm text-gray-700">
                                  Import total
                                </label>
                              </div>
                            </div>
                          </div>

                          {endType === 'date' ? (
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-2">
                                Data de finalització
                              </label>
                              <input
                                type="date"
                                value={formData.configuration.end_date}
                                onChange={(e) => setFormData(prev => ({
                                  ...prev,
                                  configuration: {
                                    ...prev.configuration,
                                    end_date: e.target.value
                                  }
                                }))}
                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                required
                              />
                            </div>
                          ) : (
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-2">
                                Import total a cobrar
                              </label>
                              <div className="flex items-center max-w-xs">
                                <input
                                  type="number"
                                  value={formData.configuration.until_amount_completed || ''}
                                  onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    configuration: {
                                      ...prev.configuration,
                                      until_amount_completed: e.target.value
                                    }
                                  }))}
                                  className="w-full p-2 border rounded-l focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                  placeholder="0.00"
                                  step="0.01"
                                  required
                                />
                                <span className="px-3 py-2 bg-gray-50 border border-l-0 rounded-r text-gray-500">
                                  €
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Configuració del cobrament */}
                <div className="space-y-6 border-b border-gray-200 py-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Configuració del cobrament</h3>
                  
                  {/* Import */}
                  <div className="space-y-4">
                    <div className="flex items-center space-x-3">
                      <input
                        type="radio"
                        id="fixedAmount"
                        checked={!formData.configuration.allow_custom_amount}
                        onChange={() => handleCustomAmountChange(false)}
                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                      />
                      <label htmlFor="fixedAmount" className="text-sm font-medium text-gray-700">
                        Import fix
                      </label>
                    </div>
                    
                    {!formData.configuration.allow_custom_amount && (
                      <div className="ml-7">
                        <div className="flex items-center max-w-xs">
                          <input
                            type="number"
                            value={formData.amount}
                            onChange={(e) => setFormData(prev => ({ ...prev, amount: e.target.value }))}
                            className="w-full p-2 border rounded-l focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            placeholder="0.00"
                            step="0.01"
                            required={!formData.configuration.allow_custom_amount}
                          />
                          <span className="px-3 py-2 bg-gray-50 border border-l-0 rounded-r text-gray-500">
                            €
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="flex items-center space-x-3">
                      <input
                        type="radio"
                        id="customAmount"
                        checked={formData.configuration.allow_custom_amount}
                        onChange={() => handleCustomAmountChange(true)}
                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                      />
                      <label htmlFor="customAmount" className="text-sm font-medium text-gray-700">
                        Permetre que el destinatari introdueixi l'import
                      </label>
                    </div>
                  </div>

                  {/* Concepte */}
                  <div className="space-y-4">
                    <div className="flex items-center space-x-3">
                      <input
                        type="radio"
                        id="fixedConcept"
                        checked={!formData.configuration.allow_custom_concept}
                        onChange={() => handleCustomConceptChange(false)}
                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                      />
                      <label htmlFor="fixedConcept" className="text-sm font-medium text-gray-700">
                        Utilitzar un concepte fix
                      </label>
                    </div>

                    {!formData.configuration.allow_custom_concept && (
                      <div className="ml-7">
                        <input
                          type="text"
                          value={formData.concept}
                          onChange={(e) => setFormData(prev => ({ ...prev, concept: e.target.value }))}
                          className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                          placeholder="Introdueix el concepte fix"
                          required={!formData.configuration.allow_custom_concept}
                        />
                      </div>
                    )}

                    <div className="flex items-center space-x-3">
                      <input
                        type="radio"
                        id="customConcept"
                        checked={formData.configuration.allow_custom_concept}
                        onChange={() => handleCustomConceptChange(true)}
                        className="text-[#ED5B52] focus:ring-[#ED5B52]"
                      />
                      <label htmlFor="customConcept" className="text-sm font-medium text-gray-700">
                        Permetre que el destinatari introdueixi el concepte
                      </label>
                    </div>
                  </div>

                  {/* Text informatiu */}
                  <div className="mt-4 bg-blue-50 p-4 rounded-md">
                    <p className="text-sm text-blue-700">
                      {formData.configuration.allow_custom_amount && formData.configuration.allow_custom_concept ? (
                        "El destinatari podrà introduir tant l'import com el concepte del pagament."
                      ) : formData.configuration.allow_custom_amount ? (
                        "El destinatari podrà introduir l'import del pagament, però s'utilitzarà la descripció com a concepte."
                      ) : formData.configuration.allow_custom_concept ? (
                        "El destinatari podrà introduir el concepte del pagament, però s'utilitzarà l'import fix especificat."
                      ) : (
                        "S'utilitzaran l'import i la descripció especificats com a valors fixos."
                      )}
                    </p>
                  </div>
                </div>

                {/* Document adjunt */}
                <div className="space-y-6 border-b border-gray-200 py-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Document adjunt (opcional)
                    </label>
                    <div className="flex items-center space-x-2">
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        className="hidden"
                        id="document-upload"
                      />
                      <label
                        htmlFor="document-upload"
                        className="bg-gray-100 hover:bg-gray-200 px-4 py-2 rounded cursor-pointer flex items-center"
                      >
                        <FaUpload className="mr-2" />
                        Pujar document
                      </label>
                      {formData.document && (
                        <div className="flex items-center space-x-2">
                          <span className="text-sm text-gray-600">
                            {formData.document.name}
                          </span>
                          <button
                            type="button"
                            onClick={() => setFormData(prev => ({ ...prev, document: null }))}
                            className="text-red-500 hover:text-red-700"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Selector de passarel·la de pagament */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Passarel·la de pagament
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {paymentGateways.map((gateway) => (
                      <div
                        key={gateway.id}
                        onClick={() => gateway.is_active && setSelectedGateway(gateway.id)}
                        className={`
                          p-4 rounded-lg border-2 transition-all duration-200
                          ${!gateway.is_active && 'opacity-50 cursor-not-allowed bg-gray-100'}
                          ${gateway.is_active && 'cursor-pointer'}
                          ${selectedGateway === gateway.id && gateway.is_active
                            ? 'border-[#ED5B52] bg-red-50' 
                            : 'border-gray-200 hover:border-gray-300'
                          }
                        `}
                      >
                        <div className="flex items-center space-x-3">
                          <input
                            type="radio"
                            name="payment-gateway"
                            value={gateway.id}
                            checked={selectedGateway === gateway.id}
                            onChange={() => gateway.is_active && setSelectedGateway(gateway.id)}
                            className="text-[#ED5B52] focus:ring-[#ED5B52]"
                            disabled={!gateway.is_active}
                            required
                          />
                          <div>
                            <p className={`font-medium ${gateway.is_active ? 'text-gray-900' : 'text-gray-500'}`}>
                              {gateway.name}
                            </p>
                            {!gateway.is_active && (
                              <p className="text-sm text-red-500">
                                Desactivada
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Botó de submit */}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200"
                  >
                    Crear cobrament obert
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default OpenPayment; 