import React, { useState } from 'react';
import { FaTrash, FaTimes, FaShoppingCart, FaArrowLeft } from 'react-icons/fa';
import { useCart } from '../../contexts/CartContext';
import CheckoutForm from '../public/CheckoutForm';

const CartSidebar = ({ shop }) => {
    const { 
        cart, 
        isOpen, 
        setIsOpen, 
        removeFromCart, 
        updateQuantity, 
        getTotal,
        getItemsCount 
    } = useCart();
    const [showCheckout, setShowCheckout] = useState(false);

    const handleCheckoutSuccess = (paymentHash) => {
        setIsOpen(false);
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/payment/${paymentHash}`;
    };

    // Función auxiliar para generar key única
    const getItemKey = (item) => {
        if (!item.selectedAttributes || Object.keys(item.selectedAttributes).length === 0) {
            return item.id;
        }
        const attributesKey = Object.entries(item.selectedAttributes)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([name, attr]) => `${name}:${attr.value}`)
            .join('|');
        return `${item.id}-${attributesKey}`;
    };

    if (!shop || !isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-hidden">
            <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsOpen(false)} />
            
            <div className="fixed inset-y-0 right-0 max-w-full flex">
                <div className="w-screen max-w-md">
                    <div className="h-full flex flex-col bg-white shadow-xl">
                        {/* Capçalera */}
                        <div className="flex items-center justify-between p-4 border-b">
                            <div className="flex items-center">
                                <FaShoppingCart className="text-[#ED5B52] mr-2" />
                                <h2 className="text-lg font-medium">
                                    {showCheckout ? 'Finalizar Compra' : `Carrito (${getItemsCount()})`}
                                </h2>
                            </div>
                            <div className="flex items-center space-x-2">
                                {showCheckout && (
                                    <button 
                                        onClick={() => setShowCheckout(false)}
                                        className="p-2 hover:bg-gray-100 rounded-full text-gray-600"
                                        title="Volver al carrito"
                                    >
                                        <FaArrowLeft />
                                    </button>
                                )}
                                <button 
                                    onClick={() => setIsOpen(false)}
                                    className="p-2 hover:bg-gray-100 rounded-full"
                                    title="Cerrar"
                                >
                                    <FaTimes />
                                </button>
                            </div>
                        </div>

                        {/* Contingut */}
                        <div className="flex-1 overflow-y-auto p-4">
                            {showCheckout ? (
                                <CheckoutForm 
                                    shop={shop}
                                    onSuccess={handleCheckoutSuccess}
                                />
                            ) : (
                                cart.length === 0 ? (
                                    <p className="text-center text-gray-500">El carrito està buit</p>
                                ) : (
                                    <div className="space-y-4">
                                        {cart.map(item => (
                                            <div key={getItemKey(item)} className="flex items-center space-x-4 bg-gray-50 p-4 rounded-lg">
                                                <div className="flex-shrink-0 w-16 h-16">
                                                    {item.images && item.images.length > 0 ? (
                                                        <img
                                                            src={item.images[0]}
                                                            alt={item.name}
                                                            className="w-full h-full object-cover rounded"
                                                        />
                                                    ) : (
                                                        <div className="w-full h-full bg-gray-200 rounded flex items-center justify-center">
                                                            <FaShoppingCart className="text-gray-400" />
                                                        </div>
                                                    )}
                                                </div>
                                                
                                                <div className="flex-1">
                                                    <h3 className="font-medium">{item.name}</h3>
                                                    <p className="text-sm text-gray-500">{item.finalPrice}€</p>
                                                    
                                                    {/* Mostrar atributos seleccionados */}
                                                    {item.selectedAttributes && Object.entries(item.selectedAttributes).length > 0 && (
                                                        <div className="mt-1 text-sm text-gray-500">
                                                            {Object.entries(item.selectedAttributes).map(([name, attr]) => (
                                                                <div key={`${getItemKey(item)}-${name}`} className="flex items-center space-x-1">
                                                                    <span className="font-medium">{name}:</span>
                                                                    <span>{attr.value}</span>
                                                                    {attr.priceModifier > 0 && (
                                                                        <span className="text-xs">
                                                                            (+{attr.priceModifier}{attr.priceModifierType === 'percentage' ? '%' : '€'})
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        value={item.quantity}
                                                        onChange={(e) => updateQuantity(item.id, parseInt(e.target.value), item.selectedAttributes)}
                                                        className="w-16 p-1 border rounded text-center"
                                                    />
                                                    <button
                                                        onClick={() => removeFromCart(item.id, item.selectedAttributes)}
                                                        className="p-2 text-red-500 hover:bg-red-50 rounded"
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            )}
                        </div>

                        {/* Footer amb total i botó de compra */}
                        <div className="border-t p-4 space-y-4">
                            <div className="flex justify-between text-lg font-medium">
                                <span>Total:</span>
                                <span>{getTotal().toFixed(2)}€</span>
                            </div>
                            {!showCheckout && (
                                <button 
                                    className="w-full bg-[#ED5B52] text-white py-3 px-4 rounded-md hover:bg-[#D64A41] transition-colors"
                                    disabled={cart.length === 0}
                                    onClick={() => setShowCheckout(true)}
                                >
                                    Finalitzar Compra
                                </button>
                            )}
                            {showCheckout && (
                                <button 
                                    form="checkout-form"
                                    type="submit"
                                    className="w-full bg-[#ED5B52] text-white py-3 px-4 rounded-md hover:bg-[#D64A41] transition-colors"
                                >
                                    Confirmar Compra ({getTotal().toFixed(2)}€)
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartSidebar; 