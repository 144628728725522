import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaPlus, FaTrash, FaTimes } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const ClientDetails = () => {
    const { id } = useParams();
    const { user, loading: authLoading } = useAuth();
    const [client, setClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('info');
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [isLoadingGroups, setIsLoadingGroups] = useState(false);

    useEffect(() => {
        if (!authLoading) {
            fetchClientData();
        }
    }, [id, authLoading]);

    const fetchClientData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Error al carregar les dades del client');
            
            const data = await response.json();
            setClient(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteAddress = async (addressId) => {
        if (window.confirm('Estàs segur que vols eliminar aquesta adreça?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-addresses/${addressId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) throw new Error('Error al eliminar l\'adreça');
                
                // Actualitzar el client després d'eliminar l'adreça
                fetchClientData();
            } catch (error) {
                alert(error.message);
            }
        }
    };

    const fetchAvailableGroups = async () => {
        setIsLoadingGroups(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Error al carregar els grups');
            
            const groups = await response.json();
            // Filtrar grups als quals el client ja pertany
            const availableGroups = groups.filter(group => 
                !client.groups?.some(clientGroup => clientGroup.id === group.id)
            );
            setAvailableGroups(availableGroups);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoadingGroups(false);
        }
    };

    const handleAddToGroup = async () => {
        if (!selectedGroup) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${selectedGroup}/customers`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ customer_id: client.id })
            });

            if (!response.ok) throw new Error('Error al afegir el client al grup');

            // Actualitzar dades del client
            fetchClientData();
            setShowGroupModal(false);
            setSelectedGroup('');
        } catch (error) {
            alert(error.message);
        }
    };

    const handleRemoveFromGroup = async (groupId) => {
        if (!window.confirm('Estàs segur que vols eliminar el client d\'aquest grup?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${groupId}/customers/${client.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al eliminar el client del grup');

            // Actualitzar dades del client
            fetchClientData();
        } catch (error) {
            alert(error.message);
        }
    };

    const formatFrequency = (frequency) => {
        const frequencies = {
            'daily': 'Diari',
            'weekly': 'Setmanal',
            'bi-weekly': 'Quinzenal',
            'monthly': 'Mensual',
            'bi-monthly': 'Bimestral',
            'quarterly': 'Trimestral',
            'semi-annually': 'Semestral',
            'annually': 'Anual'
        };
        return frequencies[frequency] || '';
    };

    if (authLoading || isLoading) {
        return <LoadingSpinner message="Carregant detalls del client..." />;
    }

    if (error) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="container mx-auto px-4 py-8 flex-grow">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                        {error}
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="text-3xl font-bold text-gray-800">{client?.name}</h1>
                        <p className="text-gray-600">{client?.email}</p>
                    </div>
                    <Link
                        to="/clients/manage"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {/* Pestanyes */}
                <div className="mb-6">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8">
                            {['info', 'addresses', 'groups', 'payments', 'orders'].map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`
                                        py-4 px-1 border-b-2 font-medium text-sm
                                        ${activeTab === tab
                                            ? 'border-[#ED5B52] text-[#ED5B52]'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        }
                                    `}
                                >
                                    {tab === 'info' && 'Informació'}
                                    {tab === 'addresses' && 'Adreces'}
                                    {tab === 'groups' && 'Grups'}
                                    {tab === 'payments' && 'Pagaments'}
                                    {tab === 'orders' && 'Comandes'}
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>

                {/* Contingut */}
                <div className="bg-white rounded-lg shadow p-6">
                    {/* Informació bàsica */}
                    {activeTab === 'info' && (
                        <div className="space-y-6">
                            <div className="flex justify-between items-start">
                                <h2 className="text-xl font-semibold">Informació bàsica</h2>
                                <Link
                                    to={`/clients/${id}/edit`}
                                    className="text-[#ED5B52] hover:text-[#D64A41] flex items-center"
                                >
                                    <FaEdit className="mr-2" /> Editar
                                </Link>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <p className="text-sm text-gray-500">Telèfon</p>
                                    <p className="text-gray-900">{client?.phone || '-'}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-500">NIF/CIF</p>
                                    <p className="text-gray-900">{client?.tax_number || '-'}</p>
                                </div>
                                <div className="md:col-span-2">
                                    <p className="text-sm text-gray-500">Notes</p>
                                    <p className="text-gray-900">{client?.notes || '-'}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Adreces */}
                    {activeTab === 'addresses' && (
                        <div className="space-y-6">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-semibold">Adreces</h2>
                                <Link
                                    to={`/clients/${id}/addresses/create`}
                                    className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                >
                                    <FaPlus className="mr-2" /> Afegir adreça
                                </Link>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {client?.addresses?.map((address) => (
                                    <div key={address.id} className="border rounded-lg p-4">
                                        <div className="flex justify-between items-start mb-2">
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                {address.type === 'billing' ? 'Facturació' : 'Enviament'}
                                            </span>
                                            <div className="flex space-x-2">
                                                <button
                                                    onClick={() => handleDeleteAddress(address.id)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                        <p className="text-gray-900">{address.address_line_1}</p>
                                        {address.address_line_2 && (
                                            <p className="text-gray-900">{address.address_line_2}</p>
                                        )}
                                        <p className="text-gray-900">
                                            {address.postal_code} {address.city}
                                        </p>
                                        <p className="text-gray-900">{address.country}</p>
                                    </div>
                                ))}
                                {(!client?.addresses || client.addresses.length === 0) && (
                                    <p className="text-gray-500 col-span-2 text-center py-4">
                                        No hi ha adreces registrades
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    {/* Grups */}
                    {activeTab === 'groups' && (
                        <div className="space-y-6">
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-semibold">Grups</h2>
                                <button 
                                    onClick={() => {
                                        fetchAvailableGroups();
                                        setShowGroupModal(true);
                                    }}
                                    className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                >
                                    <FaPlus className="mr-2" /> Afegir a grup
                                </button>
                            </div>
                            {client?.groups?.length > 0 ? (
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    {client.groups.map(group => (
                                        <div key={group.id} className="border rounded-lg p-4">
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <h3 className="font-medium">{group.name}</h3>
                                                    <p className="text-sm text-gray-500">{group.description}</p>
                                                </div>
                                                <button
                                                    onClick={() => handleRemoveFromGroup(group.id)}
                                                    className="text-red-500 hover:text-red-700"
                                                    title="Eliminar del grup"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-gray-500 text-center py-4">
                                    No pertany a cap grup
                                </p>
                            )}

                            {/* Modal per afegir a grup */}
                            {showGroupModal && (
                                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                                        <div className="flex justify-between items-center mb-4">
                                            <h3 className="text-lg font-medium">Afegir a grup</h3>
                                            <button
                                                onClick={() => setShowGroupModal(false)}
                                                className="text-gray-400 hover:text-gray-600"
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>

                                        {isLoadingGroups ? (
                                            <p className="text-center py-4">Carregant grups...</p>
                                        ) : availableGroups.length === 0 ? (
                                            <p className="text-center py-4">No hi ha grups disponibles</p>
                                        ) : (
                                            <>
                                                <select
                                                    value={selectedGroup}
                                                    onChange={(e) => setSelectedGroup(e.target.value)}
                                                    className="w-full p-2 border rounded mb-4 focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                >
                                                    <option value="">Selecciona un grup</option>
                                                    {availableGroups.map(group => (
                                                        <option key={group.id} value={group.id}>
                                                            {group.name}
                                                        </option>
                                                    ))}
                                                </select>

                                                <div className="flex justify-end space-x-2">
                                                    <button
                                                        onClick={() => setShowGroupModal(false)}
                                                        className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
                                                    >
                                                        Cancel·lar
                                                    </button>
                                                    <button
                                                        onClick={handleAddToGroup}
                                                        disabled={!selectedGroup}
                                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 disabled:opacity-50"
                                                    >
                                                        Afegir
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Pagaments */}
                    {activeTab === 'payments' && (
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold">Sol·licituds de pagament</h2>
                            {client?.payments?.length > 0 ? (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Data</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Concepte</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tipus</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Import</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Estat</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {client.payments.map(payment => (
                                                <tr key={payment.id}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {new Date(payment.created_at).toLocaleDateString('ca-ES')}
                                                    </td>
                                                    <td className="px-6 py-4">{payment.name}</td>
                                                    <td className="px-6 py-4">
                                                        {payment.payment_type?.name}
                                                        {payment.configuration?.frequency && (
                                                            <span className="text-gray-500 text-sm block">
                                                                {formatFrequency(payment.configuration.frequency)}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-4">{payment.amount}€</td>
                                                    <td className="px-6 py-4">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                            payment.status === 'paid' 
                                                                ? 'bg-green-100 text-green-800'
                                                                : 'bg-yellow-100 text-yellow-800'
                                                        }`}>
                                                            {payment.status === 'paid' ? 'Pagat' : 'Pendent'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="text-gray-500 text-center py-4">
                                    No hi ha sol·licituds de pagament
                                </p>
                            )}
                        </div>
                    )}

                    {/* Comandes */}
                    {activeTab === 'orders' && (
                        <div className="space-y-6">
                            <h2 className="text-xl font-semibold">Comandes</h2>
                            {client?.orders?.length > 0 ? (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Núm. Comanda</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Data</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Total</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Estat</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {client.orders.map(order => (
                                                <tr key={order.id}>
                                                    <td className="px-6 py-4">{order.reference}</td>
                                                    <td className="px-6 py-4">
                                                        {new Date(order.created_at).toLocaleDateString('ca-ES')}
                                                    </td>
                                                    <td className="px-6 py-4">{order.total}€</td>
                                                    <td className="px-6 py-4">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                            order.status === 'completed'
                                                                ? 'bg-green-100 text-green-800'
                                                                : 'bg-yellow-100 text-yellow-800'
                                                        }`}>
                                                            {order.status === 'completed' ? 'Completada' : 'En procés'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="text-gray-500 text-center py-4">
                                    No hi ha comandes registrades
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default ClientDetails; 