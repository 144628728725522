import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaPlus, FaMinus } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const CreateClient = () => {
    const navigate = useNavigate();
    const { user, loading: authLoading } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        tax_number: '',
        notes: '',
        addresses: []
    });

    const handleAddAddress = () => {
        setFormData(prev => ({
            ...prev,
            addresses: [...prev.addresses, {
                type: 'shipping',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                postal_code: '',
                country: '',
                is_default: true
            }]
        }));
    };

    const handleRemoveAddress = (index) => {
        setFormData(prev => ({
            ...prev,
            addresses: prev.addresses.filter((_, i) => i !== index)
        }));
    };

    const handleAddressChange = (index, field, value) => {
        setFormData(prev => ({
            ...prev,
            addresses: prev.addresses.map((address, i) => {
                if (i === index) {
                    return { ...address, [field]: value };
                }
                return address;
            })
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const clientResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customers`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    tax_number: formData.tax_number,
                    notes: formData.notes
                })
            });

            if (!clientResponse.ok) {
                const errorData = await clientResponse.json();
                throw new Error(errorData.message || 'Error al crear el client');
            }

            const client = await clientResponse.json();

            if (formData.addresses.length > 0) {
                const addressPromises = formData.addresses.map(address =>
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-addresses`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...address,
                            customer_id: client.id
                        })
                    })
                );

                await Promise.all(addressPromises);
            }

            alert('Client creat correctament');
            navigate('/clients');

        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (authLoading) {
        return <LoadingSpinner message="Carregant formulari de client..." />;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8">
                {/* Capçalera */}
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Crear nou client</h1>
                    <Link
                        to="/clients"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {/* Formulari */}
                <div className="bg-white rounded-lg shadow p-6">
                    {isLoading ? (
                        <LoadingSpinner message="Creant client..." />
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {error && (
                                <div className="bg-red-50 text-red-500 p-4 rounded-lg mb-6">
                                    {error}
                                </div>
                            )}

                            {/* Nom del client */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Nom del client *
                                </label>
                                <input
                                    type="text"
                                    required
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    placeholder="Introdueix el nom del client"
                                />
                            </div>

                            {/* Email */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Email *
                                </label>
                                <input
                                    type="email"
                                    required
                                    value={formData.email}
                                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    placeholder="exemple@email.com"
                                />
                            </div>

                            {/* Telèfon */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Telèfon
                                </label>
                                <input
                                    type="tel"
                                    value={formData.phone}
                                    onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    placeholder="+34 600 000 000"
                                />
                            </div>

                            {/* NIF/CIF */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    NIF/CIF
                                </label>
                                <input
                                    type="text"
                                    value={formData.tax_number}
                                    onChange={(e) => setFormData(prev => ({ ...prev, tax_number: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    placeholder="12345678A"
                                />
                            </div>

                            {/* Notes */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Notes
                                </label>
                                <textarea
                                    value={formData.notes}
                                    onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    rows="3"
                                    placeholder="Notes addicionals sobre el client"
                                />
                            </div>

                            {/* Secció d'adreces */}
                            <div className="mt-8">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-lg font-medium">Adreces</h3>
                                    <button
                                        type="button"
                                        onClick={handleAddAddress}
                                        className="flex items-center text-green-500 hover:text-green-600"
                                    >
                                        <FaPlus className="mr-2" /> Afegir adreça
                                    </button>
                                </div>

                                {formData.addresses.length === 0 ? (
                                    <div className="text-gray-500 text-center py-4 border rounded-lg">
                                        No hi ha adreces afegides
                                    </div>
                                ) : (
                                    formData.addresses.map((address, index) => (
                                        <div key={index} className="border rounded-lg p-4 mb-4">
                                            <div className="flex justify-between items-center mb-4">
                                                <h4 className="text-md font-medium">Adreça {index + 1}</h4>
                                                {index > 0 && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveAddress(index)}
                                                        className="text-red-500 hover:text-red-600"
                                                    >
                                                        <FaMinus />
                                                    </button>
                                                )}
                                            </div>

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Tipus *
                                                    </label>
                                                    <select
                                                        required
                                                        value={address.type}
                                                        onChange={(e) => handleAddressChange(index, 'type', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    >
                                                        <option value="billing">Facturació</option>
                                                        <option value="shipping">Enviament</option>
                                                    </select>
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Adreça línia 1 *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={address.address_line_1}
                                                        onChange={(e) => handleAddressChange(index, 'address_line_1', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Adreça línia 2
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={address.address_line_2}
                                                        onChange={(e) => handleAddressChange(index, 'address_line_2', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Ciutat *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={address.city}
                                                        onChange={(e) => handleAddressChange(index, 'city', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Província
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={address.state}
                                                        onChange={(e) => handleAddressChange(index, 'state', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Codi postal *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={address.postal_code}
                                                        onChange={(e) => handleAddressChange(index, 'postal_code', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        País *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={address.country}
                                                        onChange={(e) => handleAddressChange(index, 'country', e.target.value)}
                                                        className="w-full p-2 border rounded"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                            {/* Botons */}
                            <div className="flex justify-end space-x-3">
                                <Link
                                    to="/clients"
                                    className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                                >
                                    Cancel·lar
                                </Link>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64A41] disabled:opacity-50"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Creant...' : 'Crear client'}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default CreateClient; 