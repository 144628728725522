import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaLink, FaQrcode as FaQR, FaBarcode, FaCopy, FaSearch, FaLock, FaExternalLinkAlt } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const PaymentLink = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('payments');
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentTypes, setPaymentTypes] = useState({
    payments: [],
    openPayments: [],
    paymentForms: []
  });
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');
  const [displayType, setDisplayType] = useState(''); // 'link', 'qr', o 'barcode'
  const [selectedPaymentHash, setSelectedPaymentHash] = useState('');
  const [requiresAccessCode, setRequiresAccessCode] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [isEditingAccessCode, setIsEditingAccessCode] = useState(false);
  const [newAccessCode, setNewAccessCode] = useState('');

  // Cargar pagos
  useEffect(() => {
    const fetchAllPayments = async () => {
      setIsLoading(true);
      try {
        const [paymentsRes, openPaymentsRes, formsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }),
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          }),
          fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          })
        ]);

        const [paymentsData, openPaymentsData, formsData] = await Promise.all([
          paymentsRes.json(),
          openPaymentsRes.json(),
          formsRes.json()
        ]);

        setPaymentTypes({
          payments: paymentsData || [],
          openPayments: openPaymentsData || [],
          paymentForms: formsData || []
        });

        // Inicialitzar els pagaments filtrats amb els del tab actiu
        setFilteredPayments(paymentsData || []);
      } catch (error) {
        console.error('Error al carregar els pagaments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllPayments();
  }, [activeTab]);

  // Filtrar pagos según término de búsqueda
  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase();
    const currentItems = paymentTypes[activeTab];

    const filtered = currentItems.filter(item => {
      return (
        item?.name?.toLowerCase().includes(searchTermLower) ||
        item?.description?.toLowerCase().includes(searchTermLower) ||
        item?.amount?.toString().includes(searchTermLower) ||
        item?.status?.toLowerCase().includes(searchTermLower)
      );
    });

    setFilteredPayments(filtered);
  }, [searchTerm, paymentTypes, activeTab]);

  const handlePaymentSelect = (payment) => {
    setSelectedPayment(payment.id);
    
    let publicUrl;
    switch(activeTab) {
      case 'openPayments':
        publicUrl = `${window.location.origin}/public-open-payments/${payment.public_hash}`;
        break;
      case 'paymentForms':
        publicUrl = `${window.location.origin}/public-payment-forms/${payment.hash}`;
        break;
      default:
        publicUrl = `${window.location.origin}/public-payments/${payment.public_hash}`;
    }
    
    setGeneratedLink(publicUrl);
    setSelectedPaymentHash(payment.public_hash || payment.hash);
    setRequiresAccessCode(Boolean(payment.requires_access_code));
    setAccessCode(payment.access_code || '');
    setDisplayType('link');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink)
      .then(() => alert('Enllaç copiat al portapapers'))
      .catch(err => console.error('Error al copiar:', err));
  };

  const reloadPayments = async () => {
    setIsLoading(true);
    try {
      const [paymentsRes, openPaymentsRes, formsRes] = await Promise.all([
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }),
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/open-payments`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }),
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        })
      ]);

      const [paymentsData, openPaymentsData, formsData] = await Promise.all([
        paymentsRes.json(),
        openPaymentsRes.json(),
        formsRes.json()
      ]);

      setPaymentTypes({
        payments: paymentsData || [],
        openPayments: openPaymentsData || [],
        paymentForms: formsData || []
      });

      setFilteredPayments(paymentsData || []);
    } catch (error) {
      console.error('Error al carregar els pagaments:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleAccessCode = async () => {
    if (!selectedPayment) return;

    let endpoint;
    let currentItem;

    // Determinar l'endpoint i l'item segons el tipus actiu
    switch(activeTab) {
      case 'openPayments':
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${selectedPayment}/toggle-access-code`;
        currentItem = paymentTypes.openPayments.find(p => p.id === selectedPayment);
        break;
      case 'paymentForms':
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${selectedPayment}/toggle-access-code`;
        currentItem = paymentTypes.paymentForms.find(p => p.id === selectedPayment);
        break;
      default:
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/payments/${selectedPayment}/toggle-access-code`;
        currentItem = paymentTypes.payments.find(p => p.id === selectedPayment);
    }

    if (!currentItem) {
      console.error('No s\'ha trobat l\'element seleccionat');
      return;
    }

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          requires_access_code: true,
          access_code: newAccessCode
        })
      });

      if (!response.ok) throw new Error('Error en la resposta del servidor');

      const updatedItem = await response.json();

      // Actualitzar l'estat local
      setPaymentTypes(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].map(item =>
          item.id === selectedPayment ? { ...item, ...updatedItem } : item
        )
      }));

      // Actualitzar els filteredPayments
      setFilteredPayments(prev =>
        prev.map(item =>
          item.id === selectedPayment ? { ...item, ...updatedItem } : item
        )
      );

      setIsEditingAccessCode(false);
      setNewAccessCode('');
      setAccessCode(updatedItem.access_code);
      setRequiresAccessCode(Boolean(updatedItem.requires_access_code));

    } catch (error) {
      console.error('Error al modificar el codi d\'accés:', error);
    }
  };

  const handleRemoveAccessCode = async (item) => {
    if (!item?.id) return;

    let endpoint;
    switch(activeTab) {
      case 'openPayments':
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/open-payments/${item.id}/toggle-access-code`;
        break;
      case 'paymentForms':
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${item.id}/toggle-access-code`;
        break;
      default:
        endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/payments/${item.id}/toggle-access-code`;
    }

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          requires_access_code: false,
          access_code: null
        })
      });

      if (!response.ok) throw new Error('Error en la resposta del servidor');

      const updatedItem = await response.json();

      // Actualitzar l'estat local
      setPaymentTypes(prev => ({
        ...prev,
        [activeTab]: prev[activeTab].map(item =>
          item.id === selectedPayment ? { ...item, ...updatedItem } : item
        )
      }));

      setFilteredPayments(prev =>
        prev.map(item =>
          item.id === selectedPayment ? { ...item, ...updatedItem } : item
        )
      );

      setIsEditingAccessCode(false);
      setNewAccessCode('');
      setAccessCode('');
      setRequiresAccessCode(false);

    } catch (error) {
      console.error('Error al eliminar el codi d\'accés:', error);
    }
  };

  const handleEditAccessCode = (item) => {
    setSelectedPayment(item.id);
    setNewAccessCode(item.access_code || '');
    setIsEditingAccessCode(true);
  };

  // Funció per obtenir l'estil segons l'estat
  const getStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
        case 'active':
            return 'bg-green-100 text-green-800';
        case 'pending':
            return 'bg-yellow-100 text-yellow-800';
        case 'completed':
            return 'bg-blue-100 text-blue-800';
        case 'cancelled':
            return 'bg-red-100 text-red-800';
        case 'failed':
            return 'bg-red-100 text-red-800';
        case 'expired':
            return 'bg-gray-100 text-gray-800';
        default:
            return 'bg-gray-100 text-gray-800';
    }
  };

  // Funció per obtenir el text en català
  const getStatusText = (status) => {
    switch (status?.toLowerCase()) {
        case 'active':
            return 'Actiu';
        case 'pending':
            return 'Pendent';
        case 'completed':
            return 'Completat';
        case 'cancelled':
            return 'Cancel·lat';
        case 'failed':
            return 'Failed';
        case 'expired':
            return 'Expirat';
        default:
            return status || 'Desconegut';
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFilteredPayments(paymentTypes[tab] || []);
    setSelectedPayment(null);
    setGeneratedLink('');
  };

  const formatAmount = (item, type) => {
    if (type === 'openPayments') {
      if (!item.amount) {
        return '(Sense import)';
      }
      return `${item.amount} ${item.currency}`;
    }
    
    if (type === 'paymentForms') {
      return `${item.base_price} ${item.currency}`;
    }
    
    return `${item.amount} ${item.currency}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        {isLoading ? (
            <LoadingSpinner />
        ) : (
          <>
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold text-gray-800">
                Crear nou enllaç / QR / codi de barres
              </h1>
              <Link
                to="/cobraments"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
              >
                <FaArrowLeft className="mr-2" /> Tornar
              </Link>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              {/* Buscador */}
              <div className="mb-6">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Cerca per nom, import o estat..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 pl-10 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                  />
                  <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <FaSearch />
                  </div>
                </div>
              </div>

              {/* Pestanyes modificades amb el nou handler */}
              <div className="mb-6">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8">
                    <button
                      onClick={() => handleTabChange('payments')}
                      className={`${
                        activeTab === 'payments'
                          ? 'border-[#ED5B52] text-[#ED5B52]'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                      Pagaments Únics i Recurrents
                    </button>
                    <button
                      onClick={() => handleTabChange('openPayments')}
                      className={`${
                        activeTab === 'openPayments'
                          ? 'border-[#ED5B52] text-[#ED5B52]'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                      Pagaments Oberts
                    </button>
                    <button
                      onClick={() => handleTabChange('paymentForms')}
                      className={`${
                        activeTab === 'paymentForms'
                          ? 'border-[#ED5B52] text-[#ED5B52]'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                      Formularis
                    </button>
                  </nav>
                </div>
              </div>

              {/* Afegir missatge de càrrega i estat buit */}
              {isLoading ? (
                <div className="text-center py-4">Carregant...</div>
              ) : filteredPayments.length === 0 ? (
                <div className="text-center py-4 text-gray-500">
                  No s'han trobat {
                    activeTab === 'payments' ? 'pagaments' :
                    activeTab === 'openPayments' ? 'cobraments oberts' :
                    'formularis'
                  }
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nom
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {activeTab === 'paymentForms' ? 'Preu Base' : 'Import'}
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Estat
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Accions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredPayments.map(item => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{item.name}</div>
                          <div className="text-sm text-gray-500">{item.description}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatAmount(item, activeTab)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(item.status)}`}>
                            {getStatusText(item.status)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex justify-end space-x-2">
                            <button
                              onClick={() => handlePaymentSelect(item)}
                              className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41]"
                              title="Obtenir enllaç"
                            >
                              <FaLink />
                            </button>
                            {item.requires_access_code !== undefined && (
                              <button
                                onClick={() => handleEditAccessCode(item)}
                                className={`${
                                  item.access_code 
                                    ? 'bg-green-500 hover:bg-green-600' 
                                    : 'bg-[#ED5B52] hover:bg-[#D64A41]'
                                } text-white p-2 rounded-full`}
                                title={item.access_code ? 'Modificar codi' : 'Afegir codi'}
                              >
                                <FaLock />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {/* Panel de visualització d'enllaços */}
              {selectedPayment && (
                <div className="mt-6 p-4 border rounded-lg bg-gray-50">
                  <div className="flex space-x-4 mb-4">
                    <button
                      onClick={() => setDisplayType('link')}
                      className={`flex items-center px-4 py-2 rounded ${
                        displayType === 'link' ? 'bg-[#ED5B52] text-white' : 'bg-white border'
                      }`}
                    >
                      <FaLink className="mr-2" /> Enllaç
                    </button>
                    <button
                      onClick={() => setDisplayType('qr')}
                      className={`flex items-center px-4 py-2 rounded ${
                        displayType === 'qr' ? 'bg-[#ED5B52] text-white' : 'bg-white border'
                      }`}
                    >
                      <FaQR className="mr-2" /> Codi QR
                    </button>
                    <button
                      onClick={() => setDisplayType('barcode')}
                      className={`flex items-center px-4 py-2 rounded ${
                        displayType === 'barcode' ? 'bg-[#ED5B52] text-white' : 'bg-white border'
                      }`}
                    >
                      <FaBarcode className="mr-2" /> Codi de barres
                    </button>
                  </div>

                  <div className="bg-white p-6 border rounded-lg">
                    {displayType === 'link' && (
                      <div className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={generatedLink}
                          readOnly
                          className="flex-grow p-2 border rounded bg-gray-50"
                        />
                        <button
                          onClick={copyToClipboard}
                          className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41]"
                          title="Copiar enllaç"
                        >
                          <FaCopy />
                        </button>
                        <a
                          href={generatedLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41]"
                          title="Obrir enllaç"
                        >
                          <FaExternalLinkAlt />
                        </a>
                      </div>
                    )}
                    {displayType === 'qr' && (
                      <div className="flex justify-center">
                        <QRCodeSVG value={generatedLink} size={256} />
                      </div>
                    )}
                    {displayType === 'barcode' && (
                      <div className="flex justify-center">
                        <Barcode value={selectedPaymentHash} />
                      </div>
                    )}
                  </div>

                  {requiresAccessCode && accessCode && (
                    <div className="mt-4 p-4 bg-yellow-50 border border-yellow-200 rounded">
                      <p className="text-sm text-yellow-700 mb-2">
                        Aquest pagament està protegit amb codi d'accés:
                      </p>
                      <p className="font-mono text-lg text-center bg-white p-2 rounded">
                        {accessCode}
                      </p>
                      <p className="text-xs text-yellow-600 mt-2">
                        Comparteix aquest codi amb el destinatari del pagament
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </main>
      <Footer />

      {/* Modal de codi d'accés */}
      {isEditingAccessCode && selectedPayment && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">
              {requiresAccessCode ? 'Modificar codi d\'accés' : 'Establir codi d\'accés'}
            </h3>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Codi d'accés (6 dígits)
              </label>
              <input
                type="text"
                value={newAccessCode}
                onChange={(e) => setNewAccessCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                placeholder="Introdueix 6 dígits"
                maxLength="6"
              />
            </div>

            <div className="flex justify-between">
              {requiresAccessCode && (
                <button
                  onClick={() => handleRemoveAccessCode({ id: selectedPayment })}
                  className="px-4 py-2 text-red-600 hover:text-red-800"
                >
                  Eliminar codi d'accés
                </button>
              )}
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setIsEditingAccessCode(false);
                    setNewAccessCode('');
                  }}
                  className="px-4 py-2 border rounded hover:bg-gray-100"
                >
                  Cancel·lar
                </button>
                <button
                  onClick={handleToggleAccessCode}
                  disabled={newAccessCode.length !== 6}
                  className="px-4 py-2 bg-[#ED5B52] text-white rounded hover:bg-[#D64A41] disabled:opacity-50"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentLink; 