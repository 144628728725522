import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft, FaPlus, FaTimes } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';

// Component per mostrar una categoria i els seus fills
const CategoryTreeItem = ({ category, level = 0, selectedCategories, onToggle, onExpand, isExpanded }) => {
    const hasChildren = category.children && category.children.length > 0;
    
    return (
        <div className="category-tree-item mb-1">
            <div 
                className={`
                    flex items-center p-2 rounded-lg transition-all duration-200
                    ${selectedCategories.includes(category.id) 
                        ? 'bg-[#ED5B52]/10 border-[#ED5B52]' 
                        : 'hover:bg-gray-50'}
                `}
            >
                {/* Contenidor per mantenir l'alineació consistent */}
                <div className="flex items-center flex-1" style={{ paddingLeft: `${level * 1.5}rem` }}>
                    {/* Espai reservat per la fletxa o espaiador amb stopPropagation */}
                    <div 
                        className="w-6 flex justify-center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {hasChildren ? (
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onExpand(category.id);
                                }}
                                className="text-gray-400 hover:text-gray-600 focus:outline-none"
                            >
                                {category.isExpanded ? '▼' : '▶'}
                            </button>
                        ) : (
                            <span className="w-4"></span>
                        )}
                    </div>
                    
                    {/* Checkbox i nom de la categoria */}
                    <div 
                        className="flex items-center flex-1"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <input
                            type="checkbox"
                            checked={selectedCategories.includes(category.id)}
                            onChange={() => onToggle(category.id)}
                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52] mr-3"
                        />
                        <div>
                            <span className="font-medium">{category.name}</span>
                            {category.description && (
                                <p className="text-sm text-gray-500">{category.description}</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Comptador de productes */}
                {category.products_count > 0 && (
                    <span className="text-sm text-gray-500 ml-2">
                        ({category.products_count})
                    </span>
                )}
            </div>

            {/* Renderitzar fills */}
            {hasChildren && category.isExpanded && (
                <div className="mt-1">
                    {category.children.map(child => (
                        <CategoryTreeItem
                            key={child.id}
                            category={child}
                            level={level + 1}
                            selectedCategories={selectedCategories}
                            onToggle={onToggle}
                            onExpand={onExpand}
                            isExpanded={isExpanded}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

// Component principal de categories
const CategorySection = ({ shopId, categories, formData, setFormData, currentShopId }) => {
    const [expandedCategories, setExpandedCategories] = useState(new Set());
    
    const organizeCategories = (cats) => {
        const categoryMap = new Map();
        const rootCategories = [];

        cats.forEach(category => {
            categoryMap.set(category.id, {
                ...category,
                children: [],
                isExpanded: expandedCategories.has(category.id)
            });
        });

        cats.forEach(category => {
            const categoryWithChildren = categoryMap.get(category.id);
            if (category.parent_id && categoryMap.has(category.parent_id)) {
                const parent = categoryMap.get(category.parent_id);
                parent.children.push(categoryWithChildren);
            } else {
                rootCategories.push(categoryWithChildren);
            }
        });

        return rootCategories;
    };

    // Obtenim les categories seleccionades de manera segura
    const selectedCategories = formData.shops?.find(s => s.id === shopId)?.categories || [];
    const handleToggleCategory = (categoryId) => {
        setFormData(prev => {
            const newShops = prev.shops.map(shop => {
                if (shop.id === shopId) {
                    const isSelected = shop.categories?.includes(categoryId);
                    const newCategories = isSelected
                        ? (shop.categories || []).filter(id => id !== categoryId)
                        : [...(shop.categories || []), categoryId];
                    return { ...shop, categories: newCategories };
                }
                return shop;
            });
            return { ...prev, shops: newShops };
        });
    };

    const handleToggleExpand = (categoryId) => {
        setExpandedCategories(prev => {
            const newExpanded = new Set(prev);
            if (newExpanded.has(categoryId)) {
                newExpanded.delete(categoryId);
            } else {
                newExpanded.add(categoryId);
            }
            return newExpanded;
        });
    };

    // Funció per expandir o contraure totes les categories
    const handleToggleAll = () => {
        if (expandedCategories.size === categories.length) {
            // Si totes estan expandides, les contraurem
            setExpandedCategories(new Set());
        } else {
            // Si no, les expandim totes
            setExpandedCategories(new Set(categories.map(c => c.id)));
        }
    };

    const treeCategories = organizeCategories(categories);

    if (categories.length === 0) {
        return (
            <div className="text-gray-500 text-center py-4 border-2 border-dashed rounded-lg">
                No hi ha categories disponibles. 
                <Link to={`/online-shops/${currentShopId}/categories/create`} className="text-[#ED5B52] hover:text-[#D64A41] ml-1">
                    Crear nova categoria
                </Link>
            </div>
        );
    }

    return (
        <div className="space-y-2 border rounded-lg p-4">
            {/* Botó d'expandir/contraure tot */}
            <div className="flex justify-end mb-3">
                <button
                    type="button"
                    onClick={handleToggleAll}
                    className="text-sm text-[#ED5B52] hover:text-[#D64A41]"
                >
                    {expandedCategories.size === categories.length ? 'Contraure tot' : 'Expandir tot'}
                </button>
            </div>
            <hr className="border-gray-200" />
            {/* Arbre de categories amb més espai entre elements */}
            <div className="space-y-1">
                {treeCategories.map(category => (
                    <CategoryTreeItem
                        key={category.id}
                        category={category}
                        selectedCategories={selectedCategories}
                        onToggle={handleToggleCategory}
                        onExpand={handleToggleExpand}
                        isExpanded={expandedCategories.has(category.id)}
                    />
                ))}
            </div>
        </div>
    );
};

// Component ShopItem
const ShopItem = ({ shop, formData, setFormData, onShopDataChange, currentShopId }) => {
    const [categories, setCategories] = useState([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState(true);
    const shopData = formData.shops.find(s => s.id === shop.id);
    const isSelected = Boolean(shopData);

    useEffect(() => {
        if (isSelected) {
            const fetchShopCategories = async () => {
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${shop.id}/categories`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                    );
                    if (response.ok) {
                        const data = await response.json();
                        setCategories(data);
                    }
                } catch (error) {
                    console.error('Error carregant categories:', error);
                } finally {
                    setIsLoadingCategories(false);
                }
            };

            fetchShopCategories();
        }
    }, [shop.id, isSelected]);

    const handleShopSelect = () => {
        setFormData(prev => {
            if (isSelected) {
                // Si ja estava seleccionada, l'eliminem
                return {
                    ...prev,
                    shops: prev.shops.filter(s => s.id !== shop.id)
                };
            } else {
                // Si no estava seleccionada, l'afegim amb valors per defecte
                return {
                    ...prev,
                    shops: [...prev.shops, {
                        id: shop.id,
                        stock: 0,
                        price: '',
                        categories: []
                    }]
                };
            }
        });
    };

    return (
        <div className={`
            border rounded-lg transition-all duration-200
            ${isSelected ? 'border-[#ED5B52] bg-white' : 'border-gray-200 bg-gray-50'}
        `}>
            {/* Capçalera de la botiga amb checkbox */}
            <div 
                className="p-4 flex items-center justify-between cursor-pointer border-b"
                onClick={handleShopSelect}
            >
                <div className="flex items-center space-x-3">
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={handleShopSelect}
                        className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                    />
                    <h3 className="text-lg font-medium">{shop.name}</h3>
                </div>
            </div>

            {/* Contingut de la botiga (només visible si està seleccionada) */}
            {isSelected && (
                <div className="p-6 space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Stock
                            </label>
                            <input
                                type="number"
                                min="0"
                                value={shopData.stock || 0}
                                onChange={(e) => onShopDataChange(shop.id, 'stock', parseInt(e.target.value))}
                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Preu
                            </label>
                            <input
                                type="number"
                                step="0.01"
                                min="0"
                                value={shopData.price || ''}
                                onChange={(e) => onShopDataChange(shop.id, 'price', e.target.value)}
                                className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            />
                        </div>
                    </div>

                    {/* Secció de categories */}
                    <div>
                        <h4 className="text-md font-medium mb-2">Categories</h4>
                        {isLoadingCategories ? (
                            <div className="text-center py-4">Carregant categories...</div>
                        ) : (
                            <CategorySection
                                shopId={shop.id}
                                categories={categories}
                                formData={formData}
                                setFormData={setFormData}
                                currentShopId={currentShopId}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

// Simplifiquem el ShopSection ja que no necessitem el selector separat
const ShopSection = ({ shops, formData, setFormData, onShopDataChange, currentShopId }) => {
    return (
        <div className="space-y-4">
            {shops.map(shop => (
                <ShopItem
                    key={shop.id}
                    shop={shop}
                    formData={formData}
                    setFormData={setFormData}
                    onShopDataChange={onShopDataChange}
                    currentShopId={currentShopId}
                />
            ))}
        </div>
    );
};

const EditProduct = () => {
    const { id, idshop } = useParams();
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shops, setShops] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [currentShopId, setCurrentShopId] = useState(idshop);

    // Modifiquem l'estat inicial eliminant categories
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        sku: '',
        status: 'active',
        images: [],
        attributes: [],
        shops: [],
        weight: null,
        width: null,
        height: null,
        length: null,
        featured: false
    });

    // Funció per carregar les dades del producte
    const fetchProductData = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shop-products/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar les dades del producte');
            
            const product = await response.json();

            // Agrupem les categories per botiga
            const shopCategories = {};
            product.categories.forEach(category => {
                if (!shopCategories[category.online_shop_id]) {
                    shopCategories[category.online_shop_id] = [];
                }
                shopCategories[category.online_shop_id].push(category.id);
            });
            
            setFormData({
                name: product.name || '',
                description: product.description || '',
                price: product.price?.toString() || '',
                sku: product.sku || '',
                status: product.status || 'active',
                images: product.images || [],
                attributes: product.attributes?.map(attr => ({
                    name: attr.name || '',
                    value: attr.value || '',
                    priceModifier: attr.price_modifier?.toString() || '',
                    priceModifierType: attr.price_modifier_type || 'fixed'
                })) || [],
                shops: product.shops?.map(shop => ({
                    id: shop.id,
                    stock: shop.pivot?.stock?.toString() || '0',
                    price: shop.pivot?.price?.toString() || '',
                    minimum_stock: shop.pivot?.minimum_stock?.toString() || '0',
                    allow_backorders: shop.pivot?.allow_backorders === 1,
                    categories: shopCategories[shop.id] || [] // Assignem les categories corresponents a cada botiga
                })) || [],
                weight: product.weight?.toString() || '',
                width: product.width?.toString() || '',
                height: product.height?.toString() || '',
                length: product.length?.toString() || '',
                featured: product.featured === 1
            });

        } catch (error) {
            console.error('Error al carregar el producte:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Modifiquem l'useEffect per carregar totes les dades necessàries
    useEffect(() => {
        const loadAllData = async () => {
            try {
                setIsLoading(true);
                // Carreguem totes les dades en paral·lel
                await Promise.all([
                    fetchUserData(),
                    fetchShops(),
                    fetchCategories(),
                    fetchProductData()
                ]);
            } catch (error) {
                console.error('Error al carregar les dades:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        loadAllData();
    }, [id]); // Afegim id com a dependència

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const userData = await response.json();
                setUserName(userData.name);
            }
        } catch (error) {
            console.error('Error en obtenir les dades de l\'usuari:', error);
        }
    };

    const fetchShops = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setShops(data);
            }
        } catch (error) {
            console.error('Error en obtenir les botigues:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shop-categories`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            }
        } catch (error) {
            console.error('Error al carregar categories:', error);
        }
    };

    // Modifiquem la funció handleAttributeChange
    const handleAttributeChange = (index, field, value) => {
        setFormData(prev => ({
            ...prev,
            attributes: prev.attributes.map((attr, i) => {
                if (i === index) {
                    return {
                        ...attr,
                        [field]: value
                    };
                }
                return attr;
            })
        }));
    };

    // Modifiquem la funció handleAddAttribute
    const handleAddAttribute = () => {
        setFormData(prev => ({
            ...prev,
            attributes: [
                ...prev.attributes,
                {
                    name: '',
                    value: '',
                    priceModifier: '',
                    priceModifierType: 'fixed'
                }
            ]
        }));
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const promises = files.map(file => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(promises).then(images => {
            setFormData(prev => ({
                ...prev,
                images: [...prev.images, ...images]
            }));
        });
    };

    const handleRemoveImage = (index) => {
        setFormData(prev => ({
            ...prev,
            images: prev.images.filter((_, i) => i !== index)
        }));
    };

    // Modifiquem la funció handleSubmit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        // Comprovem si hi ha botigues seleccionades sense categories
        const shopWithoutCategories = formData.shops.find(shop => !shop.categories?.length);
        
        if (shopWithoutCategories) {
            setError('Hi ha botigues seleccionades sense categories. Si us plau, selecciona almenys una categoria per cada botiga.');
            setIsSubmitting(false);
            return;
        }

        try {
            const uniqueShops = Array.from(
                new Map(formData.shops.map(shop => [shop.id, shop])).values()
            );

            const submitData = {
                ...formData,
                price: parseFloat(formData.price),
                attributes: formData.attributes.map(attr => ({
                    name: attr.name,
                    value: attr.value,
                    priceModifier: parseFloat(attr.priceModifier) || 0,
                    priceModifierType: attr.priceModifierType
                })),
                shops: uniqueShops.map(shop => ({
                    id: shop.id,
                    stock: parseInt(shop.stock) || 0,
                    price: parseFloat(shop.price) || parseFloat(formData.price),
                    minimum_stock: parseInt(shop.minimum_stock) || 0,
                    allow_backorders: Boolean(shop.allow_backorders),
                    categories: shop.categories || []
                }))
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shop-products/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(submitData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Error: ${response.status}`);
            }

            navigate(`/online-shops/${currentShopId}`);
        } catch (error) {
            console.error('Error en actualitzar el producte:', error);
            setError(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const SuccessMessage = ({ message }) => (
        <div className="bg-green-50 text-green-500 p-4 rounded-lg mb-6">
            {message}
        </div>
    );

    const handleShopDataChange = (shopId, field, value) => {
        setFormData(prev => ({
            ...prev,
            shops: prev.shops.map(shop => {
                if (shop.id === shopId) {
                    return {
                        ...shop,
                        [field]: value
                    };
                }
                return shop;
            })
        }));
    };

    // Afegim la funció handleRemoveAttribute
    const handleRemoveAttribute = (indexToRemove) => {
        setFormData(prev => ({
            ...prev,
            attributes: prev.attributes.filter((_, index) => index !== indexToRemove)
        }));
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header userName={userName} />
            <main className="flex-grow container mx-auto px-4 py-8">
                <div className="bg-white rounded-lg shadow p-6">
                    <div className="flex items-center mb-6">
                        <Link
                            to={`/online-shops/${currentShopId}`}
                            className="mr-4 text-gray-600 hover:text-gray-800"
                        >
                            <FaArrowLeft />
                        </Link>
                        <h2 className="text-2xl font-semibold">Editar producte</h2>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        {error && (
                            <div className="bg-red-50 text-red-500 p-4 rounded-lg">
                                {error}
                            </div>
                        )}
                        {/* Informació bàsica */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Informació bàsica</h3>
                            
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Nom del producte
                                </label>
                                <input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Descripció
                                </label>
                                <textarea
                                    value={formData.description}
                                    onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    rows="3"
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Preu base
                                    </label>
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={formData.price}
                                        onChange={(e) => setFormData(prev => ({ ...prev, price: e.target.value }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        SKU
                                    </label>
                                    <input
                                        type="text"
                                        value={formData.sku}
                                        onChange={(e) => setFormData(prev => ({ ...prev, sku: e.target.value }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Status
                                    </label>
                                    <select
                                        value={formData.status}
                                        onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    >
                                        <option value="active">Activa</option>
                                        <option value="inactive">Inactiva</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Botigues */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Botigues i stock</h3>
                            <ShopSection 
                                shops={shops}
                                formData={formData}
                                setFormData={setFormData}
                                onShopDataChange={handleShopDataChange}
                                currentShopId={currentShopId}
                            />
                        </div>

                        {/* Imatges */}
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Imatges</h3>
                            <div>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className="hidden"
                                    id="images"
                                />
                                <label
                                    htmlFor="images"
                                    className="inline-block px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer"
                                >
                                    Pujar imatges
                                </label>
                            </div>
                            <div className="grid grid-cols-4 gap-4">
                                {formData.images.map((image, index) => (
                                    <div key={index} className="relative">
                                        <img
                                            src={image}
                                            alt={`Producte ${index + 1}`}
                                            className="w-full h-32 object-cover rounded"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveImage(index)}
                                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                                        >
                                            <FaTimes />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Atributs */}
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-semibold">Atributs personalitzats</h3>
                                <button
                                    type="button"
                                    onClick={handleAddAttribute}
                                    className="flex items-center text-[#ED5B52] hover:text-[#D64A41]"
                                >
                                    <FaPlus className="mr-1" /> Afegir atribut
                                </button>
                            </div>
                            {formData.attributes.map((attr, index) => (
                                <div key={index} className="flex items-center space-x-4">
                                    <div className="flex-1">
                                        <input
                                            type="text"
                                            placeholder="Nom de l'atribut"
                                            value={attr.name}
                                            onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <input
                                            type="text"
                                            placeholder="Valor"
                                            value={attr.value}
                                            onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                    </div>
                                    <div className="flex-1 flex space-x-2">
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="Modificador"
                                            value={attr.priceModifier}
                                            onChange={(e) => handleAttributeChange(index, 'priceModifier', e.target.value)}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                        <select
                                            value={attr.priceModifierType}
                                            onChange={(e) => handleAttributeChange(index, 'priceModifierType', e.target.value)}
                                            className="p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        >
                                            <option value="fixed">€</option>
                                            <option value="percentage">%</option>
                                        </select>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveAttribute(index)}
                                        className="text-red-500 hover:text-red-600"
                                    >
                                        <FaTimes />
                                    </button>
                                </div>
                            ))}
                        </div>

                        {/* Botons */}
                        <div className="flex justify-end space-x-3">
                            <Link
                                to={`/online-shops/${currentShopId}`}
                                className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Cancel·lar
                            </Link>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64A41] disabled:opacity-50"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <span className="flex items-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Guardant producte...
                                    </span>
                                ) : 'Guardar producte'}
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default EditProduct; 