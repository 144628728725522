import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaUpload, FaTrash, FaPlus } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const SinglePayment = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date(Date.now() + 30*24*60*60*1000).toISOString().split('T')[0],
    document: null,
  });

  const [recipients, setRecipients] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [showClientSelector, setShowClientSelector] = useState(false);
  const [availableClients, setAvailableClients] = useState([]);
  const [bulkAmount, setBulkAmount] = useState('');
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState('');
  const [loading, setLoading] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState('');

  // Cargar clientes disponibles
  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customers`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setAvailableClients(data);
        }
      } catch (error) {
        console.error('Error en obtenir els clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Añadir useEffect para cargar las pasarelas disponibles
  useEffect(() => {
    const fetchPaymentGateways = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setPaymentGateways(data);
          // Seleccionar la primera pasarela activa por defecto si existe
          const firstActiveGateway = data.find(gateway => gateway.is_active);
          if (firstActiveGateway) {
            setSelectedGateway(firstActiveGateway.id);
          }
        }
      } catch (error) {
        console.error('Error en obtenir les passarel·les de pagament:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentGateways();
    
  }, []);

  const handleAddEmails = (e) => {
    e.preventDefault();
    const emails = emailInput.split(',')
      .map(email => email.trim())
      .filter(email => email && !recipients.find(r => r.email === email));

    if (emails.length > 0) {
      setRecipients(prev => [
        ...prev,
        ...emails.map(email => ({
          email,
          amount: ''
        }))
      ]);
      setEmailInput('');
    }
  };

  const handleClientSelection = (client) => {
    if (!recipients.find(r => r.email === client.email)) {
      setRecipients(prev => [...prev, {
        email: client.email,
        name: client.name,
        amount: ''
      }]);
    }
  };

  const handleRemoveRecipient = (email) => {
    setRecipients(prev => prev.filter(r => r.email !== email));
  };

  const handleAmountChange = (email, amount) => {
    setRecipients(prev => prev.map(r => 
      r.email === email ? { ...r, amount } : r
    ));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setFormData(prev => ({
        ...prev,
        document: file
      }));
    } else {
      alert('Si us plau, selecciona un arxiu PDF');
    }
  };

  // Primer, modifiquem la funció formatDate per gestionar millor les dates
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return '';
      return date.toLocaleDateString('ca-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    } catch (error) {
      console.error('Error formatant la data:', error);
      return '';
    }
  };

  // Modifiquem el handleSubmit per incloure correctament la descripció
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (!user.id) {
        throw new Error('No s\'ha pogut obtenir l\'ID de l\'usuari');
      }

      // Validar que haya al menos un destinatario con importe
      if (recipients.length === 0) {
        alert('Has d\'afegir almenys un destinatari');
        return;
      }

      if (!selectedGateway) {
        alert('Has de seleccionar una passarel·la de pagament');
        return;
      }

      // Array para almacenar los payments creados
      const createdPayments = [];

      // Crear un payment para cada destinatario
      for (const recipient of recipients) {
        const paymentData = {
          user_id: user.id,
          payment_type_id: 1,
          payment_gateway_id: selectedGateway,
          name: formData.name,
          description: `Cobrament únic per a ${recipient.email}`,
          amount: parseFloat(recipient.amount),
          currency: 'EUR',
          status: 'pending',
          configuration: {
            start_date: formData.startDate,
            end_date: formData.endDate,
            recipients: [recipient.email],
            document_path: null
          }
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paymentData)
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `Error al crear el payment per a ${recipient.email}`);
        }

        const data = await response.json();
        createdPayments.push(data.payment); // Guardamos el payment creado
      }

      // Si hay un documento adjunto, subirlo
      if (formData.document && createdPayments.length > 0) {
        const formDataFile = new FormData();
        formDataFile.append('document', formData.document);
        
        // Extraer los IDs de los payments creados
        const paymentIds = createdPayments.map(payment => payment.id);
        console.log('Payment IDs a enviar:', paymentIds); // Para debug
        
        formDataFile.append('payment_ids', paymentIds.join(','));

        const uploadResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/upload-document`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formDataFile
        });

        if (!uploadResponse.ok) {
          const errorData = await uploadResponse.json();
          throw new Error(errorData.message || 'Error al pujar el document');
        }
      }

      // Redirigir a la lista de cobros o mostrar mensaje de éxito
      alert('Cobraments creats correctament');
      window.location.href = '/cobraments';

    } catch (error) {
      console.error('Error al crear els cobraments:', error);
      alert(error.message || 'Hi ha hagut un error al crear els cobraments. Si us plau, torna-ho a provar.');
    } finally {
      setLoading(false);
    }
  };

  // Función para aplicar el mismo importe a todos
  const handleApplyBulkAmount = () => {
    if (bulkAmount) {
      setRecipients(prev => prev.map(recipient => ({
        ...recipient,
        amount: bulkAmount
      })));
    }
  };

  const filteredClients = availableClients.filter(client => 
    client.name?.toLowerCase().includes(clientSearchTerm.toLowerCase()) ||
    client.email?.toLowerCase().includes(clientSearchTerm.toLowerCase()) ||
    client.phone?.toLowerCase().includes(clientSearchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold text-gray-800">Crear nou cobrament únic</h1>
              <Link
                to="/cobraments"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
              >
                <FaArrowLeft className="mr-2" /> Tornar
              </Link>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Nom del cobrament */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom del cobrament
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    required
                  />
                </div>

                {/* Modificar la secció d'afegir emails i clients */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Afegir destinataris
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      value={emailInput}
                      onChange={(e) => setEmailInput(e.target.value)}
                      placeholder="Introdueix els emails separats per comes..."
                      className="flex-grow p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    />
                    <button
                      onClick={handleAddEmails}
                      className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41]"
                    >
                      Afegir
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowClientSelector(!showClientSelector)}
                      className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] flex items-center"
                    >
                      <FaPlus className="mr-2" />
                      Clients
                    </button>
                  </div>

                  {showClientSelector && (
                    <div className="bg-white rounded-lg shadow overflow-hidden mt-4">
                      <div className="p-4 border-b">
                        <input
                          type="text"
                          value={clientSearchTerm}
                          onChange={(e) => setClientSearchTerm(e.target.value)}
                          placeholder="Cerca per nom, email o telèfon..."
                          className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                          autoFocus
                        />
                      </div>
                      
                      <div className="overflow-y-auto max-h-[300px]">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50 sticky top-0 z-10">
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                Nom
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                Email
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                Telèfon
                              </th>
                              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                Accions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {filteredClients.map((client) => (
                              <tr 
                                key={client.id}
                                className="hover:bg-gray-50 transition-colors duration-200"
                              >
                                <td className="px-6 py-2 whitespace-nowrap">
                                  {client.name || '-'}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  {client.email}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                  {client.phone || '-'}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-right">
                                  <button
                                    type="button"
                                    onClick={() => handleClientSelection(client)}
                                    className="text-[#ED5B52] hover:text-[#D64A41] font-medium"
                                    disabled={recipients.some(r => r.email === client.email)}
                                  >
                                    {recipients.some(r => r.email === client.email) 
                                      ? 'Afegit' 
                                      : 'Afegir'}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>

                {/* Llistat de destinataris amb imports */}
                {recipients.length > 0 && (
                  <div className="space-y-4">
                    {/* Aplicar mateix import */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex items-center space-x-4">
                        <span className="text-sm font-medium text-gray-700">
                          Aplicar el mateix import a tots els destinataris:
                        </span>
                        <div className="flex items-center space-x-2">
                          <input
                            type="number"
                            value={bulkAmount}
                            onChange={(e) => setBulkAmount(e.target.value)}
                            className="w-32 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            placeholder="0.00"
                            step="0.01"
                          />
                          <span>€</span>
                          <button
                            type="button"
                            onClick={handleApplyBulkAmount}
                            className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200"
                          >
                            Aplicar
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Tabla de destinatarios */}
                    <div className="border rounded-lg overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Destinatari
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Import (€)
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Accions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {recipients.map((recipient, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex flex-col">
                                  {recipient.name && (
                                    <span className="font-medium">{recipient.name}</span>
                                  )}
                                  <span className="text-gray-600">{recipient.email}</span>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                  type="number"
                                  value={recipient.amount}
                                  onChange={(e) => handleAmountChange(recipient.email, e.target.value)}
                                  className="w-32 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                  placeholder="0.00"
                                  step="0.01"
                                  required
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right">
                                <button
                                  type="button"
                                  onClick={() => handleRemoveRecipient(recipient.email)}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  <FaTrash />
                                </button>
                              </td>
                            </tr>
                          ))}
                          <tr className="bg-gray-50">
                            <td className="px-6 py-4 font-medium">
                              Total
                            </td>
                            <td className="px-6 py-4 font-medium text-[#ED5B52]">
                              {recipients.reduce((sum, r) => sum + (parseFloat(r.amount) || 0), 0).toFixed(2)}€
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Data d'inici */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Data d'inici
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={(e) => setFormData(prev => ({ 
                      ...prev, 
                      startDate: e.target.value,
                      // Actualitzem la data de fi si la nova data d'inici és posterior
                      endDate: e.target.value > prev.endDate ? e.target.value : prev.endDate
                    }))}
                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    required
                  />
                </div>

                {/* Data de fi */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Data de fi
                  </label>
                  <input
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    min={formData.startDate}
                    onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                    required
                  />
                </div>

                {/* Document adjunt */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Document adjunt (opcional)
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      className="hidden"
                      id="document-upload"
                    />
                    <label
                      htmlFor="document-upload"
                      className="bg-gray-100 hover:bg-gray-200 px-4 py-2 rounded cursor-pointer flex items-center"
                    >
                      <FaUpload className="mr-2" />
                      Pujar document
                    </label>
                    {formData.document && (
                      <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-600">
                          {formData.document.name}
                        </span>
                        <button
                          type="button"
                          onClick={() => setFormData(prev => ({ ...prev, document: null }))}
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {/* Selector de pasarel·la de pagament */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Pasarel·la de pagament
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {paymentGateways.map((gateway) => (
                      <div
                        key={gateway.id}
                        onClick={() => gateway.is_active && setSelectedGateway(gateway.id)}
                        className={`
                          p-4 rounded-lg border-2 transition-all duration-200
                          ${!gateway.is_active && 'opacity-50 cursor-not-allowed bg-gray-100'}
                          ${gateway.is_active && 'cursor-pointer'}
                          ${selectedGateway === gateway.id && gateway.is_active
                            ? 'border-[#ED5B52] bg-red-50' 
                            : 'border-gray-200 hover:border-gray-300'
                          }
                        `}
                      >
                        <div className="flex items-center space-x-3">
                          <input
                            type="radio"
                            name="payment-gateway"
                            value={gateway.id}
                            checked={selectedGateway === gateway.id}
                            onChange={() => gateway.is_active && setSelectedGateway(gateway.id)}
                            className="text-[#ED5B52] focus:ring-[#ED5B52]"
                            disabled={!gateway.is_active}
                            required
                          />
                          <div>
                            <p className={`font-medium ${gateway.is_active ? 'text-gray-900' : 'text-gray-500'}`}>
                              {gateway.name}
                            </p>
                            {!gateway.is_active && (
                              <p className="text-sm text-red-500">
                                Desactivada
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Botón submit */}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200"
                  >
                    Crear Cobrament
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default SinglePayment;
