import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSave, FaPlus, FaTrash } from 'react-icons/fa';
import Header from '../../Header';
import Footer from '../../Footer';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useAuth } from '../../../contexts/AuthContext';

// Component per les regles de visibilitat
const VisibilityRules = ({ rules, onChange, availableFields }) => {
    return (
        <div className="space-y-4 p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center space-x-2">
                <input
                    type="checkbox"
                    checked={rules.enabled}
                    onChange={(e) => onChange({...rules, enabled: e.target.checked})}
                    className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                />
                <span className="text-sm text-gray-700">Habilitar regles de visibilitat</span>
            </div>

            {rules.enabled && (
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Camp dependent
                        </label>
                        <select
                            value={rules.field_id || ''}
                            onChange={(e) => onChange({...rules, field_id: e.target.value})}
                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        >
                            <option value="">Selecciona un camp</option>
                            {availableFields.map(field => (
                                <option key={field.id} value={field.id}>
                                    {field.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Operador
                        </label>
                        <select
                            value={rules.operator}
                            onChange={(e) => onChange({...rules, operator: e.target.value})}
                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        >
                            <option value="equals">Igual a</option>
                            <option value="not_equals">Diferent de</option>
                            <option value="greater_than">Major que</option>
                            <option value="less_than">Menor que</option>
                            <option value="contains">Conté</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Valor
                        </label>
                        <input
                            type="text"
                            value={rules.value}
                            onChange={(e) => onChange({...rules, value: e.target.value})}
                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const CreatePaymentForm = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shops, setShops] = useState([]);
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        service_name: '',
        description: '',
        service_description: '',
        base_price: '0',
        currency: 'EUR',
        is_public: true,
        status: 'active',
        success_message: 'Gràcies pel teu pagament. El servei ha estat contractat correctament.',
        error_message: 'Hi ha hagut un error en el pagament. Si us plau, torna-ho a intentar.',
        sections: [
            {
                name: '',
                description: '',
                visibility_rules: {
                    enabled: false,
                    field_id: null,
                    operator: 'equals',
                    value: ''
                },
                fields: [
                    {
                        name: '',
                        label: '',
                        type: 'text',
                        options: [],
                        required: false,
                        visible: true,
                        visibility_rules: {
                            enabled: false,
                            field_id: null,
                            operator: 'equals',
                            value: ''
                        }
                    }
                ]
            }
        ],
        conditions: [],
        access_code: '',
        payment_gateway_id: '',
        payment_type_id: '',
        configuration: {
            start_date: '',
            end_date: null,
            frequency: 'monthly',
            until_amount_completed: null,
            document_path: null
        }
    });

    useEffect(() => {
        Promise.all([
            fetchShops(),
            fetchPaymentGateways(),
            fetchPaymentTypes()
        ]).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const fetchShops = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setShops(data);
            }
        } catch (error) {
            setError('Error al carregar les botigues');
        }
    };

    const fetchPaymentGateways = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPaymentGateways(data);
            }
        } catch (error) {
            setError('Error al cargar las pasarelas de pago');
        }
    };

    const fetchPaymentTypes = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-types`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setPaymentTypes(data);
            }
        } catch (error) {
            setError('Error al cargar los tipos de pago');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al crear el formulari');
            }

            navigate('/payments/forms');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const addSection = () => {
        setFormData(prev => ({
            ...prev,
            sections: [...prev.sections, {
                name: '',
                description: '',
                visibility_rules: {
                    enabled: false,
                    field_id: null,
                    operator: 'equals',
                    value: ''
                },
                fields: []
            }]
        }));
    };

    const removeSection = (index) => {
        setFormData(prev => ({
            ...prev,
            sections: prev.sections.filter((_, i) => i !== index)
        }));
    };

    const addField = (sectionIndex) => {
        const newSections = [...formData.sections];
        newSections[sectionIndex].fields.push({
            name: '',
            label: '',
            type: 'text',
            options: [],
            required: false,
            visible: true,
            visibility_rules: {
                enabled: false,
                field_id: null,
                operator: 'equals',
                value: ''
            }
        });
        setFormData(prev => ({...prev, sections: newSections}));
    };

    const removeField = (sectionIndex, fieldIndex) => {
        const newSections = [...formData.sections];
        newSections[sectionIndex].fields.splice(fieldIndex, 1);
        setFormData(prev => ({...prev, sections: newSections}));
    };

    const getAllFieldsBeforeSection = (sectionIndex) => {
        const fields = [];
        for (let i = 0; i < sectionIndex; i++) {
            formData.sections[i].fields.forEach(field => {
                fields.push({
                    id: field.id || `${i}-${field.name}`,
                    label: `${formData.sections[i].name} - ${field.label}`
                });
            });
        }
        return fields;
    };

    const getAllFieldsBeforeField = (sectionIndex, fieldIndex) => {
        const fields = getAllFieldsBeforeSection(sectionIndex);
        formData.sections[sectionIndex].fields.slice(0, fieldIndex).forEach(field => {
            fields.push({
                id: field.id || `${sectionIndex}-${field.name}`,
                label: `${formData.sections[sectionIndex].name} - ${field.label}`
            });
        });
        return fields;
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">Crear Formulari de Pagament</h1>
                            <div className="flex items-center">
                                <Link
                                    to="/payments/forms"
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                >
                                    <FaArrowLeft className="mr-2" /> Tornar
                                </Link>
                            </div>
                        </div>

                        {error && (
                            <div className="bg-red-50 text-red-500 p-4 rounded-lg mb-6">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
                            <div className="space-y-6">
                                {/* Informació bàsica */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Nom intern del formulari *
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            placeholder="Nom per identificar el formulari (ús intern)"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Nom del servei *
                                        </label>
                                        <input
                                            type="text"
                                            name="service_name"
                                            value={formData.service_name}
                                            onChange={handleChange}
                                            required
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            placeholder="Nom del servei (visible pels clients)"
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Descripció interna
                                        </label>
                                        <textarea
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            rows="3"
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            placeholder="Descripció per ús intern"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Descripció del servei
                                        </label>
                                        <textarea
                                            name="service_description"
                                            value={formData.service_description}
                                            onChange={handleChange}
                                            rows="3"
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            placeholder="Descripció visible pels clients"
                                        />
                                    </div>
                                </div>

                                {/* Configuració bàsica */}
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Preu base *
                                        </label>
                                        <input
                                            type="number"
                                            name="base_price"
                                            value={formData.base_price}
                                            onChange={handleChange}
                                            required
                                            step="0.01"
                                            min="0"
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Moneda *
                                        </label>
                                        <select
                                            name="currency"
                                            value={formData.currency}
                                            onChange={handleChange}
                                            required
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        >
                                            <option value="EUR">EUR</option>
                                            <option value="USD">USD</option>
                                            <option value="GBP">GBP</option>
                                        </select>
                                    </div>

                                    <div className="space-y-4">
                                        <div className="flex items-center">
                                            <label className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    name="is_public"
                                                    checked={formData.is_public}
                                                    onChange={handleChange}
                                                    className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                                />
                                                <span className="text-sm text-gray-700">
                                                    Formulari públic
                                                </span>
                                            </label>
                                        </div>

                                        {!formData.is_public && (
                                            <div className="mt-3">
                                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                                    Codi d'accés *
                                                </label>
                                                <input
                                                    type="text"
                                                    name="access_code"
                                                    value={formData.access_code}
                                                    onChange={handleChange}
                                                    required={!formData.is_public}
                                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="Introdueix un codi d'accés"
                                                />
                                                <p className="mt-1 text-sm text-gray-500">
                                                    Aquest codi serà necessari per accedir al formulari
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Missatges de resposta */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Missatge d'èxit
                                        </label>
                                        <input
                                            type="text"
                                            name="success_message"
                                            value={formData.success_message}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Missatge d'error
                                        </label>
                                        <input
                                            type="text"
                                            name="error_message"
                                            value={formData.error_message}
                                            onChange={handleChange}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                    </div>
                                </div>

                                {/* Configuración pagos */}
                                <hr style={{marginTop: '3rem'}}/>
                                <h2 className="text-lg font-medium text-gray-900 mb-4 mt-4">Configuració del pagament</h2>
                                <div className="grid grid-cols-2 gap-4 mb-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Pasarela de Pago
                                        </label>
                                        <select
                                            value={formData.payment_gateway_id}
                                            onChange={(e) => setFormData(prev => ({...prev, payment_gateway_id: e.target.value}))}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            required
                                        >
                                            <option value="">Selecciona una pasarela</option>
                                            {paymentGateways.map(gateway => (
                                                <option key={gateway.id} value={gateway.id}>
                                                    {gateway.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Tipo de Pago
                                        </label>
                                        <select
                                            value={formData.payment_type_id}
                                            onChange={(e) => setFormData(prev => ({...prev, payment_type_id: e.target.value}))}
                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            required
                                        >
                                            <option value="">Selecciona un tipo</option>
                                            {paymentTypes.map(type => (
                                                <option key={type.id} value={type.id}>
                                                    {type.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {/* Configuración pagament */}
                                <div className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Data d'inici *
                                            </label>
                                            <input
                                                type="date"
                                                value={formData.configuration.start_date}
                                                onChange={(e) => setFormData(prev => ({
                                                    ...prev,
                                                    configuration: {...prev.configuration, start_date: e.target.value}
                                                }))}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                required
                                            />
                                        </div>

                                        {formData.payment_type_id === '1' && (
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Data de finalització
                                                </label>
                                                <input
                                                    type="date"
                                                    value={formData.configuration.end_date || ''}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        configuration: {...prev.configuration, end_date: e.target.value || null}
                                                    }))}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                />
                                            </div>
                                        )}

                                        {formData.payment_type_id === '2' && (
                                            <>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Import màxim a cobrar
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={formData.configuration.until_amount_completed || ''}
                                                        onChange={(e) => setFormData(prev => ({
                                                            ...prev,
                                                            configuration: {
                                                                ...prev.configuration, 
                                                                until_amount_completed: e.target.value ? parseFloat(e.target.value) : null,
                                                                end_date: e.target.value ? null : prev.configuration.end_date
                                                            }
                                                        }))}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                        step="0.01"
                                                        min="0"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Data de finalització
                                                    </label>
                                                    <input
                                                        type="date"
                                                        value={formData.configuration.end_date || ''}
                                                        onChange={(e) => setFormData(prev => ({
                                                            ...prev,
                                                            configuration: {
                                                                ...prev.configuration, 
                                                                end_date: e.target.value || null,
                                                                until_amount_completed: e.target.value ? null : prev.configuration.until_amount_completed
                                                            }
                                                        }))}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {formData.payment_type_id === '2' && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Freqüència *
                                        </label>
                                        <select
                                            value={formData.configuration.frequency}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                configuration: {...prev.configuration, frequency: e.target.value}
                                            }))}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ED5B52] focus:ring-[#ED5B52]"
                                            required
                                        >
                                            <option value="daily">Diari</option>
                                            <option value="weekly">Setmanal</option>
                                            <option value="bi-weekly">Quinzenal</option>
                                            <option value="monthly">Mensual</option>
                                            <option value="bi-monthly">Bimestral</option>
                                            <option value="quarterly">Trimestral</option>
                                            <option value="semi-annually">Semestral</option>
                                            <option value="annually">Anual</option>
                                        </select>
                                    </div>
                                )}

                                <hr className="my-6"/>

                                {/* Seccions i camps */}
                                <div className="space-y-6">
                                    <div className="flex justify-between items-center">
                                        <h2 className="text-xl font-semibold text-gray-800">Seccions</h2>
                                        <button
                                            type="button"
                                            onClick={addSection}
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 flex items-center"
                                        >
                                            <FaPlus className="mr-2" /> Afegir secció
                                        </button>
                                    </div>

                                    {formData.sections.map((section, sectionIndex) => (
                                        <div key={sectionIndex} className="border rounded-lg p-4 space-y-4">
                                            <div className="flex justify-between items-start">
                                                <h3 className="text-lg font-medium text-gray-700">
                                                    Secció {sectionIndex + 1}
                                                </h3>
                                                <button
                                                    type="button"
                                                    onClick={() => removeSection(sectionIndex)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>

                                            <div className="space-y-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Nom de la secció *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={section.name}
                                                        onChange={(e) => {
                                                            const newSections = [...formData.sections];
                                                            newSections[sectionIndex].name = e.target.value;
                                                            setFormData(prev => ({...prev, sections: newSections}));
                                                        }}
                                                        required
                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                                        Descripció de la secció
                                                    </label>
                                                    <textarea
                                                        value={section.description}
                                                        onChange={(e) => {
                                                            const newSections = [...formData.sections];
                                                            newSections[sectionIndex].description = e.target.value;
                                                            setFormData(prev => ({...prev, sections: newSections}));
                                                        }}
                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                        rows="2"
                                                    />
                                                </div>

                                                <div>
                                                    <h4 className="text-md font-medium text-gray-700 mb-2">Regles de visibilitat</h4>
                                                    <VisibilityRules
                                                        rules={section.visibility_rules}
                                                        onChange={(newRules) => {
                                                            const newSections = [...formData.sections];
                                                            newSections[sectionIndex].visibility_rules = newRules;
                                                            setFormData(prev => ({...prev, sections: newSections}));
                                                        }}
                                                        availableFields={getAllFieldsBeforeSection(sectionIndex)}
                                                    />
                                                </div>

                                                {/* Camps de la secció */}
                                                <div className="space-y-4">
                                                    <div className="flex justify-between items-center">
                                                        <h4 className="text-md font-medium text-gray-700">Camps</h4>
                                                        <button
                                                            type="button"
                                                            onClick={() => addField(sectionIndex)}
                                                            className="bg-green-500 text-white px-3 py-1 rounded text-sm hover:bg-green-600 transition duration-200 flex items-center"
                                                        >
                                                            <FaPlus className="mr-1" /> Afegir camp
                                                        </button>
                                                    </div>

                                                    {section.fields.map((field, fieldIndex) => (
                                                        <div key={fieldIndex} className="border rounded p-4 space-y-4 bg-gray-50">
                                                            <div className="flex justify-between items-start">
                                                                <h5 className="text-sm font-medium text-gray-700">
                                                                    Camp {fieldIndex + 1}
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => removeField(sectionIndex, fieldIndex)}
                                                                    className="text-red-500 hover:text-red-700"
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                            </div>

                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                        Nom del camp *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        value={field.name}
                                                                        onChange={(e) => {
                                                                            const newSections = [...formData.sections];
                                                                            newSections[sectionIndex].fields[fieldIndex].name = e.target.value;
                                                                            setFormData(prev => ({...prev, sections: newSections}));
                                                                        }}
                                                                        required
                                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                                    />
                                                                </div>

                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                        Etiqueta *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        value={field.label}
                                                                        onChange={(e) => {
                                                                            const newSections = [...formData.sections];
                                                                            newSections[sectionIndex].fields[fieldIndex].label = e.target.value;
                                                                            setFormData(prev => ({...prev, sections: newSections}));
                                                                        }}
                                                                        required
                                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                                    />
                                                                </div>

                                                                <div>
                                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                        Tipus de camp *
                                                                    </label>
                                                                    <select
                                                                        value={field.type}
                                                                        onChange={(e) => {
                                                                            const newSections = [...formData.sections];
                                                                            newSections[sectionIndex].fields[fieldIndex].type = e.target.value;
                                                                            setFormData(prev => ({...prev, sections: newSections}));
                                                                        }}
                                                                        required
                                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                                    >
                                                                        <option value="text">Text</option>
                                                                        <option value="number">Número</option>
                                                                        <option value="select">Selecció</option>
                                                                        <option value="checkbox">Casella de verificació</option>
                                                                        <option value="radio">Opcions múltiples</option>
                                                                        <option value="textarea">Àrea de text</option>
                                                                    </select>
                                                                </div>

                                                                {(field.type === 'select' || field.type === 'radio') && (
                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                            Opcions (una per línia)
                                                                        </label>
                                                                        <textarea
                                                                            value={field.options.join('\n')}
                                                                            onChange={(e) => {
                                                                                const newSections = [...formData.sections];
                                                                                newSections[sectionIndex].fields[fieldIndex].options = 
                                                                                    e.target.value.split('\n').filter(opt => opt.trim());
                                                                                setFormData(prev => ({...prev, sections: newSections}));
                                                                            }}
                                                                            className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                                            rows="3"
                                                                        />
                                                                    </div>
                                                                )}

                                                                <div className="col-span-2 flex space-x-4">
                                                                    <label className="flex items-center space-x-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={field.required}
                                                                            onChange={(e) => {
                                                                                const newSections = [...formData.sections];
                                                                                newSections[sectionIndex].fields[fieldIndex].required = e.target.checked;
                                                                                setFormData(prev => ({...prev, sections: newSections}));
                                                                            }}
                                                                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                                                        />
                                                                        <span className="text-sm text-gray-700">Camp obligatori</span>
                                                                    </label>

                                                                    <label className="flex items-center space-x-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={field.visible}
                                                                            onChange={(e) => {
                                                                                const newSections = [...formData.sections];
                                                                                newSections[sectionIndex].fields[fieldIndex].visible = e.target.checked;
                                                                                setFormData(prev => ({...prev, sections: newSections}));
                                                                            }}
                                                                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                                                        />
                                                                        <span className="text-sm text-gray-700">Visible inicialment</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <h4 className="text-md font-medium text-gray-700 mb-2">Regles de visibilitat</h4>
                                                                <VisibilityRules
                                                                    rules={field.visibility_rules}
                                                                    onChange={(newRules) => {
                                                                        const newSections = [...formData.sections];
                                                                        newSections[sectionIndex].fields[fieldIndex].visibility_rules = newRules;
                                                                        setFormData(prev => ({...prev, sections: newSections}));
                                                                    }}
                                                                    availableFields={getAllFieldsBeforeField(sectionIndex, fieldIndex)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Condicions i Preus */}
                                <div className="space-y-6 border-t pt-6 mt-6">
                                    <div className="flex justify-between items-center">
                                        <h2 className="text-xl font-semibold text-gray-800">Condicions i Preus</h2>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    conditions: [...prev.conditions, {
                                                        name: '',
                                                        description: '',
                                                        rules: {
                                                            operator: 'AND',
                                                            conditions: []
                                                        },
                                                        actions: {
                                                            price_modifications: [
                                                                {
                                                                    type: 'fixed',
                                                                    value: 0,
                                                                    operation: 'add'
                                                                }
                                                            ]
                                                        },
                                                        priority: prev.conditions.length,
                                                        is_active: true
                                                    }]
                                                }));
                                            }}
                                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                        >
                                            <FaPlus className="mr-2" /> Afegir condició
                                        </button>
                                    </div>

                                    {formData.conditions.map((condition, conditionIndex) => (
                                        <div key={conditionIndex} className="border rounded-lg p-4 space-y-4 bg-gray-50">
                                            <div className="flex justify-between items-start">
                                                <h3 className="text-lg font-medium text-gray-700">Condició {conditionIndex + 1}</h3>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const newConditions = [...formData.conditions];
                                                        newConditions.splice(conditionIndex, 1);
                                                        setFormData(prev => ({...prev, conditions: newConditions}));
                                                    }}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>

                                            {/* Configuració de la condició */}
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                                        Nom de la condició *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={condition.name}
                                                        onChange={(e) => {
                                                            const newConditions = [...formData.conditions];
                                                            newConditions[conditionIndex].name = e.target.value;
                                                            setFormData(prev => ({...prev, conditions: newConditions}));
                                                        }}
                                                        required
                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    />
                                                </div>

                                                {/* Regles de la condició */}
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Regles
                                                    </label>
                                                    <select
                                                        value={condition.rules.operator}
                                                        onChange={(e) => {
                                                            const newConditions = [...formData.conditions];
                                                            newConditions[conditionIndex].rules.operator = e.target.value;
                                                            setFormData(prev => ({...prev, conditions: newConditions}));
                                                        }}
                                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    >
                                                        <option value="AND">Totes les condicions (AND)</option>
                                                        <option value="OR">Qualsevol condició (OR)</option>
                                                    </select>

                                                    {/* Llista de regles */}
                                                    {condition.rules.conditions.map((rule, ruleIndex) => (
                                                        <div key={ruleIndex} className="flex items-center space-x-2">
                                                            <select
                                                                value={rule.field}
                                                                onChange={(e) => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].rules.conditions[ruleIndex].field = e.target.value;
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="flex-1 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                            >
                                                                {formData.sections.flatMap(section =>
                                                                    section.fields.map(field => (
                                                                        <option key={field.name} value={field.name}>
                                                                            {field.label}
                                                                        </option>
                                                                    ))
                                                                )}
                                                            </select>

                                                            <select
                                                                value={rule.operator}
                                                                onChange={(e) => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].rules.conditions[ruleIndex].operator = e.target.value;
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="w-32 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                            >
                                                                <option value="equals">Igual a</option>
                                                                <option value="not_equals">Diferent de</option>
                                                                <option value="greater_than">Major que</option>
                                                                <option value="less_than">Menor que</option>
                                                                <option value="contains">Conté</option>
                                                                <option value="not_contains">No conté</option>
                                                            </select>

                                                            <input
                                                                type="text"
                                                                value={rule.value}
                                                                onChange={(e) => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].rules.conditions[ruleIndex].value = e.target.value;
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="flex-1 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                                placeholder="Valor"
                                                            />

                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].rules.conditions.splice(ruleIndex, 1);
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="text-red-500 hover:text-red-700"
                                                            >
                                                                <FaTrash />
                                                            </button>
                                                        </div>
                                                    ))}

                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            const newConditions = [...formData.conditions];
                                                            newConditions[conditionIndex].rules.conditions.push({
                                                                field: '',
                                                                operator: 'equals',
                                                                value: ''
                                                            });
                                                            setFormData(prev => ({...prev, conditions: newConditions}));
                                                        }}
                                                        className="text-blue-500 hover:text-blue-700 text-sm flex items-center mt-2"
                                                    >
                                                        <FaPlus className="mr-1" /> Afegir regla
                                                    </button>
                                                </div>

                                                {/* Modificacions de preu */}
                                                <div className="col-span-2 space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Modificacions de preu
                                                    </label>
                                                    {condition.actions.price_modifications.map((mod, modIndex) => (
                                                        <div key={modIndex} className="flex items-center space-x-2">
                                                            <select
                                                                value={mod.type}
                                                                onChange={(e) => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].actions.price_modifications[modIndex].type = e.target.value;
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="w-32 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                            >
                                                                <option value="fixed">Import fix</option>
                                                                <option value="percentage">Percentatge</option>
                                                            </select>

                                                            <select
                                                                value={mod.operation}
                                                                onChange={(e) => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].actions.price_modifications[modIndex].operation = e.target.value;
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="w-32 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                            >
                                                                <option value="add">Sumar</option>
                                                                <option value="subtract">Restar</option>
                                                                <option value="multiply">Multiplicar</option>
                                                                <option value="divide">Dividir</option>
                                                            </select>

                                                            <input
                                                                type="number"
                                                                value={mod.value}
                                                                onChange={(e) => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].actions.price_modifications[modIndex].value = parseFloat(e.target.value);
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                step="0.01"
                                                                className="flex-1 p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                                placeholder="Valor"
                                                            />

                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    const newConditions = [...formData.conditions];
                                                                    newConditions[conditionIndex].actions.price_modifications.splice(modIndex, 1);
                                                                    setFormData(prev => ({...prev, conditions: newConditions}));
                                                                }}
                                                                className="text-red-500 hover:text-red-700"
                                                            >
                                                                <FaTrash />
                                                            </button>
                                                        </div>
                                                    ))}

                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            const newConditions = [...formData.conditions];
                                                            newConditions[conditionIndex].actions.price_modifications.push({
                                                                type: 'fixed',
                                                                operation: 'add',
                                                                value: 0
                                                            });
                                                            setFormData(prev => ({...prev, conditions: newConditions}));
                                                        }}
                                                        className="text-blue-500 hover:text-blue-700 text-sm flex items-center mt-2"
                                                    >
                                                        <FaPlus className="mr-1" /> Afegir modificació de preu
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Botó de guardar */}
                                <div className="flex justify-end pt-6">
                                    <button
                                        type="submit"
                                        className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaSave className="mr-2" /> Guardar formulari
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default CreatePaymentForm; 