import React, { useState, useEffect } from 'react';
import { FaUsers, FaBox, FaShoppingCart, FaEuroSign, FaCreditCard, FaClipboard, FaMegaphone, FaUserFriends, FaArrowLeft } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar
} from 'recharts';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';

const Statistics = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [stats, setStats] = useState(null);

    useEffect(() => {
        fetchStatistics();
    }, []);

    const fetchStatistics = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/statistics`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) throw new Error('Error al carregar les estadístiques');
            
            const data = await response.json();
            setStats(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingSpinner />;
    if (error) return <div className="text-red-500 text-center p-4">{error}</div>;
    if (!stats) return null;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Estadístiques</h1>
                    <Link
                        to="/configuracio"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {/* Targetes principals */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    <StatCard
                        title="Usuaris"
                        value={stats.general.total_users}
                        icon={<FaUsers className="text-3xl text-blue-500" />}
                    />
                    <StatCard
                        title="Clients"
                        value={stats.general.total_customers}
                        icon={<FaUserFriends className="text-3xl text-green-500" />}
                    />
                    <StatCard
                        title="Ingressos Totals"
                        value={`${stats.general.total_revenue}€`}
                        icon={<FaEuroSign className="text-3xl text-yellow-500" />}
                    />
                    <StatCard
                        title="Comandes"
                        value={stats.general.total_orders}
                        icon={<FaShoppingCart className="text-3xl text-purple-500" />}
                    />
                </div>

                {/* Gràfics de tendències mensuals */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
                    <ChartCard title="Vendes Mensuals">
                        <LineChart data={stats.monthly_trends.sales}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend />
                            <Line
                                yAxisId="left"
                                type="monotone"
                                dataKey="total_orders"
                                stroke="#8884d8"
                                name="Comandes"
                            />
                            <Line
                                yAxisId="right"
                                type="monotone"
                                dataKey="total_revenue"
                                stroke="#82ca9d"
                                name="Ingressos (€)"
                            />
                        </LineChart>
                    </ChartCard>
                    
                    <ChartCard title="Creixement de Clients">
                        <LineChart data={stats.monthly_trends.customers}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend />
                            <Line
                                yAxisId="left"
                                type="monotone"
                                dataKey="new_customers"
                                stroke="#8884d8"
                                name="Nous Clients"
                            />
                        </LineChart>
                    </ChartCard>
                </div>

                {/* Estadístiques de campanyes i pagaments */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h3 className="text-xl font-bold mb-4">Campanyes Actives</h3>
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Total Campanyes</span>
                                <span className="font-semibold">{stats.general.total_campaigns || 0}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Campanyes Actives</span>
                                <span className="font-semibold">{stats.campaigns?.active_count || 0}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Total Participants</span>
                                <span className="font-semibold">{stats.campaigns?.total_participants || 0}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Mitjana Participants/Campanya</span>
                                <span className="font-semibold">{stats.campaigns?.average_participants || 0}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h3 className="text-xl font-bold mb-4">Resum de Pagaments</h3>
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Total Pagaments</span>
                                <span className="font-semibold">
                                    {stats?.payments?.general?.total_count || 0}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Pagaments Completats</span>
                                <span className="font-semibold">
                                    {stats?.payments?.general?.completed_count || 0}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Import Total</span>
                                <span className="font-semibold">
                                    {Number(stats?.payments?.general?.total_amount || 0).toFixed(2)}€
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-600">Import Retornat</span>
                                <span className="font-semibold text-red-500">
                                    -{Number(stats?.payments?.general?.refunded_amount || 0).toFixed(2)}€
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Elements més populars */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
                    <TopItemsCard
                        title="Productes Més Venuts"
                        items={stats.top_items.products}
                        valueKey="total_quantity"
                        labelKey="name"
                    />
                    <TopItemsCard
                        title="Campanyes Més Populars"
                        items={stats.top_items.campaigns}
                        valueKey="participants_count"
                        labelKey="name"
                    />
                    <TopItemsCard
                        title="Formularis Més Utilitzats"
                        items={stats.top_items.payment_forms}
                        valueKey="responses_count"
                        labelKey="name"
                    />
                </div>

                {/* Formularis de Pagament */}
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-xl font-bold mb-4">Formularis de Pagament</h3>
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Total Formularis</span>
                            <span className="font-semibold">
                                {stats?.payments?.forms?.total_forms || 0}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Formularis Actius</span>
                            <span className="font-semibold">{stats.payments.forms.active_forms}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Total Respostes</span>
                            <span className="font-semibold">{stats.payments.forms.total_responses}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Mitjana per Resposta</span>
                            <span className="font-semibold">
                                {Number(stats?.payments?.forms?.average_response_amount || 0).toFixed(2)}€
                            </span>
                        </div>
                    </div>
                </div>

                {/* Pagaments Oberts */}
                <div className="bg-white rounded-lg shadow-md p-6 mt-8">
                    <h3 className="text-xl font-bold mb-4">Pagaments Oberts</h3>
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Total Pagaments Oberts</span>
                            <span className="font-semibold">
                                {stats?.payments?.open_payments?.total_open_payments || 0}
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Pagaments Actius</span>
                            <span className="font-semibold">{stats.payments.open_payments.active_open_payments}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Total Transaccions</span>
                            <span className="font-semibold">{stats.payments.open_payments.total_transactions}</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Mitjana per Transacció</span>
                            <span className="font-semibold">
                                {Number(stats?.payments?.open_payments?.average_transaction_amount || 0).toFixed(2)}€
                            </span>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

// Components auxiliars
const StatCard = ({ title, value, icon }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex items-center justify-between">
            <div>
                <p className="text-gray-500">{title}</p>
                <p className="text-2xl font-bold">{value}</p>
            </div>
            {icon}
        </div>
    </div>
);

const ChartCard = ({ title, children }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-4">{title}</h3>
        <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
                {children}
            </ResponsiveContainer>
        </div>
    </div>
);

const TopItemsCard = ({ title, items = [], valueKey, labelKey }) => (
    <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-4">{title}</h3>
        <div className="space-y-4">
            {items?.map((item, index) => (
                <div key={item.id || index} className="flex justify-between items-center">
                    <span className="text-gray-600">{item[labelKey] || 'N/A'}</span>
                    <span className="font-semibold">{item[valueKey] || 0}</span>
                </div>
            ))}
        </div>
    </div>
);

export default Statistics; 