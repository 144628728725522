import React, { useEffect, useState } from 'react';
import { StyleProvider } from '../../../contexts/StyleContext';
import LoadingSpinner from '../../common/LoadingSpinner';

const withPublicStyles = (WrappedComponent) => {
    return function WithStylesComponent(props) {
        const [isReady, setIsReady] = useState(false);

        useEffect(() => {
            // Asegurarse de que el StyleProvider esté listo
            setIsReady(true);
        }, []);

        if (!isReady) {
            return <LoadingSpinner />;
        }

        return (
            <StyleProvider>
                <WrappedComponent {...props} />
            </StyleProvider>
        );
    };
};

export default withPublicStyles; 