import React, { useState } from 'react';
import { useCart } from '../../contexts/CartContext';

const CheckoutForm = ({ shop, onSuccess }) => {
    const { cart, clearCart } = useCart();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        tax_number: '',
        shipping_address: {
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            postal_code: '',
            country: ''
        },
        billing_address: {
            same_as_shipping: true,
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            postal_code: '',
            country: ''
        },
        notes: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            if (!shop?.id) {
                throw new Error('ID de tienda no válido');
            }

            // Preparar los datos de facturación
            const billingAddress = formData.billing_address.same_as_shipping 
                ? { ...formData.shipping_address }
                : formData.billing_address;

            // Preparar el payload
            const payload = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                tax_number: formData.tax_number,
                shipping_address: {
                    address_line_1: formData.shipping_address.address_line_1,
                    address_line_2: formData.shipping_address.address_line_2 || '',
                    city: formData.shipping_address.city,
                    state: formData.shipping_address.state || '',
                    postal_code: formData.shipping_address.postal_code,
                    country: formData.shipping_address.country
                },
                billing_address: {
                    same_as_shipping: formData.billing_address.same_as_shipping,
                    address_line_1: billingAddress.address_line_1,
                    address_line_2: billingAddress.address_line_2 || '',
                    city: billingAddress.city,
                    state: billingAddress.state || '',
                    postal_code: billingAddress.postal_code,
                    country: billingAddress.country
                },
                items: cart.map(item => ({
                    id: item.id,
                    quantity: item.quantity,
                    attributes: item.selectedAttributes ? Object.values(item.selectedAttributes) : [],
                    price: item.finalPrice
                })),
                notes: formData.notes || ''
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shops/${shop.id}/checkout`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Error al procesar el pago');
            }

            if (data.success) {
                clearCart();
                
                // Gestionar la respuesta de la pasarela de pago
                const gatewayResponse = data.data;

                if (gatewayResponse.method === 'POST') {
                    // Crear y enviar formulario de redirección
                    const form = document.createElement('form');
                    form.method = gatewayResponse.method;
                    form.action = gatewayResponse.url;
                    form.style.display = 'none';

                    Object.entries(gatewayResponse.fields || {}).forEach(([key, value]) => {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    });

                    document.body.appendChild(form);
                    form.submit();
                } else if (gatewayResponse.method === 'GET') {
                    window.location.href = gatewayResponse.url;
                }
            }
        } catch (error) {
            console.error('Error en el checkout:', error);
            alert(error.message || 'Ha ocurrido un error durante el proceso de compra');
        }
    };

    // Si no hay tienda, no renderizamos el formulario
    if (!shop) {
        return null;
    }

    return (
        <form 
            id="checkout-form"
            onSubmit={handleSubmit} 
            className="space-y-6"
        >
            {/* Datos personales */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Datos personales</h3>
                <input
                    type="text"
                    placeholder="Nombre completo *"
                    value={formData.name}
                    onChange={e => setFormData({...formData, name: e.target.value})}
                    required
                    className="w-full p-2 border rounded"
                />
                <input
                    type="email"
                    placeholder="Email *"
                    value={formData.email}
                    onChange={e => setFormData({...formData, email: e.target.value})}
                    required
                    className="w-full p-2 border rounded"
                />
                <input
                    type="tel"
                    placeholder="Teléfono"
                    value={formData.phone}
                    onChange={e => setFormData({...formData, phone: e.target.value})}
                    className="w-full p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="NIF/CIF"
                    value={formData.tax_number}
                    onChange={e => setFormData({...formData, tax_number: e.target.value})}
                    className="w-full p-2 border rounded"
                />
            </div>

            {/* Dirección de envío */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Dirección de envío</h3>
                <input
                    type="text"
                    placeholder="Dirección *"
                    value={formData.shipping_address.address_line_1}
                    onChange={e => setFormData({
                        ...formData,
                        shipping_address: {
                            ...formData.shipping_address,
                            address_line_1: e.target.value
                        }
                    })}
                    required
                    className="w-full p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Dirección (línea 2)"
                    value={formData.shipping_address.address_line_2}
                    onChange={e => setFormData({
                        ...formData,
                        shipping_address: {
                            ...formData.shipping_address,
                            address_line_2: e.target.value
                        }
                    })}
                    className="w-full p-2 border rounded"
                />
                <div className="grid grid-cols-2 gap-4">
                    <input
                        type="text"
                        placeholder="Ciudad *"
                        value={formData.shipping_address.city}
                        onChange={e => setFormData({
                            ...formData,
                            shipping_address: {
                                ...formData.shipping_address,
                                city: e.target.value
                            }
                        })}
                        required
                        className="w-full p-2 border rounded"
                    />
                    <input
                        type="text"
                        placeholder="Provincia"
                        value={formData.shipping_address.state}
                        onChange={e => setFormData({
                            ...formData,
                            shipping_address: {
                                ...formData.shipping_address,
                                state: e.target.value
                            }
                        })}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <input
                        type="text"
                        placeholder="Código Postal *"
                        value={formData.shipping_address.postal_code}
                        onChange={e => setFormData({
                            ...formData,
                            shipping_address: {
                                ...formData.shipping_address,
                                postal_code: e.target.value
                            }
                        })}
                        required
                        className="w-full p-2 border rounded"
                    />
                    <input
                        type="text"
                        placeholder="País *"
                        value={formData.shipping_address.country}
                        onChange={e => setFormData({
                            ...formData,
                            shipping_address: {
                                ...formData.shipping_address,
                                country: e.target.value
                            }
                        })}
                        required
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>

            {/* Dirección de facturación */}
            <div className="space-y-4">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        checked={formData.billing_address.same_as_shipping}
                        onChange={e => {
                            const same_as_shipping = e.target.checked;
                            setFormData({
                                ...formData,
                                billing_address: {
                                    ...formData.billing_address,
                                    same_as_shipping,
                                    ...(same_as_shipping ? formData.shipping_address : {})
                                }
                            });
                        }}
                        className="mr-2"
                    />
                    <label>Usar la misma dirección para facturación</label>
                </div>

                {!formData.billing_address.same_as_shipping && (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Dirección de facturación</h3>
                        <input
                            type="text"
                            placeholder="Dirección *"
                            value={formData.billing_address.address_line_1}
                            onChange={e => setFormData({
                                ...formData,
                                billing_address: {
                                    ...formData.billing_address,
                                    address_line_1: e.target.value
                                }
                            })}
                            required
                            className="w-full p-2 border rounded"
                        />
                        <input
                            type="text"
                            placeholder="Dirección (línea 2)"
                            value={formData.billing_address.address_line_2}
                            onChange={e => setFormData({
                                ...formData,
                                billing_address: {
                                    ...formData.billing_address,
                                    address_line_2: e.target.value
                                }
                            })}
                            className="w-full p-2 border rounded"
                        />
                        <div className="grid grid-cols-2 gap-4">
                            <input
                                type="text"
                                placeholder="Ciudad *"
                                value={formData.billing_address.city}
                                onChange={e => setFormData({
                                    ...formData,
                                    billing_address: {
                                        ...formData.billing_address,
                                        city: e.target.value
                                    }
                                })}
                                required
                                className="w-full p-2 border rounded"
                            />
                            <input
                                type="text"
                                placeholder="Provincia"
                                value={formData.billing_address.state}
                                onChange={e => setFormData({
                                    ...formData,
                                    billing_address: {
                                        ...formData.billing_address,
                                        state: e.target.value
                                    }
                                })}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <input
                                type="text"
                                placeholder="Código Postal *"
                                value={formData.billing_address.postal_code}
                                onChange={e => setFormData({
                                    ...formData,
                                    billing_address: {
                                        ...formData.billing_address,
                                        postal_code: e.target.value
                                    }
                                })}
                                required
                                className="w-full p-2 border rounded"
                            />
                            <input
                                type="text"
                                placeholder="País *"
                                value={formData.billing_address.country}
                                onChange={e => setFormData({
                                    ...formData,
                                    billing_address: {
                                        ...formData.billing_address,
                                        country: e.target.value
                                    }
                                })}
                                required
                                className="w-full p-2 border rounded"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Notas */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Notas adicionales</h3>
                <textarea
                    placeholder="Notas para el pedido"
                    value={formData.notes}
                    onChange={e => setFormData({...formData, notes: e.target.value})}
                    className="w-full p-2 border rounded"
                    rows="3"
                />
            </div>
        </form>
    );
};

export default CheckoutForm; 