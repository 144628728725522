import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { FaMoneyBill, FaExternalLinkAlt, FaCreditCard, FaLock } from 'react-icons/fa';

const PublicOpenPayments = () => {
    const [openPayments, setOpenPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOpenPayments = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/public-open-payments`);
                if (!response.ok) {
                    throw new Error('No s\'han pogut carregar els pagaments');
                }
                const data = await response.json();
                setOpenPayments(data);
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchOpenPayments();
    }, []);

    const layoutProps = {
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    if (isLoading) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-center py-8">Carregant...</div>
                </div>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">
                <section className="relative bg-gray-200 text-gray-800 py-20 rounded-lg overflow-hidden mb-12">
                    <div className="relative z-10 max-w-3xl mx-auto text-center px-4">
                        <h1 className="text-4xl font-bold mb-6">Pagaments Disponibles</h1>
                        <p className="text-xl mb-8">Accedeix als nostres mètodes de pagament oberts</p>
                    </div>
                    <div className="absolute inset-0 bg-gray-300 opacity-50"></div>
                </section>

                <section className="mb-12">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {openPayments.map(payment => (
                            <Link 
                                key={payment.id} 
                                to={`/public-open-payments/${payment.public_hash}`}
                                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                            >
                                <div className="p-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center">
                                            <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
                                                <FaCreditCard className="w-6 h-6 text-green-600" />
                                            </div>
                                            <h2 className="text-xl font-semibold ml-3">{payment.name}</h2>
                                        </div>
                                        {payment.requires_access_code && (
                                            <FaLock className="text-gray-400" />
                                        )}
                                    </div>
                                    <p className="text-gray-600 line-clamp-2 mb-4">{payment.description}</p>
                                    <div className="flex items-center justify-between text-sm">
                                        <span className="text-green-600 font-semibold">
                                            {payment.amount ? `${payment.amount}€` : 'Import personalitzat'}
                                        </span>
                                        <span className="text-gray-500">
                                            {payment.payment_type?.name || 'Pagament únic'}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </section>
            </main>
        </PublicLayout>
    );
};

export default PublicOpenPayments; 