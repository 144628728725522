import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../resources/iconsmall.png';
import { getErrorMessage } from '../utils/errorMessages';

function Alert({ errors, message, type }) {
  const alertStyles = {
    error: 'bg-red-50 border-red-500 text-red-700',
    success: 'bg-green-50 border-green-500 text-green-700',
    warning: 'bg-yellow-50 border-yellow-500 text-yellow-700'
  };

  return (
    <div className={`border-l-4 p-3 mb-4 ${alertStyles[type]} text-sm`} role="alert">
      <h3 className="font-semibold text-base mb-1">
        {type === 'error' ? 'Hi ha hagut alguns errors:' : type === 'success' ? 'Èxit!' : 'Avís!'}
      </h3>
      {Array.isArray(errors) ? (
        <ul className="list-disc list-inside">
          {errors.map((error, index) => (
            <li key={index} className="mb-1">{error}</li>
          ))}
        </ul>
      ) : (
        <p>{message || errors}</p>
      )}
    </div>
  );
}

function RecordarContraseña() {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/password/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setAlert({ message: data.message, type: 'success' });
      } else {
        setAlert({ message: data.message || getErrorMessage('unknown_error'), type: 'error' });
      }
    } catch (error) {
      setAlert({ message: getErrorMessage('network_error'), type: 'error' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="w-full max-w-2xl p-6 bg-white shadow-lg rounded-lg">
        <div className="text-center mb-6">
          <Link to="/">
            <img src={logo} alt="Logotipo" className="w-20 mx-auto mb-4 cursor-pointer" />
          </Link>
          <h2 className="text-2xl font-bold">Recordar Contrasenya</h2>
          <p className="text-sm text-gray-500">Introdueix el teu correu electrònic per restablir la contrasenya</p>
        </div>
        {alert && (
          <Alert errors={alert.errors} message={alert.message} type={alert.type} />
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Correu electrònic</label>
            <input
              type="email"
              className="w-full mt-1 p-2 border rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#ED5B52] text-white p-3 rounded hover:bg-[#D64A41] transition duration-200"
          >
            Rebre instruccions per restablir la contrasenya
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to="/login" className="text-[#ED5B52] hover:underline">Tornar a l'inici de sessió</Link>
        </div>
      </div>
      <footer className="mt-8 text-center text-gray-500">
        <p>&copy; {new Date().getFullYear()} cobraments.com . Tots els drets reservats.</p>
        <p>
          <Link to="/politica-privacitat" className="text-[#ED5B52] hover:underline">Política de privacitat</Link> |&nbsp;
          <Link to="/termes-servei" className="text-[#ED5B52] hover:underline">Termes del servei</Link>
        </p>
      </footer>
    </div>
  );
}

export default RecordarContraseña;
