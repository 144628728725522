import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../../Header';
import Footer from '../../../Footer';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { useAuth } from '../../../../contexts/AuthContext';
import { FaArrowLeft, FaSave, FaPlus, FaTrash } from 'react-icons/fa';

const EditField = () => {
    const { formId, sectionId, fieldId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const [field, setField] = useState({
        name: '',
        label: '',
        type: 'text',
        required: false,
        visible: true,
        order: 0,
        visibility_rules: {
            enabled: false,
            field_id: null,
            operator: 'equals',
            value: null
        }
    });

    useEffect(() => {
        const loadData = async () => {
            if (!formId) return;
            
            try {
                setIsLoading(true);
                
                // Cargar las secciones y campos disponibles
                const sectionsResponse = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );

                if (!sectionsResponse.ok) {
                    throw new Error('Error al carregar les seccions');
                }

                const sections = await sectionsResponse.json();
                const availableFields = [];

                sections.forEach(section => {
                    section.fields?.forEach(f => {
                        if (f.id !== parseInt(fieldId)) {
                            availableFields.push({
                                id: f.id,
                                name: f.name,
                                label: f.label,
                                section_name: section.name,
                                section_id: section.id
                            });
                        }
                    });
                });

                setFormFields(availableFields);

                // Si estamos editando, cargar los datos del campo
                if (fieldId) {
                    const fieldResponse = await fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections/${sectionId}/fields/${fieldId}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                    );

                    if (!fieldResponse.ok) {
                        throw new Error('Error al carregar el camp');
                    }

                    const fieldData = await fieldResponse.json();
                    setField({
                        ...fieldData,
                        visibility_rules: fieldData.visibility_rules || {
                            enabled: false,
                            field_id: null,
                            operator: 'equals',
                            value: null
                        }
                    });
                }

                setIsLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
                setIsLoading(false);
            }
        };

        loadData();
    }, [formId, sectionId, fieldId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const url = fieldId 
                ? `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections/${sectionId}/fields/${fieldId}`
                : `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections/${sectionId}/fields`;

            const response = await fetch(url, {
                method: fieldId ? 'PUT' : 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(field)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al desar el camp');
            }

            navigate(`/payments/forms/${formId}`);
        } catch (error) {
            setError(error.message);
        }
    };

    const fieldTypes = [
        { value: 'text', label: 'Text' },
        { value: 'textarea', label: 'Text llarg' },
        { value: 'number', label: 'Número' },
        { value: 'select', label: 'Selector' },
        { value: 'radio', label: 'Opcions (radio)' },
        { value: 'checkbox', label: 'Casella de verificació' },
        { value: 'date', label: 'Data' },
        { value: 'email', label: 'Correu electrònic' },
        { value: 'tel', label: 'Telèfon' }
    ];

    const [newOption, setNewOption] = useState('');

    const addOption = () => {
        if (newOption.trim()) {
            setField({
                ...field,
                options: [...field.options, newOption.trim()]
            });
            setNewOption('');
        }
    };

    const removeOption = (index) => {
        setField({
            ...field,
            options: field.options.filter((_, i) => i !== index)
        });
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar el camp
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">
                                {fieldId ? 'Editar Camp' : 'Nou Camp'}
                            </h1>
                            <div className="flex items-center">
                                <button
                                    onClick={() => navigate(`/payments/forms/${formId}`)}
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                >
                                    <FaArrowLeft className="mr-2" /> Tornar
                                </button>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                {/* Informació bàsica */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Nom intern
                                        </label>
                                        <input
                                            type="text"
                                            value={field.name}
                                            onChange={(e) => setField({...field, name: e.target.value})}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Etiqueta visible
                                        </label>
                                        <input
                                            type="text"
                                            value={field.label}
                                            onChange={(e) => setField({...field, label: e.target.value})}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Tipus i configuració bàsica */}
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Tipus de camp
                                        </label>
                                        <select
                                            value={field.type}
                                            onChange={(e) => setField({...field, type: e.target.value})}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        >
                                            {fieldTypes.map(type => (
                                                <option key={type.value} value={type.value}>
                                                    {type.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Ordre
                                        </label>
                                        <input
                                            type="number"
                                            value={field.order}
                                            onChange={(e) => setField({...field, order: parseInt(e.target.value)})}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                            min="0"
                                        />
                                    </div>
                                    <div className="flex items-center space-x-6">
                                        <label className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={field.required}
                                                onChange={(e) => setField({...field, required: e.target.checked})}
                                                className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                            />
                                            <span className="text-sm text-gray-700">Obligatori</span>
                                        </label>
                                        <label className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={field.visible}
                                                onChange={(e) => setField({...field, visible: e.target.checked})}
                                                className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                            />
                                            <span className="text-sm text-gray-700">Visible</span>
                                        </label>
                                    </div>
                                </div>

                                {/* Opcions per camps select/radio */}
                                {(field.type === 'select' || field.type === 'radio') && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Opcions
                                        </label>
                                        <div className="space-y-2">
                                            {field.options.map((option, index) => (
                                                <div key={index} className="flex items-center space-x-2">
                                                    <input
                                                        type="text"
                                                        value={option}
                                                        onChange={(e) => {
                                                            const newOptions = [...field.options];
                                                            newOptions[index] = e.target.value;
                                                            setField({...field, options: newOptions});
                                                        }}
                                                        className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={() => removeOption(index)}
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            ))}
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="text"
                                                    value={newOption}
                                                    onChange={(e) => setNewOption(e.target.value)}
                                                    className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                                    placeholder="Nova opció"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={addOption}
                                                    className="bg-green-500 text-white p-2 rounded hover:bg-green-600"
                                                >
                                                    <FaPlus />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Regles de visibilitat */}
                                <div>
                                    <label className="flex items-center space-x-2 mb-4">
                                        <input
                                            type="checkbox"
                                            checked={field.visibility_rules.enabled}
                                            onChange={(e) => setField({
                                                ...field,
                                                visibility_rules: {
                                                    ...field.visibility_rules,
                                                    enabled: e.target.checked
                                                }
                                            })}
                                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                        />
                                        <span className="text-sm text-gray-700">
                                            Habilitar regles de visibilitat
                                        </span>
                                    </label>

                                    {field.visibility_rules.enabled && (
                                        <div className="mt-4 space-y-4 border-t pt-4">
                                            <div className="flex items-center gap-4">
                                                <select
                                                    value={field.visibility_rules.field_id || ''}
                                                    onChange={(e) => {
                                                        setField(prev => ({
                                                            ...prev,
                                                            visibility_rules: {
                                                                ...prev.visibility_rules,
                                                                field_id: e.target.value
                                                            }
                                                        }));
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                >
                                                    <option value="">Selecciona un camp</option>
                                                    {formFields.map(f => (
                                                        <option 
                                                            key={f.id} 
                                                            value={`${f.section_id}-${f.name}`}
                                                        >
                                                            {f.section_name} - {f.label}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={field.visibility_rules.operator || 'equals'}
                                                    onChange={(e) => {
                                                        setField({
                                                            ...field,
                                                            visibility_rules: {
                                                                ...field.visibility_rules,
                                                                operator: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                >
                                                    <option value="equals">Igual a</option>
                                                    <option value="not_equals">Diferent de</option>
                                                    <option value="greater_than">Major que</option>
                                                    <option value="less_than">Menor que</option>
                                                    <option value="contains">Conté</option>
                                                </select>

                                                <input
                                                    type="text"
                                                    value={field.visibility_rules.value || ''}
                                                    onChange={(e) => {
                                                        setField({
                                                            ...field,
                                                            visibility_rules: {
                                                                ...field.visibility_rules,
                                                                value: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                    placeholder="Valor"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Botó de guardar */}
                                <div className="flex justify-end pt-6">
                                    <button
                                        type="submit"
                                        className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaSave className="mr-2" /> Desar canvis
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default EditField;