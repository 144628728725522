import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaLink, FaExternalLinkAlt, FaLock, FaPlus } from 'react-icons/fa';
import Header from '../../Header';
import Footer from '../../Footer';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useAuth } from '../../../contexts/AuthContext';

const PaymentFormDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [form, setForm] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [responses, setResponses] = useState([]);
    const [isLoadingResponses, setIsLoadingResponses] = useState(true);
    const [responsesError, setResponsesError] = useState(null);

    useEffect(() => {
        fetchFormDetails();
        fetchFormResponses();
    }, [id]);

    const fetchFormDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els detalls del formulari');

            const data = await response.json();
            setForm(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFormResponses = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${id}/responses`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar les respostes');

            const data = await response.json();
            setResponses(Array.isArray(data) ? data : data.data || []);
        } catch (error) {
            setResponsesError(error.message);
        } finally {
            setIsLoadingResponses(false);
        }
    };

    const toggleStatus = async () => {
        try {
            setIsUpdating(true);
            const newStatus = form.status === 'active' ? 'inactive' : 'active';
            
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${id}/toggle-status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus })
            });

            if (!response.ok) throw new Error('Error al canviar l\'estat del formulari');

            setForm(prev => ({...prev, status: newStatus}));
        } catch (error) {
            setError(error.message);
        } finally {
            setIsUpdating(false);
        }
    };

    const copyFormLink = () => {
        const link = `${window.location.origin}/public-payment-forms/${form.hash}`;
        navigator.clipboard.writeText(link);
    };

    const getFrequencyLabel = (frequency) => {
        const frequencies = {
            'daily': 'Diari',
            'weekly': 'Setmanal',
            'bi-weekly': 'Quinzenal',
            'monthly': 'Mensual',
            'bi-monthly': 'Bimestral',
            'quarterly': 'Trimestral',
            'semi-annually': 'Semestral',
            'annually': 'Anual'
        };
        return frequencies[frequency] || frequency;
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar el formulari
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : form ? (
                    <>
                        <div className="flex justify-between items-center mb-6">
                            <h1 className="text-3xl font-bold text-gray-800">
                                {user?.name}
                            </h1>
                            <div className="flex gap-4">
                                <button
                                    onClick={toggleStatus}
                                    disabled={isUpdating}
                                    className={`px-4 py-2 rounded transition duration-200 flex items-center ${
                                        form.status === 'active'
                                            ? 'bg-red-500 hover:bg-red-600 text-white'
                                            : 'bg-green-500 hover:bg-green-600 text-white'
                                    }`}
                                >
                                    {isUpdating ? (
                                        <span>Actualitzant...</span>
                                    ) : (
                                        <span>
                                            {form.status === 'active' ? 'Desactivar' : 'Activar'} formulari
                                        </span>
                                    )}
                                </button>
                                <Link
                                    to={`/payments/forms/${id}/edit`}
                                    className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                >
                                    <FaEdit className="mr-2" /> Editar
                                </Link>
                                <Link
                                    to="/payments/forms"
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                >
                                    <FaArrowLeft className="mr-2" /> Tornar
                                </Link>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6">
                            {/* Informació bàsica */}
                            <div className="space-y-6">
                                <div>
                                    <h2 className="text-xl font-semibold mb-4">Informació General</h2>
                                    <dl className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <dt className="font-medium">Nom:</dt>
                                            <dd>{form.name}</dd>
                                        </div>
                                        <div>
                                            <dt className="font-medium">Nom del servei:</dt>
                                            <dd>{form.service_name}</dd>
                                        </div>
                                        <div>
                                            <dt className="font-medium">Preu base:</dt>
                                            <dd>{form.base_price} {form.currency}</dd>
                                        </div>
                                        <div>
                                            <dt className="font-medium">Estat:</dt>
                                            <dd>
                                                <span className={`px-2 py-1 rounded-full text-sm ${
                                                    form.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                    {form.status === 'active' ? 'Actiu' : 'Inactiu'}
                                                </span>
                                            </dd>
                                        </div>
                                        <div>
                                            <dt className="font-medium">Públic:</dt>
                                            <dd>
                                                <span className={`px-2 py-1 rounded-full text-sm ${
                                                    form.is_public ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                                                }`}>
                                                    {form.is_public ? 'Sí' : 'No'}
                                                </span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>

                                {/* Enllaç del formulari */}
                                <div className="p-6 border-t border-gray-200">
                                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                                        <FaLink className="mr-2 text-gray-500" />
                                        Enllaç del formulari
                                    </h2>
                                    <div className="space-y-4">
                                        <div className="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg">
                                            <input
                                                type="text"
                                                value={`${window.location.origin}/public-payment-forms/${form.hash}`}
                                                className="flex-1 bg-transparent border-none focus:ring-0"
                                                readOnly
                                            />
                                            <button
                                                onClick={copyFormLink}
                                                className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                                title="Copiar enllaç del formulari"
                                            >
                                                <FaLink className="mr-2" /> Copiar
                                            </button>
                                            <a
                                                href={`/public-payment-forms/${form.hash}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                                title="Veure formulari"
                                            >
                                                <FaExternalLinkAlt className="mr-2" /> Veure
                                            </a>
                                        </div>
                                        {Boolean(form.requires_access_code) && (
                                            <div className="text-sm text-gray-600 flex items-center">
                                                <FaLock className="mr-2" />
                                                Aquest formulari requereix codi d'accés
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Seccions i camps */}
                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-xl font-semibold">Seccions i Camps</h2>
                                        <button
                                            onClick={() => navigate(`/payments/forms/${id}/sections/new`)}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                                        >
                                            Afegir Secció
                                        </button>
                                    </div>
                                    {form.sections.map((section, index) => (
                                        <div key={section.id} className="mb-6 p-4 border rounded">
                                            <div className="flex justify-between items-center mb-2">
                                                <h3 className="text-lg font-medium">{section.name}</h3>
                                                <div className="flex gap-2">
                                                    <Link
                                                        to={`/payments/forms/${id}/sections/${section.id}/edit`}
                                                        className="text-blue-500 hover:text-blue-700"
                                                    >
                                                        <FaEdit />
                                                    </Link>
                                                    <button
                                                        onClick={() => navigate(`/payments/forms/${id}/sections/${section.id}/fields/new`)}
                                                        className="text-green-500 hover:text-green-700"
                                                    >
                                                        <FaPlus />
                                                    </button>
                                                </div>
                                            </div>
                                            {section.description && (
                                                <p className="text-gray-600 mb-4">{section.description}</p>
                                            )}
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {section.fields.map((field) => (
                                                    <div key={field.id} className="p-3 bg-gray-50 rounded flex justify-between items-start">
                                                        <div>
                                                            <p className="font-medium">{field.label}</p>
                                                            <p className="text-sm text-gray-600">Tipus: {field.type}</p>
                                                        </div>
                                                        <Link
                                                            to={`/payments/forms/${id}/sections/${section.id}/fields/${field.id}/edit`}
                                                            className="text-blue-500 hover:text-blue-700"
                                                        >
                                                            <FaEdit />
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Condicions */}
                                <div>
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-xl font-semibold">Condicions</h2>
                                        <button
                                            onClick={() => navigate(`/payments/forms/${id}/conditions/new`)}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                                        >
                                            Afegir Condició
                                        </button>
                                    </div>
                                    {form.conditions.map((condition) => (
                                        <div key={condition.id} className="mb-4 p-4 border rounded flex justify-between items-start">
                                            <div>
                                                <h3 className="font-medium">{condition.name}</h3>
                                                {condition.description && (
                                                    <p className="text-gray-600 mb-2">{condition.description}</p>
                                                )}
                                                <p className="text-sm">
                                                    Prioritat: {condition.priority}
                                                    <span className="ml-4">
                                                        {condition.is_active ? 'Activa' : 'Inactiva'}
                                                    </span>
                                                </p>
                                            </div>
                                            <Link
                                                to={`/payments/forms/${id}/conditions/${condition.id}/edit`}
                                                className="text-blue-500 hover:text-blue-700"
                                            >
                                                <FaEdit />
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                                <div className="mb-4">
                                    <h3 className="text-lg font-medium">Configuració de pagament</h3>
                                    <div className="mt-2 grid grid-cols-2 gap-4">
                                        <div>
                                            <span className="font-medium">Pasarela:</span>
                                            <span className="ml-2">{form.payment_gateway?.name || 'No configurada'}</span>
                                        </div>
                                        <div>
                                            <span className="font-medium">Tipus:</span>
                                            <span className="ml-2">{form.payment_type?.name || 'No configurat'}</span>
                                        </div>
                                        {form.payment_type?.slug === 'cobrament-recurrent' && (
                                            <div>
                                                <span className="font-medium">Freqüència:</span>
                                                <span className="ml-2">
                                                    {form.configuration?.frequency ? getFrequencyLabel(form.configuration.frequency) : 'No configurada'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Respostes del formulari */}
                        <div className="mt-8 bg-white rounded-lg shadow">
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-4">Respostes rebudes</h2>
                                {isLoadingResponses ? (
                                    <div className="flex items-center justify-center py-4">
                                        <LoadingSpinner />
                                    </div>
                                ) : responsesError ? (
                                    <div className="bg-white rounded-lg p-4">
                                        <div className="text-center">
                                            <div className="text-red-500 text-lg font-semibold mb-2">
                                                Error al carregar les respostes
                                            </div>
                                            <p className="text-gray-600">{responsesError}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Data
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Sol·licitant
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Import Final
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Estat Pagament
                                                    </th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Estat Resposta
                                                    </th>
                                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Accions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {responses && responses.length > 0 ? (
                                                    responses.map((response) => (
                                                        <tr key={response.id}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {new Date(response.created_at).toLocaleDateString('ca-ES')}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm">
                                                                    <div className="font-medium text-gray-900">
                                                                        {`${response.responses['0-Nom'] || ''} ${response.responses['0-Cognoms'] || ''}`}
                                                                    </div>
                                                                    <div className="text-gray-500">
                                                                        {response.responses['0-Email'] || ''}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {response.calculated_price ? 
                                                                    `${parseFloat(response.calculated_price).toFixed(2)} ${form?.currency}` : '-'}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <span className={`px-2 py-1 rounded-full text-sm ${
                                                                    response.payment?.status === 'completed' 
                                                                        ? 'bg-green-100 text-green-800' 
                                                                        : response.payment?.status === 'failed'
                                                                        ? 'bg-red-100 text-red-800'
                                                                        : response.payment?.status === 'cancelled'
                                                                        ? 'bg-gray-100 text-gray-800'
                                                                        : 'bg-yellow-100 text-yellow-800'
                                                                }`}>
                                                                    {response.payment?.status === 'completed' ? 'Pagat' 
                                                                        : response.payment?.status === 'failed' ? 'Fallat'
                                                                        : response.payment?.status === 'cancelled' ? 'Cancel·lat'
                                                                        : 'Pendent'}
                                                                </span>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <span className={`px-2 py-1 rounded-full text-sm ${
                                                                    response.status === 'validated' 
                                                                        ? 'bg-green-100 text-green-800' 
                                                                        : response.status === 'in_correction'
                                                                        ? 'bg-orange-100 text-orange-800'
                                                                        : 'bg-yellow-100 text-yellow-800'
                                                                }`}>
                                                                    {response.status === 'validated' ? 'Validada' 
                                                                        : response.status === 'in_correction' ? 'En correcció'
                                                                        : 'Pendent'}
                                                                </span>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                                                                <button
                                                                    onClick={() => navigate(`/payments/forms/${form.id}/responses/${response.id}`)}
                                                                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#ED5B52] hover:bg-[#D64A41] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ED5B52]"
                                                                >
                                                                    <FaEdit className="mr-2" />
                                                                    Veure
                                                                </button>
                                                                <button
                                                                    onClick={() => navigate(`/payments/${response.payment_id}`)}
                                                                    className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ED5B52]"
                                                                >
                                                                    <FaExternalLinkAlt className="mr-2" />
                                                                    Pagament
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                                            No hi ha respostes per aquest formulari
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-gray-500 text-xl font-semibold">
                                No s'ha trobat el formulari
                            </div>
                        </div>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default PaymentFormDetails; 