import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PublicLayout from './layouts/PublicLayout';
import { FaWpforms, FaExternalLinkAlt, FaLock, FaClock, FaCalendarAlt } from 'react-icons/fa';

const PublicPaymentForms = () => {
    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchForms = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/paymentforms`);
                if (!response.ok) {
                    throw new Error('No s\'han pogut carregar els formularis');
                }
                const data = await response.json();
                setForms(data);
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchForms();
    }, []);

    const layoutProps = {
        hasShops: true,
        hasPayments: true,
        hasForms: true,
        error: error
    };

    if (isLoading) {
        return (
            <PublicLayout {...layoutProps}>
                <div className="flex items-center justify-center h-[calc(100vh-64px)]">
                    <div className="text-center py-8">Carregant...</div>
                </div>
            </PublicLayout>
        );
    }

    return (
        <PublicLayout {...layoutProps}>
            <main className="max-w-7xl mx-auto px-4 py-8">
                <section className="relative bg-gray-200 text-gray-800 py-20 rounded-lg overflow-hidden mb-12">
                    <div className="relative z-10 max-w-3xl mx-auto text-center px-4">
                        <h1 className="text-4xl font-bold mb-6">Formularis de Pagament</h1>
                        <p className="text-xl mb-8">Accedeix als nostres formularis de pagament disponibles</p>
                    </div>
                    <div className="absolute inset-0 bg-gray-300 opacity-50"></div>
                </section>

                <section className="mb-12">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {forms.map(form => (
                            <Link 
                                key={form.id} 
                                to={`/public-payment-forms/${form.hash}`}
                                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                            >
                                <div className="p-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center">
                                            <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                                                <FaWpforms className="w-6 h-6 text-blue-600" />
                                            </div>
                                            <h2 className="text-xl font-semibold ml-3">{form.name}</h2>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            {form.requires_access_code && (
                                                <FaLock className="text-gray-400" title="Requereix codi d'accés" />
                                            )}
                                            {form.isRecurring && (
                                                <FaClock className="text-gray-400" title="Pagament recurrent" />
                                            )}
                                        </div>
                                    </div>
                                    
                                    <p className="text-gray-600 line-clamp-2 mb-4">{form.description}</p>
                                    
                                    <div className="flex items-center justify-between text-sm">
                                        <div className="flex items-center text-gray-500">
                                            <FaCalendarAlt className="mr-2" />
                                            <span>
                                                {new Date(form.configuration?.start_date).toLocaleDateString()}
                                            </span>
                                        </div>
                                        {form.base_price && (
                                            <span className="text-blue-600 font-semibold">
                                                Des de {form.base_price}€
                                            </span>
                                        )}
                                    </div>

                                    {form.service_name && (
                                        <div className="mt-4 pt-4 border-t border-gray-100">
                                            <p className="text-sm text-gray-500">
                                                {form.service_name}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </Link>
                        ))}
                    </div>
                </section>
            </main>
        </PublicLayout>
    );
};

export default PublicPaymentForms; 