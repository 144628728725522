import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaSearch, FaExternalLinkAlt } from 'react-icons/fa';
import Header from '../../../Header';
import Footer from '../../../Footer';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { useAuth } from '../../../../contexts/AuthContext';

const PaymentFormResponses = () => {
    const { formId } = useParams();
    const { user } = useAuth();
    const [responses, setResponses] = useState([]);
    const [form, setForm] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('');

    useEffect(() => {
        Promise.all([
            fetchFormDetails(),
            fetchResponses()
        ]).finally(() => {
            setIsLoading(false);
        });
    }, [formId]);

    const fetchFormDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al carregar els detalls del formulari');
            const data = await response.json();
            setForm(data);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchResponses = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/responses`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al carregar les respostes');
            const data = await response.json();
            setResponses(Array.isArray(data) ? data : data.data || []);
        } catch (error) {
            setError(error.message);
        }
    };

    const getStatusBadgeClass = (status) => {
        const statusClasses = {
            'validated': 'bg-green-100 text-green-800',
            'pending': 'bg-yellow-100 text-yellow-800',
            'in_correction': 'bg-orange-100 text-orange-800'
        };
        return statusClasses[status] || 'bg-gray-100 text-gray-800';
    };

    const getPaymentStatusBadgeClass = (status) => {
        const statusClasses = {
            'completed': 'bg-green-100 text-green-800',
            'pending': 'bg-yellow-100 text-yellow-800',
            'failed': 'bg-red-100 text-red-800',
            'cancelled': 'bg-gray-100 text-gray-800'
        };
        return statusClasses[status] || 'bg-gray-100 text-gray-800';
    };

    const getStatusLabel = (status) => {
        const labels = {
            'validated': 'Validada',
            'pending': 'Pendent',
            'in_correction': 'En correcció'
        };
        return labels[status] || status;
    };

    const getPaymentStatusLabel = (status) => {
        const labels = {
            'completed': 'Pagat',
            'pending': 'Pendent',
            'failed': 'Fallat',
            'cancelled': 'Cancel·lat'
        };
        return labels[status] || status;
    };

    const filteredResponses = responses.filter(response => {
        const searchFields = [
            response.responses['0-Nom'],
            response.responses['0-Cognoms'],
            response.responses['0-Email']
        ].join(' ').toLowerCase();

        const matchesSearch = searchFields.includes(searchTerm.toLowerCase());
        const matchesDate = !dateFilter || response.created_at.includes(dateFilter);
        const matchesStatus = !statusFilter || response.status === statusFilter;
        const matchesPaymentStatus = !paymentStatusFilter || response.payment?.status === paymentStatusFilter;

        return matchesSearch && matchesDate && matchesStatus && matchesPaymentStatus;
    });

    if (isLoading) return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="flex-grow container mx-auto px-4 py-8">
                <LoadingSpinner />
            </main>
            <Footer />
        </div>
    );

    if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
    if (!form) return <div className="text-center py-4">No s'ha trobat el formulari</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8">
                {/* Capçalera */}
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">
                        Respostes de {form.name}
                    </h1>
                    <Link
                        to={`/payments/forms`}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {/* Filtres */}
                <div className="bg-white rounded-lg shadow p-6 mb-6">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cerca
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Cerca per nom, email..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-full p-2 border rounded-md pl-10"
                                />
                                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Data
                            </label>
                            <input
                                type="date"
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Estat Resposta
                            </label>
                            <select
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            >
                                <option value="">Tots els estats</option>
                                <option value="validated">Validada</option>
                                <option value="pending">Pendent</option>
                                <option value="in_correction">En correcció</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Estat Pagament
                            </label>
                            <select
                                value={paymentStatusFilter}
                                onChange={(e) => setPaymentStatusFilter(e.target.value)}
                                className="w-full p-2 border rounded-md"
                            >
                                <option value="">Tots els estats</option>
                                <option value="completed">Pagat</option>
                                <option value="pending">Pendent</option>
                                <option value="failed">Fallat</option>
                                <option value="cancelled">Cancel·lat</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Taula de respostes */}
                <div className="bg-white rounded-lg shadow overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Data
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Sol·licitant
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Import Final
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Estat Pagament
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Estat Resposta
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Accions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredResponses.length > 0 ? (
                                filteredResponses.map((response) => (
                                    <tr key={response.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {new Date(response.created_at).toLocaleDateString('ca-ES')}
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm font-medium text-gray-900">
                                                {`${response.responses['0-Nom'] || ''} ${response.responses['0-Cognoms'] || ''}`}
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {response.responses['0-Email'] || ''}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {response.calculated_price} {form.currency}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${getPaymentStatusBadgeClass(response.payment?.status)}`}>
                                                {getPaymentStatusLabel(response.payment?.status)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusBadgeClass(response.status)}`}>
                                                {getStatusLabel(response.status)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                                            <Link
                                                to={`/payments/forms/${formId}/responses/${response.id}`}
                                                className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-[#ED5B52] hover:bg-[#D64A41] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ED5B52]"
                                            >
                                                <FaEye className="mr-2" />
                                                Veure
                                            </Link>
                                            <Link
                                                to={`/payments/${response.payment_id}`}
                                                className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ED5B52]"
                                            >
                                                <FaExternalLinkAlt className="mr-2" />
                                                Pagament
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                        No s'han trobat respostes
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default PaymentFormResponses; 