import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSearch, FaCheck, FaTimes } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';

const AssignRoles = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [savingChanges, setSavingChanges] = useState(false);

    useEffect(() => {
        Promise.all([fetchUsers(), fetchRoles()]);
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al obtenir els usuaris');
            const data = await response.json();
            setUsers(data);
        } catch (err) {
            setError(err.message);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/roles`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al obtenir els rols');
            const data = await response.json();
            setRoles(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserRoles = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/roles`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al obtenir els rols de l\'usuari');
            const data = await response.json();
            setUserRoles(data);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleUserSelect = async (user) => {
        setSelectedUser(user);
        await fetchUserRoles(user.id);
    };

    const handleRoleToggle = async (roleId) => {
        if (!selectedUser) return;
        
        setSavingChanges(true);
        try {
            const hasRole = userRoles.some(role => role.id === roleId);
            const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/users/${selectedUser.id}/roles/${roleId}`;
            const method = hasRole ? 'DELETE' : 'POST';
            
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) throw new Error('Error al actualitzar els rols');

            // Actualizar la lista de roles del usuario
            await fetchUserRoles(selectedUser.id);
            setSuccessMessage('Rols actualitzats correctament');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            setError(err.message);
        } finally {
            setSavingChanges(false);
        }
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-12 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Assignació de Rols</h1>
                    <Link
                        to="/users"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {successMessage && (
                    <div className="bg-green-50 text-green-500 p-4 rounded-lg mb-6">
                        {successMessage}
                    </div>
                )}

                {error && (
                    <div className="bg-red-50 text-red-500 p-4 rounded-lg mb-6">
                        {error}
                    </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Lista de usuarios */}
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <div className="mb-4">
                            <div className="relative">
                                <FaSearch className="absolute left-3 top-3 text-gray-400" />
                                <input
                                    type="text"
                                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md"
                                    placeholder="Cercar usuaris..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="overflow-y-auto max-h-[500px]">
                            {loading ? (
                                <div className="text-center py-4">Carregant usuaris...</div>
                            ) : (
                                <ul className="divide-y divide-gray-200">
                                    {filteredUsers.map(user => (
                                        <li
                                            key={user.id}
                                            className={`py-3 px-4 cursor-pointer hover:bg-gray-50 ${
                                                selectedUser?.id === user.id ? 'bg-green-50' : ''
                                            }`}
                                            onClick={() => handleUserSelect(user)}
                                        >
                                            <div className="font-medium">{user.name}</div>
                                            <div className="text-sm text-gray-500">{user.email}</div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    {/* Asignación de roles */}
                    <div className="bg-white rounded-lg shadow-md p-6">
                        {selectedUser ? (
                            <>
                                <h3 className="text-lg font-medium mb-4">
                                    Rols per a {selectedUser.name}
                                </h3>
                                <div className="space-y-3">
                                    {roles.map(role => (
                                        <div
                                            key={role.id}
                                            className="flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50"
                                        >
                                            <div>
                                                <div className="font-medium">{role.name}</div>
                                                <div className="text-sm text-gray-500">{role.description}</div>
                                            </div>
                                            <button
                                                onClick={() => handleRoleToggle(role.id)}
                                                disabled={savingChanges}
                                                className={`p-2 rounded-full ${
                                                    userRoles.some(r => r.id === role.id)
                                                        ? 'bg-green-100 text-green-600 hover:bg-green-200'
                                                        : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                                                }`}
                                            >
                                                {userRoles.some(r => r.id === role.id) ? (
                                                    <FaCheck className="w-5 h-5" />
                                                ) : (
                                                    <FaTimes className="w-5 h-5" />
                                                )}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-500 py-8">
                                Selecciona un usuari per gestionar els seus rols
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default AssignRoles; 