import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaSave, FaTimes, FaEye, FaCheckCircle, FaTimesCircle, FaComment } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const OnlineShopOrderDetails = () => {
    const { shopId, orderId } = useParams();
    const { user } = useAuth();
    const [order, setOrder] = useState(null);
    const [shop, setShop] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditingStatus, setIsEditingStatus] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [statusNote, setStatusNote] = useState('');

    useEffect(() => {
        fetchShopDetails();
        fetchOrderDetails();
    }, [shopId, orderId]);

    const fetchShopDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${shopId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setShop(data);
            }
        } catch (error) {
            setError('Error al carregar els detalls de la botiga');
        }
    };

    const fetchOrderDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${shopId}/orders/${orderId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                
                // Parseamos las direcciones
                if (typeof data.shipping_address === 'string') {
                    const addressParts = data.shipping_address.split(', ');
                    data.shipping_address = {
                        address: addressParts[0], // address_line_1 + address_line_2
                        city: addressParts[1],
                        state: addressParts[2],
                        postal_code: addressParts[3],
                        country: addressParts[4],
                        name: data.customer_name,
                        email: data.customer_email,
                        phone: data.customer_phone
                    };
                }

                if (typeof data.billing_address === 'string') {
                    const addressParts = data.billing_address.split(', ');
                    data.billing_address = {
                        address: addressParts[0], // address_line_1 + address_line_2
                        city: addressParts[1],
                        state: addressParts[2],
                        postal_code: addressParts[3],
                        country: addressParts[4],
                        name: data.customer_name,
                        email: data.customer_email,
                        phone: data.customer_phone,
                        tax_id: data.customer_tax_number
                    };
                }

                setOrder(data);
                setNewStatus(data.status);
            } else {
                throw new Error('Error al carregar els detalls de la comanda');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStatusUpdate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${shopId}/orders/${orderId}/status`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: newStatus,
                    notes: ''
                }),
            });

            if (!response.ok) {
                throw new Error('Error al actualitzar l\'estat de la comanda');
            }

            setIsEditingStatus(false);
            await fetchOrderDetails();
        } catch (error) {
            setError(error.message);
        }
    };

    const getStatusBadgeClass = (status) => {
        const statusClasses = {
            'pending': 'bg-yellow-100 text-yellow-800',
            'processing': 'bg-blue-100 text-blue-800',
            'completed': 'bg-green-100 text-green-800',
            'cancelled': 'bg-red-100 text-red-800'
        };
        return statusClasses[status] || 'bg-gray-100 text-gray-800';
    };

    const getStatusButtonClass = (status) => {
        switch (status) {
            case 'pending':
                return 'bg-yellow-500 hover:bg-yellow-600';
            case 'processing':
                return 'bg-blue-500 hover:bg-blue-600';
            case 'completed':
                return 'bg-green-500 hover:bg-green-600';
            case 'cancelled':
                return 'bg-red-500 hover:bg-red-600';
            default:
                return 'bg-gray-500 hover:bg-gray-600';
        }
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            <Header />
            <main className="flex-grow container mx-auto px-4 py-8">
                {isLoading ? (
                        <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar la comanda
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : !order || !shop ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-gray-500 text-xl font-semibold">
                                No s'ha trobat la comanda
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {/* Cabecera con botones */}
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                                <div className="flex items-center">
                                    <Link
                                        to={`/online-shops/${shopId}/orders`}
                                        className="flex items-center text-gray-600 hover:text-gray-900"
                                    >
                                        <FaArrowLeft className="mr-2" />
                                        Tornar
                                    </Link>
                                    <span className="mx-4 text-gray-300">|</span>
                                    <span className="text-xl font-semibold">Comanda #{order?.reference}</span>
                                </div>
                            </div>
                            
                            {/* Estado actual */}
                            <div className="mt-4 flex items-center">
                                <span className="text-gray-600 mr-2">Estat actual:</span>
                                <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusBadgeClass(order.status)}`}>
                                    {order.status}
                                </span>
                            </div>
                        </div>

                        {/* Detalls de la comanda */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                            {/* Informació general */}
                            <div className="bg-white rounded-lg shadow p-6">
                                <h3 className="text-lg font-semibold mb-4">Informació general</h3>
                                <div className="space-y-3">
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Referència:</span>
                                        <span className="font-medium">{order.reference}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Data:</span>
                                        <span className="font-medium">
                                            {new Date(order.created_at).toLocaleDateString('ca-ES')}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Total:</span>
                                        <span className="font-medium">
                                            {new Intl.NumberFormat('ca-ES', {
                                                style: 'currency',
                                                currency: shop.currency
                                            }).format(order.total)}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Estat:</span>
                                        {!isEditingStatus ? (
                                            <div className="flex items-center space-x-2">
                                                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(order.status)}`}>
                                                    {order.status}
                                                </span>
                                                <button
                                                    onClick={() => setIsEditingStatus(true)}
                                                    className="text-blue-500 hover:text-blue-700"
                                                >
                                                    <FaEdit />
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="flex items-center space-x-2">
                                                <select
                                                    value={newStatus}
                                                    onChange={(e) => setNewStatus(e.target.value)}
                                                    className="border rounded p-1"
                                                >
                                                    <option value="pending">Pendent</option>
                                                    <option value="processing">En procés</option>
                                                    <option value="completed">Completada</option>
                                                    <option value="cancelled">Cancel·lada</option>
                                                </select>
                                                <button
                                                    onClick={handleStatusUpdate}
                                                    className="text-green-500 hover:text-green-700"
                                                >
                                                    <FaSave />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setIsEditingStatus(false);
                                                        setNewStatus(order.status);
                                                    }}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTimes />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Informació del client */}
                            <div className="bg-white rounded-lg shadow p-6">
                                <h3 className="text-lg font-semibold mb-4">Informació del client</h3>
                                <div className="space-y-3">
                                    <div>
                                        <span className="text-gray-600">Nom:</span>
                                        <p className="font-medium">{order.customer.name}</p>
                                    </div>
                                    <div>
                                        <span className="text-gray-600">Email:</span>
                                        <p className="font-medium">{order.customer.email}</p>
                                    </div>
                                    <div>
                                        <span className="text-gray-600">Telèfon:</span>
                                        <p className="font-medium">{order.customer.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Direcciones */}
                        {order.shipping_address && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                                {/* Dirección de entrega */}
                                <div className="bg-white rounded-lg shadow p-6">
                                    <h3 className="text-lg font-semibold mb-4">Adreça d'entrega</h3>
                                    <div className="space-y-3">
                                        {order.shipping_address.name && (
                                            <div>
                                                <span className="text-gray-600">Nom:</span>
                                                <p className="font-medium">{order.shipping_address.name}</p>
                                            </div>
                                        )}
                                        {order.shipping_address.address && (
                                            <div>
                                                <span className="text-gray-600">Adreça:</span>
                                                <p className="font-medium">{order.shipping_address.address}</p>
                                            </div>
                                        )}
                                        {order.shipping_address.city && (
                                            <div>
                                                <span className="text-gray-600">Ciutat:</span>
                                                <p className="font-medium">{order.shipping_address.city}</p>
                                            </div>
                                        )}
                                        {order.shipping_address.postal_code && (
                                            <div>
                                                <span className="text-gray-600">Codi Postal:</span>
                                                <p className="font-medium">{order.shipping_address.postal_code}</p>
                                            </div>
                                        )}
                                        {order.shipping_address.country && (
                                            <div>
                                                <span className="text-gray-600">País:</span>
                                                <p className="font-medium">{order.shipping_address.country}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Dirección de facturación */}
                                {order.billing_address && (
                                    <div className="bg-white rounded-lg shadow p-6">
                                        <h3 className="text-lg font-semibold mb-4">Adreça de facturació</h3>
                                        <div className="space-y-3">
                                            {order.billing_address.name && (
                                                <div>
                                                    <span className="text-gray-600">Nom:</span>
                                                    <p className="font-medium">{order.billing_address.name}</p>
                                                </div>
                                            )}
                                            {order.billing_address.address && (
                                                <div>
                                                    <span className="text-gray-600">Adreça:</span>
                                                    <p className="font-medium">{order.billing_address.address}</p>
                                                </div>
                                            )}
                                            {order.billing_address.city && (
                                                <div>
                                                    <span className="text-gray-600">Ciutat:</span>
                                                    <p className="font-medium">{order.billing_address.city}</p>
                                                </div>
                                            )}
                                            {order.billing_address.postal_code && (
                                                <div>
                                                    <span className="text-gray-600">Codi Postal:</span>
                                                    <p className="font-medium">{order.billing_address.postal_code}</p>
                                                </div>
                                            )}
                                            {order.billing_address.country && (
                                                <div>
                                                    <span className="text-gray-600">País:</span>
                                                    <p className="font-medium">{order.billing_address.country}</p>
                                                </div>
                                            )}
                                            {order.billing_address.tax_id && (
                                                <div>
                                                    <span className="text-gray-600">NIF/CIF:</span>
                                                    <p className="font-medium">{order.billing_address.tax_id}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Información del pago */}
                        {order.payment && (
                            <div className="bg-white rounded-lg shadow p-6 mb-6">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-lg font-semibold">Informació del pagament</h3>
                                    <Link
                                        to={`/payments/${order.payment.id}`}
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 flex items-center"
                                    >
                                        <FaEye className="mr-2" /> Veure detalls
                                    </Link>
                                </div>
                                <div className="space-y-4">
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Total pagat:</span>
                                        <span className="font-medium">
                                            {new Intl.NumberFormat('ca-ES', {
                                                style: 'currency',
                                                currency: shop.currency
                                            }).format(order.payment.amount)}
                                        </span>
                                    </div>
                                    {order.payment.payment_type && (
                                        <div className="flex justify-between items-center">
                                            <span className="text-gray-600">Tipus de pagament:</span>
                                            <span className="font-medium">{order.payment.payment_type.name}</span>
                                        </div>
                                    )}
                                    {order.payment.payment_gateway && (
                                        <div className="flex justify-between items-center">
                                            <span className="text-gray-600">Passarel·la:</span>
                                            <span className="font-medium">{order.payment.payment_gateway.name}</span>
                                        </div>
                                    )}
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Estat del pagament:</span>
                                        <span className={`px-2 py-1 text-xs font-semibold rounded-full ${
                                            order.payment.status === 'completed' ? 'bg-green-100 text-green-800' :
                                            order.payment.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                                            order.payment.status === 'failed' ? 'bg-red-100 text-red-800' :
                                            'bg-gray-100 text-gray-800'
                                        }`}>
                                            {order.payment.status}
                                        </span>
                                    </div>
                                    {order.payment.transaction_id && (
                                        <div className="flex justify-between items-center">
                                            <span className="text-gray-600">ID Transacció:</span>
                                            <span className="font-medium">{order.payment.transaction_id}</span>
                                        </div>
                                    )}
                                    {order.payment.status === 'failed' && order.payment.error_message && (
                                        <div className="mt-2 p-3 bg-red-50 text-red-700 rounded">
                                            <p className="font-medium">Error en el pagament:</p>
                                            <p>{order.payment.error_message}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Notas del cliente */}
                        {order.notes && (
                            <div className="bg-white rounded-lg shadow p-6 mb-6">
                                <h3 className="text-lg font-semibold mb-4 flex items-center">
                                    <FaComment className="mr-2 text-gray-500" />
                                    Notes del client
                                </h3>
                                <div className="bg-gray-50 p-4 rounded border border-gray-200">
                                    <p className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                        {order.notes}
                                    </p>
                                </div>
                            </div>
                        )}

                        {/* Productes */}
                        <div className="bg-white rounded-lg shadow overflow-hidden mb-6">
                            <h3 className="text-lg font-semibold p-6 border-b">Productes</h3>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Producte
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Quantitat
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Preu unitari
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {order.items.map((item) => (
                                        <tr key={item.id}>
                                            <td className="px-6 py-4">
                                                <div className="text-sm font-medium text-gray-900">
                                                    {item.product.name}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {item.quantity}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {new Intl.NumberFormat('ca-ES', {
                                                    style: 'currency',
                                                    currency: shop.currency
                                                }).format(item.price)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {new Intl.NumberFormat('ca-ES', {
                                                    style: 'currency',
                                                    currency: shop.currency
                                                }).format(item.price * item.quantity)}
                                            </td>
                                        </tr>
                                    ))}
                                    {/* Fila de totales */}
                                    <tr className="bg-gray-50 font-semibold">
                                        <td colSpan="3" className="px-6 py-4 text-right text-gray-900">
                                            Total Productes:
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-gray-900">
                                            {new Intl.NumberFormat('ca-ES', {
                                                style: 'currency',
                                                currency: shop.currency
                                            }).format(order.items.reduce((total, item) => total + (item.price * item.quantity), 0))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* Historial d'estats */}
                        <div className="bg-white rounded-lg shadow p-6">
                            <h3 className="text-lg font-semibold mb-4">Historial d'estats</h3>
                            {order.status_history && order.status_history.length > 0 ? (
                                <div className="space-y-4">
                                    {order.status_history.map((history, index) => (
                                        <div key={index} className="border-l-4 border-gray-200 pl-4">
                                            <div className="flex justify-between items-start">
                                                <div>
                                                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusBadgeClass(history.status)}`}>
                                                        {history.status}
                                                    </span>
                                                    {history.notes && (
                                                        <p className="text-gray-600 mt-1">{history.notes}</p>
                                                    )}
                                                </div>
                                                <span className="text-sm text-gray-500">
                                                    {new Date(history.created_at).toLocaleString('ca-ES')}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-gray-500">No hi ha historial d'estats</p>
                            )}
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default OnlineShopOrderDetails; 