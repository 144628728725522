import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSave, FaPalette, FaFont, FaImage, FaUser, FaShoppingCart, FaCalendarAlt, FaCreditCard } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const ColorPicker = ({ label, value, onChange }) => (
    <div>
        <label className="block text-gray-700 mb-2">{label}</label>
        <div className="flex items-center space-x-2">
            <input
                type="color"
                value={value}
                onChange={onChange}
                className="h-10 w-20"
            />
            <input
                type="text"
                value={value}
                onChange={(e) => onChange({ target: { value: e.target.value } })}
                className="flex-1 p-2 border rounded"
            />
        </div>
    </div>
);

const PreviewSection = ({ formData }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Preview</h2>
        <div 
            className="p-6 rounded-lg"
            style={{
                backgroundColor: formData.background_color,
                color: formData.text_color,
                fontFamily: `'${formData.body_font}', sans-serif`
            }}
        >
            {/* Capçalera amb Logo */}
            <div className="flex justify-between items-center mb-8">
                <div className="flex items-center">
                    {formData.logo_path ? (
                        <img 
                            src={`${process.env.REACT_APP_BACKEND_URL}/storage/${formData.logo_path}`}
                            alt="Logo" 
                            style={{ 
                                width: formData.logo_width,
                                maxHeight: '50px',
                                objectFit: 'contain'
                            }}
                        />
                    ) : (
                        <div 
                            className="text-2xl font-bold"
                            style={{
                                fontFamily: `'${formData.heading_font}', sans-serif`,
                                color: formData.heading_color
                            }}
                        >
                            Logo
                        </div>
                    )}
                </div>
                <div className="flex items-center space-x-4">
                    <button 
                        className="p-2 rounded-full hover:bg-gray-100"
                        style={{ color: formData.secondary_color }}
                    >
                        <FaUser />
                    </button>
                    <button 
                        className="p-2 rounded-full hover:bg-gray-100"
                        style={{ color: formData.secondary_color }}
                    >
                        <FaShoppingCart />
                    </button>
                </div>
            </div>

            {/* Títol i Descripció */}
            <div className="text-center mb-8">
                <h1 
                    style={{
                        color: formData.heading_color,
                        fontFamily: `'${formData.heading_font}', sans-serif`
                    }}
                    className="text-3xl font-bold mb-2"
                >
                    Perfil prova
                </h1>
                <p style={{ color: formData.text_color }}>
                    Aquest és el meu perfil públic de cobraments.com
                </p>
            </div>

            {/* Secció de Pagaments */}
            <div className="mb-6">
                <h2 
                    style={{
                        color: formData.heading_color,
                        fontFamily: `'${formData.heading_font}', sans-serif`
                    }}
                    className="text-xl font-semibold mb-4"
                >
                    Pagaments
                </h2>
                <p style={{ color: formData.text_color }} className="mb-4">
                    Realitza els teus pagaments de forma segura i ràpida.
                </p>

                {/* Targetes de Pagament */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Pagament Recurrent */}
                    <div 
                        className="p-6 rounded-lg"
                        style={{
                            backgroundColor: formData.secondary_background,
                            borderColor: formData.secondary_color,
                            borderWidth: '1px'
                        }}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <div style={{ color: formData.accent_color }}>
                                <FaCalendarAlt size={24} />
                            </div>
                            <span 
                                className="font-semibold"
                                style={{ color: formData.heading_color }}
                            >
                                35.00€
                            </span>
                        </div>
                        <h3 
                            style={{
                                color: formData.heading_color,
                                fontFamily: `'${formData.heading_font}', sans-serif`
                            }}
                            className="font-semibold mb-2"
                        >
                            Pagament Recurrent
                        </h3>
                        <p style={{ color: formData.text_color }} className="text-sm mb-4">
                            Descripció
                        </p>
                        <button
                            style={{
                                backgroundColor: formData.primary_color,
                                color: '#ffffff',
                                borderColor: formData.secondary_color,
                                borderWidth: '1px'
                            }}
                            className="w-full py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                        >
                            Realitzar<br/>pagament
                        </button>
                    </div>

                    {/* Pagament Únic */}
                    <div 
                        className="p-6 rounded-lg"
                        style={{
                            backgroundColor: formData.secondary_background,
                            borderColor: formData.secondary_color,
                            borderWidth: '1px'
                        }}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <div style={{ color: formData.accent_color }}>
                                <FaCreditCard size={24} />
                            </div>
                            <span 
                                className="font-semibold"
                                style={{ color: formData.heading_color }}
                            >
                                22.50€
                            </span>
                        </div>
                        <h3 
                            style={{
                                color: formData.heading_color,
                                fontFamily: `'${formData.heading_font}', sans-serif`
                            }}
                            className="font-semibold mb-2"
                        >
                            Pagament<br/>Únic
                        </h3>
                        <p style={{ color: formData.text_color }} className="text-sm mb-4">
                            Descripció
                        </p>
                        <button
                            style={{
                                backgroundColor: formData.primary_color,
                                color: '#ffffff',
                                borderColor: formData.secondary_color,
                                borderWidth: '1px'
                            }}
                            className="w-full py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
                        >
                            Realitzar pagament
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api`;

const StyleSettings = () => {
    const { user, loading: authLoading } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);
    const [profileExists, setProfileExists] = useState(false);

    const [formData, setFormData] = useState({
        primary_color: '#3B82F6',
        secondary_color: '#1E40AF',
        accent_color: '#EF4444',
        text_color: '#111827',
        heading_color: '#1F2937',
        background_color: '#FFFFFF',
        secondary_background: '#F3F4F6',
        heading_font: 'Inter',
        body_font: 'Inter',
        logo_width: '150px',
        logo_path: null,
        favicon_path: null
    });

    // Añadir este estado para las previsualizaciones
    const [previews, setPreviews] = useState({
        logo: null,
        favicon: null
    });

    // Primer comprovem si existeix el perfil
    useEffect(() => {
        const checkProfile = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/profile`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accept': 'application/json'
                    }
                });
                
                if (response.ok) {
                    const profileData = await response.json();
                    setProfileExists(true);
                    // Si existeix el perfil, carreguem els seus estils
                    fetchStyles();
                } else {
                    setError("Cal crear primer un perfil abans de personalitzar els estils");
                    setIsLoading(false);
                }
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };

        if (!authLoading) {
            checkProfile();
        }
    }, [authLoading]);

    const fetchStyles = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/profile-styles`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                }
            });
            
            if (!response.ok) throw new Error(`Error ${response.status}`);
            
            const data = await response.json();
            setFormData(data.style);
        } catch (error) {
            setError(`Error al carregar els estils: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        
        if (type === 'file') {
            if (files && files[0]) {
                // Crear URL para previsualización
                const previewUrl = URL.createObjectURL(files[0]);
                setPreviews(prev => ({
                    ...prev,
                    [name]: previewUrl
                }));

                setFormData(prev => ({
                    ...prev,
                    [name === 'logo' ? 'logo_path' : 'favicon_path']: files[0]
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError('');
        setSuccessMessage('');

        try {
            // Crear FormData para manejar archivos
            const formDataToSend = new FormData();

            // Añadir todos los campos que no son archivos
            formDataToSend.append('primary_color', formData.primary_color);
            formDataToSend.append('secondary_color', formData.secondary_color);
            formDataToSend.append('accent_color', formData.accent_color);
            formDataToSend.append('text_color', formData.text_color);
            formDataToSend.append('heading_color', formData.heading_color);
            formDataToSend.append('background_color', formData.background_color);
            formDataToSend.append('secondary_background', formData.secondary_background);
            formDataToSend.append('heading_font', formData.heading_font);
            formDataToSend.append('body_font', formData.body_font);
            formDataToSend.append('logo_width', formData.logo_width);

            // Manejar logo y favicon
            if (formData.logo_path instanceof File) {
                formDataToSend.append('logo', formData.logo_path);
            } else if (typeof formData.logo_path === 'string') {
                formDataToSend.append('logo_path', formData.logo_path);
            }

            if (formData.favicon_path instanceof File) {
                formDataToSend.append('favicon', formData.favicon_path);
            } else if (typeof formData.favicon_path === 'string') {
                formDataToSend.append('favicon_path', formData.favicon_path);
            }

            const response = await fetch(`${API_BASE_URL}/profile-styles`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    // No incluir Content-Type, FormData lo establece automáticamente
                },
                body: formDataToSend
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al guardar els estils');
            }

            const data = await response.json();
            setSuccessMessage(data.message);
            await fetchStyles();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        // Afegim les fonts necessàries
        const fonts = ['Inter', 'Roboto', 'Open Sans', 'Montserrat'];
        fonts.forEach(font => {
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css2?family=${font.replace(' ', '+')}:wght@400;600;700&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
        });
    }, []);

    // Limpiar las URLs de previsualización cuando el componente se desmonte
    useEffect(() => {
        return () => {
            if (previews.logo) URL.revokeObjectURL(previews.logo);
            if (previews.favicon) URL.revokeObjectURL(previews.favicon);
        };
    }, [previews]);

    if (authLoading || isLoading) {
        return <LoadingSpinner message="Carregant estils..." />;
    }

    if (!profileExists) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="container mx-auto px-4 py-12 flex-grow">
                    <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded mb-4">
                        Cal crear primer un perfil abans de personalitzar els estils.
                        <Link to="/configuracio/perfil" className="ml-2 underline">
                            Crear perfil
                        </Link>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-12 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Personalització d'Estils</h1>
                    <div className="flex space-x-4">
                        <button
                            onClick={() => setPreviewVisible(!previewVisible)}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
                        >
                            {previewVisible ? 'Ocultar Preview' : 'Mostrar Preview'}
                        </button>
                        <Link
                            to="/configuracio"
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                        >
                            <FaArrowLeft className="mr-2" /> Tornar
                        </Link>
                    </div>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                        {successMessage}
                    </div>
                )}

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
                        {/* Colors */}
                        <div className="mb-8">
                            <h2 className="text-xl font-semibold mb-4 flex items-center">
                                <FaPalette className="mr-2" /> Colors
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <ColorPicker
                                    label="Color Principal"
                                    value={formData.primary_color}
                                    onChange={(e) => handleChange({ target: { name: 'primary_color', value: e.target.value } })}
                                />
                                <ColorPicker
                                    label="Color Secundari"
                                    value={formData.secondary_color}
                                    onChange={(e) => handleChange({ target: { name: 'secondary_color', value: e.target.value } })}
                                />
                                <ColorPicker
                                    label="Color d'Accent"
                                    value={formData.accent_color}
                                    onChange={(e) => handleChange({ target: { name: 'accent_color', value: e.target.value } })}
                                />
                                <ColorPicker
                                    label="Color del Text"
                                    value={formData.text_color}
                                    onChange={(e) => handleChange({ target: { name: 'text_color', value: e.target.value } })}
                                />
                                <ColorPicker
                                    label="Color dels Títols"
                                    value={formData.heading_color}
                                    onChange={(e) => handleChange({ target: { name: 'heading_color', value: e.target.value } })}
                                />
                                <ColorPicker
                                    label="Color de Fons"
                                    value={formData.background_color}
                                    onChange={(e) => handleChange({ target: { name: 'background_color', value: e.target.value } })}
                                />
                                <ColorPicker
                                    label="Color de Fons Secundari"
                                    value={formData.secondary_background}
                                    onChange={(e) => handleChange({ target: { name: 'secondary_background', value: e.target.value } })}
                                />
                            </div>
                        </div>

                        {/* Tipografia */}
                        <div className="mb-8">
                            <h2 className="text-xl font-semibold mb-4 flex items-center">
                                <FaFont className="mr-2" /> Tipografia
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-gray-700 mb-2">Font dels Títols</label>
                                    <select
                                        name="heading_font"
                                        value={formData.heading_font}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="Inter">Inter</option>
                                        <option value="Roboto">Roboto</option>
                                        <option value="Open Sans">Open Sans</option>
                                        <option value="Montserrat">Montserrat</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-gray-700 mb-2">Font del Text</label>
                                    <select
                                        name="body_font"
                                        value={formData.body_font}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="Inter">Inter</option>
                                        <option value="Roboto">Roboto</option>
                                        <option value="Open Sans">Open Sans</option>
                                        <option value="Montserrat">Montserrat</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Imatges i Mides */}
                        <div className="mb-8">
                            <h2 className="text-xl font-semibold mb-4 flex items-center">
                                <FaImage className="mr-2" /> Imatges i Mides
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-gray-700 mb-2">Logotip</label>
                                    <div className="mb-2">
                                        {previews.logo ? (
                                            <img 
                                                src={previews.logo}
                                                alt="Nova previsualització del logo" 
                                                className="max-h-20 mb-2"
                                            />
                                        ) : formData.logo_path && typeof formData.logo_path === 'string' ? (
                                            <img 
                                                src={`${process.env.REACT_APP_BACKEND_URL}/storage/${formData.logo_path}`}
                                                alt="Logo actual" 
                                                className="max-h-20 mb-2"
                                            />
                                        ) : null}
                                    </div>
                                    <input
                                        type="file"
                                        name="logo"
                                        onChange={handleChange}
                                        accept="image/*"
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 mb-2">Favicon</label>
                                    <div className="mb-2">
                                        {previews.favicon ? (
                                            <img 
                                                src={previews.favicon}
                                                alt="Nova previsualització del favicon" 
                                                className="max-h-20 mb-2"
                                            />
                                        ) : formData.favicon_path && typeof formData.favicon_path === 'string' ? (
                                            <img 
                                                src={`${process.env.REACT_APP_BACKEND_URL}/storage/${formData.favicon_path}`}
                                                alt="Favicon actual" 
                                                className="max-h-20 mb-2"
                                            />
                                        ) : null}
                                    </div>
                                    <input
                                        type="file"
                                        name="favicon"
                                        onChange={handleChange}
                                        accept="image/*"
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 mb-2">Amplada del Logo</label>
                                    <input
                                        type="text"
                                        name="logo_width"
                                        value={formData.logo_width}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded"
                                        placeholder="Ex: 150px"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={isSaving}
                                className={`bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center ${
                                    isSaving ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            >
                                <FaSave className="mr-2" />
                                {isSaving ? 'Guardant...' : 'Guardar Canvis'}
                            </button>
                        </div>
                    </form>

                    {/* Preview */}
                    {previewVisible && <PreviewSection formData={formData} />}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default StyleSettings; 