import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaSave, FaUser, FaBuilding, FaEnvelope, FaPhone, FaMapMarkerAlt, FaGlobe } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const ProfileSettings = () => {
    const { user, loading: authLoading } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');

    const [formData, setFormData] = useState({
        accountName: '',
        companyName: '',
        nif: '',
        email: '',
        phone: '',
        address: {
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: ''
        },
        socialMedia: {
            linkedin: '',
            twitter: '',
            facebook: '',
            instagram: '',
            website: ''
        }
    });

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profile`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                
                if (!response.ok) throw new Error('Error al carregar les dades del perfil');
                
                const data = await response.json();
                setFormData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (!authLoading) {
            fetchProfileData();
        }
    }, [authLoading]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const [section, field] = name.split('.');
            setFormData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) throw new Error('Error al guardar les dades');

            setSuccessMessage('Dades guardades correctament');
        } catch (error) {
            setError(error.message);
        } finally {
            setIsSaving(false);
        }
    };

    if (authLoading || isLoading) {
        return <LoadingSpinner message="Carregant dades del perfil..." />;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-12 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Dades i Perfil</h1>
                    <Link
                        to="/configuracio"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                        {successMessage}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
                    {/* Dades Bàsiques */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaUser className="mr-2" /> Dades Bàsiques
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-gray-700 mb-2">Nom del Compte</label>
                                <input
                                    type="text"
                                    name="accountName"
                                    value={formData.accountName}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Nom de la Societat</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">NIF</label>
                                <input
                                    type="text"
                                    name="nif"
                                    value={formData.nif}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* Contacte */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaEnvelope className="mr-2" /> Contacte
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-gray-700 mb-2">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Telèfon</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* Adreça */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaMapMarkerAlt className="mr-2" /> Adreça
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="md:col-span-2">
                                <label className="block text-gray-700 mb-2">Carrer i Número</label>
                                <input
                                    type="text"
                                    name="address.street"
                                    value={formData.address.street}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Ciutat</label>
                                <input
                                    type="text"
                                    name="address.city"
                                    value={formData.address.city}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Província</label>
                                <input
                                    type="text"
                                    name="address.state"
                                    value={formData.address.state}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Codi Postal</label>
                                <input
                                    type="text"
                                    name="address.postalCode"
                                    value={formData.address.postalCode}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">País</label>
                                <input
                                    type="text"
                                    name="address.country"
                                    value={formData.address.country}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* Xarxes Socials */}
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4 flex items-center">
                            <FaGlobe className="mr-2" /> Xarxes Socials
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-gray-700 mb-2">LinkedIn</label>
                                <input
                                    type="url"
                                    name="socialMedia.linkedin"
                                    value={formData.socialMedia.linkedin}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Twitter</label>
                                <input
                                    type="url"
                                    name="socialMedia.twitter"
                                    value={formData.socialMedia.twitter}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Facebook</label>
                                <input
                                    type="url"
                                    name="socialMedia.facebook"
                                    value={formData.socialMedia.facebook}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 mb-2">Instagram</label>
                                <input
                                    type="url"
                                    name="socialMedia.instagram"
                                    value={formData.socialMedia.instagram}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                            <div className="md:col-span-2">
                                <label className="block text-gray-700 mb-2">Lloc Web</label>
                                <input
                                    type="url"
                                    name="socialMedia.website"
                                    value={formData.socialMedia.website}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={isSaving}
                            className={`bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center ${
                                isSaving ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            <FaSave className="mr-2" />
                            {isSaving ? 'Guardant...' : 'Guardar Canvis'}
                        </button>
                    </div>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default ProfileSettings; 