import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEye, FaShoppingCart, FaExchangeAlt, FaExternalLinkAlt, FaSearch, FaFileAlt } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const PaymentTransactions = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showGatewayModal, setShowGatewayModal] = useState(false);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [gatewayData, setGatewayData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [gatewayFilter, setGatewayFilter] = useState('');

    useEffect(() => {
        fetchTransactions();
    }, []);

    const fetchTransactions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-transactions`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar les transaccions');

            const result = await response.json();
            setTransactions(Array.isArray(result.data) ? result.data : []);
            
        } catch (error) {
            setError(error.message);
            setTransactions([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGatewayInfo = async (transaction) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-transactions/${transaction.id}/gateway-info`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error al obtenir informació del gateway');
            }
            
            const data = await response.json();
            setGatewayData(data.data);
            setSelectedTransaction(transaction);
            setShowGatewayModal(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRefund = async (amount) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-transactions/${selectedTransaction.id}/refund`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ amount: parseFloat(amount) })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error al processar la devolució');
            }

            const data = await response.json();
            alert(data.message); // O usar un sistema de notificaciones más elegante
            setShowRefundModal(false);
            fetchTransactions(); // Recargar transacciones
        } catch (error) {
            console.error('Error:', error);
            alert(error.message); // O usar un sistema de notificaciones más elegante
        }
    };

    const filteredTransactions = transactions.filter(transaction => {
        const searchFields = [
            transaction.reference,
            transaction.authorization_code,
            transaction.payment_gateway?.name
        ].join(' ').toLowerCase();

        const matchesSearch = searchFields.includes(searchTerm.toLowerCase());
        const matchesDate = !dateFilter || transaction.created_at.includes(dateFilter);
        const matchesStatus = !statusFilter || transaction.status === statusFilter;
        const matchesGateway = !gatewayFilter || transaction.payment_gateway?.id.toString() === gatewayFilter.toString();

        return matchesSearch && matchesDate && matchesStatus && matchesGateway;
    });

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="flex-grow container mx-auto px-4 py-8">
                    <LoadingSpinner />
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">
                        Historial de Transaccions
                    </h1>
                    <Link
                        to="/cobraments"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {error ? (
                    <div className="text-red-500 text-center py-4">{error}</div>
                ) : (
                    <>
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Cerca
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Cerca per referència..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="w-full p-2 border rounded-md pl-10"
                                        />
                                        <FaSearch className="absolute left-3 top-3 text-gray-400" />
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Data
                                    </label>
                                    <input
                                        type="date"
                                        value={dateFilter}
                                        onChange={(e) => setDateFilter(e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Estat
                                    </label>
                                    <select
                                        value={statusFilter}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                    >
                                        <option value="">Tots els estats</option>
                                        <option value="completed">Completat</option>
                                        <option value="pending">Pendent</option>
                                        <option value="failed">Fallat</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Passarel·la
                                    </label>
                                    <select
                                        value={gatewayFilter}
                                        onChange={(e) => setGatewayFilter(e.target.value)}
                                        className="w-full p-2 border rounded-md"
                                    >
                                        <option value="">Totes les passarel·les</option>
                                        {Array.from(new Set(transactions.map(t => t.payment_gateway?.id)))
                                            .filter(Boolean)
                                            .map(gatewayId => {
                                                const gateway = transactions.find(t => t.payment_gateway?.id === gatewayId)?.payment_gateway;
                                                return gateway ? (
                                                    <option key={gateway.id} value={gateway.id.toString()}>
                                                        {gateway.name}
                                                    </option>
                                                ) : null;
                                            })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Referència
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Import
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Passarel·la
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Estat
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Codi Autorització
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Data
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Accions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {filteredTransactions.length > 0 ? (
                                                filteredTransactions.map((transaction) => {
                                                    console.log('Transaction:', {
                                                        id: transaction.id,
                                                        payment: transaction.payment,
                                                        payment_form_id: transaction.payment?.payment_form_id
                                                    });
                                                    
                                                    return (
                                                        <tr key={transaction.id}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {transaction.reference}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {transaction.amount}€
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {transaction.payment_gateway?.name || 'No especificada'}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                                    transaction.status === 'completed' ? 'bg-green-100 text-green-800' : 
                                                                    transaction.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                                                                    transaction.status === 'failed' ? 'bg-red-100 text-red-800' :
                                                                    'bg-gray-100 text-gray-800'
                                                                }`}>
                                                                    {transaction.status === 'completed' ? 'Completat' :
                                                                     transaction.status === 'pending' ? 'Pendent' :
                                                                     transaction.status === 'failed' ? 'Fallat' :
                                                                     transaction.status}
                                                                </span>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {transaction.authorization_code || '-'}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {new Date(transaction.created_at).toLocaleString('ca-ES')}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <div className="flex justify-end space-x-2">
                                                                    <button
                                                                        onClick={() => navigate(`/payments/${transaction.payment_id}`)}
                                                                        className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                        title="Veure pagament"
                                                                    >
                                                                        <FaEye />
                                                                    </button>
                                                                    
                                                                    <button
                                                                        onClick={() => handleGatewayInfo(transaction)}
                                                                        className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                        title="Informació del gateway"
                                                                    >
                                                                        <FaExternalLinkAlt />
                                                                    </button>
                                                                    
                                                                    {transaction.payment?.payment_form_id && (
                                                                        <button
                                                                            onClick={() => {
                                                                                console.log('Navigating to form:', transaction.payment.payment_form_id);
                                                                                navigate(`/payments/forms/${transaction.payment.payment_form_id}/responses`);
                                                                            }}
                                                                            className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors duration-200"
                                                                            title="Veure respostes del formulari"
                                                                        >
                                                                            <FaFileAlt />
                                                                        </button>
                                                                    )}
                                                                    
                                                                    {transaction.payment?.order_id && (
                                                                        <button
                                                                            onClick={() => {
                                                                                if (transaction.payment.order?.online_shop_id) {
                                                                                    navigate(`/online-shops/${transaction.payment.order.online_shop_id}/orders/${transaction.payment.order_id}`);
                                                                                } else {
                                                                                    console.error('No s\'ha trobat la botiga associada a la comanda');
                                                                                }
                                                                            }}
                                                                            className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                            title="Veure comanda"
                                                                        >
                                                                            <FaShoppingCart />
                                                                        </button>
                                                                    )}
                                                                    
                                                                    {transaction.status === 'completed' && (
                                                                        <button
                                                                            onClick={() => {
                                                                                setSelectedTransaction(transaction);
                                                                                setShowRefundModal(true);
                                                                            }}
                                                                            className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                            title="Gestionar devolució"
                                                                        >
                                                                            <FaExchangeAlt />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                                                        No s'han trobat transaccions
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </main>
            <Footer />
            {showGatewayModal && (
                <GatewayInfoModal
                    transaction={selectedTransaction}
                    gatewayData={gatewayData}
                    onClose={() => {
                        setShowGatewayModal(false);
                        setSelectedTransaction(null);
                        setGatewayData(null);
                    }}
                />
            )}

            {showRefundModal && (
                <RefundModal
                    transaction={selectedTransaction}
                    onClose={() => {
                        setShowRefundModal(false);
                        setSelectedTransaction(null);
                    }}
                    onRefund={handleRefund}
                />
            )}
        </div>
    );
};

const GatewayInfoModal = ({ transaction, onClose, gatewayData }) => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">Informació del Gateway</h3>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">×</button>
            </div>
            <div className="mt-4">
                <pre className="whitespace-pre-wrap bg-gray-50 p-4 rounded">
                    {JSON.stringify(gatewayData, null, 2)}
                </pre>
            </div>
        </div>
    </div>
);

const RefundModal = ({ transaction, onClose, onRefund }) => {
    const [amount, setAmount] = useState(transaction.amount);
    const [isPartial, setIsPartial] = useState(false);

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium">Gestionar Devolució</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">×</button>
                </div>
                <div className="mt-4">
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Tipus de devolució
                        </label>
                        <select 
                            className="mt-1 block w-full rounded-md border-gray-300"
                            onChange={(e) => setIsPartial(e.target.value === 'partial')}
                        >
                            <option value="full">Completa</option>
                            <option value="partial">Parcial</option>
                        </select>
                    </div>
                    {isPartial && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Import a retornar
                            </label>
                            <input
                                type="number"
                                step="0.01"
                                max={transaction.amount}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300"
                            />
                        </div>
                    )}
                    <button
                        onClick={() => onRefund(amount)}
                        className="w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                        Processar Devolució
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentTransactions; 