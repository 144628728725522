import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaUpload, FaTrash, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';

const CreateOnlineShop = () => {
    const [userName, setUserName] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        payment_gateway_id: '',
        primary_color: '#ED5B52',
        currency: 'EUR',
        status: 'active',
        configuration: {
            contact_email: '',
            shipping_policy: '',
            terms_conditions: ''
        }
    });

    const [paymentGateways, setPaymentGateways] = useState([]);
    const [logoPreview, setLogoPreview] = useState(null);
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        fetchUserData();
        fetchPaymentGateways();
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const userData = await response.json();
                setUserName(userData.name);
            }
        } catch (error) {
            console.error('Error en obtenir les dades de l\'usuari:', error);
        }
    };

    const fetchPaymentGateways = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al carregar les passarel·les de pagament');
            const data = await response.json();
            setPaymentGateways(data);
            // Seleccionar la primera passarel·la activa per defecte
            const firstActiveGateway = data.find(gateway => gateway.is_active);
            if (firstActiveGateway) {
                setFormData(prev => ({...prev, payment_gateway_id: firstActiveGateway.id}));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
                setLogo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            // Verificar que tenim una passarel·la de pagament vàlida
            if (!formData.payment_gateway_id) {
                throw new Error('Cal seleccionar una passarel·la de pagament');
            }

            // Preparar les dades en format JSON
            const shopData = {
                name: formData.name,
                description: formData.description,
                payment_gateway_id: formData.payment_gateway_id,
                primary_color: formData.primary_color,
                currency: formData.currency,
                status: formData.status,
                configuration: formData.configuration,
                logo: logo
            };

            console.log('Enviant dades:', shopData);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(shopData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Error: ${response.status}`);
            }

            const result = await response.json();
            console.log('Botiga creada:', result);

            alert('Botiga online creada correctament');
            window.location.href = '/online-shops';

        } catch (error) {
            console.error('Error detallat:', error);
            alert(error.message || 'Hi ha hagut un error al crear la botiga');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="flex-1 container mx-auto px-4 py-12">
                {/* Capçalera amb nom usuari i botó tornar */}
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">{userName}</h1>
                    <Link
                        to="/cobraments"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                {/* Títol de la pàgina */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-semibold text-gray-800">Crear nova botiga online</h2>
                </div>

                <div className="bg-white rounded-lg shadow-md p-6">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Informació bàsica */}
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold text-gray-700 border-b pb-2">Informació bàsica</h2>
                            
                            <div className="grid grid-cols-1 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Nom de la botiga</label>
                                    <input
                                        type="text"
                                        value={formData.name}
                                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                                        className="block w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Descripció</label>
                                    <textarea
                                        value={formData.description}
                                        onChange={(e) => setFormData({...formData, description: e.target.value})}
                                        className="block w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        rows="3"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Logo</label>
                                    <div className="mt-1">
                                        {logoPreview ? (
                                            <div className="flex items-center space-x-2">
                                                <img src={logoPreview} alt="Logo preview" className="h-20 w-20 object-contain" />
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setLogoPreview(null);
                                                        setLogo(null);
                                                    }}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="flex items-center space-x-2">
                                                <label className="cursor-pointer bg-gray-100 hover:bg-gray-200 px-4 py-2 rounded flex items-center">
                                                    <FaUpload className="mr-2" />
                                                    Pujar logo
                                                    <input
                                                        type="file"
                                                        onChange={handleImageChange}
                                                        accept="image/*"
                                                        className="hidden"
                                                    />
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Color principal</label>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="color"
                                            value={formData.primary_color}
                                            onChange={(e) => setFormData({...formData, primary_color: e.target.value})}
                                            className="block w-20 h-10 rounded border focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        />
                                        <span className="text-sm text-gray-500">{formData.primary_color}</span>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <label className="text-sm font-medium text-gray-700">Estat de la botiga:</label>
                                    <div className="flex items-center space-x-2">
                                        <button
                                            type="button"
                                            onClick={() => setFormData(prev => ({...prev, status: prev.status === 'active' ? 'inactive' : 'active'}))}
                                            className="text-2xl"
                                        >
                                            {formData.status === 'active' ? 
                                                <FaToggleOn className="text-green-500" /> : 
                                                <FaToggleOff className="text-red-500" />
                                            }
                                        </button>
                                        <span className="text-sm text-gray-700">
                                            {formData.status === 'active' ? 'Botiga activa' : 'Botiga inactiva'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Informació legal i contacte */}
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold text-gray-700 border-b pb-2">Informació legal i contacte</h2>
                            
                            <div className="grid grid-cols-1 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Email de contacte</label>
                                    <input
                                        type="email"
                                        value={formData.configuration.contact_email}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            configuration: {...formData.configuration, contact_email: e.target.value}
                                        })}
                                        className="block w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Política d'enviaments</label>
                                    <textarea
                                        value={formData.configuration.shipping_policy}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            configuration: {...formData.configuration, shipping_policy: e.target.value}
                                        })}
                                        className="block w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        rows="3"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Termes i condicions</label>
                                    <textarea
                                        value={formData.configuration.terms_conditions}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            configuration: {...formData.configuration, terms_conditions: e.target.value}
                                        })}
                                        className="block w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        rows="3"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Configuració pagament */}
                        <div className="space-y-4">
                            <h2 className="text-lg font-semibold text-gray-700 border-b pb-2">Configuració pagament</h2>
                            
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Passarel·la de pagament</label>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                    {paymentGateways.map((gateway) => (
                                        <div
                                            key={gateway.id}
                                            onClick={() => gateway.is_active && setFormData({...formData, payment_gateway_id: gateway.id})}
                                            className={`
                                                p-4 rounded-lg border-2 transition-all duration-200
                                                ${!gateway.is_active && 'opacity-50 cursor-not-allowed bg-gray-100'}
                                                ${gateway.is_active && 'cursor-pointer'}
                                                ${formData.payment_gateway_id === gateway.id && gateway.is_active
                                                    ? 'border-[#ED5B52] bg-red-50' 
                                                    : 'border-gray-200 hover:border-gray-300'
                                                }
                                            `}
                                        >
                                            <div className="flex items-center space-x-3">
                                                <input
                                                    type="radio"
                                                    name="payment-gateway"
                                                    value={gateway.id}
                                                    checked={formData.payment_gateway_id === gateway.id}
                                                    onChange={() => gateway.is_active && setFormData({...formData, payment_gateway_id: gateway.id})}
                                                    className="text-[#ED5B52] focus:ring-[#ED5B52]"
                                                    disabled={!gateway.is_active}
                                                    required
                                                />
                                                <div>
                                                    <p className={`font-medium ${gateway.is_active ? 'text-gray-900' : 'text-gray-500'}`}>
                                                        {gateway.name}
                                                    </p>
                                                    {!gateway.is_active && (
                                                        <p className="text-sm text-red-500">
                                                            Desactivada
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Botó de submit */}
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200"
                            >
                                Crear botiga
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default CreateOnlineShop; 