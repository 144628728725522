import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import VerifyEmail from './components/VerifyEmail';
import RecordarContrasenya from './components/RecordarContrasenya';
import ResetPassword from './components/ResetPassword';
import PaymentGateways from './components/PaymentGateways';
import Payments from './components/Payments';
import SinglePayment from './components/payments/SinglePayment';
import ManageSinglePayments from './components/payments/ManageSinglePayments';
import ViewPayment from './components/payments/ViewPayment';
import RecurringPayment from './components/payments/RecurringPayment';
import ManageRecurringPayments from './components/payments/ManageRecurringPayments';
import PublicPayment from './components/public/PublicPayment';
import PaymentLink from './components/payments/PaymentLink';
import OpenPayment from './components/payments/OpenPayment';
import ManageOpenPayments from './components/payments/ManageOpenPayments';
import OpenPaymentDetails from './components/payments/OpenPaymentDetails';
import CreateOnlineShop from './components/shops/CreateOnlineShop';
import ManageOnlineShops from './components/shops/ManageOnlineShops';
import OnlineShopDetails from './components/shops/OnlineShopDetails';
import CreateProduct from './components/shops/CreateProduct';
import CreateCategory from './components/shops/CreateCategory';
import Clients from './components/Clients';
import CreateClient from './components/clients/CreateClient';
import ManageCustomers from './components/clients/ManageCustomers';
import ClientDetails from './components/clients/ClientDetails';
import EditClient from './components/clients/EditClient';
import CreateAddress from './components/clients/CreateAddress';
import CreateGroup from './components/clients/groups/CreateGroup';
import ManageGroups from './components/clients/groups/ManageGroups';
import GroupDetails from './components/clients/groups/GroupDetails';
import EditProduct from './components/shops/EditProduct';
import PublicPlace from './components/public/PublicPlace';
import CreatePaymentForm from './components/payments/forms/CreatePaymentForm';
import ManagePaymentForms from './components/payments/forms/ManagePaymentForms';
import PaymentFormDetails from './components/payments/forms/PaymentFormDetails';
import EditPaymentForm from './components/payments/forms/EditPaymentForm';
import PublicOpenPayment from './components/public/PublicOpenPayment';
import PublicOnlineShop from './components/public/PublicOnlineShop';
import { CartProvider } from './contexts/CartContext';
import CartSidebar from './components/cart/CartSidebar';
import PublicShops from './components/public/PublicShops';
import PublicPaymentForm from './components/public/PublicPaymentForm';
import EditSection from './components/payments/forms/sections/EditSection';
import EditField from './components/payments/forms/fields/EditField';
import EditCondition from './components/payments/forms/conditions/EditCondition';
import PaymentFormResponseDetails from './components/payments/forms/responses/PaymentFormResponseDetails';
import OnlineShopOrders from './components/shops/OnlineShopOrders';
import OnlineShopOrderDetails from './components/shops/OnlineShopOrderDetails';
import CampaignDetails from './components/campaigns/CampaignDetails';
import ManageCampaigns from './components/campaigns/ManageCampaigns';
import CreateCampaign from './components/campaigns/CreateCampaign';
import PublicCampaign from './components/public/PublicCampaign';
import PaymentTransactions from './components/payments/PaymentTransactions';
import Users from './components/Users';
import CreateUser from './components/users/CreateUser';
import ManageUsers from './components/users/ManageUsers';
import CreateRole from './components/users/CreateRole';
import AssignRoles from './components/users/AssignRoles';
import ManageUserRoles from './components/users/ManageUserRoles';
import EditUser from './components/users/EditUser';
import EditRole from './components/users/EditRole';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import EditCategory from './components/shops/EditCategory';
import PaymentFormResponses from './components/payments/forms/responses/PaymentFormResponses';
import PublicPaymentForms from './components/public/PublicPaymentForms';
import PublicOpenPayments from './components/public/PublicOpenPayments';
import Settings from './components/Settings';
import ProfileSettings from './components/settings/ProfileSettings';
import StyleSettings from './components/settings/StyleSettings';
import LegalSettings from './components/settings/LegalSettings';
import ContactSettings from './components/settings/ContactSettings';
import Statistics from './components/statistics/Statistics';
import LoadingSpinner from './components/common/LoadingSpinner';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { StyleProvider } from './contexts/StyleContext';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const location = useLocation();
    
    console.log('PrivateRoute:', { isAuthenticated, loading }); // Debug

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!isAuthenticated) {
        console.log('Not authenticated, redirecting to login'); // Debug
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

function App() {
  return (
   
        <Router>
          <StyleProvider>
            <CartProvider>
              <AuthProvider>
                <Routes>
                  {/* Rutas protegidas */}
                  <Route path="/passarelles" element={<PrivateRoute><PaymentGateways /></PrivateRoute>} />
                  <Route path="/cobraments" element={<PrivateRoute><Payments /></PrivateRoute>} />
                  <Route path="/payments/create/unic" element={<PrivateRoute><SinglePayment /></PrivateRoute>} />
                  <Route path="/payments/manage/unic" element={<PrivateRoute><ManageSinglePayments /></PrivateRoute>} />
                  <Route path="/payments/:id" element={<PrivateRoute><ViewPayment /></PrivateRoute>} />
                  <Route path="/payments/create/recurrent" element={<PrivateRoute><RecurringPayment /></PrivateRoute>} />
                  <Route path="/payments/manage/recurrent" element={<PrivateRoute><ManageRecurringPayments /></PrivateRoute>} />
                  <Route path="/payments/create/link" element={<PrivateRoute><PaymentLink /></PrivateRoute>} />
                  <Route path="/payments/create/obert" element={<PrivateRoute><OpenPayment /></PrivateRoute>} />
                  <Route path="/payments/manage/obert" element={<PrivateRoute><ManageOpenPayments /></PrivateRoute>} />
                  <Route path="/open-payments/:id" element={<PrivateRoute><OpenPaymentDetails /></PrivateRoute>} />
                  <Route path="/online-shops/create" element={<PrivateRoute><CreateOnlineShop /></PrivateRoute>} />
                  <Route path="/online-shops" element={<PrivateRoute><ManageOnlineShops /></PrivateRoute>} />
                  <Route path="/online-shops/:id" element={<PrivateRoute><OnlineShopDetails /></PrivateRoute>} />
                  <Route path="/online-shops/:id/products/create" element={<PrivateRoute><CreateProduct /></PrivateRoute>} />
                  <Route path="/online-shops/:id/categories/create" element={<PrivateRoute><CreateCategory /></PrivateRoute>} />
                  <Route path="/online-shops/:idshop/products/:id/edit" element={<PrivateRoute><EditProduct /></PrivateRoute>} />
                  <Route path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
                  <Route path="/clients/create" element={<PrivateRoute><CreateClient /></PrivateRoute>} />
                  <Route path="/clients/manage" element={<PrivateRoute><ManageCustomers /></PrivateRoute>} />
                  <Route path="/clients/:id" element={<PrivateRoute><ClientDetails /></PrivateRoute>} />
                  <Route path="/clients/:id/edit" element={<PrivateRoute><EditClient /></PrivateRoute>} />
                  <Route path="/clients/:id/addresses/create" element={<PrivateRoute><CreateAddress /></PrivateRoute>} />
                  <Route path="/clients/groups/create" element={<PrivateRoute><CreateGroup /></PrivateRoute>} />
                  <Route path="/clients/groups" element={<PrivateRoute><ManageGroups /></PrivateRoute>} />
                  <Route path="/clients/groups/:id" element={<PrivateRoute><GroupDetails /></PrivateRoute>} />
                  <Route path="/payments/create/formulari" element={<PrivateRoute><CreatePaymentForm /></PrivateRoute>} />
                  <Route path="/payments/forms" element={<PrivateRoute><ManagePaymentForms /></PrivateRoute>} />
                  <Route path="/payments/forms/:id" element={<PrivateRoute><PaymentFormDetails /></PrivateRoute>} />
                  <Route path="/payments/forms/:id/edit" element={<PrivateRoute><EditPaymentForm /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/sections/new" element={<PrivateRoute><EditSection /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/sections/:sectionId/edit" element={<PrivateRoute><EditSection /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/sections/:sectionId/fields/new" element={<PrivateRoute><EditField /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/sections/:sectionId/fields/:fieldId/edit" element={<PrivateRoute><EditField /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/conditions/new" element={<PrivateRoute><EditCondition /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/conditions/:conditionId/edit" element={<PrivateRoute><EditCondition /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/responses/:responseId" element={<PrivateRoute><PaymentFormResponseDetails /></PrivateRoute>} />
                  <Route path="/online-shops/:id/orders" element={<PrivateRoute><OnlineShopOrders /></PrivateRoute>} />
                  <Route path="/online-shops/:shopId/orders/:orderId" element={<PrivateRoute><OnlineShopOrderDetails /></PrivateRoute>} />
                  <Route path="/clients/campaigns/create" element={<PrivateRoute><CreateCampaign /></PrivateRoute>} />
                  <Route path="/clients/campaigns/manage" element={<PrivateRoute><ManageCampaigns /></PrivateRoute>} />
                  <Route path="/clients/campaigns/:id" element={<PrivateRoute><CampaignDetails /></PrivateRoute>} />
                  <Route path="/payment-transactions" element={<PrivateRoute><PaymentTransactions /></PrivateRoute>} />
                  <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
                  <Route path="/users/create" element={<PrivateRoute><CreateUser /></PrivateRoute>} />
                  <Route path="/users/manage" element={<PrivateRoute><ManageUsers /></PrivateRoute>} />
                  <Route path="/users/roles/create" element={<PrivateRoute><CreateRole /></PrivateRoute>} />
                  <Route path="/users/assign-roles" element={<PrivateRoute><AssignRoles /></PrivateRoute>} />
                  <Route path="/users/manage-roles" element={<PrivateRoute><ManageUserRoles /></PrivateRoute>} />
                  <Route path="/users/edit/:id" element={<PrivateRoute><EditUser /></PrivateRoute>} />
                  <Route path="/users/roles/edit/:id" element={<PrivateRoute><EditRole /></PrivateRoute>} />
                  <Route path="/online-shops/:shopId/categories/:categoryId/edit" element={<PrivateRoute><EditCategory /></PrivateRoute>} />
                  <Route path="/payments/forms/:formId/responses" element={<PrivateRoute><PaymentFormResponses /></PrivateRoute>} />
                  <Route path="/configuracio" element={<PrivateRoute><Settings /></PrivateRoute>} />
                  <Route path="/configuracio/perfil" element={<PrivateRoute><ProfileSettings /></PrivateRoute>} />
                  <Route path="/configuracio/styles" element={<PrivateRoute><StyleSettings /></PrivateRoute>} />
                  <Route path="/configuracio/legal" element={<PrivateRoute><LegalSettings /></PrivateRoute>} />
                  <Route path="/configuracio/contacte" element={<PrivateRoute><ContactSettings /></PrivateRoute>} />
                  <Route path="/configuracio/estadistiques" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                  {/* Rutas públicas */}
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                  <Route path="/verify-email" element={<VerifyEmail />} />
                  <Route path="/recordar-contrasenya" element={<RecordarContrasenya />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/public-payment-forms" element={<PublicPaymentForms />} />
                  <Route path="/public-open-payments" element={<PublicOpenPayments />} />
                  <Route path="/public" element={<PublicPlace />} />
                  <Route path="/public-payments/:hash" element={<PublicPayment />} />
                  <Route path="/public-open-payments/:hash" element={<PublicOpenPayment />} />
                  <Route path="/shops" element={<PublicShops />} />
                  <Route path="/shops/:id" element={<PublicOnlineShop />} />
                  <Route path="/public-payment-forms/:hash" element={<PublicPaymentForm />} />
                  <Route path="/c/:hash" element={<PublicCampaign />} />
                </Routes>
                <CartSidebar />
                <ToastContainer />
              </AuthProvider>
            </CartProvider>
          </StyleProvider>
        </Router>
      
  );
}

export default App;
