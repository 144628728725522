import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaShoppingCart, FaUser, FaFileInvoiceDollar } from 'react-icons/fa';
import { useCart } from '../../../contexts/CartContext';
import { useLocation, useNavigate } from 'react-router-dom';
import LegalModal from '../../common/LegalModal';

const PublicLayout = ({ children, shop, error, hasShops, hasPayments, hasForms, profileId }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [profileInfo, setProfileInfo] = useState(null);
    const { setIsOpen, getItemsCount } = useCart();
    const location = useLocation();
    const navigate = useNavigate();
    const [legalTexts, setLegalTexts] = useState(null);
    const [modalContent, setModalContent] = useState({
        isOpen: false,
        title: '',
        content: ''
    });
    const [profileStyle, setProfileStyle] = useState(null);

    useEffect(() => {
        const fetchProfileInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profile/public`);
                if (!response.ok) throw new Error('Error carregant la informació del perfil');
                const data = await response.json();
                setProfileInfo(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchProfileInfo();
    }, []);

    useEffect(() => {
        const fetchLegalTexts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/legal-texts/public`);
                if (!response.ok) throw new Error('Error carregant els textos legals');
                const data = await response.json();
                setLegalTexts(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchLegalTexts();
    }, []);

    useEffect(() => {
        const fetchProfileStyle = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/profiles/styles`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accept': 'application/json'
                    }
                });
                if (!response.ok) throw new Error('Error carregant l\'estil del perfil');
                const data = await response.json();
                setProfileStyle(data.style);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchProfileStyle();
    }, []);

    const handleCartClick = () => {
        if (location.pathname.includes('/shops/')) {
            setIsOpen(true);
        } else {
            navigate('/shops');
        }
    };

    const handleLegalClick = (type) => {
        let title = '';
        let content = '';

        switch (type) {
            case 'terms':
                title = 'Condicions d\'ús';
                content = legalTexts?.terms_conditions || '';
                break;
            case 'privacy':
                title = 'Política de privacitat';
                content = legalTexts?.privacy_policy || '';
                break;
            case 'cookies':
                title = 'Política de cookies';
                content = legalTexts?.cookies_policy || '';
                break;
            default:
                return;
        }

        setModalContent({
            isOpen: true,
            title,
            content
        });
    };

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50 flex items-center justify-center">
                <div className="text-center">
                    <h1 className="text-2xl font-bold text-red-500 mb-4">Error</h1>
                    <p className="text-gray-600">{error}</p>
                </div>
            </div>
        );
    }

    return (
            <div className="min-h-screen flex flex-col bg-gray-50">
                <header className="sticky top-0 z-50 bg-white shadow-sm">
                    <div className="max-w-7xl mx-auto">
                        <div className="flex justify-between items-center px-4 py-4 sm:px-6 lg:px-8">
                            {/* Logo i nom */}
                            <div className="flex items-center">
                                <Link to="/public" className="flex items-center">
                                    {profileStyle?.logo_path ? (
                                        <img 
                                            src={`${process.env.REACT_APP_BACKEND_URL}/storage/${profileStyle.logo_path}`}
                                            alt="Logo" 
                                            className="h-8 w-auto mr-2"
                                            style={{ width: 'auto' }}
                                        />
                                    ) : (
                                        <div className="h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center">
                                            <span className="text-gray-500 text-xs">Logo</span>
                                        </div>
                                    )}
                                </Link>
                            </div>

                            {/* Icones */}
                            <div className="flex items-center space-x-4">
                                {hasPayments && (
                                    <Link to={``} className="text-gray-600 hover:text-[#ED5B52]">
                                        <FaUser className="h-6 w-6" />
                                    </Link>
                                )}
                                {hasShops && (
                                    <button 
                                        onClick={handleCartClick}
                                        className="relative p-2 hover:bg-gray-100 rounded-full"
                                    >
                                        <FaShoppingCart className="h-6 w-6 text-gray-600 hover:text-[#ED5B52]" />
                                        {getItemsCount() > 0 && (
                                            <span className="absolute -top-1 -right-1 bg-[#ED5B52] text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                                                {getItemsCount()}
                                            </span>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>

                    </div>
                </header>

                {/* Contingut principal */}
                <main className="flex-grow">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8">
                        {children}
                    </div>
                </main>

                {/* Footer */}
                <footer className="bg-white shadow-sm mt-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                            {/* Sobre nosaltres */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Sobre nosaltres</h3>
                                {profileInfo?.company_name && (
                                    <p className="text-gray-600 mt-2">{profileInfo.company_name}</p>
                                )}
                                {profileInfo?.nif && (
                                    <p className="text-gray-600">{profileInfo.nif}</p>
                                )}
                            </div>

                            {/* Contacte */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Contacte</h3>
                                {profileInfo?.address && (
                                    <div className="text-gray-600 mb-2">
                                        {profileInfo.address.street}<br />
                                        {profileInfo.address.postalCode} {profileInfo.address.city}<br />
                                        {profileInfo.address.state}, {profileInfo.address.country}
                                    </div>
                                )}
                                {profileInfo?.email && (
                                    <p className="text-gray-600 mb-2">
                                        <a href={`mailto:${profileInfo.email}`} className="hover:text-[#ED5B52]">
                                            {profileInfo.email}
                                        </a>
                                    </p>
                                )}
                                {profileInfo?.phone && (
                                    <p className="text-gray-600 mb-2">
                                        <a href={`tel:${profileInfo.phone}`} className="hover:text-[#ED5B52]">
                                            {profileInfo.phone}
                                        </a>
                                    </p>
                                )}
                            </div>

                            {/* Xarxes Socials */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Segueix-nos</h3>
                                {profileInfo?.social_media && (
                                    <div className="space-y-2">
                                        {Object.entries(profileInfo.social_media).map(([key, value]) => 
                                            value && (
                                                <a 
                                                    key={key}
                                                    href={value}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-gray-600 hover:text-[#ED5B52] block capitalize"
                                                >
                                                    {key}
                                                </a>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>

                            {/* Informació Legal */}
                            <div>
                                <h3 className="text-lg font-semibold mb-4">Informació Legal</h3>
                                <div className="space-y-2">
                                    {legalTexts?.terms_conditions && (
                                        <button 
                                            onClick={() => handleLegalClick('terms')}
                                            className="text-gray-600 hover:text-[#ED5B52] block text-left w-full"
                                        >
                                            Condicions d'ús
                                        </button>
                                    )}
                                    {legalTexts?.privacy_policy && (
                                        <button 
                                            onClick={() => handleLegalClick('privacy')}
                                            className="text-gray-600 hover:text-[#ED5B52] block text-left w-full"
                                        >
                                            Política de privacitat
                                        </button>
                                    )}
                                    {legalTexts?.cookies_policy && (
                                        <button 
                                            onClick={() => handleLegalClick('cookies')}
                                            className="text-gray-600 hover:text-[#ED5B52] block text-left w-full"
                                        >
                                            Política de cookies
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Copyright */}
                        <div className="border-t border-gray-200 mt-8 pt-8 text-center text-gray-600">
                            <p>&copy; {new Date().getFullYear()} {profileInfo?.account_name}. Tots els drets reservats.</p>
                        </div>
                    </div>
                </footer>

                {/* Modal */}
                <LegalModal
                    isOpen={modalContent.isOpen}
                    onClose={() => setModalContent({ ...modalContent, isOpen: false })}
                    title={modalContent.title}
                    content={modalContent.content}
                />
            </div>
    );
};

export default PublicLayout; 