import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSave } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../common/LoadingSpinner';

const CreateAddress = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, loading: authLoading } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        customer_id: id,
        type: 'shipping',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        is_default: false
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-addresses`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al crear l\'adreça');
            }

            navigate(`/clients/${id}`);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    if (authLoading) {
        return <LoadingSpinner message="Carregant formulari d'adreça..." />;
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Nova Adreça</h1>
                    <button
                        onClick={() => navigate(`/clients/${id}`)}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </button>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                <div className="bg-white rounded-lg shadow p-6">
                    {isLoading ? (
                        <LoadingSpinner message="Creant adreça..." />
                    ) : (
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Tipus d'adreça *
                                    </label>
                                    <select
                                        name="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    >
                                        <option value="shipping">Enviament</option>
                                        <option value="billing">Facturació</option>
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Adreça *
                                    </label>
                                    <input
                                        type="text"
                                        name="address_line_1"
                                        value={formData.address_line_1}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        placeholder="Carrer, número, pis..."
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Adreça (línia 2)
                                    </label>
                                    <input
                                        type="text"
                                        name="address_line_2"
                                        value={formData.address_line_2}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        placeholder="Informació addicional..."
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Població *
                                    </label>
                                    <input
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Província
                                    </label>
                                    <input
                                        type="text"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Codi Postal *
                                    </label>
                                    <input
                                        type="text"
                                        name="postal_code"
                                        value={formData.postal_code}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        País *
                                    </label>
                                    <input
                                        type="text"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    />
                                </div>

                                <div className="md:col-span-2">
                                    <label className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            name="is_default"
                                            checked={formData.is_default}
                                            onChange={handleChange}
                                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                        />
                                        <span className="text-sm text-gray-700">
                                            Establir com a adreça predeterminada per aquest tipus
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-end pt-4">
                                <button
                                    type="submit"
                                    className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                >
                                    <FaSave className="mr-2" /> Guardar
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default CreateAddress; 