import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../../Header';
import Footer from '../../../Footer';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { useAuth } from '../../../../contexts/AuthContext';
import { FaArrowLeft, FaSave, FaPlus, FaTrash } from 'react-icons/fa';

const EditSection = () => {
    const { formId, sectionId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [section, setSection] = useState({
        name: '',
        description: '',
        order: 0,
        visibility_rules: {
            enabled: false,
            field_id: null,
            operator: 'equals',
            value: null
        }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formFields, setFormFields] = useState([]);

    console.log('Params:', { formId, sectionId });

    const fetchFormFields = async () => {
        if (!formId) return;
        
        try {
            const sectionsResponse = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (!sectionsResponse.ok) {
                throw new Error('Error al carregar les seccions');
            }

            const sections = await sectionsResponse.json();
            const allFields = [];

            sections.forEach(section => {
                section.fields.forEach(field => {
                    allFields.push({
                        ...field,
                        section_name: section.name
                    });
                });
            });

            setFormFields(allFields);
        } catch (error) {
            console.error('Error carregant els camps:', error);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            if (!formId) return;
            
            try {
                setIsLoading(true);
                await fetchFormFields();

                if (!sectionId) {
                    console.log('No hi ha sectionId, sortint...');
                    setIsLoading(false);
                    return;
                }

                console.log('Fent fetch de la secció...');
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections/${sectionId}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accept': 'application/json'
                        }
                    }
                );

                console.log('Response status:', response.status);

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error al carregar la secció');
                }

                const data = await response.json();
                console.log("Dades rebudes:", data);
                
                if (!data) {
                    throw new Error('No s\'han rebut dades del servidor');
                }

                setSection({
                    name: data.name || '',
                    description: data.description || '',
                    order: data.order || 0,
                    visibility_rules: {
                        enabled: data.visibility_rules?.enabled || false,
                        field_id: data.visibility_rules?.field_id || null,
                        operator: data.visibility_rules?.operator || 'equals',
                        value: data.visibility_rules?.value || null
                    }
                });
            } catch (error) {
                console.error('Error detallat:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();

        return () => {
            console.log('Component desmuntat');
        };
    }, [formId, sectionId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        
        try {
            const url = sectionId 
                ? `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections/${sectionId}`
                : `${process.env.REACT_APP_BACKEND_URL}/api/payment-forms/${formId}/sections`;

            const response = await fetch(url, {
                method: sectionId ? 'PUT' : 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(section)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al desar la secció');
            }

            navigate(`/payments/forms/${formId}`);
        } catch (error) {
            console.error('Error detallat:', error);
            setError(error.message);
        }
    };

    const handleCancel = () => {
        navigate(`/payments/forms/${formId}`);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar la secció
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">
                                Editar secció
                            </h1>
                            <div className="flex items-center">
                                <button
                                    onClick={() => navigate(`/payments/forms/${formId}`)}
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                                >
                                    <FaArrowLeft className="mr-2" /> Tornar
                                </button>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow p-6">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Nom
                                    </label>
                                    <input
                                        type="text"
                                        value={section.name}
                                        onChange={(e) => setSection({...section, name: e.target.value})}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Descripció
                                    </label>
                                    <textarea
                                        value={section.description}
                                        onChange={(e) => setSection({...section, description: e.target.value})}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        rows="3"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Ordre
                                    </label>
                                    <input
                                        type="number"
                                        value={section.order}
                                        onChange={(e) => setSection({...section, order: parseInt(e.target.value)})}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                        min="0"
                                    />
                                </div>

                                <div>
                                    <label className="flex items-center space-x-2 mb-4">
                                        <input
                                            type="checkbox"
                                            checked={section.visibility_rules.enabled}
                                            onChange={(e) => setSection({
                                                ...section,
                                                visibility_rules: {
                                                    ...section.visibility_rules,
                                                    enabled: e.target.checked
                                                }
                                            })}
                                            className="rounded text-[#ED5B52] focus:ring-[#ED5B52]"
                                        />
                                        <span className="text-sm text-gray-700">
                                            Habilitar regles de visibilitat
                                        </span>
                                    </label>

                                    {/* Formulari de regles de visibilitat */}
                                    {section.visibility_rules.enabled && (
                                        <div className="mt-4 space-y-4 border-t pt-4">
                                            <div className="flex items-center gap-4">
                                                <select
                                                    value={section.visibility_rules.field_id ? section.visibility_rules.field_id.split('-')[1] || '' : ''}
                                                    onChange={(e) => {
                                                        setSection({
                                                            ...section,
                                                            visibility_rules: {
                                                                ...section.visibility_rules,
                                                                field_id: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                >
                                                    <option value="">Selecciona un camp</option>
                                                    {formFields.map(field => (
                                                        <option 
                                                            key={field.id} 
                                                            value={field.name}
                                                        >
                                                            {field.section_name} - {field.label}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={section.visibility_rules.operator || 'equals'}
                                                    onChange={(e) => {
                                                        setSection({
                                                            ...section,
                                                            visibility_rules: {
                                                                ...section.visibility_rules,
                                                                operator: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                >
                                                    <option value="equals">Igual a</option>
                                                    <option value="not_equals">Diferent de</option>
                                                    <option value="greater_than">Major que</option>
                                                    <option value="less_than">Menor que</option>
                                                    <option value="contains">Conté</option>
                                                </select>

                                                <input
                                                    type="text"
                                                    value={section.visibility_rules.value || ''}
                                                    onChange={(e) => {
                                                        setSection({
                                                            ...section,
                                                            visibility_rules: {
                                                                ...section.visibility_rules,
                                                                value: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                                    placeholder="Valor"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Botó de guardar */}
                                <div className="flex justify-end pt-6">
                                    <button
                                        type="submit"
                                        className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaSave className="mr-2" /> Desar canvis
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default EditSection; 