import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaTrash, FaUserPlus, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import Header from '../../Header';
import Footer from '../../Footer';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingSpinner from '../../common/LoadingSpinner';

const GroupDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, loading: authLoading } = useAuth();
    const [group, setGroup] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        is_active: true
    });

    useEffect(() => {
        if (!authLoading) {
            fetchGroupData();
            fetchAvailableCustomers();
        }
    }, [id, authLoading]);

    const fetchGroupData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Error al carregar les dades del grup');
            
            const data = await response.json();
            setGroup(data);
            setCustomers(data.customers || []);
            setFormData({
                name: data.name,
                description: data.description || '',
                is_active: data.is_active
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAvailableCustomers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customers`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Error al carregar els clients disponibles');
            
            const data = await response.json();
            setAvailableCustomers(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al actualitzar el grup');
            }

            const updatedGroup = await response.json();
            setGroup(updatedGroup);
            setIsEditing(false);
            alert('Grup actualitzat correctament');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleToggleStatus = async () => {
        try {
            const newStatus = !group.is_active;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, is_active: newStatus })
            });

            if (!response.ok) throw new Error('Error al canviar l\'estat del grup');

            const updatedGroup = await response.json();
            setGroup(updatedGroup);
            setFormData(prev => ({ ...prev, is_active: newStatus }));
        } catch (error) {
            setError(error.message);
        }
    };

    const handleAddCustomer = async () => {
        if (!selectedCustomer) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${id}/customers`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ customer_id: selectedCustomer })
            });

            if (!response.ok) throw new Error('Error al afegir el client al grup');

            fetchGroupData();
            setSelectedCustomer('');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleRemoveCustomer = async (customerId) => {
        if (!window.confirm('Estàs segur que vols eliminar aquest client del grup?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customer-groups/${id}/customers/${customerId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al eliminar el client del grup');

            fetchGroupData();
        } catch (error) {
            setError(error.message);
        }
    };

    if (authLoading || isLoading) {
        return <LoadingSpinner message="Carregant detalls del grup..." />;
    }

    if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
    if (!group) return <div className="text-center py-4">Grup no trobat</div>;

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">{formData?.name}</h1>
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => setIsEditing(!isEditing)}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 flex items-center"
                        >
                            <FaEdit className="mr-2" /> {isEditing ? 'Cancel·lar' : 'Editar'}
                        </button>
                        <button
                            onClick={handleToggleStatus}
                            className={`${
                                group.is_active ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                            } text-white px-4 py-2 rounded transition duration-200 flex items-center`}
                        >
                            {group.is_active ? <FaToggleOff className="mr-2" /> : <FaToggleOn className="mr-2" />}
                            {group.is_active ? 'Desactivar' : 'Activar'}
                        </button>
                        <button
                            onClick={() => navigate('/clients/groups')}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                        >
                            <FaArrowLeft className="mr-2" /> Tornar
                        </button>
                    </div>
                </div>

                {isEditing ? (
                    <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6 mb-8">
                        <div className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Nom del grup *
                                </label>
                                <input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    required
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Descripció
                                </label>
                                <textarea
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    rows="4"
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="bg-[#ED5B52] text-white px-6 py-2 rounded hover:bg-[#D64A41] transition duration-200"
                                >
                                    Guardar Canvis
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="bg-white rounded-lg shadow p-6 mb-8">
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-medium text-gray-700">Descripció</h3>
                                <p className="text-gray-600">{group.description || 'Sense descripció'}</p>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium text-gray-700">Estat</h3>
                                <span className={`px-2 py-1 text-sm font-semibold rounded-full ${
                                    group.is_active
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-red-100 text-red-800'
                                }`}>
                                    {group.is_active ? 'Actiu' : 'Inactiu'}
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="bg-white rounded-lg shadow p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold text-gray-800">Clients del Grup</h2>
                        <div className="flex items-center space-x-2">
                            <select
                                value={selectedCustomer}
                                onChange={(e) => setSelectedCustomer(e.target.value)}
                                className="p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                            >
                                <option value="">Selecciona un client</option>
                                {availableCustomers
                                    .filter(c => !customers.some(gc => gc.id === c.id))
                                    .map(customer => (
                                        <option key={customer.id} value={customer.id}>
                                            {customer.name}
                                        </option>
                                    ))
                                }
                            </select>
                            <button
                                onClick={handleAddCustomer}
                                disabled={!selectedCustomer}
                                className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center disabled:opacity-50"
                            >
                                <FaUserPlus className="mr-2" /> Afegir Client
                            </button>
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Nom
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Telèfon
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Accions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {customers.map((customer) => (
                                    <tr key={customer.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {customer.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {customer.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {customer.phone || '-'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right">
                                            <button
                                                onClick={() => handleRemoveCustomer(customer.id)}
                                                className="text-red-600 hover:text-red-900"
                                                title="Eliminar del grup"
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {customers.length === 0 && (
                                    <tr>
                                        <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                            No hi ha clients en aquest grup
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default GroupDetails; 