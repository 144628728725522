import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { FaArrowLeft, FaSave, FaSpinner, FaUsers } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';

const EditRole = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        slug: '',
        description: ''
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [usersCount, setUsersCount] = useState(0);

    useEffect(() => {
        fetchRole();
    }, [id]);

    const fetchRole = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/roles/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al obtenir les dades del rol');

            const data = await response.json();
            setFormData({
                name: data.name,
                slug: data.slug,
                description: data.description || ''
            });
            setUsersCount(data.users?.length || 0);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/roles/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || 'Error al actualitzar el rol');
            }

            navigate('/users/roles', {
                state: { message: 'Rol actualitzat correctament' }
            });
        } catch (err) {
            setError(err.message);
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-gray-100 flex flex-col">
                <Header />
                <main className="container mx-auto px-4 py-12 flex-grow">
                    <div className="text-center">
                        <FaSpinner className="animate-spin h-8 w-8 mx-auto text-green-500" />
                        <p className="mt-2">Carregant...</p>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-12 flex-grow">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-800">Editar Rol</h1>
                    <Link
                        to="/users/roles"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                    >
                        <FaArrowLeft className="mr-2" /> Tornar
                    </Link>
                </div>

                <div className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
                    {error && (
                        <div className="bg-red-50 text-red-500 p-4 rounded-lg mb-6">
                            {error}
                        </div>
                    )}

                    <div className="mb-6 flex items-center text-gray-600 bg-gray-50 p-4 rounded-lg">
                        <FaUsers className="mr-2" />
                        <span>Aquest rol està assignat a {usersCount} usuari{usersCount !== 1 ? 's' : ''}</span>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                                Nom del Rol
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                placeholder="Introdueix el nom del rol"
                            />
                        </div>

                        <div>
                            <label htmlFor="slug" className="block text-sm font-medium text-gray-700 mb-1">
                                Identificador (Slug)
                            </label>
                            <input
                                type="text"
                                id="slug"
                                name="slug"
                                value={formData.slug}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 bg-gray-50"
                                placeholder="identificador-del-rol"
                                readOnly={formData.slug === 'admin'}
                            />
                            {formData.slug === 'admin' && (
                                <p className="mt-1 text-sm text-gray-500">
                                    L'identificador del rol administrador no es pot modificar
                                </p>
                            )}
                        </div>

                        <div>
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                                Descripció
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                rows="4"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                placeholder="Descriu les funcions i permisos d'aquest rol"
                            ></textarea>
                        </div>

                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={() => navigate('/users/roles')}
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition duration-200"
                            >
                                Cancel·lar
                            </button>
                            <button
                                type="submit"
                                disabled={saving || formData.slug === 'admin'}
                                className={`px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-200 flex items-center ${
                                    (saving || formData.slug === 'admin') ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            >
                                {saving ? (
                                    <>
                                        <FaSpinner className="animate-spin mr-2" />
                                        Desant...
                                    </>
                                ) : (
                                    <>
                                        <FaSave className="mr-2" />
                                        Desar Canvis
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default EditRole; 