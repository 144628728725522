import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from './common/LoadingSpinner';

const Clients = () => {
  const { user, loading: authLoading } = useAuth();

  if (authLoading) {
    return <LoadingSpinner message="Carregant la gestió de clients..." />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Gestió de Clients</h1>
          <Link
            to="/dashboard"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Tornar
          </Link>
        </div>

        {/* Blocs principals */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {/* Clients */}
          <ClientCard 
            title="Clients"
            description="Gestiona els teus clients i les seves dades personals"
            createLink="/clients/create"
            manageLink="/clients/manage"
          />

          {/* Grups */}
          <ClientCard 
            title="Grups"
            description="Organitza els teus clients en grups per una millor gestió"
            createLink="/clients/groups/create"
            manageLink="/clients/groups"
          />

          {/* Campanyes */}
          <ClientCard 
            title="Campanyes"
            description="Organitzar esdeveniments i activitats per captar nous clients i fidelitzar els existents"
            createLink="/clients/campaigns/create"
            manageLink="/clients/campaigns/manage"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

// Component reutilitzable per a les targetes
const ClientCard = ({ title, description, createLink, manageLink }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
    <div className="flex-grow">
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
    </div>
    <div className="mt-auto">
      <hr className="my-4 border-gray-200" />
      <div className="flex space-x-4">
        <Link 
          to={createLink}
          className="flex-1 text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
        >
          Crear
        </Link>
        <Link 
          to={manageLink}
          className="flex-1 text-center border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-green-500 hover:text-white transition duration-200"
        >
          Administrar
        </Link>
      </div>
    </div>
  </div>
);

export default Clients; 