import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaCheck, FaTimes, FaPlus, FaTrashRestore, FaTrash } from 'react-icons/fa';
import Header from '../Header';
import Footer from '../Footer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const OnlineShopDetails = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [showDeleted, setShowDeleted] = useState(false);
    const [shop, setShop] = useState(null);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [error, setError] = useState(null);
    const [productsError, setProductsError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editForm, setEditForm] = useState({
        name: '',
        description: '',
        payment_gateway_id: '',
        primary_color: '',
        currency: '',
        status: '',
        configuration: {
            contact_email: '',
            shipping_policy: '',
            terms_conditions: ''
        }
    });
    const [paymentGateways, setPaymentGateways] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesError, setCategoriesError] = useState(null);
    const [isLoadingCategories, setIsLoadingCategories] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editCategoryForm, setEditCategoryForm] = useState({
        name: '',
        description: '',
        parent_id: ''
    });
    const [showDeletedCategories, setShowDeletedCategories] = useState(false);
    const [showDeletedProducts, setShowDeletedProducts] = useState(false);
    const [deletedCategories, setDeletedCategories] = useState([]);
    const [deletedProducts, setDeletedProducts] = useState([]);

    useEffect(() => {
        fetchShopDetails();
        fetchProducts();
        fetchCategories();
        fetchPaymentGateways();
    }, [id]);

    const fetchShopDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els detalls de la botiga');

            const data = await response.json();
            setShop(data);
            setEditForm({
                name: data.name || '',
                description: data.description || '',
                payment_gateway_id: data.payment_gateway_id || '',
                primary_color: data.primary_color || '#ED5B52',
                currency: data.currency || 'EUR',
                status: data.status || 'active',
                configuration: {
                    contact_email: data.configuration?.contact_email || '',
                    shipping_policy: data.configuration?.shipping_policy || '',
                    terms_conditions: data.configuration?.terms_conditions || ''
                }
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProducts = async () => {
        try {
            setIsLoadingProducts(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/products`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar els productes');

            const data = await response.json();
            setProducts(data);
        } catch (error) {
            setProductsError(error.message);
        } finally {
            setIsLoadingProducts(false);
        }
    };

    const fetchPaymentGateways = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar les passarel·les de pagament');

            const data = await response.json();
            setPaymentGateways(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories?with_count=true`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) throw new Error('Error al carregar les categories');

            const data = await response.json();
            setCategories(data);
        } catch (error) {
            setCategoriesError(error.message);
        } finally {
            setIsLoadingCategories(false);
        }
    };

    const fetchDeletedCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories/deleted`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al carregar les categories eliminades');
            const data = await response.json();
            setDeletedCategories(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchDeletedProducts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/products/deleted`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (!response.ok) throw new Error('Error al carregar els productes eliminats');
            const data = await response.json();
            setDeletedProducts(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editForm)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al actualitzar la botiga');
            }

            await fetchShopDetails();
            setIsEditing(false);
            alert('Botiga actualitzada correctament');
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const handleToggleStatus = async () => {
        try {
            const newStatus = shop.status === 'active' ? 'inactive' : 'active';
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/toggle-status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: newStatus })
            });

            if (!response.ok) throw new Error('Error al canviar l\'estat de la botiga');

            await fetchShopDetails();
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const handleDelete = async (shopId) => {
        if (window.confirm('Estàs segur que vols eliminar aquesta botiga?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${shopId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.ok) {
                    alert('Botiga eliminada correctament');
                    window.location.href = '/online-shops';
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error al eliminar la botiga');
                }
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };

    const handleEditCategory = (categoryId) => {
        const category = categories.find(c => c.id === categoryId);
        if (category) {
            setEditingCategory(category);
            setEditCategoryForm({
                name: category.name,
                description: category.description || '',
                parent_id: category.parent_id || ''
            });
            setIsEditModalOpen(true);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        if (window.confirm('Estàs segur que vols eliminar aquesta categoria?')) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories/${categoryId}`, 
                    {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error al eliminar la categoria');
                }

                await fetchCategories(); // Recarreguem les categories
                alert('Categoria eliminada correctament');
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };

    const handleSubmitEditCategory = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories/${editingCategory.id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editCategoryForm)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al actualitzar la categoria');
            }

            await fetchCategories();
            setIsEditModalOpen(false);
            alert('Categoria actualitzada correctament');
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const handleRestoreCategory = async (categoryId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/online-shops/${id}/categories/${categoryId}/restore`,
                {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error al restaurar la categoria');
            }

            // Actualitzem les llistes
            await fetchCategories();
            await fetchDeletedCategories();
            alert('Categoria restaurada correctament');
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const handleRestoreProduct = async (productId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/online-shop-products/${productId}/restore`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (!response.ok) throw new Error('Error al restaurar el producte');

            // Actualitzem les llistes
            await fetchProducts();
            await fetchDeletedProducts();
            alert('Producte restaurat correctament');
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };

    const handleDeleteProduct = async (productId) => {
        if (window.confirm('Estàs segur que vols eliminar aquest producte?')) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/online-shop-products/${productId}`,
                    {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );

                if (!response.ok) throw new Error('Error al eliminar el producte');

                // Actualitzem les llistes
                await fetchProducts();
                alert('Producte eliminat correctament');
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };

    // Formulari d'edició
    const renderEditForm = () => (
        <form onSubmit={handleSubmitEdit} className="space-y-6">
            {/* Informació bàsica */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Informació bàsica</h3>
                
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Nom de la botiga
                    </label>
                    <input
                        type="text"
                        value={editForm.name}
                        onChange={(e) => setEditForm(prev => ({ ...prev, name: e.target.value }))}
                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Descripció
                    </label>
                    <textarea
                        value={editForm.description}
                        onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        rows="3"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Passarel·la de pagament
                    </label>
                    <select
                        value={editForm.payment_gateway_id}
                        onChange={(e) => setEditForm(prev => ({ ...prev, payment_gateway_id: e.target.value }))}
                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        required
                    >
                        <option value="">Selecciona una passarel·la</option>
                        {paymentGateways?.map(gateway => (
                            <option key={gateway.id} value={gateway.id}>
                                {gateway.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Configuració */}
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Configuració</h3>
                
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Email de contacte
                    </label>
                    <input
                        type="email"
                        value={editForm.configuration.contact_email}
                        onChange={(e) => setEditForm(prev => ({
                            ...prev,
                            configuration: {
                                ...prev.configuration,
                                contact_email: e.target.value
                            }
                        }))}
                        className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                        required
                    />
                </div>
            </div>

            <div className="flex justify-end space-x-3">
                <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                >
                    Cancel·lar
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64A41]"
                >
                    Guardar canvis
                </button>
            </div>
        </form>
    );

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            <Header />
            <main className="container mx-auto px-4 py-8">
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-red-500 text-xl font-semibold mb-2">
                                Error al carregar la botiga
                            </div>
                            <p className="text-gray-600">{error}</p>
                        </div>
                    </div>
                ) : shop ? (
                    <>
                        <div className="flex justify-between items-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-800">Detalls de la botiga online</h1>
                            <Link
                                to="/online-shops"
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
                            >
                                <FaArrowLeft className="mr-2" /> Tornar
                            </Link>
                        </div>

                        {/* Detalls de la botiga */}
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            {isEditing ? (
                                renderEditForm()
                            ) : (
                                <div className="space-y-6">
                                    <div className="flex justify-between items-start">
                                        <h2 className="text-xl font-semibold">Detalls de la botiga</h2>
                                        <div className="flex gap-2">
                                            <button
                                                onClick={() => setIsEditing(true)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
                                            >
                                                <FaEdit className="inline mr-2" /> Editar
                                            </button>
                                            <button
                                                onClick={handleToggleStatus}
                                                className={`flex items-center px-4 py-2 rounded transition duration-200 ${
                                                    shop.status === 'active'
                                                        ? 'bg-red-500 hover:bg-red-600 text-white'
                                                        : 'bg-green-500 hover:bg-green-600 text-white'
                                                }`}
                                            >
                                                {shop.status === 'active' ? (
                                                    <>
                                                        <FaTimes className="mr-2" /> Desactivar
                                                    </>
                                                ) : (
                                                    <>
                                                        <FaCheck className="mr-2" /> Activar
                                                    </>
                                                )}
                                            </button>
                                            <button
                                                onClick={() => handleDelete(shop.id)}
                                                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200"
                                            >
                                                <FaTrash className="inline mr-2" /> Eliminar
                                            </button>
                                        </div>
                                    </div>

                                    {/* Resta del contingut dels detalls... */}
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Informació General</h3>
                                            <dl className="space-y-2">
                                                <div>
                                                    <dt className="font-medium">Nom:</dt>
                                                    <dd>{shop.name}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Descripció:</dt>
                                                    <dd>{shop.description || 'No especificada'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Passarel·la de pagament:</dt>
                                                    <dd>{shop.payment_gateway?.name || 'No especificada'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Estat:</dt>
                                                    <dd>
                                                        <span className={`px-2 py-1 rounded-full text-sm ${
                                                            shop.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                        }`}>
                                                            {shop.status === 'active' ? 'Activa' : 'Inactiva'}
                                                        </span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>

                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Configuració</h3>
                                            <dl className="space-y-2">
                                                <div>
                                                    <dt className="font-medium">Email de contacte:</dt>
                                                    <dd>{shop.configuration?.contact_email || 'No especificat'}</dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium">Moneda:</dt>
                                                    <dd>{shop.currency}</dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Secció de Categories */}
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Categories</h2>
                                <div className="flex space-x-4">
                                    <button
                                        onClick={() => {
                                            setShowDeletedCategories(!showDeletedCategories);
                                            if (!showDeletedCategories) fetchDeletedCategories();
                                        }}
                                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200 flex items-center"
                                    >
                                        <FaTrashRestore className="mr-2" /> 
                                        {showDeletedCategories ? 'Mostrar actives' : 'Mostrar eliminades'}
                                    </button>
                                    <Link
                                        to={`/online-shops/${shop.id}/categories/create`}
                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaPlus className="mr-2" /> Nova categoria
                                    </Link>
                                </div>
                            </div>

                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Nom
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Descripció
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Categoria pare
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Productes
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Accions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {(showDeletedCategories ? deletedCategories : categories).map((category) => (
                                            <tr key={category.id}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {category.name}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-500">
                                                        {category.description || 'Sense descripció'}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        {category.parent?.name || 'Categoria principal'}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        {category.products_count || 0}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    {showDeletedCategories ? (
                                                        // Accions per categories eliminades
                                                        <div className="flex justify-end space-x-2">
                                                            <button
                                                                onClick={() => handleRestoreCategory(category.id)}
                                                                className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors duration-200"
                                                                title="Restaurar"
                                                            >
                                                                <FaTrashRestore />
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        // Accions per categories actives
                                                        <div className="flex justify-end space-x-2">
                                                            <Link
                                                                to={`/online-shops/${id}/categories/${category.id}/edit`}
                                                                className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                title="Editar"
                                                            >
                                                                <FaEdit />
                                                            </Link>
                                                            <button
                                                                onClick={() => handleDeleteCategory(category.id)}
                                                                className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200"
                                                                title="Eliminar"
                                                            >
                                                                <FaTrash />
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Secció de Productes */}
                        <div className="bg-white rounded-lg shadow p-6 mb-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Productes</h2>
                                <div className="flex space-x-4">
                                    <button
                                        onClick={() => {
                                            setShowDeletedProducts(!showDeletedProducts);
                                            if (!showDeletedProducts) fetchDeletedProducts();
                                        }}
                                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200 flex items-center"
                                    >
                                        <FaTrashRestore className="mr-2" /> 
                                        {showDeletedProducts ? 'Mostrar actius' : 'Mostrar eliminats'}
                                    </button>
                                    <Link
                                        to={`/online-shops/${id}/products/create`}
                                        className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200 flex items-center"
                                    >
                                        <FaPlus className="mr-2" /> Afegir producte
                                    </Link>
                                </div>
                            </div>

                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Imatge
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Nom
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Preu
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Stock
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Categories
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Estat
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Accions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {(showDeletedProducts ? deletedProducts : products).map((product) => {
                                            const pivot = product.pivot;
                                            const price = Number(pivot?.price || product.price || 0);
                                            const stock = parseInt(pivot?.stock) || 0;
                                            return (
                                                <tr key={product.id}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="h-10 w-10">
                                                            {product.images && product.images.length > 0 ? (
                                                                <img
                                                                    src={product.images[0]}
                                                                    alt={product.name}
                                                                    className="h-10 w-10 object-cover rounded-md"
                                                                />
                                                            ) : (
                                                                <div className="h-10 w-10 bg-gray-200 rounded-md flex items-center justify-center">
                                                                    <span className="text-gray-500 text-xs">No img</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900">{product.name}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex flex-col">
                                                            <div className="text-sm text-gray-900">
                                                                {price.toFixed(2)}€
                                                            </div>
                                                            {pivot?.price !== product.price && (
                                                                <div className="text-xs text-gray-400">
                                                                    Preu base: {Number(product.price).toFixed(2)}€
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm text-gray-900">
                                                            {Number(stock)}
                                                            {Boolean(pivot?.allow_backorders) && (
                                                                <span className="ml-1 text-xs text-blue-600">(Comandes pendents permeses)</span>
                                                            )}
                                                            {Number(stock) <= Number(pivot?.minimum_stock ?? 0) && (
                                                                <span className="ml-1 text-xs text-red-600">(Stock baix)</span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <div className="flex flex-wrap gap-1">
                                                            {product.categories && product.categories.map(category => (
                                                                <span
                                                                    key={category.id}
                                                                    className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
                                                                >
                                                                    {category.name}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 py-1 rounded-full text-xs ${
                                                            product.status === 'active' 
                                                                ? 'bg-green-100 text-green-800' 
                                                                : 'bg-red-100 text-red-800'
                                                        }`}>
                                                            {product.status === 'active' ? 'Actiu' : 'Inactiu'}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        {showDeletedProducts ? (
                                                            // Accions per productes eliminats
                                                            <div className="flex justify-end space-x-2">
                                                                <button
                                                                    onClick={() => handleRestoreProduct(product.id)}
                                                                    className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors duration-200"
                                                                    title="Restaurar"
                                                                >
                                                                    <FaTrashRestore />
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            // Accions per productes actius
                                                            <div className="flex justify-end space-x-2">
                                                                <Link
                                                                    to={`/online-shops/${id}/products/${product.id}/edit`}
                                                                    className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                                                                    title="Editar"
                                                                >
                                                                    <FaEdit />
                                                                </Link>
                                                                <button
                                                                    onClick={() => handleDeleteProduct(product.id)}
                                                                    className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200"
                                                                    title="Eliminar"
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="bg-white rounded-lg shadow p-8">
                        <div className="text-center">
                            <div className="text-gray-500 text-xl font-semibold">
                                No s'ha trobat la botiga
                            </div>
                        </div>
                    </div>
                )}
            </main>
            <Footer />

            {/* Modal d'edició de categoria */}
            {isEditModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-semibold">Editar Categoria</h3>
                            <button 
                                onClick={() => setIsEditModalOpen(false)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <FaTimes />
                            </button>
                        </div>
                        
                        <form onSubmit={handleSubmitEditCategory} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Nom
                                </label>
                                <input
                                    type="text"
                                    value={editCategoryForm.name}
                                    onChange={(e) => setEditCategoryForm(prev => ({
                                        ...prev,
                                        name: e.target.value
                                    }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Descripció
                                </label>
                                <textarea
                                    value={editCategoryForm.description}
                                    onChange={(e) => setEditCategoryForm(prev => ({
                                        ...prev,
                                        description: e.target.value
                                    }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                    rows="3"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Categoria pare
                                </label>
                                <select
                                    value={editCategoryForm.parent_id}
                                    onChange={(e) => setEditCategoryForm(prev => ({
                                        ...prev,
                                        parent_id: e.target.value
                                    }))}
                                    className="w-full p-2 border rounded focus:ring-[#ED5B52] focus:border-[#ED5B52]"
                                >
                                    <option value="">Categoria principal</option>
                                    {categories
                                        .filter(c => c.id !== editingCategory.id)
                                        .map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="flex justify-end space-x-3 mt-4">
                                <button
                                    type="button"
                                    onClick={() => setIsEditModalOpen(false)}
                                    className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                                >
                                    Cancel·lar
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-[#ED5B52] text-white rounded-md hover:bg-[#D64A41]"
                                >
                                    Guardar canvis
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OnlineShopDetails; 