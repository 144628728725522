import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaTrash, FaPlus, FaToggleOn, FaToggleOff, FaSearch, FaArrowLeft } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';
import LoadingSpinner from './common/LoadingSpinner';

function Alert({ errors, message, type }) {
  const alertStyles = {
    error: 'bg-red-50 border-red-500 text-red-700',
    success: 'bg-green-50 border-green-500 text-green-700',
    warning: 'bg-yellow-50 border-yellow-500 text-yellow-700'
  };

  return (
    <div className={`border-l-4 p-3 mb-4 ${alertStyles[type]} text-sm`} role="alert">
      <h3 className="font-semibold text-base mb-1">
        {type === 'error' ? 'Hi ha hagut alguns errors:' : type === 'success' ? 'Èxit!' : 'Avís!'}
      </h3>
      {Array.isArray(errors) ? (
        <ul className="list-disc list-inside">
          {errors.map((error, index) => (
            <li key={index} className="mb-1">{error}</li>
          ))}
        </ul>
      ) : (
        <p>{message || errors}</p>
      )}
    </div>
  );
}

function PaymentGateways() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const [gateways, setGateways] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentGateway, setCurrentGateway] = useState(null);
  const [newGateway, setNewGateway] = useState({
    name: '',
    payment_gateway_type_id: '',
    configuration: {},
    is_active: true
  });
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [gatewayTypes, setGatewayTypes] = useState([]);
  const [alert, setAlert] = useState(null);
  const [configurationText, setConfigurationText] = useState('');
  const [jsonError, setJsonError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!authLoading) {
      fetchGateways();
      fetchGatewayTypes();
    }
  }, [authLoading]);

  const fetchGateways = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setGateways(Array.isArray(data) ? data : []);
      } else {
        throw new Error('Error fetching gateways');
      }
    } catch (error) {
      console.error('Error:', error);
      setGateways([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGatewayTypes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateway-types`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setGatewayTypes(Array.isArray(data) ? data : []);
      } else {
        throw new Error('Error fetching gateway types');
      }
    } catch (error) {
      console.error('Error:', error);
      setGatewayTypes([]);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    // Implementa la lògica de cerca aquí
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGateway(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleConfigChange = (e) => {
    const { name, value } = e.target;
    setNewGateway(prev => ({
      ...prev,
      configuration: {
        ...prev.configuration,
        [name]: value
      }
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!newGateway.name) formErrors.name = "El nom és obligatori";
    if (!newGateway.payment_gateway_type_id) formErrors.payment_gateway_type_id = "El tipus és obligatori";
    // Afegeix més validacions segons els camps necessaris
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleConfigurationChange = (e) => {
    const text = e.target.value;
    setConfigurationText(text);
    
    if (text.trim() === '') {
      // Si el campo está vacío, establecemos un objeto vacío como configuración
      setNewGateway(prev => ({...prev, configuration: {}}));
      setJsonError(null);
      // Opcionalmente, podrías establecer un valor por defecto en el campo
      setConfigurationText('{}');
    } else {
      try {
        const parsedJson = JSON.parse(text);
        setJsonError(null);
        setNewGateway(prev => ({...prev, configuration: parsedJson}));
      } catch (error) {
        setJsonError('JSON inválido');
        // No actualizamos la configuración si el JSON es inválido
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (jsonError) {
      setAlert({
        message: 'Por favor, corrige el JSON antes de enviar',
        type: 'error'
      });
      return;
    }
    setAlert(null);

    const url = isEditMode 
      ? `${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways/${currentGateway.id}`
      : `${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways`;
    const method = isEditMode ? 'PUT' : 'POST';
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newGateway)
      });

      const responseText = await response.text();
      console.log('Respuesta completa:', responseText);

      if (response.ok) {
        let data;
        try {
          data = JSON.parse(responseText);
          setIsModalOpen(false);
          fetchGateways();
          setNewGateway({
            name: '',
            payment_gateway_type_id: '',
            configuration: {},
            is_active: true
          });
          setAlert({ 
            message: isEditMode ? 'Passarel·la actualitzada amb èxit' : 'Passarel·la afegida amb èxit', 
            type: 'success' 
          });
          setIsEditMode(false);
          setCurrentGateway(null);
        } catch (parseError) {
          console.error('Error al analizar la respuesta JSON:', parseError);
          setAlert({
            message: 'Error al procesar la respuesta del servidor',
            type: 'error',
            errors: [
              `Respuesta no válida: ${responseText.substring(0, 500)}...`,
              `Error de análisis: ${parseError.toString()}`
            ]
          });
        }
      } else {
        // Manejar errores
        setAlert({
          message: 'Error al procesar la solicitud',
          type: 'error',
          errors: [responseText]
        });
      }
    } catch (error) {
      console.error('Error de red:', error);
      setAlert({ 
        message: `Error de xarxa: ${error.message}`, 
        type: 'error',
        errors: [error.toString()]
      });
    }
  };

  const toggleGatewayStatus = async (id, currentStatus) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ is_active: !currentStatus })
      });

      if (response.ok) {
        fetchGateways();
      } else {
        throw new Error('Error toggling gateway status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteGateway = async (id) => {
    if (window.confirm('Estàs segur que vols eliminar aquesta passarel·la?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment-gateways/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          fetchGateways();
        } else {
          throw new Error('Error deleting gateway');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const openEditModal = (gateway) => {
    resetFormState();
    setCurrentGateway(gateway);
    setNewGateway({
      name: gateway.name,
      payment_gateway_type_id: gateway.payment_gateway_type_id,
      configuration: gateway.configuration,
      is_active: gateway.is_active
    });
    setConfigurationText(JSON.stringify(gateway.configuration || {}, null, 2));
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const openAddModal = () => {
    resetFormState();
    setIsEditMode(false);
    setCurrentGateway(null);
    setIsModalOpen(true);
  };

  const resetFormState = () => {
    setNewGateway({
      name: '',
      payment_gateway_type_id: '',
      configuration: {},
      is_active: true
    });
    setConfigurationText('{}');
    setJsonError(null);
    setAlert(null);
  };

  if (authLoading || isLoading) {
    return <LoadingSpinner message="Carregant passarel·les de pagament..." />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <main className="container mx-auto px-4 py-12 flex-grow">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Gestió de Passarel·les de Pagament</h1>
          <Link
            to="/dashboard"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Tornar
          </Link>
        </div>

        <div className="flex justify-between items-center mb-6">
          <button
            onClick={openAddModal}
            className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200"
          >
            <FaPlus className="inline-block mr-2" /> Nova Passarel·la
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <table className="w-full">
            <thead>
              <tr className="text-left">
                <th className="pb-2">Nom</th>
                <th className="pb-2">Tipus</th>
                <th className="pb-2">Estat</th>
                <th className="pb-2">Accions</th>
              </tr>
            </thead>
            <tbody>
              {gateways.map((gateway) => {
                const gatewayType = gatewayTypes.find(type => type.id === gateway.payment_gateway_type_id);
                return (
                  <tr key={gateway.id}>
                    <td className="py-2">{gateway.name}</td>
                    <td className="py-2">
                      {gatewayType ? gatewayType.name : 'Tipo no especificado'}
                    </td>
                    <td className="py-2">
                      <button
                        onClick={() => toggleGatewayStatus(gateway.id, gateway.is_active)}
                        className={`text-2xl ${gateway.is_active ? 'text-green-500' : 'text-red-500'}`}
                      >
                        {gateway.is_active ? <FaToggleOn /> : <FaToggleOff />}
                      </button>
                    </td>
                    <td className="py-2">
                      <div className="flex space-x-2">
                        <button 
                          onClick={() => openEditModal(gateway)}
                          className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                        >
                          <FaEye />
                        </button>
                        <button 
                          onClick={() => deleteGateway(gateway.id)} 
                          className="bg-[#ED5B52] text-white p-2 rounded-full hover:bg-[#D64A41] transition-colors duration-200"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="relative p-8 border w-full max-w-xl shadow-lg rounded-md bg-white">
            <h3 className="text-2xl font-bold mb-6 text-center">
              {isEditMode ? 'Editar Passarel·la' : 'Afegir Nova Passarel·la'}
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Nom
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={newGateway.name}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#ED5B52]"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                  Tipus
                </label>
                <select
                  id="type"
                  name="payment_gateway_type_id"
                  value={newGateway.payment_gateway_type_id}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#ED5B52]"
                  required
                >
                  <option value="">Selecciona un tipus</option>
                  {gatewayTypes.map(type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="configuration">
                  Configuració (JSON)
                </label>
                <textarea
                  id="configuration"
                  name="configuration"
                  value={configurationText}
                  onChange={handleConfigurationChange}
                  className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 ${
                    jsonError ? 'border-red-500 focus:ring-red-500' : 'focus:ring-[#ED5B52]'
                  }`}
                  rows="4"
                  required
                />
                {jsonError && (
                  <p className="text-red-500 text-xs italic">{jsonError}</p>
                )}
              </div>
              <div className="mb-6">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="is_active"
                    checked={newGateway.is_active}
                    onChange={(e) => setNewGateway(prev => ({...prev, is_active: e.target.checked}))}
                    className="mr-2"
                  />
                  <span className="text-gray-700 text-sm font-bold">Actiu</span>
                </label>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-[#ED5B52] hover:bg-[#D64A41] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-200"
                >
                  {isEditMode ? 'Actualitzar' : 'Afegir'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setIsEditMode(false);
                    setCurrentGateway(null);
                    resetFormState();
                  }}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-200"
                >
                  Cancel·lar
                </button>
              </div>
            </form>
            {alert && (
              <div className="mt-4">
                <Alert errors={alert.errors} message={alert.message} type={alert.type} />
              </div>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default PaymentGateways;
