import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const generateProductKey = (productId, attributes) => {
        if (!attributes || Object.keys(attributes).length === 0) {
            return productId.toString();
        }

        const attributesKey = Object.entries(attributes)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([name, attr]) => `${name}:${attr.value}`)
            .join('|');

        return `${productId}-${attributesKey}`;
    };

    const addToCart = (product, quantity) => {
        setCart(currentCart => {
            const productKey = generateProductKey(product.id, product.selectedAttributes);

            const existingItemIndex = currentCart.findIndex(item => {
                const itemKey = generateProductKey(item.id, item.selectedAttributes);
                return itemKey === productKey;
            });

            if (existingItemIndex !== -1) {
                const newCart = [...currentCart];
                newCart[existingItemIndex] = {
                    ...newCart[existingItemIndex],
                    quantity: newCart[existingItemIndex].quantity + quantity,
                    finalPrice: product.finalPrice || product.pivot.price,
                    selectedAttributes: product.selectedAttributes
                };
                return newCart;
            }

            return [...currentCart, {
                ...product,
                quantity,
                finalPrice: product.finalPrice || product.pivot.price,
                selectedAttributes: product.selectedAttributes
            }];
        });
    };

    const removeFromCart = (productId, attributes = null) => {
        setCart(currentCart => {
            const productKey = generateProductKey(productId, attributes);
            return currentCart.filter(item => {
                const itemKey = generateProductKey(item.id, item.selectedAttributes);
                return itemKey !== productKey;
            });
        });
    };

    const updateQuantity = (productId, quantity, attributes = null) => {
        setCart(currentCart => {
            return currentCart.map(item => {
                const productKey = generateProductKey(productId, attributes);
                const itemKey = generateProductKey(item.id, item.selectedAttributes);
                
                if (productKey === itemKey) {
                    return { ...item, quantity };
                }
                return item;
            });
        });
    };

    const getTotal = () => {
        return cart.reduce((sum, item) => {
            const itemPrice = parseFloat(item.finalPrice);
            return sum + (itemPrice * item.quantity);
        }, 0);
    };

    const getItemsCount = () => {
        return cart.reduce((sum, item) => sum + item.quantity, 0);
    };

    const clearCart = () => {
        setCart([]);
        localStorage.removeItem('cart');
    };

    return (
        <CartContext.Provider value={{
            cart,
            isOpen,
            setIsOpen,
            addToCart,
            removeFromCart,
            updateQuantity,
            clearCart,
            getTotal,
            getItemsCount
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext); 