import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import logo from '../resources/iconsmall.png';

const Header = () => {
  const { user, loading, logout } = useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al tancar la sessió:', error);
      navigate('/login');
    }
  };

  if (loading) {
    return null;
  }

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length >= 3) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payments/search?term=${term}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setSearchResults(data.data || []);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error en la búsqueda:', error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleResultClick = () => {
    setShowResults(false);
  };

  const formatFrequency = (frequency) => {
    const frequencies = {
      'daily': 'Diari',
      'weekly': 'Setmanal',
      'bi-weekly': 'Quinzenal',
      'monthly': 'Mensual',
      'bi-monthly': 'Bimestral',
      'quarterly': 'Trimestral',
      'semi-annually': 'Semestral',
      'annually': 'Anual'
    };
    return frequencies[frequency] || '';
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Sense data';
    const date = new Date(dateString);
    // Comprovar si la data és vàlida
    if (isNaN(date.getTime())) return 'Data invàlida';
    return date.toLocaleDateString('ca-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <Link to="/dashboard" className="flex items-center">
          <img src={logo} alt="Logotip" className="h-10 w-auto" />
        </Link>
        
        {user && (
          <>
            <div className="flex-grow mx-4 relative">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Cerca pagaments..."
                  value={searchTerm}
                  onChange={handleSearch}
                  onFocus={() => setShowResults(true)}
                  className="w-full p-2 pl-8 pr-4 rounded border focus:outline-none focus:ring-2 focus:ring-[#ED5B52]"
                />
                <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
              {searchResults.length > 0 && showResults && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-y-auto">
                  {searchResults.map((payment) => (
                    <Link 
                      key={payment.id} 
                      to={`/payments/${payment.id}`}
                      onClick={handleResultClick}
                      className="block p-2 hover:bg-gray-100"
                    >
                      <div className="flex justify-between items-center">
                        <span>{payment.name}</span>
                        <span>{payment.amount}€</span>
                      </div>
                      <div className="text-sm text-gray-500">
                        {payment.customer_name || payment.customer_email}
                        {' - '}
                        {formatDate(payment.start_date)}
                        {' - '}
                        {payment.payment_type && (
                          <span className="mr-2">
                            {payment.payment_type}
                            {payment.frequency && 
                              ` (${formatFrequency(payment.frequency)})`
                            }
                          </span>
                        )}
                        <span className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          payment.status === 'paid' ? 'bg-green-100 text-green-800' : 
                          payment.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                          payment.status === 'paused' ? 'bg-blue-100 text-blue-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {payment.status === 'paid' ? 'Pagat' :
                           payment.status === 'pending' ? 'Pendent' :
                           payment.status === 'paused' ? 'Pausat' :
                           'Eliminat'}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
            
            <div className="flex items-center gap-4">
              <span className="text-gray-700">
                {user.name}
              </span>
              <button
                onClick={handleLogout}
                className="bg-[#ED5B52] text-white px-4 py-2 rounded hover:bg-[#D64A41] transition duration-200"
              >
                Tancar Sessió
              </button>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header; 